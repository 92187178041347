import React, { Fragment, useState, useContext } from 'react';
import { AutoComplete } from 'antd';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';

// 整理成 Ant Design 的結構
const handleMemberList = (data) => data.reduce((acc, {
    departmentName,
    groupName,
    userId,
    nameChinese,
}) => {

    acc.push({
        label: `${nameChinese}@${groupName} (${departmentName})`,
        value: `${String(userId)}_${nameChinese}_${groupName}_${departmentName}`,
    });

    return acc;

}, []);

const ToDoListForm = () => {

    // Context
    const {
        allMembers,
        currEvent,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        filterAssigneeOpts: { result, isResponse },
        createToDoList,
        updateToDoList,
        getFilterAssigneeOpts,
        projectDispatch,
    } = useContext(ProjectContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // State
    const [selected, setSelected] = useState({
        assignee: (formStorageData?.assigneeId ? `${formStorageData.assigneeId}_${formStorageData.assignee}` : '') ?? '',
    });

    // 隱藏 Modal
    const hideModal = () => lightboxDispatch({ type: 'HIDE' });

    // 過濾搜尋 (技能、特質、工具過濾)
    const btnClickFilterAssignee = () => {

        getFilterAssigneeOpts();
        formStorageDispatch({ type: 'COLLECT', payload: { ...formStorageData, prevStep: currEvent } });
        lightboxDispatch({ type: 'SHOW', currEvent: 'filterAssignee' });

    };

    // 還原
    const btnResetFilter = () => {

        setSelected({ assignee: '' });
        projectDispatch({ type: 'filter_assignee_reset', payload: false });

    };

    // Autocomplete change 事件
    const handleChangeOption = (value) => {

        // 給畫面用
        setSelected({
            ...selected,
            assignee: value,
        });

    };

    // Autocomplete filter 事件
    const handleFilterOption = (inputVal, option) => {

        const regex = new RegExp(inputVal);
        return regex.test(option.value);

    };

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        reqData = {
            ...reqData,
            id: formStorageData.id,
            assignee: selected.assignee ? +selected.assignee.split('_')[0] : null,
        };

        // 已有成本不允許更改指派人
        if (formStorageData.hasCost) delete reqData.assignee;

        if (currEvent === 'updateToDoList') updateToDoList(reqData);
        else createToDoList(reqData);

    };

    const { name, hasCost } = formStorageData;

    return (

        <form
            className="form-task"
            onSubmit={handleSubmit(handleReqData)}
        >
            <FormRow
                labelTitle="項目名稱"
                required={true}
                error={errors.name && true}
                {...errors.name?.type === 'maxLength' && { errorMesg: '含空格不得超過 255 個字' }}
            >
                <input
                    type="text"
                    name="name"
                    defaultValue={name}
                    ref={register({ required: true, maxLength: 255 })}
                    placeholder="限制 255 個字內"
                />
            </FormRow>

            <div className={`row ${hasCost ? 'has-cost' : ''}`}>
                <div className="title">
                    指派人
                    {
                        hasCost ? <span className="warning-text">(已有成本不允許更改)</span> : (

                            <Fragment>
                                <span
                                    className="filter-search"
                                    onClick={btnClickFilterAssignee}
                                >(過濾指派人)</span>
                                <span
                                    className="filter-reset"
                                    onClick={btnResetFilter}
                                >還原</span>
                            </Fragment>

                        )
                    }
                </div>
                <div className="field noBorder">
                    <AutoComplete
                        allowClear={true}
                        placeholder="可輸入姓名、組別或部門"
                        options={handleMemberList(isResponse ? result : allMembers)}
                        value={(selected.assignee === '') ? '' : selected.assignee?.split('_')[1]}
                        disabled={hasCost}
                        onChange={handleChangeOption}
                        filterOption={handleFilterOption}
                    />
                </div>
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );
};

export default ToDoListForm;
