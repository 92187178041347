import React from 'react';
import PropTypes from 'prop-types';
import { Result, Empty } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Buttons from '../components/Buttons';
import moonConst from '../lib/moon.const';

const { emptyText } = moonConst;

// 空資料
const EmptyData = ({ description }) => (

    <div className="noData">
        <Empty
            description={description}
        />
    </div>

);

// 無權限 || 頁面施工中
const PageBroken = ({ noAuth }) => (

    <Result
        className="brokenWrap"
        status="500"
        title="Warning"
        subTitle={`${noAuth ? '你沒有權限可以瀏覽' : '頁面施工中'}...`}
        extra={
            <Link
                to="/"
                className="btn-back"
            >
                <Buttons
                    type="default"
                    text="回首頁"
                    icon={<HomeOutlined />}
                />
            </Link>
        }
    />

);

// EmptyData
EmptyData.defaultProps = {
    description: emptyText,
};

EmptyData.propTypes = {
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
};

// PageBroken
PageBroken.defaultProps = {
    noAuth: false,
};

PageBroken.propTypes = {
    noAuth: PropTypes.bool.isRequired,
};

export {
    PageBroken as default,
    EmptyData,
};
