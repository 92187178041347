import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import Tables from '../../components/Tables';
import moon from '../../lib/moon';

const { Summary } = Table;
const { priceWithCommas, add } = moon;

// 組別排序：先給前端排序，若之後有變更再請後端先排序
const sortGroup = (data) => data.sort((a, b) => {

    if (a.groupId < b.groupId) return -1;
    if (a.groupId > b.groupId) return 1;
    return 0;

});

// 總計 - 小組預算與成本
const renderGroupBudgetSummary = (data) => {

    const summary = data.reduce((obj, { budget, cost, balance }) => {

        obj.budget = obj.budget || 0;
        obj.budget = add(obj.budget, budget);

        obj.cost = obj.cost || 0;
        obj.cost = add(obj.cost, cost);

        obj.balance = obj.balance || 0;
        obj.balance = add(obj.balance, balance);

        return obj;

    }, {});

    const { budget, cost, balance } = summary;

    return (

        <Summary.Row className="table-summary">
            <Summary.Cell>總計</Summary.Cell>
            <Summary.Cell>{priceWithCommas(budget)}</Summary.Cell>
            <Summary.Cell>{priceWithCommas(cost)}</Summary.Cell>
            <Summary.Cell>
                <span className={`balance ${balance < 0 ? 'warning-text' : ''}`}>{priceWithCommas(balance)}</span>
            </Summary.Cell>
        </Summary.Row>

    );

};

// 總計 - 小組成本明細
const renderGroupCostSummary = (data, costDetail) => {

    const summary = data.reduce((obj, { cost, totalHours }) => {

        obj.cost = obj.cost || 0;
        obj.cost = add(obj.cost, cost);

        obj.totalHours = obj.totalHours || 0;
        obj.totalHours = add(obj.totalHours, totalHours);

        return obj;

    }, {});

    const { cost, totalHours } = summary;

    return (

        <Summary.Row className="table-summary">
            <Summary.Cell>總計</Summary.Cell>
            <Summary.Cell>{costDetail.length} 人</Summary.Cell>
            <Summary.Cell>{priceWithCommas(cost)}</Summary.Cell>
            <Summary.Cell>{totalHours || 0}h</Summary.Cell>
        </Summary.Row>

    );

};

const TimelineDataGroupDetail = ({ budgetDetail, costDetail, projectBudget }) => {

    // 表格欄位 - 小組預算
    const budgetColumns = [
        {
            title: '組別',
            dataIndex: 'groupName',
            align: 'center',
        },
        {
            title: `專案預算 ${priceWithCommas(projectBudget)}`,
            children: [
                {
                    title: '預算',
                    dataIndex: 'budget',
                    align: 'right',
                    render: (budget) => priceWithCommas(budget),
                },
                {
                    title: '成本',
                    dataIndex: 'cost',
                    align: 'right',
                    render: (cost) => priceWithCommas(cost),
                },
                {
                    title: '小計 (預算-成本)',
                    dataIndex: 'balance',
                    align: 'right',
                    render: (balance) => (balance < 0) ? <span className="warning-text">{priceWithCommas(balance)}</span> : priceWithCommas(balance),
                },
            ],
        }
    ];

    // 表格欄位 - 小組人力成本與工時
    const costColumns = [
        {
            title: '組別',
            dataIndex: 'groupName',
            align: 'center',
        },
        {
            title: '指派人',
            dataIndex: 'assignee',
            align: 'right',
            render: (assignee, { assigneeId }) => (

                <Link
                    to={`/myProject/${assigneeId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {assignee}
                </Link>

            ),
        },
        {
            title: '成本',
            dataIndex: 'cost',
            align: 'right',
            render: (cost) => priceWithCommas(cost),
        },
        {
            title: '總時數',
            dataIndex: 'totalHours',
            align: 'right',
            render: (totalHours) => `${totalHours}h`,
        },
    ];

    return (

        <Fragment>
            <Tables
                className="timelineDataTable timelineData-group-budget"
                columns={budgetColumns}
                data={budgetDetail}
                rowKey="groupId"
                showTitle={true}
                showPage={false}
                summary={renderGroupBudgetSummary}
            />

            <Tables
                className="timelineDataTable timelineData-group-cost"
                columns={costColumns}
                data={sortGroup(costDetail)}
                rowKey="id"
                showTitle={true}
                showPage={false}
                summary={(data) => renderGroupCostSummary(data, costDetail)}
            />
        </Fragment>

    );

};

TimelineDataGroupDetail.defaultProps = {
    projectBudget: 0,
    budgetDetail: [],
    costDetail: [],
};

TimelineDataGroupDetail.propTypes = {
    projectBudget: PropTypes.number,
    budgetDetail: PropTypes.array.isRequired,
    costDetail: PropTypes.array.isRequired,
};

export {
    TimelineDataGroupDetail as default,
};
