import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import { GlobalContext } from '../../context/global.state';
import { HomeStore } from './Home';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';

const {
    transferDateFormat,
    checkObjLength,
} = moon;

const { workLogHour } = moonConst;

// 過濾已登錄資料
const filterData = (workLogTypes, logType) => workLogTypes.filter(({ code }) => code === logType)[0];

// 已登錄工時暫存
const LogData = ({
    date,
    name,
    color,
    textColor,
    hour,
    logType,
}) => (

    <div className="item-task storageData">
        登錄於 <span className="date">{transferDateFormat(date)}</span>
        <span>
            <Tags
                text={name}
                color={color}
                textColor={textColor}
                className={logType === 'estimate' ? 'log-black' : ''}
            />
            <span>x {hour}日</span>
        </span>
    </div>

);

// 任務、專案清單
const TaskList = ({
    dataList,
    currWorkLog,
    handleWorkLog,
}) => {

    // Context
    const { workLogTypes } = useContext(GlobalContext);

    const {
        projectId,
        taskId,
        name,
        groupName,
        projectName,
    } = dataList;

    return (

        <Link
            to={`/projects/${projectId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="dashboardTaskWrap"
        >
            <h2
                className="item-task project-name"
                title={projectName}
            >
                {projectName}
            </h2>

            {
                // 已填過工時
                (checkObjLength(currWorkLog) && currWorkLog[taskId]) &&
                    <LogData
                        date={currWorkLog[taskId].workLogStartDate}
                        name={filterData(workLogTypes, currWorkLog[taskId].workLogType).name}
                        color={filterData(workLogTypes, currWorkLog[taskId].workLogType).colorCode}
                        textColor={filterData(workLogTypes, currWorkLog[taskId].workLogType).colorText}
                        hour={workLogHour[currWorkLog[taskId].workLogHours]}
                        logType={currWorkLog[taskId].workLogType}
                    />
            }

            <Row
                align="bottom"
                className="item-task"
            >
                <Col
                    flex="0 0 calc(100% - 80px)"
                    className="info"
                >
                    <div>項目名稱: {name}</div>
                    {
                        groupName &&
                            <div className="group-name">群組名稱: {groupName}</div>
                    }
                </Col>

                <Col
                    className="action"
                    flex="1"
                >
                    <Buttons
                        text="填工時"
                        onClick={(e) => handleWorkLog({ taskId, name }, e)}
                    />
                </Col>
            </Row>
        </Link>

    );

};

const MyTasks = ({ handleWorkLog, currWorkLog }) => {

    // Context
    const { myTasks } = useContext(HomeStore);

    return (

        <div className="my-tasks">
            {
                myTasks.map((obj) => (

                    <TaskList
                        key={obj.taskId}
                        dataList={obj}
                        currWorkLog={currWorkLog}
                        handleWorkLog={handleWorkLog}
                    />

                ))
            }
        </div>

    );

};

TaskList.propTypes = {
    dataList: PropTypes.object.isRequired,
    currWorkLog: PropTypes.object,
    handleWorkLog: PropTypes.func,
};

MyTasks.propTypes = {
    currWorkLog: PropTypes.object,
    handleWorkLog: PropTypes.func,
};

export {
    MyTasks as default,
};
