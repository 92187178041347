import React from 'react';
import PropTypes from 'prop-types';
import DownloadExcel from '../DownloadExcel';

const FilesDownload = ({ worklog, credit }) => (

    <div>
        {
            worklog &&
                <DownloadExcel
                    message="完整工時成本計算"
                    onClick={worklog}
                />
        }

        {
            credit &&
                <DownloadExcel
                    message="Credit"
                    onClick={credit}
                />
        }
    </div>

);

FilesDownload.propTypes = {
    worklog: PropTypes.func,
    credit: PropTypes.func,
};

export default FilesDownload;
