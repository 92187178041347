import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Setting.scss';
import moonConst from '../../lib/moon.const';

const { setting } = moonConst;

const SettingColumns = ({
    data: {
        name,
        pageKey,
        regular,
        icon,
    },
}) => (

    <Link
        key={pageKey}
        to={`/setting/${pageKey}`}
        className="column"
    >
        <i className={`${regular ? 'far' : 'fas'} fa-${icon}`}></i>
        <p>{name}</p>
    </Link>

);

const Setting = () => {

    useEffect(() => {

        document.title = '設定';

    });

    return (

        <div className="settingColumns">
            {
                setting.map((obj) => (

                    <SettingColumns
                        key={obj.pageKey}
                        data={obj}
                    />

                ))
            }
        </div>

    );

};

export default Setting;
