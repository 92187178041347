import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const DownloadExcel = ({ message, onClick }) => (

    <Tooltip
        title={message}
        placement="bottom"
        overlayClassName="tooltip todayDayoffTooltip"
    >
        <span
            className="download-excel"
            onClick={onClick}
        >
            <img src={`${process.env.PUBLIC_URL}/images/excel.png`} alt={message} />
        </span>
    </Tooltip>

);

DownloadExcel.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func,
};

export default DownloadExcel;
