import React, { useContext } from 'react';
import { Timeline } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
    faCalendarDay,
    faTrashAlt,
    faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons';

import Buttons from '../../components/Buttons';
import FontIcon from '../../components/FontIcon';
import TimeLines from '../../components/TimeLines';
import { EmptyData } from '../../containers/PageBroken';

import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';

const { Item } = Timeline;
const { transferDateFormat, priceWithCommas } = moon;
const { today } = moonConst;

export default ({ userId }) => {

    // Context
    const {
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        salary,
        removeSalary,
        formStorageDispatch,
    } = useContext(MemberContext);

    // 新增按鈕
    const btnCreate = () => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: { userId },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'createSalary' });

    };

    return (

        <div className="salary">
            <div className="profile-on-top">
                <h2 className="title">薪資異動紀錄</h2>

                <Buttons
                    text="新增"
                    className="titleBtn"
                    handleClick={btnCreate}
                />
            </div>

            {
                !!salary.length ? (

                    <TimeLines className="salaryTimeline">
                        {
                            salary.map(({
                                salaryId,
                                amount,
                                effectiveDate,
                                createDate,
                                creator,
                                notes,
                            }) => (

                                <Item
                                    key={salaryId}
                                    className="timelineItem"
                                    label={`${transferDateFormat(effectiveDate)} 生效`}
                                    {
                                        // 未生效
                                        ...(transferDateFormat(effectiveDate) > transferDateFormat(today)) ? { color: 'gray' } : {}
                                    }
                                    {
                                        // 今日
                                        ...(transferDateFormat(effectiveDate) === transferDateFormat(today)) ? { dot: <span className="today"><FontIcon icon={faCalendarDay} /></span> } : {}
                                    }
                                >
                                    <div className="timeline-create-info">
                                        <EditOutlined />
                                        <span className="creator">{creator ? creator : ''}</span>
                                        <span>{`於 ${transferDateFormat(createDate)} 更新`}</span>

                                        <span
                                            className="btn-delete"
                                            onClick={() => removeSalary({ userId, salaryId })}
                                        >
                                            <FontIcon icon={faTrashAlt} />
                                            <span>刪除</span>
                                        </span>
                                    </div>

                                    <div className="timeline-content">
                                        <span>薪資 <span className="large-text">{priceWithCommas(amount)}</span></span>
                                        <FontIcon icon={faLongArrowAltUp} />
                                    </div>

                                    {
                                        // 有備註才出現
                                        notes &&
                                            <div className="timeline-notes">
                                                <h4>備註</h4>
                                                <p>{notes}</p>
                                            </div>
                                    }
                                </Item>

                            ))
                        }
                    </TimeLines>

                ) : <EmptyData />
            }
        </div>

    );

};
