import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = ({ tip, showTip }) => (

    <div className="loadingWrap">
        <Spin
            indicator={<LoadingOutlined spin />}
            tip={showTip && tip}
        />
    </div>

);

Loading.defaultProps = {
    tip: '載入中...',
    showTip: true,
};

Loading.propTypes = {
    tip: PropTypes.string,
    showTip: PropTypes.bool,
};

export {
    Loading as default,
};
