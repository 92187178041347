import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import '../components/Guest.scss';
import Buttons from '../components/Buttons';
import { FormRow } from '../components/LightboxForm';
import Service from '../lib/moon.service';

const Login = () => {

    let history = useHistory();

    // State
    const [loading, setLoading] = useState(false);

    //
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 送資料
    const handleReqData = (reqData) => {

        setLoading(true);
        let auth = btoa(`${reqData.account}:${reqData.password}`);

        Service.login({ headers: { Authorization: `Basic ${auth}`} })
            .then(() => {

                setLoading(false);
                history.push('/index');

            })
            .finally(() => {

                setLoading(false);

            });

    };

    return (

        <form
            onSubmit={handleSubmit(handleReqData)}
            className="guest-box loginWrap"
        >
            <FormRow
                labelTitle="帳號"
                required
                error={errors.account && true}
                {...(errors.account?.type === 'pattern') && { errorMesg: '格式錯誤' }}
            >
                <input
                    type="text"
                    name="account"
                    ref={register({ required: true })}
                />
            </FormRow>

            <FormRow
                labelTitle="密碼"
                required
                error={errors.password && true}
                {...(errors.password?.type === 'pattern') && { errorMesg: '格式錯誤' }}
            >
                <input
                    type="password"
                    name="password"
                    ref={register({ required: true })}
                />
            </FormRow>

            <Buttons
                {...loading && { loading }}
                text="送出"
                htmlType="submit"
            />
        </form>

    );

};

export default Login;
