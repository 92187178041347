import React, { Fragment, useContext } from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';
import CalendarTool from '../../components/CalendarTool';
import AdvancedSearch from '../../components/AdvancedSearch';
import SearchItems from '../../components/SearchItems';
import { GlobalContext } from '../../context/global.state';

const Search = ({
    selectedGroup,
    checkedAllGroup,
    handleCheckedAllToggle,
    handleCheckboxChange,
    handleSearchClear,
    handleSubmit,
}) => {

    // Context
    const { groups } = useContext(GlobalContext);

    return (

        <Fragment>

            <CalendarTool
                showSelectedDateBox={false}
                className="lazyCalendar"
                validRange={[moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'weeks')]}
            />

            <AdvancedSearch
                showQuery={false}
                formCheck={{
                    handleClear: handleSearchClear,
                    handleSubmit: handleSubmit,
                }}
            >
                <SearchItems
                    className="checkboxLists"
                    title="組別"
                    showToggleAll={true}
                    handleToggleAll={handleCheckedAllToggle}
                >
                    {
                        groups.map(({ id, name }) => (
                            <Checkbox
                                key={id}
                                name={id}
                                className="checkbox-item"
                                checked={selectedGroup[id] || ((checkedAllGroup && !selectedGroup[id]) ? selectedGroup[id] : checkedAllGroup)}
                                onChange={handleCheckboxChange}
                            >
                                {name}
                            </Checkbox>
                        ))
                    }
                </SearchItems>
            </AdvancedSearch>

        </Fragment>

    );

};

export default Search;
