import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

const GanttTimeline = ({
    width,
    height,
    rows,
    options,
}) => (

    <Chart
        width={width}
        height={height}
        chartType="Timeline"
        chartLanguage="zh-TW"
        // loader={<div>Loading Chart</div>}
        columns={[
            { type: 'string', id: 'Task Name' },
            { type: 'string', id: 'Name' },
            { type: 'string', id: 'style', role: 'style' },
            { type: 'string', id: 'tooltip', role: 'tooltip', p: { html: true } },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
        ]}
        rows={[...rows]}
        options={{
            avoidOverlappingGridLines: false,
            tooltip: { isHtml: true },
            ...options,
        }}
        legendToggle
    />

);

GanttTimeline.defaultProps = {
    width: '100%',
    height: '100%',
    rows: [],
};

GanttTimeline.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    rows: PropTypes.array.isRequired,
    option: PropTypes.object,
};

export {
    GanttTimeline as default,
};

// Example
// ['3D-文永翔 453093', '確', '#009900', 'A-12', new Date("2020-9-8 00:00:00"), new Date("2020-9-10 12:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-13', new Date("2020-9-11 00:00:00"), new Date("2020-9-14 16:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-14', new Date("2020-9-15 00:00:00"), new Date("2020-9-15 16:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-15', new Date("2020-9-16 00:00:00"), new Date("2020-9-16 16:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-16', new Date("2020-9-17 00:00:00"), new Date("2020-9-17 16:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-17', new Date("2020-9-18 00:00:00"), new Date("2020-9-18 8:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-18', new Date("2020-9-21 00:00:00"), new Date("2020-9-21 6:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-19', new Date("2020-9-22 00:00:00"), new Date("2020-9-22 6:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-110', new Date("2020-9-23 00:00:00"), new Date("2020-9-23 6:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-111', new Date("2020-9-24 00:00:00"), new Date("2020-9-24 6:00:00")],
// ['3D-文永翔 453093', '確', '#009900', 'A-112', new Date("2020-9-25 00:00:00"), new Date("2020-9-25 6:00:00")],
// ['資料收集與開會-林奇鋒 16400', '確', '#009900', 'A-113', new Date("2020-9-2 00:00:00"), new Date("2020-9-2 12:00:00")],
// ['資料收集與開會-林奇鋒 16400', '假', '#F5C500', 'A-114', new Date("2020-9-11 00:00:00"), new Date("2020-9-11 24:00:00")],
// ['資料收集與開會-林奇鋒 16400', '確', '#009900', 'A-115', new Date("2020-9-16 00:00:00"), new Date("2020-9-16 6:00:00")],
// ['資料收集與開會-林奇鋒 16400', '確', '#009900', 'A-116', new Date("2020-9-23 00:00:00"), new Date("2020-9-23 6:00:00")],
// ['開會/SUP-林奇鋒 6150', '確', '#009900', 'A-117', new Date("2020-9-3 00:00:00"), new Date("2020-9-3 6:00:00")],
// ['開會/SUP-林奇鋒 6150', '假', '#F5C500', 'A-118', new Date("2020-9-11 00:00:00"), new Date("2020-9-11 24:00:00")],
// ['開會/SUP-林奇鋒 6150', '確', '#009900', 'A-119', new Date("2020-9-14 00:00:00"), new Date("2020-9-14 6:00:00")],
// ['開會/SUP-林奇鋒 6150', '確', '#009900', 'A-120', new Date("2020-9-15 00:00:00"), new Date("2020-9-15 6:00:00")],
// ['USD pipeline-林新華 6150', '確', '#009900', 'A-121', new Date("2020-9-4 00:00:00"), new Date("2020-9-4 12:00:00")],
// ['USD pipeline-林新華 6150', '假', '#F5C500', 'A-122', new Date("2020-9-4 12:00:00"), new Date("2020-9-4 24:00:00")],
// ['USD pipeline-林新華 6150', '確', '#009900', 'A-123', new Date("2020-9-7 00:00:00"), new Date("2020-9-10 12:00:00")],
// ['USD pipeline-林新華 6150', '假', '#F5C500', 'A-124', new Date("2020-9-10 12:00:00"), new Date("2020-9-10 24:00:00")],
// ['SEQ03_Animation-蔡立明 74800', '確', '#009900', 'A-125', new Date("2020-9-24 00:00:00"), new Date("2020-9-26 12:00:00")],
// ['SEQ03_Animation-蔡立明 74800', '確', '#009900', 'A-126', new Date("2020-9-28 12:00:00"), new Date("2020-9-30 24:00:00")],
// ['SEQ03_Animation-蔡立明 74800', '加', '#3B7DBF', 'A-127', new Date("2020-10-2 00:00:00"), new Date("2020-10-3 24:00:00")],
