import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import moon from '../../lib/moon';

const { transferDateFormat } = moon;

const DetailDate = ({
    columnWidth,
    data: { deadline, startDate, endDate },
}) => (

    <Fragment>
        <Row>
            <Col flex="1">Deadline:</Col>
            <Col flex={columnWidth}>{transferDateFormat(deadline)}</Col>
        </Row>

        <Row>
            <Col flex="1">開始時間:</Col>
            <Col flex={columnWidth}>{transferDateFormat(startDate)}</Col>
        </Row>

        <Row>
            <Col flex="1">結束時間:</Col>
            <Col flex={columnWidth}>{transferDateFormat(endDate)}</Col>
        </Row>
    </Fragment>

);

DetailDate.defaultProps = {
    columnWidth: '0.8',
};

DetailDate.propTypes = {
    columnWidth: PropTypes.string,
    data: PropTypes.object,
};

export {
    DetailDate as default,
};
