import React, { useContext } from 'react';
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

import './Profile.scss';
import Tags from '../../components/Tags';
import Skeletons from '../../components/Skeletons';
import FontIcon from '../../components/FontIcon';
import { MemberContext } from '../../context/member/member.state';
import moonConst from '../../lib/moon.const';
import { GlobalContext } from '../../context/global.state';

const { memberWorkStatus } = moonConst;

export default () => {

    // Context
    const { isLoading } = useContext(GlobalContext);
    const {
        profile: {
            employeeId,
            nameChinese,
            email,
            departmentName,
            groupName,
            roleName,
            workStatus,
        },
    } = useContext(MemberContext);

    return (

        <Skeletons
            loading={isLoading}
            avatar={{ size: 200 }}
            rows={{ rows: 4 }}
        >
            <div className="left">
                <span className="thumbnail">
                    <span className="title">{employeeId}</span>
                </span>

                <Tags
                    color={`#${memberWorkStatus?.[workStatus]?.color}`}
                    text={memberWorkStatus?.[workStatus]?.text}
                />
            </div>

            <div className="right">
                <h2 className="title">
                    {nameChinese}
                    <span className="small-text">(員編: {employeeId})</span>
                </h2>

                <p>
                    <FontIcon icon={faEnvelope} />
                    <span>{email}</span>
                </p>

                <p>{roleName}@{groupName}．{departmentName}</p>
            </div>
        </Skeletons>

    );

};
