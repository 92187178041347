import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
    faCalendarDay,
    faTrashAlt,
    faArrowRightArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

import FontIcon from './FontIcon';
import TimeLines from './TimeLines';
import GroupTimeLineForm from './GroupTimeLineForm';

import { MemberContext } from '../context/member/member.state';
import moon from '../lib/moon';
import moonConst from '../lib/moon.const';

const { Item } = Timeline;
const { transferDateFormat } = moon;
const { today } = moonConst;

const GroupTimeLine = ({ message }) => {

    const {
        groupHistory,
        removeGroupHistory,
    } = useContext(MemberContext);

    return (

        <Fragment>
            <h2 className="gropuTimeline-header">組別異動紀錄 {message && message}</h2>
            <GroupTimeLineForm />
            <TimeLines className="groupTimeline">
                {
                    groupHistory.map(({
                        id,
                        groupName,
                        notes,
                        effectiveDate,
                        createDate,
                        creator,
                    }) => (

                        <Item
                            key={id}
                            className="timelineItem"
                            label={`${transferDateFormat(effectiveDate)} 生效`}
                            {
                                // 未生效
                                ...(transferDateFormat(effectiveDate) > transferDateFormat(today)) ? { color: 'gray' } : {}
                            }
                            {
                                // 今日
                                ...(transferDateFormat(effectiveDate) === transferDateFormat(today)) ? { dot: <span className="today"><FontIcon icon={faCalendarDay} /></span> } : {}
                            }
                        >
                            <div className="timeline-create-info">
                                <EditOutlined />
                                <span className="creator">{creator ? creator : ''}</span>
                                <span>{`於 ${transferDateFormat(createDate)} 更新`}</span>

                                <span
                                    className="btn-delete"
                                    onClick={() => removeGroupHistory({ id })}
                                >
                                    <FontIcon icon={faTrashAlt} />
                                    <span>刪除</span>
                                </span>
                            </div>

                            <div className="timeline-content">
                                <span>調到</span>
                                <FontIcon icon={faArrowRightArrowLeft} />
                                <span>{groupName}</span>
                            </div>

                            {
                                // 有備註才出現
                                notes &&
                                    <div className="timeline-notes">
                                        <h4>備註</h4>
                                        <p>{notes}</p>
                                    </div>
                            }
                        </Item>

                    ))
                }
            </TimeLines>
        </Fragment>

    );

};

GroupTimeLine.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
};

export default GroupTimeLine;
