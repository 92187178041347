import React, { Fragment, useContext } from 'react';
import {
    Upload,
    Tooltip,
    Alert,
    message,
} from 'antd';

import {
    DeleteOutlined,
    PlusOutlined,
    FileJpgOutlined,
    FilePdfOutlined,
    FileUnknownOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import './UploadFiles.scss';
import { ProjectContext } from '../../context/project/project.state';
import Service from '../../lib/moon.service';

// 允許上船格式: jpg/jpeg/pdf
const formatRegex = new RegExp('(jpe?g|pdf)');

// 檔案路徑
const filePath = (id) => `/files/${id}`;

// 檔名切割
const splitFileName = (fileName) => {

    const splitName = fileName.split('.')[1];
    const obj = {
        jpg: <FileJpgOutlined />,
        jpeg: <FileJpgOutlined />,
        pdf: <FilePdfOutlined />,
    };

    return obj[splitName];
};

// 檔案列表
const renderFileItems = (originNode, file, currFileList, { handleFileRemove }) => {

    const { uid, name, size, uploadTime, creator } = file;

    // render tooltip title
    const renderTooltipTitle = ({ creator, uploadTime }) => `${creator || ''}於 ${moment(uploadTime).format('YYYY/MM/DD HH:mm:ss')} 上傳`;

    // 檔案預覽
    const handleFilePreview = () => window.open(Service.previewFile(+uid));

    return (

        <div
            className="fileItemWrap"
            onClick={handleFilePreview}
        >
            <div
                className="upload-file-avatar"
                title={renderTooltipTitle({ creator, uploadTime })}
            >
                {formatRegex.test(name) ? splitFileName(file.name) : <FileUnknownOutlined />}
            </div>

            <h5
                className="upload-file-name"
                title={name}
            >
                {name}
            </h5>

            <div className="upload-file-info">
                <span className="size">
                    {
                        // 小於 1MB 顯示 KB
                        (size / 1024 / 1024 > 1) ? `${Math.floor((size / 1024 / 1024) * 100) / 100} MB` : `${Math.floor(size / 1024)} KB`
                    }
                </span>
                <Tooltip
                    title="刪除"
                    placement="bottom"
                    overlayClassName="tooltip"
                >
                    <span
                        className="action"
                        onClick={(e) => handleFileRemove(e, uid)}
                    >
                        <DeleteOutlined />
                    </span>
                </Tooltip>
            </div>
        </div>

    );

};

// 整理成 Ant Design 的格式
const handleFileList = (files) => files.reduce((arr, { id, name, size, uploadTime, creator }) => {

    const obj = {
        uid: id,
        name,
        size,
        uploadTime,
        creator,
        url: filePath(id),
    };

    arr.push(obj);
    return arr;

}, []);

const UploadFiles = () => {

    // Context
    const {
        detail: { id: pid },
        fileList,
        uploadFile,
        removeFile,
    } = useContext(ProjectContext);

    // 刪除檔案按鈕
    const btnRemove = (e, uid) => {

        e.stopPropagation();
        removeFile({ pid, id: uid });

    };

    // 送資料
    const handleUploadData = (param) => {

        const { file } = param;
        const isLt10M = file.size / 1024 / 1024 < 10;
        const formData = new FormData();

        if (!isLt10M) {

            message.error('檔案不能超過 10MB，請重新上傳!!!');
            return;

        }

        formData.append('pid', pid);
        formData.append('file', file);
        uploadFile(formData);

    };

    return (

        <Fragment>
            <Alert
                className="upload-warning-box"
                type="warning"
                showIcon={true}
                message="備註"
                description={
                    <ul>
                        <li>僅支援 JPG、JPEG、PDF 檔案格式</li>
                        <li>檔案大小不得超過 10MB</li>
                    </ul>
                }
            />

            <Upload
                listType="picture-card"
                className="fileUpload"
                accept=".pdf,.jpg,.jpeg" // 限制檔案格式
                fileList={handleFileList(fileList)}
                itemRender={(originNode, file, currFileList) => renderFileItems(originNode, file, currFileList, { handleFileRemove: btnRemove })}
                customRequest={handleUploadData}
            >
                <div className="btn-upload">
                    <PlusOutlined />
                    <div>選擇檔案</div>
                </div>
            </Upload>
        </Fragment>

    );

};

export default UploadFiles;
