import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

const Skeletons = (props) => {

    const {
        loading,
        avatar,
        rows,
        children,
    } = props;

    return (

        <Skeleton
            loading={loading}
            avatar={avatar}
            paragraph={rows}
            active
        >
            {children}
        </Skeleton>

    );

};

Skeletons.defaultProps = {
    loading: true,
    paragraph: {
        rows: 3,
    },
};

Skeletons.propTypes = {
    loading: PropTypes.bool,
    avatar: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
    ]),
    // Notes: antd 官方是用陣列，先不檢查 children 欄位
    // children: PropTypes.array.isRequired,
};

export {
    Skeletons as default,
};
