import React, {
    Fragment,
    useEffect,
    useContext
} from 'react';

import './SkillBase.scss';
import Tab from '../../components/Tab';
import LightboxForm from '../../components/LightboxForm';
import { EmptyData } from '../../containers/PageBroken';

import Header from './Header';
import MemberSkill from './MemberSkill';
import AreaSkill from './AreaSkill';
import Settings from './Settings';
import AreaForm from './AreaForm';
import ScoreSkillForm from './ScoreSkillForm';
import ManageAreaSkillForm from './ManageAreaSkillForm';
import MemberSkillForm from './MemberSkillForm';
import ManageLabelForm from './ManageLabelForm';
import SettingsForm from './SettingsForm';

import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import moonConst from '../../lib/moon.const';

const { lightboxTitle } = moonConst;

const SkillBase = () => {

    // Context
    const {
        visible,
        currEvent,
        formStorageDispatch,
        popConfirmDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        skillAreaList,
        personalSkill,
        getSkillList,
        getMemberList,
        skillDispatch,
    } = useContext(SkillContext);

    useEffect(() => {

        document.title = '技能樹';

        // 取得技能領域列表
        if (!skillAreaList.length) getSkillList();

    }, []);

    // Tab 點擊事件
    const handleClick = (e) => {

        popConfirmDispatch({ type: 'HIDE' });
        skillDispatch({ type: 'skill_member_list_search', payload: { isSearch: false } });

        // 點擊技能評分 Tab 才觸發 Request
        if (e === 'member') getMemberList();

    };

    // Lightbox Title
    const renderLightboxTitle = () => {

        switch (currEvent) {
            case 'scoreSkill':
                return `${personalSkill.nameChinese}的技能評分`;

            case 'manageLabel':
                return `${personalSkill.nameChinese}的特質與擅長工具`;

            default:
                return lightboxTitle[currEvent];
        }

    };

    // Lightbox 身體
    const renderLightboxContent = () => {

        switch (currEvent) {
            case 'createArea':
            case 'updateArea':
                return <AreaForm />;

            case 'manageAreaSkill':
                return <ManageAreaSkillForm />;

            case 'manageMemberSkill':
                return <MemberSkillForm />;

            case 'manageLabel':
                return <ManageLabelForm />;

            case 'createFeature':
            case 'createTool':
                return <SettingsForm />;

            default:
                return <ScoreSkillForm />;
        }

    };

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    return (

        <Fragment>
            <Header />

            <Tab
                className="skillTab"
                onChange={handleClick}
                tabData={{
                    skill: {
                        name: '技能領域表',
                        component: skillAreaList.length ? <AreaSkill /> : <EmptyData />,
                    },
                    member: {
                        name: '技能評分',
                        component: <MemberSkill />,
                    },
                    settings: {
                        name: '設定',
                        className: 'tab-pane-settings',
                        component: <Settings />,
                    }
                }}
            />

            {
                // Lightbox
                visible &&
                    <LightboxForm
                        title={renderLightboxTitle()}
                        visible={visible}
                        handleCancel={hideModal}
                        {...(currEvent === 'scoreSkill' ||
                             currEvent === 'manageAreaSkill' ||
                             currEvent === 'createMemberSkill' ||
                             currEvent === 'updateMemberSkill') && { width: 400 }}
                    >
                        {renderLightboxContent()}
                    </LightboxForm>
            }
        </Fragment>

    );

};

export default SkillBase;
