import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';

const AreaForm = () => {

    // Context
    const {
        currEvent,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createArea,
        updateArea,
    } = useContext(SkillContext);

    // State
    const [color, setColor] = useState(formStorageData.colorCode || '#D9D9D9');

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 色碼
    const handleChangeColor = ({ target }) => setColor(target.value);

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...formStorageData,
            ...reqData,
            colorCode: color,
        };

        if (currEvent === 'updateArea') updateArea(reqData);
        else createArea(reqData);

    };

    const { name, colorCode } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <FormRow
                labelTitle="領域名稱"
                required={true}
                error={errors.name && true}
            >
                <input
                    type="text"
                    name="name"
                    defaultValue={name}
                    ref={register({ required: true })}
                />
            </FormRow>

            <FormRow
                labelTitle="色碼"
                className="colorWrap"
                required={true}
                error={errors.colorCode && true}
            >
                <input
                    type="color"
                    name="colorCode"
                    defaultValue={colorCode || '#D9D9D9'} // 預設改為灰色
                    onChange={handleChangeColor}
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default AreaForm;
