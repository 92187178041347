import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import Wrap from './Wrap';
import Login from './pages/Login';
import Guest from './components/Guest';
import useToken from './lib/useToken';
import moonConst from './lib/moon.const';

const { routeConfig } = moonConst;

export const PrivateRoute = ({ children, ...rest }) => {

    const token = useToken();

    return (

        token ? <Route {...rest} render={() => token ? children : <Redirect to={`/login`} />} /> : <Guest />

    );

};

const Frame = () => {

    // Custom Hook
    const token = useToken();

    // State
    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {

        const pathname = window.location.pathname.split('/');
        setPath(routeConfig.filter((route) => {

            const regex = new RegExp(pathname);
            return regex.test(route);

        })[0]);

    }, [path]);

    return (

        <Router basename={process.env.PUBLIC_URL} forceRefresh={true}>
            <Switch>
                <Route path={`/login`}>
                    {token ? <Redirect to={path || 'index'} /> : <Login />}
                </Route>

                <PrivateRoute path={path}>
                    <Wrap />
                </PrivateRoute>
            </Switch>
        </Router>

    );

};

export default Frame;
