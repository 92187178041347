// 整理資料成月曆結構
const transferDataToCalendar = (lists) => lists.reduce((obj, { date, items }) => {

    obj[date] = items;
    return obj;

}, {});

// 整理每月總時數成物件結構
const transferMonthSummary = (lists) => lists.reduce((obj, { date, hours }) => {

    obj[date] = hours;
    return obj;

}, {});

// Project
const projectReducer = (state, { type, payload, id }) => {

    switch (type) {
        case 'project_list':
            return {
                ...state,
                projectList: payload,
            };

        case 'project_list_search':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    ...payload,
                },
            };

        case 'project_detail': {
            const { detail, files, groupBudget } = payload;

            return {
                ...state,
                detail,
                fileList: files,
                groupBudget,
            };
        }

        case 'task_list':
            return {
                ...state,
                taskList: payload,
            };

        case 'create_task':
            return {
                ...state,
                taskList: [...state.taskList, { ...payload }],
            };

        case 'update_task':
            return {
                ...state,
                taskList: state.taskList.map((obj) => (obj.id === payload.id) ? obj = payload : obj),
            };

        case 'remove_task':
            return {
                ...state,
                taskList: state.taskList.filter(({ id }) => id !== payload),
            };

        case 'project_form_opts':
            return {
                ...state,
                formOpts: payload,
            };

        case 'create_project':
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    lists: [{ ...payload }, ...state.projectList.lists],
                },
            };

        // 專案列表 > 編輯
        case 'update_project_list':
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    lists: state.projectList.lists.map((obj) => (obj.id === payload.id) ? obj = payload : obj),
                },
            };

        // 專案詳細 > 編輯
        case 'update_project':
            return {
                ...state,
                detail: payload,
            };

        case 'remove_project':
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    lists: state.projectList.lists.filter(({ id }) => id !== payload),
                },
            };

        // 個人專案 > 取得被指派項目
        case 'todo_list':
            return {
                ...state,
                myToDo: {
                    ...state.myToDo,
                    [id]: payload, // 專案 ID
                },
            };

        case 'create_todo':
        case 'update_todo':
        case 'remove_todo':
            return {
                ...state,
                taskList: payload,
                filterAssigneeOpts: {
                    ...state.filterAssigneeOpts,
                    isResponse: false,
                },
            };

        // 個人專案 > 編輯項目
        case 'update_my_todo':
            return {
                ...state,
                myToDo: {
                    ...state.myToDo,
                    [payload.pid]: state.myToDo[payload.pid].map((obj) => {

                        if (obj.id === id) obj.name = payload.name;
                        return obj;

                    }),
                },
                filterAssigneeOpts: {
                    ...state.filterAssigneeOpts,
                    isResponse: false,
                },
            };

        // 個人專案 > 刪除項目
        case 'remove_my_todo':
            return {
                ...state,
                myToDo: {
                    ...state.myToDo,
                    [payload]: state.myToDo[payload].filter((obj) => obj.id !== id),
                },
            };

        // 搜尋項目
        case 'task_search':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    ...payload,
                    isSearch: true,
                },
            };

        // 還原搜尋項目
        case 'task_search_reset':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isSearch: payload,
                },
            };

        case 'worklog_list':
            return {
                ...state,
                workLog: {
                    ...payload,
                    lists: transferDataToCalendar(payload.lists),
                    aMonthSummary: transferMonthSummary(payload.aMonthSummary),
                },
            };

        case 'worklog_list_a_day':
            return {
                ...state,
                workLog: { ...state.workLog, aDay: payload },
            };

        case 'create_worklog': {
            const {
                detail,
                groupBudget,
                taskId,
                cost,
                extraCost,
                totalHours,
                ...rest
            } = payload;

            return {
                ...state,
                detail: {
                    ...state.detail,
                    cost: detail.cost,
                    extraCost: detail.extraCost,
                    profit: detail.profit,
                },
                taskList: state.taskList.map((obj) => {

                    if (obj.id === taskId) {

                        obj.cost = cost;
                        obj.extraCost = extraCost;
                        obj.totalHours = totalHours;

                    }

                    return obj;

                }),
                workLog: {
                    ...state.workLog,
                    ...rest,
                },
                groupBudget,
            };
        }

        case 'update_worklog':
        case 'remove_worklog':
            const {
                detail,
                groupBudget,
                taskId,
                cost,
                extraCost,
                totalHours,
                ...rest
            } = payload;

            return {
                ...state,
                detail: {
                    ...state.detail,
                    cost: detail.cost,
                    extraCost: detail.extraCost,
                    profit: detail.profit,
                },
                taskList: state.taskList.map((obj) => {

                    if (obj.id === taskId) {

                        obj.cost = cost;
                        obj.extraCost = extraCost;
                        obj.totalHours = totalHours;

                    }

                    return obj;

                }),
                workLog: {
                    ...state.workLog,
                    ...rest,
                    lists: transferDataToCalendar(rest.lists),
                    aMonthSummary: transferMonthSummary(rest.aMonthSummary),
                    ...(type === 'remove_worklog') && { aDay: state.workLog.aDay.filter((obj) => obj.id !== id) },
                },
                groupBudget,
            };

        // 個人專案 > 填工時
        case 'create_my_worklog': {
            return {
                ...state,
                myToDo: {
                    ...state.myToDo,
                    [payload.pid]: state.myToDo[payload.pid].map((obj) => {

                        if (obj.id === payload.taskId) {

                            obj.cost = payload.cost;
                            obj.extraCost = payload.extraCost;
                            obj.totalHours = payload.totalHours;

                        }

                        return obj;

                    }),
                },
            };
        }

        // 個人專案 > 編輯工時、刪除工時
        case 'update_my_worklog':
        case 'remove_my_worklog':

            const config = {
                update_my_worklog: payload.aDay,
                remove_my_worklog: state.workLog.aDay.filter((obj) => obj.id !== id),
            };

            return {
                ...state,
                workLog: {
                    ...state.workLog,
                    totalHours: payload.totalHours,
                    lists: transferDataToCalendar(payload.lists),
                    aMonthSummary: transferMonthSummary(payload.aMonthSummary),
                    aDay: config[type],
                },
                myToDo: {
                    ...state.myToDo,
                    [payload.pid]: state.myToDo[payload.pid].map((obj) => {

                        if (obj.id === payload.taskId) {

                            obj.cost = payload.cost;
                            obj.extraCost = payload.extraCost;
                            obj.totalHours = payload.totalHours;

                        }

                        return obj;

                    }),
                },
            };

        case 'other_fee_list':
            return {
                ...state,
                otherFee: payload.lists,
                outSourceOpts: payload.outsources,
            };

        case 'create_other_fee':
            return {
                ...state,
                otherFee: [{ ...payload.updater }, ...state.otherFee],
                detail: {
                    ...state.detail,
                    ...payload.detail,
                },
            };

        case 'remove_other_fee':
            return {
                ...state,
                otherFee: state.otherFee.filter((obj) => obj.id !== id), // 另計支出 ID
                detail: {
                    ...state.detail,
                    ...payload,
                },
            };

        case 'update_file':
            return {
                ...state,
                fileList: [{ ...payload }, ...state.fileList],
            };

        case 'remove_file':
            return {
                ...state,
                fileList: state.fileList.filter((obj) => obj.id !== id), // 檔案 ID
            };

        case 'create_department_budget':
        case 'update_department_budget':
        case 'remove_department_budget':
            return {
                ...state,
                groupBudget: payload,
            };

        // 取得過濾指派人篩選項目
        case 'get_filter_assignee_opts':
            return {
                ...state,
                filterAssigneeOpts: {
                    ...state.filterAssigneeOpts,
                    ...payload,
                },
            };

        // 過濾指派人篩選後的項目
        case 'filter_assignee':
            return {
                ...state,
                filterAssigneeOpts: {
                    ...state.filterAssigneeOpts,
                    result: payload,
                    isResponse: true,
                },
            };

        // 還原過濾指派人
        case 'filter_assignee_reset':
            return {
                ...state,
                filterAssigneeOpts: {
                    ...state.filterAssigneeOpts,
                    isResponse: payload,
                },
            };

        default:
            return state;
    }

};

export { projectReducer };
