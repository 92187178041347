import React, { Fragment, useContext } from 'react';
import { Card } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

import Buttons from '../../components/Buttons';
import Loading from '../../components/Loading';
import { FormRow } from '../../components/LightboxForm';
import { EmptyData } from '../../containers/PageBroken';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';

const { Meta } = Card;
const { priceWithCommas, renderWithoutValue } = moon;

// Tmpl: Card
const CardList = ({
    data: {
        id,
        name,
        userId,
        nameChinese,
        price,
        notes,
    },
}) => {

    // Context
    const { removeOtherFee } = useContext(ProjectContext);

    return (

        <Card
            key={id}
            data-id={id}
            className="other-fee-card"
        >
            <Meta
                title={
                    <Fragment>
                        {name}
                        <span
                            className="btn-delete"
                            onClick={(e) => removeOtherFee({ id, name, price }, e)}
                        >
                            <DeleteOutlined /> 刪除
                        </span>
                    </Fragment>
                }
                description={
                    <Fragment>
                        <div className="assignee">指派人: {renderWithoutValue(nameChinese)}</div>
                        <div className="price">金額: {priceWithCommas(price)}</div>
                        {
                            notes && <div className="notes">備註: {notes}</div>
                        }
                    </Fragment>
                }
            />
        </Card>

    );

};

// 另計支出表單
const OtherFeeForm = () => {

    // Context
    const {
        detail,
        createOtherFee,
        outSourceOpts,
    } = useContext(ProjectContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 送資料
    const handleReqData = (reqData, { target }) => {

        reqData = {
            ...reqData,
            pid: detail.id,
            price: +reqData.price,
            userId: +reqData.userId,
        };

        // 未填指派人就刪除此欄位
        if (!reqData.userId) delete reqData.userId;
        createOtherFee(reqData);

        // 送出後清空所有欄位
        target.reset();

    };

    return (

        <form
            className="lightbox-wrap other-fee-form"
            onSubmit={handleSubmit(handleReqData)}
        >
            <FormRow
                labelTitle="項目"
                className="item"
                required={true}
                error={errors.name && true}
            >
                <input
                    type="text"
                    name="name"
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="items">
                <div className="row">
                    <div className="title">指派人</div>
                    <div className="field noBorder">
                        <select name="userId" ref={register}>
                            <option value="">請選擇</option>
                            {
                                outSourceOpts.map(({ id, nameChinese }) => (
                                    <option
                                        key={id}
                                        value={id}
                                    >
                                        {nameChinese}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <FormRow
                    labelTitle="金額"
                    required={true}
                    error={errors.price && true}
                >
                    <input
                        type="number"
                        name="price"
                        ref={register({ required: true })}
                    />
                </FormRow>
            </div>

            <div className="items">
                <FormRow labelTitle="備註">
                    <input
                        type="text"
                        name="notes"
                        ref={register}
                    />
                </FormRow>

                <Buttons
                    text="送出"
                    htmlType="submit"
                    className="btn-submit"
                />
            </div>
        </form>
    );

};

const OtherFeeList = () => {

    // Context
    const { isLoading } = useContext(GlobalContext);
    const { otherFee } = useContext(ProjectContext);

    return (

        <Fragment>
            <OtherFeeForm />

            {
                // 另計支出 List
                isLoading ? <Loading /> : (

                    otherFee.length ? (

                        otherFee.map((obj) => (

                            <CardList
                                key={obj.id}
                                data={obj}
                            />

                        ))

                    ) : <EmptyData />

                )
            }
        </Fragment>

    );

};

export default OtherFeeList;
