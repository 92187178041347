import moment from 'moment';

const moonConst = {
    today: moment().format('YYYY-MM-DD'),
    thisYear: moment().year(),
    sendSuccessText: '資料已成功送出',
    emptyText: '目前沒有資料...',
    errorText: '此欄位為必填!',
    startDateText: '開始時間',
    endDateText: '結束時間',
    formatDate: 'YYYY/MM/DD',
    pmbHost: (process.env.NODE_ENV === 'production') ? process.env.PUBLIC_URL : `//192.168.8.65${process.env.PUBLIC_URL}`,
    apiHost: process.env.REACT_APP_API_HOST ? '//' + process.env.REACT_APP_API_HOST : undefined,

    // Breadcrum 麵包屑對應文字
    breadcrumb: {
        dashboard: '看板',
        member: '成員',
        profile: '個人資料',
        members: '所有員工',
        dayoff: '休假人員',
        project: '專案',
        myProject: '個人專案',
        projects: '所有專案',
        affair: '總務',
        analysis: '統計',
        setting: '設定',
        groups: '角色',
        filterGroups: '過濾組別',
        projectSetting: '專案設定',
        authority: '權限',
        workLogType: '登錄工時與假別',
        calendar: '行事曆',
        costRatio: '管銷成本',
        organize: '管理組織架構',
        organizeList: '組織架構大表',
    },

    // 側邊攔
    navbar: [
        {
            name: '看板',
            pageKey: 'index',
            icon: 'Home',
        },
        {
            name: '成員',
            pageKey: 'member',
            subItems: [
                {
                    name: '個人資料',
                    pageKey: 'profile',
                    icon: 'User',
                },
                {
                    name: '所有員工',
                    pageKey: 'members',
                    icon: 'Team',
                },
                {
                    name: '休假人員',
                    pageKey: 'dayoff',
                    icon: 'BarChart',
                },
                {
                    name: '閒置清單',
                    pageKey: 'lazyList',
                    icon: 'Warning',
                },
            ],
        },
        {
            name: '專案',
            pageKey: 'project',
            subItems: [
                {
                    name: '個人專案',
                    pageKey: 'myProject',
                    icon: 'Solution',
                },
                {
                    name: '所有專案',
                    pageKey: 'projects',
                    icon: 'UnorderedList',
                },
                {
                    name: '技能樹',
                    pageKey: 'skill',
                    icon: 'RadarChart',
                },
            ],
        },
        {
            name: '總務',
            pageKey: 'affair',
            subItems: [
                // {
                //     // 暫時註解
                //     name: '統計',
                //     pageKey: 'analysis',
                //     icon: 'PieChart',
                // },
                {
                    name: '設定',
                    pageKey: 'setting',
                    icon: 'Setting',
                },
            ],
        },
    ],

    // 設定頁
    setting: [
        {
            name: '角色',
            pageKey: 'groups',
            icon: 'users-cog',
            regular: false,
        },
        {
            name: '專案設定',
            pageKey: 'projectSetting',
            icon: 'list-ul',
            regular: false,
        },
        {
            name: '權限',
            pageKey: 'authority',
            icon: 'user-lock',
            regular: false,
        },
        {
            name: '登錄工時與假別',
            pageKey: 'workLogType',
            icon: 'holly-berry',
            regular: false,
        },
        {
            name: '行事曆',
            pageKey: 'calendar',
            icon: 'calendar-alt',
            regular: true,
        },
        {
            name: '管銷成本',
            pageKey: 'costRatio',
            icon: 'search-dollar',
            regular: false,
        },
        {
            name: '組織架構',
            pageKey: 'organize',
            icon: 'building',
            regular: false,
        },
    ],

    // Prompt type
    prompts: {
        info: '提示',
        warning: '警告',
        error: '錯誤',
        success: '成功',
    },

    // Yes or No
    yesOrNo: {
        'false': '否',
        'true': '是',
    },

    // Switch 開關
    switch: {
        '0': '關',
        '1': '開',
    },

    // 員工在職狀態 (自訂樣式)
    memberWorkStatus: {
        atWork: {
            text: '在職中',
            color: '21BA45',
        },
        leaveOfAbsence: {
            text: '留職停薪',
            color: 'B8B8B8',
        },
        leave: {
            text: '已離職',
            color: 'EF5350',
        },
    },

    // 權限文字對應
    authorityLabel: {
        allow_not_register_work_hours: "不用登錄工時",
        edit_authority: '編輯權限',
        view_all_members: '觀看全部成員資料',
        edit_all_members: '編輯全部成員資料',
        edit_all_projects: '編輯全部專案資料',
        edit_assign_projects: '編輯指派專案資料',
        edit_all_logs_from_project: '編輯全部專案工時',
        edit_assign_logs_from_project: '編輯指派的專案工時',
        edit_day_off: '編輯休假',
        view_analysis: '觀看統計',
        edit_settings: '編輯設定',
        edit_salary: '編輯薪資',
        edit_annual_leave: '加值年假', 
        edit_all_tasks_from_project: '編輯全部專案任務',
        edit_assign_tasks_from_project: '編輯指派專案任務',
        edit_access_control: '編輯門禁資料',
        view_skill_tree: '觀看技能樹',
        edit_skill_tree: '編輯技能樹',
        view_personal_cost: '觀看人力與工時圖表'
    },

    // 工時
    workLogHour: {
        960: '2',
        720: '1.5',
        480: '1',
        120: '1/4',
        240: '半',
        160: '1/3',
        320: '2/3',
        360: '3/4',
    },

    // 付款狀態 (自訂樣式)
    projectPaymentStatus: {
        alreadyPaid: {
            text: '已付款',
            color: '87d068',
        }, // 1
        recovery: {
            text: '追帳中',
            color: 'faad14',
        }, // 2
        notInvoiced: {
            text: '未開發票',
            color: 'EF5350',
        }, // 3
    },

    // action
    actionText: {
        create: '新增',
        update: '編輯',
        delete: '刪除',
    },

    // Project lightbox title
    lightboxTitle: {
        createProject: '新增專案',
        updateProject: '編輯專案',
        createTask: '新增任務',
        updateTask: '編輯任務',
        createToDoList: '新增項目',
        updateToDoList: '編輯項目',
        createWorkLog: '新增工時',
        updateWorkLog: '編輯工時',
        createGroupBudget: '新增小組預算',
        updateGroupBudget: '編輯小組預算',
        createMember: '新增員工',
        updateMember: '編輯個人資料',
        createSalary: '新增薪資異動',
        manageAreaSkill: '管理技能與領域',
        createArea: '新增領域',
        updateArea: '編輯領域',
        manageMemberSkill: '管理員工技能',
        createFeature: '新增特質',
        createTool: '新增軟體與工具',
    },

    // 得分 mapping
    scoreConfig: {
        8: {
            name: 'third',
            color: '#b78670',
        },
        9: {
            name: 'second',
            color: 'silver',
        },
        10: {
            name: 'first',
            color: 'gold',
        },
    },

    // route
    routeConfig: [
        '/',
        '/index',
        '/profile/:userId',
        '/members',
        '/dayoff',
        '/lazyList',
        '/myProject/:userId',
        '/projects',
        '/projects/:id',
        '/projects/:id/timeline',
        '/skill',
        '/setting',
        '/setting/groups',
        '/setting/projectSetting',
        '/setting/authority',
        '/setting/workLogType',
        '/setting/calendar',
        '/setting/company',
        '/setting/costRatio',
    ],
};

export default moonConst;
