import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Buttons from '../Buttons';
import { FormRow, ErrorMesg } from '../LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import PMB from '../../lib/moon.pmb';

const { checkReqData } = PMB;

const GroupBudgetForm = () => {

    // Context
    const {
        groups,
        currEvent,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createGroupBudget,
        updateGroupBudget,
    } = useContext(ProjectContext);

     // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...formStorageData,
            ...reqData,
            groupId: +reqData.groupId,
            budget: +reqData.budget,
        };

        // 檢查所有欄位並將空欄位轉成 null
        checkReqData(reqData);

        if (currEvent === 'updateGroupBudget') updateGroupBudget(reqData);
        else createGroupBudget(reqData);

    };

    const { groupId, budget } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>

            <div className="items">
                <div className={`row ${errors.groupId ? 'hasError' : ''}`}>
                    <div className="title isRequired">組別 (必填)</div>
                    <div className="field noBorder">
                        <select
                            name="groupId"
                            defaultValue={groupId}
                            ref={register}
                        >
                            {
                                groups.map(({ id, name }) => (
                                    <option
                                        key={id}
                                        value={id}
                                    >
                                        {name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    {
                        errors.groupId && <ErrorMesg />
                    }
                </div>

                <FormRow
                    labelTitle="預算"
                    required={true}
                    error={errors.budget && true}
                >
                    <input
                        type="number"
                        name="budget"
                        defaultValue={budget}
                        ref={register({ required: true })}
                    />
                </FormRow>
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="default"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default GroupBudgetForm;
