import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import '../projectList/TimelineData.scss';
import Loading from '../../components/Loading';
import { EmptyData } from '../../containers/PageBroken';
import GanttTimeline from '../../components/GanttTimeline';
import TimelineDataOwnSearch from './TimelineDataOwnSearch';
import TimelineDataOwnProjectList from './TimelineDataOwnProjectList';
import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { priceWithCommas, transferHourFormat } = moon;

// 處理日期格式
const dateFormat = (date) => (date === null) ? '' : moment(date).format('YYYY-MM-DD');

// Service: 小組預算與成本
async function fetchDataProjectList (id) {

    return await Service.projectOwnCost({ userId: id });

};

// Service: 小組成本詳細
async function fetchDataTimeline (id) {

    return await Service.projectTimelineDataList({ userId: id });

};

// 提示
const tooltip = ({
    codeName,
    projectName,
    taskName,
    hours,
    cost,
    colorCode,
    startDate,
    endDate,
}) => (`

    <div class="timeline_tooltip_wrap">
        <h3 class="title">
            <span class="name">${codeName}</span>
            <span class="square" style="background-color: ${colorCode};"></span>
            <span class="small-text">(共 ${transferHourFormat(hours)}h)</span>
        </h3>
        <div class="info">
            ${
                taskName ? `
                    <div>
                        <div class="label">項目名稱</div> ${taskName}
                    </div>
                ` : ''
            }
            <div>
                <div class="label">專案名稱</div> ${projectName}
            </div>
            <div>
                <div class="label">期間</div> ${moment(startDate).format('YYYY/MM/DD (ddd)')} ~ ${moment(endDate).format('YYYY/MM/DD (ddd)')}
            </div>
            ${
                cost ? `
                    <div>
                        <div class="label">成本</div> ${priceWithCommas(cost)}
                    </div>
                ` : ''
            }
        </div>
    </div>

`);

// 整理資料結構
const handleDataStructure = (data) => data.map((obj) => {

    const {
        projectName,
        colorCode,
        startDate,
        endDate,
    } = obj;

    let arr = [
        projectName,
        '',
        colorCode,
        tooltip(obj),
        new Date(startDate),
        new Date(endDate),
    ];

    return Object.values(arr);

});

const TimelineDataOwn = ({ userId }) => {

    // State
    const [selected, setSelected] = useState({});
    const [showLoading, setShowLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // Data
    const [ownProjectList, setOwnProjectList] = useState([]);
    const [ownProjectListStore, setOwnProjectListStore] = useState([]);
    const [ownToDoList, setOwnToDoList] = useState([]);
    const [timelineLists, setTimelineLists] = useState([]);
    const [timelineListStore, setTimelineListStore] = useState([]);

    // React Hook Form
    const { handleSubmit, reset } = useForm();

    useEffect(() => {

        fetchDataProjectList(userId)
            .then(handleFetchDataProjectList);

        fetchDataTimeline(userId)
            .then(handleFetchDataTimeline);

    }, [userId]);

    // 處理小組預算與成本 ajax 回傳
    const handleFetchDataProjectList = ({ projectLists, toDoLists }) => {

        setOwnProjectList(projectLists);
        setOwnProjectListStore(projectLists);
        setOwnToDoList(toDoLists);

    };

    // 處理小組預算與成本 ajax 回傳
    const handleFetchDataTimeline = ({ lists }) => {

        setTimelineListStore(lists);
        setTimelineLists(lists);
        setShowLoading(false);

    };

    // 搜尋: Rangepicker
    const handleRangepicker = (dates) => {

        if (!dates) {

            resetRangePickerValue();
            return;

        }

        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            userId,
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        };

        // 起始結束日未填就不送
        if (!reqData.startDate || !reqData.endDate) return;

        Service.projectOwnTimelineDataSearch(reqData)
            .then(({ lists, projectLists }) => {

                setOwnProjectList(projectLists);
                setTimelineLists(lists);

            });

    };

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setOwnProjectList(ownProjectListStore);
        setTimelineLists(timelineListStore);
        setSelected({});
        resetRangePickerValue();

    };

    // 還原 RangePicker
    const resetRangePickerValue = () => {

        setStartDate(null);
        setEndDate(null);

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <Row gutter={20}>
            <Col flex="0 0 480px">
                <TimelineDataOwnProjectList
                    ownProjectList={ownProjectList}
                    ownToDoList={ownToDoList}
                />
            </Col>

            <Col
                flex="1"
                className="timeline"
            >
                <div className="timelineDataSearch timelineDataOwnSearch">
                    <form
                        className="searchForm"
                        onSubmit={handleSubmit(handleReqData)}
                    >
                        <TimelineDataOwnSearch
                            selected={selected}
                            startDate={startDate}
                            endDate={endDate}
                            handleRangepicker={handleRangepicker}
                            handleSearchClear={handleSearchClear}
                        />
                    </form>

                    <span className="searchResult">
                        共 {timelineLists.length} 筆資料
                    </span>
                </div>

                <div className="timelineDataWrap">
                    {
                        showLoading ? <Loading /> : (

                            timelineLists.length ? (

                                <GanttTimeline
                                    width={1600}
                                    height={'80vh'}
                                    rows={handleDataStructure(timelineLists)}
                                />

                            ) : <EmptyData />

                        )
                    }
                </div>
            </Col>
        </Row>

    );

};

export {
    TimelineDataOwn as default,
};
