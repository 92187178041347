import React from 'react';
import { useHistory } from 'react-router-dom';
import Buttons from '../components/Buttons';
import './Guest.scss';

const Guest = () => {

    let history = useHistory();

    return (

        <section className="guest-box guestWrap">
            <div className="align">
                <h1 className="title">尚未登入...</h1>

                <Buttons
                    text="點我登入"
                    onClick={() => history.push(`/login`)}
                />
            </div>
        </section>

    );

};

export default Guest;
