import React, { Fragment, useState, useEffect, useContext } from 'react';
import { AutoComplete, Radio } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Buttons from '../../components/Buttons';
import { FormRow, OtherErrorMesg } from '../../components/LightboxForm';
import { Rangepickers } from '../../components/Datepickers';
import Tags from '../../components/Tags';

import { GlobalContext } from '../../context/global.state';
import { DayoffContext } from '../../context/dayoff/dayoff.state';
import moonConst from '../../lib/moon.const';

const RadioGroup = Radio.Group;
const { workLogHour } = moonConst;

// 整理成 Ant Design 的結構
const handleMemberList = (data) => data.reduce((acc, {
    departmentName,
    groupName,
    userId,
    nameChinese,
}) => {

    acc.push({
        label: `${nameChinese}@${groupName} (${departmentName})`,
        value: `${String(userId)}_${nameChinese}_${groupName}_${departmentName}`,
    });

    return acc;

}, []);

const LeaveTypeForm = ({ selectedDate }) => {

    // Context
    const {
        allMembers,
        getAllMembers,
        dayOffTypes,
        currEvent,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createDayoff,
        updateDayoff,
    } = useContext(DayoffContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
    } = useForm();

    useEffect(() => {

        // 編輯不需要取所有員工列表
        if (!allMembers.length && currEvent === 'createDayoff') getAllMembers();

    }, []);

    // State
    const [showLeaveHourRadios, setShowLeaveHourRadios] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [storage, setStorage] = useState({
        ...formStorageData,
        leaveStartDate: '',
        leaveEndDate: '',
    });
    const [selected, setSelected] = useState({
        nameChinese: (formStorageData?.userId ? `${formStorageData.userId}_${formStorageData.nameChinese}` : '') ?? '',
    });
    const [showError, setShowError] = useState(false);

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' })

    };

    // Rangepicker
    const handleRangepicker = (dates) => {

        const [startDate, endDate] = dates;
        const leaveStartDate = moment(startDate).format('YYYY-MM-DD');
        const leaveEndDate = moment(endDate).format('YYYY-MM-DD');

        // 超過 8H 都要被 disabled
        const range = (leaveEndDate > leaveStartDate);

        setStorage({
            ...storage,
            leaveStartDate,
            leaveEndDate,
            leaveHours: range ? 480 : 240,
        });

        // 是否為同一天，若為區間則 "非 8H" 都要被 disabled
        setDisabled(range);

    };

    // Autocomplete change 事件
    const handleChangeOption = (value) => {

        // 給畫面用
        setSelected({
            ...selected,
            nameChinese: value,
        });

    };

    // Autocomplete filter 事件
    const handleFilterOption = (inputVal, option) => {

        const regex = new RegExp(inputVal);
        return regex.test(option.value);

    };

    // 假別
    const handleLeaveTypeChange = ({ target }) => {

        setShowLeaveHourRadios(true);
        setStorage({
            ...storage,
            code: target.value,
        });

    };

    // 時數
    const handleLeaveHourChange = ({ target }) => {

        setStorage({
            ...storage,
            leaveHours: target.value,
        });

    };

    // 過濾假別時數
    const filterHourOfLeaveType = (leaveType) => dayOffTypes.filter(({ code }) => code === leaveType)[0] || '';

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        reqData = {
            ...storage,
            ...reqData,
            userId: +selected.nameChinese.split('_')[0] || '',
            leaveStartDate: (currEvent === 'updateDayoff') ? moment(reqData.leaveStartDate).format('YYYY-MM-DD') : storage.leaveStartDate,
            leaveEndDate: (currEvent === 'updateDayoff') ? moment(reqData.leaveStartDate).format('YYYY-MM-DD') : storage.leaveEndDate,
        };

        delete reqData.nameChinese;

        // 檢查是否填寫
        const checking = Object.keys(reqData).some((obj) => (reqData[obj] === '' || reqData[obj] === 'undefined'));
        setShowError(checking);

        if (checking) return;
        if (currEvent === 'updateDayoff') updateDayoff(reqData);
        else createDayoff(reqData);

    };

    const { code, leaveHours, nameChinese } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            {
                // 大顆新增按鈕
                currEvent === 'createDayoff' ? (

                    <div className="row">
                        <div className="title isRequired">休假日期 (YYYY/MM/DD)</div>
                        <Rangepickers
                            handleChange={handleRangepicker}
                        />
                    </div>

                ) : (

                    <FormRow
                        labelTitle="日期 (YYYY/MM/DD)"
                        className="readOnly"
                        notes="無法更改日期"
                    >
                        <input
                            type="text"
                            name="leaveStartDate"
                            defaultValue={moment(selectedDate).format('YYYY/MM/DD')}
                            ref={register}
                            readOnly={true}
                        />
                    </FormRow>

                )
            }

            {
                currEvent === 'updateDayoff' ? (

                    <FormRow
                        labelTitle="姓名"
                        className="readOnly"
                        required
                    >
                        <input
                            type="text"
                            defaultValue={nameChinese}
                            disabled
                        />
                    </FormRow>

                ) : (

                    <div className="row leaveSelectName">
                        <div className="title isRequired">姓名</div>
                        <AutoComplete
                            placeholder="可輸入姓名、組別或部門"
                            options={handleMemberList(allMembers)}
                            value={selected.nameChinese.split('_')[1]}
                            disabled={!!(currEvent === 'updateDayoff')}
                            onChange={handleChangeOption}
                            filterOption={handleFilterOption}
                        />
                    </div>

                )
            }

            <div className="leaveTypeWrap leaveType">
                <h4 className="title isRequired">假別</h4>

                <RadioGroup
                    name="leaveType"
                    className="form-radios"
                    defaultValue={code}
                    onChange={handleLeaveTypeChange}
                >
                    {
                        dayOffTypes.map(({
                            id,
                            code,
                            name,
                            shortHandText,
                            colorCode,
                            colorText,
                        }) => (

                            <Radio
                                key={id}
                                value={code}
                            >
                                <span>{name}</span>

                                <Tags
                                    color={colorCode}
                                    textColor={colorText}
                                    text={shortHandText}
                                />
                            </Radio>

                        ))
                    }
                </RadioGroup>
            </div>

            <div className="leaveTypeWrap leaveHour">
                <h4 className="title isRequired">時數</h4>

                <RadioGroup
                    name="leaveHours"
                    className="form-radios"
                    defaultValue={leaveHours}
                    onChange={handleLeaveHourChange}
                >
                    {
                        showLeaveHourRadios ? (
                            filterHourOfLeaveType(storage.code).provideHours.map((hour) => (

                                <Radio
                                    key={hour}
                                    value={hour}
                                    {...!(hour === 480) ? { disabled } : null}
                                >
                                    {workLogHour[hour]}日
                                </Radio>

                            ))

                        ) : (

                            // 預設值
                            <Fragment>
                                <Radio value={240} disabled={disabled}>半日</Radio>
                                <Radio value={480}>1日</Radio>
                            </Fragment>

                        )
                    }
                </RadioGroup>
            </div>

            {showError && <OtherErrorMesg />}

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default LeaveTypeForm;
