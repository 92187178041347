import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import moment from 'moment';

import useToken from './lib/useToken';
import Guest from './components/Guest';

// Reducer
import { GlobalContext } from './context/global.state';
import { DayoffProvider } from './context/dayoff/dayoff.state';
import { ProjectProvider } from './context/project/project.state';
import { MemberProvider } from './context/member/member.state';
import { SkillProvider } from './context/skill/skill.state';

// 左側
import PageBroken from './containers/PageBroken';

// 右側
import Dashboard from './pages/index';
import Profile from './pages/member/Profile';
import Members from './pages/memberList/Members';
import Dayoff from './pages/dayoff/Dayoff';
import LazyList from './pages/lazyList/LazyList';

import MyProject from './pages/project/MyProject';
import Projects from './pages/projectList/Projects';
import SingleDetail from './pages/projectDetail/SingleDetail';
import TimelineData from './pages/projectList/TimelineData';
import SkillBase from './pages/skill/SkilBase';

import Setting from './pages/setting/Setting';
import Authority from './pages/setting/Authority';
import CalendarSetting from './pages/setting/CalendarSetting';
import Groups from './pages/setting/Groups';
import ProjectSetting from './pages/setting/ProjectSetting';
import CostRatio from './pages/setting/CostRatio';
import WorkLogType from './pages/setting/WorkLogType';
import Organize from './pages/setting/organize/Organize';
import OrganizeList from './pages/setting/organize/OrganizeList';

// 測試用
import Test from './pages/Test';
import PageNotFound from './containers/404';

const Content = () => {

    const token = useToken();

    // Context
    const {
        user,
        hasAuth,
        updateCalendar,
        hasCreateBtn,
        getGlobalData,
    } = useContext(GlobalContext);

    useEffect(() => {

        if (moment(updateCalendar) > moment(localStorage.getItem('update_calendar'))) localStorage.clear();
        if (!Object.keys(user).length) getGlobalData();
        if (!localStorage.getItem('update_calendar')) {

            localStorage.setItem('update_calendar', updateCalendar);
            localStorage.removeItem('calendar_2022'); // 強制清除

        }

    }, [updateCalendar]);

    return (

        <main className={`main ${hasCreateBtn ? 'hasCreateBtn' : ''}`}>

            {/*
                成員
                    > 個人資料
                    > 所有員工
                    > 休假人員 (總表)
            */}
            <MemberProvider>
                {/* Profile: 權限與否 */}
                <Route path={`/profile/:userId`} exact render={(props) => {

                    return token ? ((hasAuth.view_all_members || (+props.match.params.userId === user.userId)) ? <Profile {...props} /> : <PageBroken noAuth={true} />) : <Guest />;

                }} />
                <Route path={`/members`} exact render={(props) => token ? <Members {...props} /> : <Guest />} />
            </MemberProvider>

            <DayoffProvider>
                <Route path={`/dayoff`} exact render={(props) => token ? <Dayoff {...props} /> : <Guest />} />
            </DayoffProvider>

            <Route path={`/lazyList`} exact render={(props) => token ? <LazyList {...props} /> : <Guest />} />

            {/*
                專案
                    > 個人專案
                    > 所有專案
                    > 專案詳細
            */}
            <ProjectProvider>
                {/* 首頁 */}
                <Route path={`/index`} exact component={token ? Dashboard : Guest} />

                <Route path={`/myProject/:userId`} exact render={(props) => token ? <MyProject {...props} /> : <Guest />} />
                <Route path={`/projects`} exact render={(props) => token ? <Projects {...props} /> : <Guest />} />
                <Route path={`/projects/:id`} exact render={(props) => token ? <SingleDetail {...props} /> : <Guest />} />
                <Route path={`/projects/:id/timeline`} exact render={(props) => token ? <TimelineData {...props} /> : <Guest />} />
            </ProjectProvider>

            {/* 僅有權限之人才能進到這一頁 (by 權限頁設置) */}
            <SkillProvider>
                <Route path={`/skill`} exact render={(props) => token ? ((hasAuth.view_skill_tree) ? <SkillBase {...props} /> : <PageBroken noAuth={true} />) : <Guest />} />
            </SkillProvider>

            {/*
                總務 > 設定
                    -  群組
                    -  過濾群組
                    -  專案設定 (狀態與類型)
                    -  權限
                    -  登錄工時種類與假別
                    -  行事曆
                    -  子公司
                    -  工作天數與管銷成本
            */}
            <Route path={`/setting`} exact render={(props) => token ? <Setting {...props} /> : <Guest />} />
            <Route path={`/setting/groups`} exact render={(props) => token ? <Groups {...props} /> : <Guest />} />
            <Route path={`/setting/projectSetting`} exact render={(props) => token ? <ProjectSetting {...props} /> : <Guest />} />
            <Route path={`/setting/authority`} exact render={(props) => token ? <Authority {...props} /> : <Guest />} />
            <Route path={`/setting/workLogType`} exact render={(props) => token ? <WorkLogType {...props} /> : <Guest />} />
            <Route path={`/setting/calendar`} exact render={(props) => token ? <CalendarSetting {...props} /> : <Guest />} />
            <Route path={`/setting/costRatio`} exact render={(props) => token ? <CostRatio {...props} /> : <Guest />} />
            <Route path={`/setting/organize`} exact render={(props) => token ? <Organize {...props} /> : <Guest />} />
            <Route path={`/setting/organizeList`} exact render={(props) => token ? <OrganizeList {...props} /> : <Guest />} />


            {/* 錯誤頁面 */}
            <Route path={`/error`} exact component={PageNotFound} />

            {
                // 測試頁面: 僅本機測試環境才顯示
                process.env.NODE_ENV !== 'production' &&
                    <Route path={`/test`} exact component={Test} />
            }


            {/* 檢查頁面 */}
            {/* 首頁: 強制導向 index */}
            <Route path={`/`} exact render={() => <Redirect to={`/index`} />} />
            <Route path={`/index.html`} exact render={() => <Redirect to={`/index`} />} />


            {/* Profile: 錯誤路徑 */}
            <Route path={`/profile`} exact render={() => {

                return (user && user.userId) && <Redirect to={`/profile/${user.userId}`} />;

            }} />

        </main>

    );

};

export default Content;
