import React from 'react';
import { Result } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Buttons from '../components/Buttons';

const PageNotFound = () => (

    <Result
        className="brokenWrap page-not-found"
        status="404"
        title="404"
        subTitle="頁面不存在!!!"
        extra={
            <Link
                to="/"
                className="btn-back"
            >
                <Buttons
                    type="default"
                    text="回首頁"
                    icon={<HomeOutlined />}
                />
            </Link>
        }
    />

);

export {
    PageNotFound as default,
};
