const dayoffReducer = (state, { type, payload }) => {

    switch (type) {
        case 'member_list_month': // 當月
        case 'create_dayoff':
            return {
                ...state,
                subItems: payload.reduce((obj, { date, length, members }) => {

                    obj[date] = {
                        length,
                        lists: members,
                    };

                    return obj;

                }, {}),
            };

        // 當日
        case 'member_list_day':
            return {
                ...state,
                aDayList: payload,
            };

        case 'update_dayoff':
            return {
                ...state,
                aDayList: state.aDayList.map((obj) => (obj.id === payload.id) ? obj = payload : obj),
                subItems: {
                    ...state.subItems,
                    [payload.selectedDate]: {
                        ...state.subItems[payload.selectedDate],
                        lists: state.subItems[payload.selectedDate].lists.map((obj) => {

                            if (obj.id === payload.id) {

                                obj.code = payload.code;
                                obj.colorCode = payload.colorCode;
                                obj.colorText = payload.colorText;
                                obj.hours = payload.leaveHours;

                            }

                            return obj;

                        }),
                    },
                },
            };

        case 'remove_dayoff':
            return {
                ...state,
                aDayList: state.aDayList.filter(({ id }) => id !== payload.id),
                subItems: {
                    ...state.subItems,
                    [payload.selectedDate]: {
                        lists: state.subItems[payload.selectedDate].lists.filter(({ id }) => id !== payload.id),
                        length: state.subItems[payload.selectedDate].length - 1,
                    },
                },
            };

        default:
            return state;
    }

};

export {
    dayoffReducer,
};
