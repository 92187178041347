import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Tables from '../../components/Tables';
import Tags from '../../components/Tags';
import Prompt from '../../components/Prompt';

import { GlobalContext } from '../../context/global.state';
import { DayoffContext } from '../../context/dayoff/dayoff.state';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const { workLogHour } = moonConst;
const { mappingText } = PMB;

// 建立 Lightbox title
const renderLightboxTitle = (id) => (

    <Fragment>
        刪除
        <span className="small-text">(ID: {id})</span>
    </Fragment>

);

//
const List = ({ selectedDate }) => {

    // Context
    const {
        hasAuth: {
            view_all_members, // 觀看全部成員資料
            edit_day_off, // 編輯總表格子(休假)
        },
        dayOffTypes,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        aDayList,
        removeDayoff,
    } = useContext(DayoffContext);

    const columns = [
        {
            title: '假別',
            dataIndex: '',
            align: 'center',
            render: ({ code, colorCode, colorText }) => (

                <Tags
                    color={colorCode}
                    textColor={colorText || '#000'}
                    text={mappingText(dayOffTypes, code).name}
                />

            ),
        },
        {
            title: '時數 (日)',
            dataIndex: 'leaveHours',
            className: 'col-leaveHour',
            align: 'center',
            render: (leaveHours) => `${workLogHour[leaveHours]}日`,
        },
        {
            title: '姓名',
            dataIndex: 'nameChinese',
            className: 'col-projectName',
            render: (nameChinese, { userId }) => (

                <Link
                    to={`/${view_all_members ? 'profile' : 'myProject'}/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nameChinese || '--'}
                </Link>
            ),
        },
        {
            title: '建立者',
            dataIndex: 'creator',
            render: (creator) => `${creator}更新`,
        },
        {
            title: '操作',
            dataIndex: '',
            width: 140,
            render: (data) => (

                edit_day_off ? (

                    <Fragment>
                        <span
                            className="btn-update"
                            onClick={() => btnUpdateDayoff(data)}
                        >
                            <EditOutlined /> 編輯
                        </span>
                        <span
                            className="btn-delete"
                            onClick={(e) => btnRemoveDayoff(data, e)}
                        >
                            <DeleteOutlined /> 刪除
                        </span>
                    </Fragment>

                ) : '--'

            ),
        },
    ];

    // 編輯按鈕
    const btnUpdateDayoff = ({
        id,
        userId,
        nameChinese,
        code,
        leaveHours,
    }) => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id,
                userId,
                nameChinese,
                code,
                leaveHours,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateDayoff' });

    };

    // 刪除按鈕
    const btnRemoveDayoff = ({
        id,
        nameChinese,
        colorCode,
        colorText,
        leaveHours,
        shortHandText,
    }, e) => {

        e.stopPropagation();
        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: (

                <span>
                    你確定要刪除「
                    <span className="data">
                        {nameChinese}
                        <span className="tag">
                            <Tags
                                color={colorCode}
                                textColor={colorText}
                                text={shortHandText}
                                className="dayoff-delete"
                            />
                            <span>{`x ${workLogHour[leaveHours]}日`}</span>
                        </span>
                    </span>
                    」的這筆資料嗎？
                </span>

            ),
            callback: () => removeDayoff({ id, selectedDate }),
        });

    };

    return (

        <Tables
            className="dayoffLists"
            columns={columns}
            data={aDayList}
            rowKey="id"
            showTitle={true}
            showPage={false}
        />

    );

};

export default List;
