import React, { Fragment, useContext } from 'react';

import {
    HomeOutlined,
    UserOutlined,
    TeamOutlined,
    BarChartOutlined,
    WarningOutlined,
    SolutionOutlined,
    UnorderedListOutlined,
    RadarChartOutlined,
    PieChartOutlined,
    SettingOutlined,
} from '@ant-design/icons';

import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/global.state';
import moonConst from '../lib/moon.const';

const { navbar } = moonConst;

// 為了 antd 4 的 icon 寫法而做的 mapping fn
const iconConvertComponent = (param) => {

    return {
        Home: HomeOutlined,
        User: UserOutlined,
        Team: TeamOutlined,
        BarChart: BarChartOutlined,
        Warning: WarningOutlined,
        Solution: SolutionOutlined,
        UnorderedList: UnorderedListOutlined,
        RadarChart: RadarChartOutlined,
        PieChart: PieChartOutlined,
        Setting: SettingOutlined,
    }[param];

};

// 加 Active
const targetActive = (page, path) => (page === path) ? 'active' : '';

// 過濾權限：編輯設定 & 觀看統計才顯示總務區塊
const filterAuthMenu = ({ edit_settings, view_analysis }) => navbar.filter((obj) => {

    return (edit_settings || view_analysis) ? obj : obj.pageKey !== 'affair';

});

// 子層權限設定: 編輯設定 || 觀看統計
const subMenuAuthConfig = ({ view_skill_tree, edit_settings, view_analysis }) => ({
    skill: view_skill_tree ? '' : 'hide',
    setting: edit_settings ? '' : 'hide',
});

// 選單所需元件
const renderMenuLayout = ({
    icon,
    name,
    pageKey,
    user,
    globalDispatch,
}) => {

    const IconComp = iconConvertComponent(icon);
    const paramLink = (pageKey === 'myProject') ? `${pageKey}/${user.userId}` : `${pageKey}/${user.userId}`;
    const path = (pageKey === 'profile' || pageKey === 'myProject') ? paramLink : pageKey;
    const handleClick = () => globalDispatch({ type: 'PAGE', payload: pageKey });

    return (

        <Fragment>
            <IconComp />
            <span>{name}</span>
            <Link to={`/${path}`} onClick={handleClick} />
        </Fragment>

    );

};

// 建立子層選單
const renderSubMenus = ({
    subItems,
    user,
    pathname,
    hasAuth,
    globalDispatch,
}) => (

    subItems.map(({ pageKey, icon, name }) => (

        <div
            key={pageKey}
            className={`sub-menu ${subMenuAuthConfig(hasAuth)[pageKey] || ''} ${targetActive(pageKey, pathname)}`}
        >
            {
                renderMenuLayout({
                    icon,
                    name,
                    pageKey,
                    user,
                    globalDispatch,
                })
            }
        </div>

    ))

);

const NavbarLinks = () => {

    const location = useLocation();
    const regExp = new RegExp(`^./`);
    const pathName = location.pathname.replace(regExp, '').split('/')[1];

    // Context
    const {
        user,
        hasAuth,
        globalDispatch,
    } = useContext(GlobalContext);

    return (

        <div className="navbar-wrap">
            {
                filterAuthMenu(hasAuth).map(({
                    icon,
                    name,
                    pageKey,
                    subItems,
                }) => (

                    <div
                        key={pageKey}
                        className={`menu ${subItems && subItems.length ? '' : 'menu-single'} ${targetActive(pageKey, pathName)}`}
                    >
                        {
                            // 有子層
                            (subItems && subItems.length) ? (

                                <Fragment>
                                    <h5 className="small-title">{name}</h5>

                                    {
                                        renderSubMenus({
                                            subItems,
                                            user,
                                            hasAuth,
                                            pathname: pathName,
                                            globalDispatch,
                                        })
                                    }
                                </Fragment>

                            ) : (

                                <Fragment>
                                    {
                                        renderMenuLayout({
                                            icon,
                                            name,
                                            pageKey,
                                            user,
                                            globalDispatch,
                                        })
                                    }
                                </Fragment>

                            )
                        }
                    </div>

                ))
            }
        </div>

    );

};

export {
    NavbarLinks as default,
};
