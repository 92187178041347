import axios from 'axios';
import Cookies from 'js-cookie';
import { Modal } from 'antd';
import moon from './moon';
import moonConst from './moon.const';

const { add, mul } = moon;
const { pmbHost, apiHost } = moonConst;

const PMB = {
    /**
     * @author Betty
     * @param  {object{} || string} service - 如果是字串，則為 service.url
     *   @param {string} service.url
     *   @param {string} [service.method = 'post']
     *   @param {string} [service.dataType = 'json']
     * @param  {object{}} reqData
     * @param  {object{}} option
     * @returns {promise}
     */
    serviceProxy: (service, reqData = {}, option) => {

        // method, url 與環境設定
        const CONFIG = () => {

                let url = '';
                let method = option?.fake ? 'get' : 'post';

                if (typeof service === 'string') url = service;
                else {

                    url = service.url;
                    method = service.method;

                }

                return {
                    url: option?.fake ? `${process.env.PUBLIC_URL}/json${url}` : `${apiHost ? apiHost : pmbHost + '/api'}${url}`,
                    method,
                };

            },
            showErrorMesg = (message, callback) => {

                Modal.error({
                    title: '發生錯誤',
                    content: message || '出了些狀況，請找研發',
                    ...callback && {
                        onOk: () => {

                            if (callback) callback();

                        },
                    },
                });

            };

        // 回傳 promise
        return new Promise((resolve, reject) => {

            const authHeader = {
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`,
                },
            };

            axios[CONFIG().method](CONFIG().url, reqData, {
                withCredentials: true,
                ...authHeader,
                ...option,
            })
            .then(
                // result: 1
                ({ data }) => {

                    resolve(data.data);

                },
                // result: 0
                ({ response }) => {

                    const {
                        data: { message },
                    } = response;

                    reject(showErrorMesg(message));

                },
            )
            .catch((error) => {

                console.log('error:', error);

            })

        });

    },

    /**
     * @author Betty
     * @param {string} query - 代入 URL 的 query string
     * @return {boolean}
     */
    getURLParams: (query) => {

        const urlParams = new URLSearchParams(window.location.search);

        return (urlParams.get(query) === '1') ? true : false;

    },

    /**
     * @author Betty
     * @param {string} path - /${pathname}/xxx
     * @return {array}
     */
    getPathname: (path) => path.split('/'),

    /**
     * @author Betty
     * @param {string || number} param1 - 可以是字串或數字
     * @param {string || number} param2 - 可以是字串或數字
     * @return {boolean}
     * 排序: Ascend/Descend
     */
    compareData: (param1, param2) => {

        if (typeof param1 === 'number') return param1 - param2;
        else {

            if (param1 > param2) return 1;
            if (param1 < param2) return -1;

            return 0;

        }

    },

    /**
     * @author Betty
     * @param {string} param1 - 字串
     * @param {string} param2 - 字串
     * 清除 DOM
     */
    removeElems: (elems, classname) => {

        [].forEach.call(document.querySelectorAll(`${elems}`), (elem) => elem.classList.remove(`${classname}`));

    },

    /**
     * @author Betty
     * @param {string} param1 - 字串
     * @return {object}
     */
    tagStyle: (textColor) => ({
        color: textColor,
    }),

    /**
     * @author Betty
     * @param {array} param1 - 陣列
     * @param {string} param2 - 字串
     * @return {object}
     */
    mappingText: (array, code) => array.filter((obj) => obj.code === code)[0],

    /**
     * @author Betty
     * @param {object} param1 - 物件
     * @return {boolean}
     */
    checkReqData: (reqData) => {

        let params = Object.keys(reqData).map((key) => {

            if (reqData[key] === '') reqData[key] = null;
            return reqData;

        })[0];

        let checkEmpty = Object.keys(params).some((key) => params[key] === null);

        return checkEmpty;

    },

    /**
     * @author Betty
     * @param {number} param1
     * @return {number}
     */
    roundNumber: (num) => Math.round(num * 100) / 100,

    /**
     * @author Betty
     * @param {number} param1 - 小時
     * @param {number} param2 - 分鐘
     * @return {number}
     */
    transferHourToTime: (hour, minute) => {

        const newHour = mul(+hour, 60);
        const newMinute = (+minute === 30) ? +minute : 0;

        return add(newHour, newMinute);

    },

    /**
     * @author Betty
     * @param {number} param1 - 分鐘
     * @return {object} - 小時、分鐘
     */
    transferTimeToHour: (param) => {

        const hour = (param / 60);
        const rhour = Math.floor(hour);
        const minute = (hour - rhour) * 60;
        const rminute = Math.round(minute);

        return { hour: rhour || 0, minute: rminute || 0 };

    },

    /**
     * @author Betty
     * @param {array} param1 - 整理領域結構
     * @return {object} - 名稱、顏色
     */
    handleAreaData: (data) => data.reduce((acc, obj) => {

        acc[obj.id] = {
            name: obj.name,
            color: obj.colorCode,
        };

        return acc;

    }, {}),

    /**
     * @author Betty
     * @param {object} param1 - 收集 checkbox 的物件
     * @return {array} - 回傳陣列結構 ['string]
     */
    collectCheckboxWithString: (object) => Object.keys(object).filter((key) => object[key]),

    /**
     * @author Betty
     * @param {object} param1 - 收集 checkbox 的物件
     * @return {array} - 回傳陣列結構 [id]
     */
    collectCheckboxWithNumber: (object) => Object.keys(object).filter((key) => object[key]).flatMap((key) => +key),

    /**
     * @author Betty
     * @param {array, object} param1 - 整理資料
     * @return {array} - 回傳陣列結構
     * Antd 表格欄位過濾結構: 部門與組別
     */
    antdTableFilter: (array, type = 'group') => {

        let data = (type === 'group') ? array : Object.keys(array);

        return data.reduce((acc, curr) => {

            const param1 = (type === 'group') ? curr.id : +curr;
            const param2 = (type === 'group') ? curr.name : array[curr];
            const obj = {
                text: param2,
                value: param1,
            };

            acc.push(obj);
            return acc;

        }, []);

    },

};

export default PMB;
