import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Calendar } from 'antd';
import moment from 'moment';

import './CalendarTool.scss';
import '../lib/momentConfig';
import Tags from './Tags';
import PopConfirm from './PopConfirm';
import { Store } from '../Wrap';
import moonConst from '../lib/moon.const';
import PMB from '../lib/moon.pmb';

const { workLogHour } = moonConst;
const { roundNumber } = PMB;
const workLogText = {
    estimate: '預計',
    work: '確認',
};

// 事項: 完整樣式
const FullItem = ({
    data: {
        id,
        nameChinese,
        colorCode,
        colorText,
        shortHandText,
        hours,
    },
}) => (

    <div
        data-id={id}
        className="item"
    >
        <span className="username">{nameChinese}</span>

        <Tags
            color={colorCode}
            textColor={colorText}
            text={shortHandText}
        />

        <span>{`x ${workLogHour[hours]}日`}</span>
    </div>

);

// 事項: 簡易樣式
const SimpleItem = ({
    withoutText,
    data: {
        code,
        colorCode,
        colorText,
        shortHandText,
        hours,
    },
}) => (

    <div
        className="item"
        style={{
            color: colorText,
            backgroundColor: colorCode,
        }}
    >
        {
            !withoutText && `${shortHandText ? shortHandText : workLogText[code]} x ${roundNumber(hours)}h`
        }
    </div>

);

const CalendarTool = ({
    showDetailTip,
    showSelectedDateBox,
    className,
    selectedDate,
    subItemType,
    subItems,
    withoutText,
    fullscreen,
    validRange,
    handleSelected,
    handleClickDate,
    handleCancelDetailTip,
    panelChangeCallback,
    children,
}) => {

    // Context
    const { calendarCallback, calendarList } = useContext(Store);

    // State
    const [month, setMonth] = useState(0);

    // 年、月下拉選單
    const handlePanelChange = (date) => {

        const getYear = moment(date).year();

        // 取年
        setMonth(moment(date).month() + 1);

        // 告知 Wrap.js 是否需要更新 calendar
        calendarCallback({ year: getYear, state: !localStorage.getItem(`calendar_${getYear}`) });

        // Dayoff 頁 panel change 的 callback
        panelChangeCallback && panelChangeCallback(moment(date).format('YYYY-MM'));

    };

    // 日期格式
    const dateFormat = (date) => moment(date).format('YYYY-MM-DD');

    // [年模式] 過濾選取的月份
    const filterMonth = (data) => {

        const getMonth = month ? month : (moment().month() + 1);
        return data.filter(({ date }) => (moment(date).month() + 1) === getMonth);

    };

    // [年模式] 過濾每個月的特殊節日
    const filterMonthCell = (data) => {

        return data.reduce((acc, obj) => {

            const { date } = obj;
            const key = `month_${moment(date).month() + 1}`;

            acc[key] = acc[key] || [];
            acc[key].push(obj);

            return acc;

        }, {});

    };

    // [月模式] 套用節日到日
    const dateFullCellRender = (date) => {

        const listData = filterMonth(calendarList);
        const targetHoliday = listData.filter((obj) => obj.date === dateFormat(date))[0] || '';
        const subItemData = subItems[dateFormat(date)];

        const {
            calendarId,
            name,
            isHoliday,
        } = targetHoliday;

        return (

            <PopConfirm
                title={
                    <Fragment>
                        <h2 className="date-title">{moment(date).format('YYYY/MM/DD (ddd)')}</h2>
                        {showDetailTip && children}
                    </Fragment>
                }
                visible={(selectedDate === moment(date).format('YYYY-MM-DD')) && showDetailTip}
                onCancel={handleCancelDetailTip}
            >
                 <div
                    className={`dates ${isHoliday ? 'isHoliday' : 'notHoliday'}`}
                    title={name && name}
                    data-id={calendarId}
                    data-name={name}
                    data-date={dateFormat(date)}
                    data-holiday={isHoliday ? '1' : '0'}
                    onClick={handleClickDate}
                >
                    <div className="date">{moment(date).date()}</div>

                    {
                        // 有 name 為特殊節日
                        // name && <p className="name">{name}</p>
                        name && (

                            withoutText ? <span className="holiday-dot"></span> : <p className="name">{name}</p>

                        )
                    }

                    {
                        // 子事項
                        !!Object.entries(subItems).length &&
                            <div className={`items type-${subItemType}`}>
                                {
                                    subItemData &&
                                        renderItems({
                                            withoutText,
                                            items: subItemData?.lists || subItemData,
                                            length: subItemData?.length || 0,
                                        })
                                }
                            </div>
                    }
                </div>
            </PopConfirm>

        );

    };

    // 每日事項
    const renderItems = ({ withoutText, items, length }) => (

        <Fragment>
            {
                items.map((obj, idx) => (

                    <Fragment key={idx}>

                        {subItemType === 'simple' ? <SimpleItem data={obj} withoutText={withoutText} /> : <FullItem data={obj} />}

                    </Fragment>

                ))
            }

            {
                // 剩餘筆數提示
                (subItemType !== 'simple' && length > 5) &&
                    <span className="moreItems">還有 {length - items.length} 筆</span>
            }
        </Fragment>

    );

    // 套用節日到每個月份
    const monthCellRender = (value) => {

        const listData = filterMonthCell(calendarList);
        const getMonth = moment(value).month() + 1;

        return (

            listData[`month_${getMonth}`] && listData[`month_${getMonth}`].map(({
                calendarId,
                date,
                name,
                isHoliday,
                holidayCategory,
            }) => (

                <div
                    key={calendarId}
                    className={`month-mode-list ${!isHoliday && 'notHoliday'}`}
                >
                    <span className="label">{moment(date).format('M/D (ddd)')}</span>
                    <span>{name ? name : holidayCategory}</span>
                </div>

            ))

        );

    };

    return (

        <Fragment>

            {
                showSelectedDateBox &&
                    <Alert
                        className="noticeBox"
                        message={`選取日期：${selectedDate ? moment(selectedDate).format('YYYY/MM/DD (ddd)') : moment().format('YYYY/MM/DD (ddd)')}`}
                    />
            }

            <Calendar
                className={`calendarWrap ${className && className}`}
                fullscreen={fullscreen}
                validRange={validRange}
                dateFullCellRender={dateFullCellRender}
                monthCellRender={monthCellRender}
                onSelect={handleSelected}
                onPanelChange={handlePanelChange}
                locale={{
                    lang: {
                        locale: 'zh-tw',
                        month: '月',
                        year: '年',
                    }
                }}
            />

        </Fragment>

    );

};

CalendarTool.defaultProps = {
    showDetailTip: false,
    showSelectedDateBox: true,
    className: '',
    withoutText: false,
    fullscreen: true,
    subItemType: 'full',
    subItems: {},
};

CalendarTool.propTypes = {
    showDetailTip: PropTypes.bool,
    showSelectedDateBox: PropTypes.bool,
    className: PropTypes.string,
    fullscreen: PropTypes.bool,
    validRange: PropTypes.array,
    selectedDate: PropTypes.string,
    subItemType: PropTypes.string,
    subItems: PropTypes.object,
    withoutText: PropTypes.bool,
    handleSelected: PropTypes.func,
    handleClickDate: PropTypes.func,
    panelChangeCallback: PropTypes.func,
};

export default CalendarTool;
