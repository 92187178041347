import React, {
    useState,
    useEffect,
    useContext
} from 'react';

import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';

import './LazyList.scss';
import Loading from '../../components/Loading';
import Search from './Search';
import List from './List';
import { GlobalContext } from '../../context/global.state';
import Service from '../../lib/moon.service';

// 過濾已選取 checkboxes
const filterSelectedCheckboxes = (selected) => Object.keys(selected).filter((key) => selected[key]);

// 整理資料
const arrangeCheckboxesData = ({ data, checked = false }) => data.reduce((obj, param) => {

    const key = param.id;

    obj[key] = checked;
    return obj;

}, {});

// 正規檢查 Checkbox name
const checkboxRegex = (checkboxes) => new RegExp(`^(${checkboxes.join('|')})$`);

// Service
async function fetchData () {

    return await Service.lazyList();
}

const LazyList = () => {

    // State
    const [isSearch, setIsSearch] = useState(false);
    const [checkedAllGroup, setCheckedAllGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    // Data
    const [lists, setLists] = useState([]);
    const [searchResult, setSearchResult] = useState([]);

    // Context
    const { groups } = useContext(GlobalContext);

    // React Hook Form
    const { handleSubmit, reset } = useForm();

    useEffect(() => {

        document.title = '閒置清單';

        fetchData()
            .then(handleFetchData);

    }, []);

    const handleFetchData = ({ lists }) => {

        setLists(lists);

    };

    // 搜尋: checkbox 全選
    const handleCheckedAllToggle = () => {

        const targetSelected = {
            ...selectedGroup,
            ...arrangeCheckboxesData({ data: groups, checked: !checkedAllGroup }),
        };

        setCheckedAllGroup(!checkedAllGroup);
        setSelectedGroup({ ...targetSelected });

    };

    // 還原 selected checkboxes
    const resetSelected = () => {

        setSelectedGroup(arrangeCheckboxesData({ data: groups }));

    };

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setIsSearch(false);
        setSearchResult([]);
        resetSelected();

    };

    // 搜尋: checkbox 事件
    const handleCheckboxChange = ({ target }) => {

        const { name, checked } = target;

        setSelectedGroup({
            ...selectedGroup,
            [name]: checked,
        });

    };

    // 搜尋: 送資料
    const handleSearchReqData = (reqData) => {

        reqData = {
            ...reqData,
            groups: filterSelectedCheckboxes(selectedGroup).flatMap((obj) => +obj),
        };

        setIsSearch(true);
        setSearchResult(lists.filter(({ groupId }) => {

            return (reqData.groups.length ? checkboxRegex(reqData.groups).test(groupId) : true);

        }));

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <Row gutter={20}>
            <Col flex="0 0 220px">
                <Search
                    selectedGroup={selectedGroup}
                    checkedAllGroup={checkedAllGroup}
                    handleSearchClear={handleSearchClear}
                    handleSubmit={handleSubmit(handleSearchReqData)}
                    handleCheckboxChange={handleCheckboxChange}
                    handleCheckedAllToggle={handleCheckedAllToggle}
                />
            </Col>

            <Col flex="1">
                <List
                    membersData={isSearch ? searchResult : lists}
                />
            </Col>
        </Row>

    );

};

export default LazyList;
