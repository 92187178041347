import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export default function useToken () {

    // State
    const [token, setToken] = useState('');

    useEffect(() => {

        setToken(Cookies.get('access_token'));

    }, []);

    return token;

}
