import React, { Fragment, useState, useEffect } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import './Test.scss';
import Buttons from '../components/Buttons';
import Tables from '../components/Tables';
import Drawers from '../components/Drawers';
import Cards from '../components/Cards';
import CalendarTool from '../components/CalendarTool';
import { EmptyData } from '../containers/PageBroken';
import { Chart } from 'react-google-charts';

const filterArea = (data) => data.reduce((acc, obj) => {

    acc[obj.id] = {
        name: obj.name,
        color: obj.colorCode,
    };

    return acc;

}, {});

const handleData = (data) => data.reduce((acc, { areaId, id, name, members }) => {

    const obj = { id, name, members };

    if (acc[areaId]) acc[areaId].push(obj);
    else acc[areaId] = [obj];

    return acc;

}, {});

const fakeData = {
    areas: [
        {
            id: 1111,
            name: "領域01",
            colorCode: "#B8B8B8",
        },
        {
            id: 2222,
            name: "領域02",
            colorCode: "#4CA8AA",
        },
        {
            id: 3333,
            name: "領域03",
            colorCode: "#EF5350",
        },
    ],
    lists: [
        {
            areaId: 1111,
            id: 11111111,
            name: '領域01-001',
            members: [111, 333, 444],
        },
        {
            areaId: 1111,
            id: 22222222,
            name: '領域01-002',
            members: [222],
        },
        {
            areaId: 1111,
            id: 33333333,
            name: '領域01-003',
            members: [111],
        },
        {
            areaId: 2222,
            id: 44444444,
            name: '領域02-001',
            members: [999],
        },
        {
            areaId: 2222,
            id: 55555555,
            name: '領域02-002',
            members: [444, 555],
        },
    ],
};

// console.log('filterArea:', filterArea(fakeData.areas))
// console.log('handleData:', handleData(fakeData.lists))

const newObj = handleData(fakeData.lists);

const Test = () => {

    const [visible, setVisible] = useState(false);

    // console.log('check1:', moment('2020-10-17').startOf('week').subtract(1, 'weeks').format('YYYY-MM-DD'));
    // console.log('check2:', moment('2020-10-17').endOf('week').subtract(1, 'weeks').format('YYYY-MM-DD'));
    // console.log('startOf:', moment().startOf('week').day(-7).format('YYYY-MM-DD'));
    // console.log('endOf:', moment().endOf('week').day(7).format('YYYY-MM-DD'));

    return (

        <Fragment>

            <div className="skillWrap">
                {
                    Object.keys(newObj).map((key) => {

                        return (

                            <div
                                key={key}
                                className="row-test"
                            >
                                <div
                                    className="test-left"
                                    style={{ backgroundColor: filterArea(fakeData.areas)[key].color }}
                                >
                                    {filterArea(fakeData.areas)[key].name}
                                </div>

                                <div className="test-middle">
                                    {
                                        newObj[key].map((obj) => (

                                            <div key={obj.id}>
                                                <div className="_name">{obj.name}</div>
                                                <div className="_members">
                                                    {
                                                        obj.members.map((data, idx) => (

                                                            <div key={idx}>{data}</div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        ))
                                    }
                                </div>

                                <div className="test-right">
                                    <Buttons text="編輯" />
                                    <Buttons text="刪除" />
                                </div>
                            </div>

                        );

                    })
                }
            </div>

            {/* <CalendarTool
                showSelectedDateBox={false}
                className="betty-test"
                validRange={[moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'weeks')]}
            /> */}

            {/* <Chart
                width={1000}
                height={350}
                chartType="Calendar"
                data={[
                    [{ type: 'date', id: 'Date' }, { type: 'number', id: 'Won/Loss' }],
                    [new Date(2012, 3, 13), 37032],
                    [new Date(2012, 3, 14), 38024],
                    [new Date(2012, 3, 15), 38024],
                ]}
                options={{
                    title: '月曆',
                }}
            /> */}

            {/* <Buttons
                handleClick={() => setVisible(true)}
                data-button="data attribute"
                icon={<PlayCircleOutlined />}
            /> */}

        </Fragment>

    );

};

export {
    Test as default,
};
