import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { CheckSquareOutlined } from '@ant-design/icons';
import moment from 'moment';

import { OtherErrorMesg } from '../../components/LightboxForm';
import Tags from '../../components/Tags';
import { Rangepickers } from '../../components/Datepickers';
import { FormRow } from '../../components/LightboxForm';

import { GlobalContext } from '../../context/global.state';
import PMB from '../../lib/moon.pmb';

const RadioGroup = Radio.Group;
const { transferTimeToHour } = PMB;

const WorkLogForm = ({
    selectedDate,
    storage,
    currTask,
    showError,
    currBtnType,
    handleRangepicker,
    handleWorkLogTypeChange,
    register,
    errors,
    currEvent,
}) => {

    // Context
    const {
        workLogTypes,
        workLogRemind: { workLogMinutesToday },
    } = useContext(GlobalContext);

    const { workLogType, workLogHours } = storage;

    return (

        <Fragment>

            <FormRow
                labelTitle={
                    <Fragment>
                        項目名稱
                        {currTask.taskId && <span className="small-text">(項目 ID: {currTask.taskId})</span>}
                    </Fragment>
                }
                className="readOnly"
            >
                <input
                    type="text"
                    defaultValue={currTask.name}
                    readOnly
                />
            </FormRow>

            <div className="row">
                {
                    currBtnType === 'fill' ? (

                        <Fragment>
                            <div className="title isRequired">登錄日期 (YYYY/MM/DD)</div>
                            <Rangepickers
                                handleChange={handleRangepicker}
                            />
                        </Fragment>

                    ) : (

                        <FormRow
                            labelTitle="登錄日期 (YYYY/MM/DD)"
                            className="readOnly"
                            notes="無法更改日期，若要更新日期請先刪除後再重新建立"
                        >
                            <input
                                type="text"
                                defaultValue={moment(selectedDate).format('YYYY/MM/DD')}
                                readOnly={true}
                            />
                        </FormRow>

                    )
                }

            </div>

            <div className="items workLogWrap">
                <div className="row workLogType">
                    <div className="title isRequired">種類</div>
                    <div className="field noBorder">
                        <RadioGroup
                            name="logType"
                            className="form-radios"
                            defaultValue={workLogType}
                            onChange={handleWorkLogTypeChange}
                        >
                            {
                                workLogTypes.map(({
                                    id,
                                    code,
                                    name,
                                    colorCode,
                                    colorText,
                                }) => (

                                    <Radio
                                        key={id}
                                        value={code}
                                        className={code === 'estimate' ? 'estimate' : ''}
                                    >
                                        <span>{name}</span>
                                        <Tags
                                            color={colorCode}
                                            textColor={colorText}
                                            text={name}
                                        />
                                        {
                                            (code === 'overtime' || code === 'overtime_honor') &&
                                                <span className="small-text">(限假日)</span>
                                        }
                                        {
                                            (code === 'extra') &&
                                                <span className="small-text">(限平日超過8H)</span>
                                        }
                                    </Radio>

                                ))
                            }
                        </RadioGroup>
                    </div>
                </div>

                <div className="row workLogHours">
                    <div className="title isRequired">工時 <span className="warning-text">(最小單位為 30 分鐘)</span></div>
                    <div className="field noBorder">
                        <span className="field label-hour">
                            <input
                                type="number"
                                name="hour"
                                defaultValue={workLogHours && transferTimeToHour(workLogHours).hour}
                                placeholder="小時"
                                ref={register({ required: true, min: 0 })}
                            />
                            {
                                // errors.hour && <div className="required">必填欄位</div>
                            }
                        </span>
                        <select
                            className="label-minute"
                            name="minute"
                            defaultValue={(workLogHours && transferTimeToHour(workLogHours).minute) || '00'}
                            ref={register}
                        >
                            <option value="00">00</option>
                            <option value="30">30</option>
                        </select>
                    </div>

                    {
                        errors.hour && <div className="required">必填欄位</div>
                    }

                    <ul className="workLogReminder">
                        {
                            (currEvent === 'updateWorkLog') &&
                                <li className="reminder-item">
                                    <CheckSquareOutlined />
                                    原時數為 {transferTimeToHour(workLogHours).hour}h {transferTimeToHour(workLogHours).minute}m
                                </li>
                        }
                        <li className="reminder-item">
                            <CheckSquareOutlined />
                            今日已填 {transferTimeToHour(workLogMinutesToday).hour}h {transferTimeToHour(workLogMinutesToday).minute}m
                        </li>
                    </ul>
                </div>
            </div>

            {showError && <OtherErrorMesg />}

        </Fragment>

    );

};

WorkLogForm.defaultProps = {
    showError: false,
};

WorkLogForm.propTypes = {
    storage: PropTypes.object,
    showError: PropTypes.bool,
    currWorkLogType: PropTypes.string.isRequired,
    handleRangepicker: PropTypes.func,
    handleWorkLogTypeChange: PropTypes.func,
    handleWorkLogHoursChange: PropTypes.func,
};

export {
    WorkLogForm as default,
};
