import React, {
    Fragment,
    useState,
    useContext,
} from 'react';

import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';

import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import Loading from '../../components/Loading';
import Drawers from '../../components/Drawers';
import FilesDownload from '../../components/project/FilesDownload';

import UploadFiles from './UploadFiles';
import ToDoList from './ToDoList';
import ChangeLogs from './ChangeLogs';
import OtherFeeList from './OtherFeeList';
import DetailAssignee from './DetailAssignee';
import DetailDate from './DetailDate';
import DetailAccounting from './DetailAccounting';
import DetailGroupBudget from './DetailGroupBudget';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';
import PMB from '../../lib/moon.pmb';
import Service from '../../lib/moon.service';

const { priceWithCommas, checkObjLength } = moon;
const { mappingText } = PMB;
const detailColumnRight = '0.8';

// 專案狀態 Tag
const renderTag = ({ text, colorCode, colorText, className }) => (

    <Tags
        className={className}
        color={colorCode}
        textColor={colorText}
        text={text}
    />

);

// 無連結字
const renderTextWithoutLink = (text) => text ? text : '--';

// TitleBar
const TitleBar = ({
    isHide,
    titleKey,
    text,
    withCollapse,
    onClick,
    children,
}) => (

    <h3
        className="label-title"
        {...titleKey ? { onClick: (e) => onClick(titleKey, e) } : {}}
    >
        {
            text ? <span>{text}</span> : children
        }

        {
            withCollapse &&
                <CaretDownOutlined
                    className="arrow-down"
                    rotate={isHide[titleKey] ? 180 : 0}
                />
        }
    </h3>

);

const Detail = ({
    handleUpdateProject,
}) => {

    // Context
    const {
        projectStatus,
        hasAuth: { edit_all_projects, view_personal_cost }, // 編輯全部專案資料
        isLoading,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        detail,
        otherFee,
        otherFeeList,
    } = useContext(ProjectContext);

    // State
    const [isHide, setIsHide] = useState({
        details: false,
        upload: false,
        tasks: false,
        history: false,
    });

    const [showDrawer, setShowDrawer] = useState(false);

    // Title 收合
    const handleToggleTitleBar = (key) => {

        setIsHide({
            ...isHide,
            [key]: !isHide[key],
        });

    };

    // 關閉 Drawer 區塊
    const handleCloseDrawer = () => setShowDrawer(false);

    // 另計支出事件與取得列表
    const handleOtherFee = (pid) => {

        setShowDrawer(true);

        if (otherFee.length) return;
        otherFeeList(pid);

    };

    // 新增小組預算按鈕
    const btnCreate = () => {

        formStorageDispatch({ type: 'COLLECT', payload: { pid: detail.id }});
        lightboxDispatch({ type: 'SHOW', currEvent: 'createGroupBudget' });

    };

    // Excel
    const btnDownloadExcel = (id, type) => {

        const serviceKey = (type === 'credit') ? 'downloadExcelCredit' : 'downloadExcelWorklog';
        window.open(Service[serviceKey](`projectId=${id}`));

    };

    return (

        <div className="projectDetailWrap">
            <Row className="project-header">
                <Col
                    flex="1"
                    className="title"
                >
                    {detail?.id && <h1>{`P${detail?.id} - ${detail?.name}`}</h1>}
                </Col>

                {
                    edit_all_projects &&
                        <Col
                            span={5}
                            className="btn-actions"
                        >
                            <Buttons
                                text="編輯專案"
                                className="btn-update"
                                handleClick={() => handleUpdateProject(detail)}
                            />
                        </Col>
                }
            </Row>

            <Row className="project-detail">
                <Col
                    flex="1"
                    className="column-left"
                >
                    {/* Details */}
                    <div className="projectDetailSectionWrap section-details">
                        <TitleBar
                            isHide={isHide}
                            titleKey="details"
                            text="明細"
                            withCollapse={true}
                            onClick={handleToggleTitleBar}
                        />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className={`detail-content ${isHide.details ? 'hide' : ''}`}>

                                    <Row>
                                        <Col flex="1" className="col-right">
                                            <Row>
                                                <Col flex="1">狀態:</Col>
                                                <Col flex="1">
                                                    {
                                                        renderTag({
                                                            text: mappingText(projectStatus, detail?.code)?.name,
                                                            colorCode: mappingText(projectStatus, detail?.code)?.colorCode,
                                                            colorText: mappingText(projectStatus, detail?.code)?.colorText,
                                                        })
                                                    }
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">類型:</Col>
                                                <Col flex="1">{renderTextWithoutLink(detail?.typeName)}</Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">預算(未稅):</Col>
                                                <Col flex="1">{priceWithCommas(detail?.budget)}</Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">其他下載:</Col>
                                                <Col flex="1">
                                                    <FilesDownload
                                                        worklog={view_personal_cost ? () => btnDownloadExcel(detail.id) : null}
                                                        credit={() => btnDownloadExcel(detail.id, 'credit')}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col flex="1">
                                            <Row>
                                                <Col flex="1">
                                                    <span
                                                        className="other-fee"
                                                        onClick={() => handleOtherFee(detail.id)}
                                                    >
                                                        另計支出
                                                    </span>:
                                                </Col>
                                                <Col flex="1">{priceWithCommas(detail?.otherFee)}</Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">人力成本:</Col>
                                                <Col flex="1">{priceWithCommas(detail?.cost)}</Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">超時成本:</Col>
                                                <Col flex="1">{priceWithCommas(detail?.extraCost)}</Col>
                                            </Row>

                                            <Row>
                                                <Col flex="1">利潤:</Col>
                                                <Col flex="1">{priceWithCommas(detail?.profit)}</Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* Notes */}
                    <div className="projectDetailSectionWrap section-notes">
                        <TitleBar text="備註" />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className="detail-content">
                                    {detail?.notes}
                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* UploadFiles */}
                    <div className="projectDetailSectionWrap section-UploadFiless">
                        <TitleBar
                            isHide={isHide}
                            titleKey="upload"
                            text="附加檔案"
                            withCollapse={true}
                            onClick={handleToggleTitleBar}
                        />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className={`detail-content ${isHide.upload ? 'hide' : ''}`}>
                                    <UploadFiles
                                        showHintMesg={<Fragment>為了讓系統能最穩定地執行，請盡量將檔案控制在 <span className="size">10MB</span> 以下。</Fragment>}
                                    />
                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* Tasks */}
                    <div className="projectDetailSectionWrap section-tasks">
                        <TitleBar
                            isHide={isHide}
                            titleKey="tasks"
                            text="任務與項目"
                            withCollapse={true}
                            onClick={handleToggleTitleBar}
                        />

                        <div className={`detail-content ${isHide.tasks ? 'hide' : ''}`}>
                            {isLoading ? <Loading /> : <ToDoList />}
                        </div>
                    </div>

                    {/* Project History */}
                    {
                        // Notes: 待上線後再做，先隱藏
                        false &&
                            <div className="projectDetailSectionWrap section-history">
                                <TitleBar
                                    isHide={isHide}
                                    titleKey="history"
                                    text="專案異動記錄"
                                    withCollapse={true}
                                    onClick={handleToggleTitleBar}
                                />

                                <div className={`detail-content ${isHide.history ? 'hide' : ''}`}>
                                    <ChangeLogs />
                                </div>
                            </div>
                    }
                </Col>

                <Col span={6}>
                    {/* People */}
                    <div className="projectDetailSectionWrap section-people">
                        <TitleBar text="指派人" />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className="detail-content detail-content-people">
                                    <DetailAssignee
                                        columnWidth={detailColumnRight}
                                        data={detail}
                                    />
                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* Dates */}
                    <div className="projectDetailSectionWrap section-dates">
                        <TitleBar text="日期" />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className="detail-content">
                                    <DetailDate
                                        columnWidth={detailColumnRight}
                                        data={detail}
                                    />
                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* DetailAccounting */}
                    <div className="projectDetailSectionWrap section-Detailaccounting">
                        <TitleBar text="財務資訊" />

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className="detail-content">
                                    <DetailAccounting
                                        columnWidth={detailColumnRight}
                                        data={detail}
                                    />
                                </div>

                            ) : <Loading />
                        }
                    </div>

                    {/* Department Budget */}
                    <div className="projectDetailSectionWrap section-department-budget">
                        <TitleBar>
                            <span>小組預算</span>
                            <span
                                className="btn-add"
                                onClick={btnCreate}
                            >
                                <PlusOutlined />
                            </span>
                        </TitleBar>

                        {
                            // 加個 Loading 當轉場效果
                            (detail && checkObjLength(detail)) ? (

                                <div className="detail-content">
                                    <DetailGroupBudget />
                                </div>

                            ) : <Loading />
                        }
                    </div>
                </Col>
            </Row>

            {
                // Drawer
                showDrawer &&
                    <Drawers
                        width={400}
                        className="otherFeeWrap"
                        title="另計支出"
                        handleClose={handleCloseDrawer}
                    >
                        <OtherFeeList />
                    </Drawers>
            }
        </div>

    );

};

Detail.propTypes = {
    handleUpdateProject: PropTypes.func,
};

export default Detail;
