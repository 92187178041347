import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import './Cards.scss';

const More = ({ linkTo, linkToText, blank }) => (

    <Link
        to={linkTo}
        {...blank ? { target: '_blank' } : null }
        rel="noopener noreferrer"
    >
        {linkToText}
    </Link>

);

const Cards = ({
    className,
    title,
    children,
    linkTo,
    linkToText,
    blank,
}) => (

    <Card
        title={title}
        bordered={false}
        className={`cardsWrap ${className ? className : ''}`}
        {
            ...linkTo &&
                {
                    extra: (
                        <More
                            linkTo={linkTo}
                            linkToText={linkToText}
                            blank={blank}
                        />
                    ),
                }
        }
    >
        {children}
    </Card>

);

Cards.defaultProps = {
    title: '',
    linkToText: '顯示更多',
    className: '',
    blank: true,
};

Cards.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    className: PropTypes.string,
    children: PropTypes.any,
    linkTo: PropTypes.string,
    linkToText: PropTypes.string,
    blank: PropTypes.bool,
};

export {
    Cards as default,
}
