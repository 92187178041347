import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';


// 有連結字
const renderTextWithLink = ({ id, name }) => (

    name ? (

        <Link
            to={`/myProject/${id}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {name}
        </Link>

    ) : '--'

);

const DetailAssignees = ({
    columnWidth,
    data: {
        directorId,
        directorName,
        producerId,
        producerName,
        managerId,
        managerName,
        managerAssistantId,
        managerAssistantName,
        CGLeadId,
        CGLeadName,
        projectCoordinatorId,
        projectCoordinatorName,
        leaderId,
        leaderName,
        extraPMId,
        extraPMName,
    },
}) => (

    <Fragment>
        <Row>
            <Col flex="1">總監:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: directorId, name: directorName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">監製:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: producerId, name: producerName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">PM:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: managerId, name: managerName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">PA:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: managerAssistantId, name: managerAssistantName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">PC:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: projectCoordinatorId, name: projectCoordinatorName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">CG Lead:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: CGLeadId, name: CGLeadName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">組長:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: leaderId, name: leaderName })}
            </Col>
        </Row>

        <Row>
            <Col flex="1">其他指派人:</Col>
            <Col flex={columnWidth}>
                {renderTextWithLink({ id: extraPMId, name: extraPMName })}
            </Col>
        </Row>
    </Fragment>

);

DetailAssignees.defaultProps = {
    columnWidth: '0.8',
};

DetailAssignees.propTypes = {
    columnWidth: PropTypes.string,
    data: PropTypes.object,
};

export {
    DetailAssignees as default,
};
