import React, { useContext } from 'react';
import { Popconfirm } from 'antd';
import { faUserTie, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faBlackTie } from '@fortawesome/free-brands-svg-icons';

import Buttons from '../../components/Buttons';
import Prompt from '../../components/Prompt';
import FontIcon from '../../components/FontIcon';
import PersonalSkillList from './PersonalSkillList';

import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const { scoreConfig } = moonConst;
const { handleAreaData } = PMB;

// 整理技能領域資料結構
const handleAreaSkillData = (data) => data.reduce((acc, { areaId, id, name, members }) => {

    const obj = { id, name, members };

    if (acc[areaId]) acc[areaId].push(obj);
    else acc[areaId] = (id !== null) ? [obj] : [];

    return acc;

}, {});

//
const renderSkillItemTmpl = (param) => (

    <div className="skill-item">
        <div className="name">{param?.name}</div>
        <div className="members no-item">{param?.member}</div>
    </div>

);

// 技能領域項目
const SkillRow = ({
    id,
    areaData,
    areaSkillData,
    btnUpdateArea,
    btnRemoveArea,
    handleClickMember,
    handleClose,
    btnUpdatePersonalSkill,
    btnScoreSkill,
    btnManageLabel,
}) => {

    // Context
    const {
        hasAuth: { edit_skill_tree },
        isShow,
        formStorageData,
    } = useContext(GlobalContext);

    return (

        <div className="skill-row">
            <div
                className="skill-row--left"
                style={{ backgroundColor: areaData[id]?.color || '#FFF' }}
            >
                <span>{areaData[id]?.name || '--'}</span>
            </div>

            <div className={`skill-row--middle`}>
                {
                    areaSkillData[id].length ? (

                        renderSkillMembers({
                            isShow,
                            data: areaSkillData[id],
                            formStorageData,
                            handleClickMember,
                            handleClose,
                            btnUpdatePersonalSkill,
                            btnScoreSkill,
                            btnManageLabel,
                        })

                    ) : renderSkillItemTmpl()
                }
            </div>

            <div className="skill-row--right">
                <Buttons
                    type="secondary"
                    text="編輯"
                    disabled={!edit_skill_tree || (!areaData[id]?.name)}
                    handleClick={() => btnUpdateArea(+id)}
                />
                <Buttons
                    type="danger"
                    text="刪除"
                    disabled={!edit_skill_tree || !!areaSkillData[id].length}
                    handleClick={() => btnRemoveArea(id)}
                />
            </div>
        </div>

    );

};

// 該技能的所有人
const renderSkillMembers = ({
    isShow,
    data,
    formStorageData,
    handleClickMember,
    handleClose,
    btnUpdatePersonalSkill,
    btnScoreSkill,
    btnManageLabel,
}) => data.map(({ id, name, members }) => (

    <div
        key={id}
        className="skill-item"
    >
        <div className="name">{name}</div>
        <ul className="members">
            {
                members.length ? members.map(({
                    id,
                    userId,
                    nameChinese,
                    score,
                    isTechLeader,
                    isCGLead,
                }, idx) => (

                    <li key={idx}>
                        <Popconfirm
                            title={
                                <PersonalSkillList
                                    btnUpdatePersonalSkill={btnUpdatePersonalSkill}
                                    btnScoreSkill={btnScoreSkill}
                                    btnManageLabel={btnManageLabel}
                                />
                            }
                            overlayClassName="detail-tip personal-skill-tip"
                            placement="right"
                            visible={(formStorageData.id === id) && isShow}
                            cancelButtonProps={{
                                className: 'btn-close'
                            }}
                            cancelText="關閉"
                            onCancel={handleClose}
                        >
                            <span
                                className="item-wrap"
                                onClick={() => handleClickMember({ id, userId })}
                            >
                                {
                                    isTechLeader &&
                                        <span className="member-status leader">
                                            <FontIcon icon={faBlackTie} />
                                        </span>
                                }

                                {
                                    isCGLead &&
                                        <span className="member-status manager">
                                            <FontIcon icon={faUserTie} />
                                        </span>
                                }

                                <span className={`member ${(isTechLeader || isCGLead) ? '' : 'no-status'}`}>{nameChinese}</span>
                                <span className="small-text">(得分:{score})</span>

                                {
                                    // 顯示金銀銅
                                    (score > 7) &&
                                        <span className={`crown ${scoreConfig[score].name}-place`}>
                                            <FontIcon icon={faCrown} />
                                        </span>
                                }
                            </span>
                        </Popconfirm>
                    </li>

                )) : '--'
            }
        </ul>
    </div>

));

const AreaSkill = () => {

    // Context
    const {
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        areas,
        skillAreaList,
        removeArea,
        getPersonalSkillScoreList,
        skillDispatch,
    } = useContext(SkillContext);

    // 關閉 Detail Tip 區塊
    const handleClose = () => {

        formStorageDispatch({ type: 'CLEAR' });
        popConfirmDispatch({ type: 'HIDE' });

    };

    // 編輯領域按鈕
    const btnUpdateArea = (id) => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id,
                name: handleAreaData(areas)[id].name,
                colorCode: handleAreaData(areas)[id].color,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateArea' });

    };

    // 刪除領域按鈕
    const btnRemoveArea = (id) => {

        if (+id === 1) {

            Prompt('warning', {
                title: '警告',
                mesg: '預設條件不允許刪除',
            });

            return;

        }

        removeArea({ id: +id, name: handleAreaData(areas)[id].name });

    };

    // 點擊名字
    const handleClickMember = ({ id, userId }) => {

        getPersonalSkillScoreList({ userId });
        formStorageDispatch({ type: 'COLLECT', payload: { id }});
        popConfirmDispatch({ type: 'SHOW' });

    };

    // 管理員工技能按鈕
    const btnUpdatePersonalSkill = (type) => {

        if (type === 'edit') skillDispatch({ type: 'personal_skill_score', payload: { type } });
        lightboxDispatch({ type: 'SHOW', currEvent: 'manageMemberSkill' });

    };

    // 技能評分按鈕
    const btnScoreSkill = (obj) => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: { ...formStorageData, ...obj },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'scoreSkill' });

    };

    // 管理標籤按鈕
    const btnManageLabel = () => lightboxDispatch({ type: 'SHOW', currEvent: 'manageLabel' });

    return (

        <div className="skillWrap">
            <div className="skill-row skill-row-title">
                <div className="skill-row--left">領域</div>
                <div className="skill-row--middle">
                    {renderSkillItemTmpl({ name: '技能', member: '人員' })}
                </div>
                <div className="skill-row--right">行為</div>
            </div>

            {
                Object.keys(handleAreaSkillData(skillAreaList)).map((key) => (

                    <SkillRow
                        key={key}
                        id={key}
                        areaData={handleAreaData(areas)}
                        areaSkillData={handleAreaSkillData(skillAreaList)}
                        btnUpdateArea={btnUpdateArea}
                        btnRemoveArea={btnRemoveArea}
                        handleClickMember={handleClickMember}
                        handleClose={handleClose}
                        btnUpdatePersonalSkill={btnUpdatePersonalSkill}
                        btnScoreSkill={btnScoreSkill}
                        btnManageLabel={btnManageLabel}
                    />

                ))
            }
        </div>

    );

};

export default AreaSkill;
