import React, { useContext, useState } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Buttons from '../../components/Buttons';
import Datepickers from '../../components/Datepickers';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import PMB from '../../lib/moon.pmb';

const { checkReqData } = PMB;

// 過濾在職中
const filterAtWorkPeople = (data) => data.filter(({ workStatus }) => (workStatus === 'atWork'));

// 人員選項
const rednerOptsPeople = (option) => option.map(({ id, nameChinese }) => (

    <option
        key={id}
        value={id}
    >
        {nameChinese}
    </option>

));

// 狀態選項
const rednerOptsStatus = (option) => option.map(({ code, name }) => (

    <option
        key={code}
        value={code}
    >
        {name}
    </option>

));

// 其他選項
const rednerOptsType = (option) => option.map(({ id, name }) => (

    <option
        key={id}
        value={id}
    >
        {name}
    </option>

));

const ProjectForm = () => {

    // Context
    const {
        projectStatus,
        currEvent,
        page,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        formOpts: {
            projectTypes,
            paymentStatus: paymentStatusOpts,
            producers,
            directors,
            managers,
            managerAssistants,
            projectCoordinators,
            CGLeads,
            leaders,
            extraPMs,
            companies,
        },
        createProject,
        updateProjectList,
        updateProject,
    } = useContext(ProjectContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // State
    const [storage, setStorage] = useState({ ...formStorageData });

    // 隱藏 Modal
    const hideModal = () => {

        setStorage({});
        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 開始時間
    const handleStartDate = (date) => {

        setStorage({
            ...storage,
            startDate: date.format('YYYY-MM-DD'),
        });

    };

    // 結束時間
    const handleEndDate = (date) => {

        setStorage({
            ...storage,
            endDate: date.format('YYYY-MM-DD'),
        });

    };

    // Deadline
    const handleDeadline = (date) => {

        setStorage({
            ...storage,
            deadline: date.format('YYYY-MM-DD'),
        });

    };

    // 合約 Checkbox
    const handleIsContract = ({ target: { checked } }) => {

        setStorage({
            ...storage,
            isContract: checked,
        });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...storage,
            ...reqData,
            budget: +reqData.budget,
            budgetTax: +reqData.budgetTax,
            // 將所有 ID 轉數字
            typeId: reqData.typeId ? +reqData.typeId : '',
            directorId: reqData.directorId ? +reqData.directorId : '',
            producerId: reqData.producerId ? +reqData.producerId : '',
            managerId: reqData.managerId ? +reqData.managerId : '',
            managerAssistantId: reqData.managerAssistantId ? +reqData.managerAssistantId : '',
            projectCoordinatorId: reqData.projectCoordinatorId ? +reqData.projectCoordinatorId : '',
            CGLeadId: reqData.CGLeadId ? +reqData.CGLeadId : '',
            extraPMId: reqData.extraPMId ? +reqData.extraPMId : '',
            leaderId: reqData.leaderId ? +reqData.leaderId : '',
            companyId: reqData.companyId ? +reqData.companyId : '',
        };

        // 檢查所有欄位並將空欄位轉成 null
        checkReqData(reqData);

        if (currEvent === 'updateProject') (page === 'detail') ? updateProject(reqData) : updateProjectList(reqData);
        else createProject(reqData);

    };

    const {
        name,
        typeId,
        code,
        directorId,
        producerId,
        managerId,
        managerAssistantId,
        leaderId,
        CGLeadId,
        projectCoordinatorId,
        extraPMId,
        startDate,
        endDate,
        deadline,
        notes,
        budget,
        budgetTax,
        paymentStatus,
        companyId,
        isContract,
    } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <FormRow
                labelTitle="專案名稱"
                required={true}
                error={errors.name && true}
            >
                <input
                    type="text"
                    name="name"
                    defaultValue={currEvent === 'createProject' ? '' : name}
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="items">
                <div className="row">
                    <div className="title">專案類型</div>
                    <div className="field noBorder">
                        <select
                            name="typeId"
                            defaultValue={typeId && typeId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsType(projectTypes)}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">專案狀態</div>
                    <div className="field noBorder">
                        <select
                            name="code"
                            defaultValue={code}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsStatus(projectStatus)}
                        </select>
                    </div>
                </div>
            </div>

            <div className="items">
                <div className="row">
                    <div className="title">總監</div>
                    <div className="field noBorder">
                        <select
                            name="directorId"
                            defaultValue={directorId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(directors))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">監製</div>
                    <div className="field noBorder">
                        <select
                            name="producerId"
                            defaultValue={producerId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(producers))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">PM</div>
                    <div className="field noBorder">
                        <select
                            name="managerId"
                            defaultValue={managerId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(managers))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">PA</div>
                    <div className="field noBorder">
                        <select
                            name="managerAssistantId"
                            defaultValue={managerAssistantId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(managerAssistants))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="items">
                <div className="row">
                    <div className="title">PC</div>
                    <div className="field noBorder">
                        <select
                            name="projectCoordinatorId"
                            defaultValue={projectCoordinatorId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(projectCoordinators))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">CG Lead</div>
                    <div className="field noBorder">
                        <select
                            name="CGLeadId"
                            defaultValue={CGLeadId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(CGLeads))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">組長</div>
                    <div className="field noBorder">
                        <select
                            name="leaderId"
                            defaultValue={leaderId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(leaders))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">其他指派人</div>
                    <div className="field noBorder">
                        <select
                            name="extraPMId"
                            defaultValue={extraPMId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsPeople(filterAtWorkPeople(extraPMs))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="items">
                <div className="row">
                    <div className="title">開始時間 (YYYY/MM/DD)</div>

                    <Datepickers
                        name="startDate"
                        defaultDate={startDate ? moment(startDate) : null}
                        handleChange={handleStartDate}
                    />
                </div>

                <div className="row">
                    <div className="title">Deadline (YYYY/MM/DD)</div>

                    <Datepickers
                        name="deadline"
                        defaultDate={deadline ? moment(deadline) : null}
                        handleChange={handleDeadline}
                    />
                </div>

                <div className="row">
                    <div className="title">結束時間 (YYYY/MM/DD)</div>

                    <Datepickers
                        name="endDate"
                        defaultDate={endDate ? moment(endDate) : null}
                        handleChange={handleEndDate}
                    />
                </div>
            </div>

            <div className="items">
                <FormRow labelTitle="預算 (未稅)">
                    <input
                        type="number"
                        name="budget"
                        defaultValue={budget}
                        ref={register}
                    />
                </FormRow>

                <FormRow labelTitle="預算 (含稅)">
                    <input
                        type="number"
                        name="budgetTax"
                        defaultValue={budgetTax}
                        ref={register}
                    />
                </FormRow>
            </div>

            <div className="items">
                <div className="row">
                    <div className="title">款項狀態</div>
                    <div className="field noBorder">
                        <select
                            name="paymentStatus"
                            defaultValue={paymentStatus}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsStatus(paymentStatusOpts)}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="title">隸屬公司</div>
                    <div className="field noBorder">
                        <select
                            name="companyId"
                            defaultValue={companyId}
                            ref={register}
                        >
                            <option value="">請選擇</option>
                            {rednerOptsType(companies)}
                        </select>
                    </div>
                </div>
            </div>

            <FormRow
                labelTitle="備註"
                noBorder={true}
                className="textarea"
            >
                <textarea
                    name="notes"
                    defaultValue={notes}
                    ref={register}
                />
            </FormRow>

            <label className="contractWrap">
                <div className="checkboxWrap">
                    <Checkbox
                        name="isContract"
                        defaultChecked={isContract}
                        onChange={handleIsContract}
                    /> 簽合約
                </div>
            </label>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default ProjectForm;
