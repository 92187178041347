import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import Cookies from 'js-cookie';

import './Navbar.scss';
import NavbarLinks from './NavbarLinks';
import Buttons from '../components/Buttons';

const { Sider } = Layout;

const styles = {
    width: 240,
};

const Navbar = () => {

    let history = useHistory();

    // 登出
    const handleLogout = () => {

        Cookies.remove('access_token');
        history.go();

    };

    return (

        <Sider
            className="navbar"
            width={styles.width}
        >
            <NavbarLinks />

            <div className="menu-logout">
                <Buttons
                    text="點我登出"
                    handleClick={handleLogout}
                />
            </div>
        </Sider>

    );

};

export default Navbar;
