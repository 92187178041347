import React, { useContext } from 'react';
import Buttons from '../../components/Buttons';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';

const Header = () => {

    // Context
    const {
        hasAuth: { edit_skill_tree },
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const { skillDispatch } = useContext(SkillContext);

    // 管理技能與領域按鈕
    const btnSkillAreaManage = () => {

        lightboxDispatch({ type: 'SHOW', currEvent: 'manageAreaSkill' });
        popConfirmDispatch({ type: 'HIDE' });

    };

    // 新增領域按鈕
    const btnCreateArea = () => {

        lightboxDispatch({ type: 'SHOW', currEvent: 'createArea' });
        popConfirmDispatch({ type: 'HIDE' });

    };

    // 管理員工技能按鈕
    const btnManageMemberSkill = () => {

        skillDispatch({ type: 'manage_skill_member', payload: {} });
        lightboxDispatch({ type: 'SHOW', currEvent: 'manageMemberSkill' });
        popConfirmDispatch({ type: 'HIDE' });

    };

    return (

        <div className="skill-header">
            <Buttons
                text="新增領域"
                disabled={!edit_skill_tree} // 無編輯技能樹權限就 disabled
                handleClick={btnCreateArea}
            />
            <Buttons
                text="管理技能與領域"
                disabled={!edit_skill_tree} // 無編輯技能樹權限就 disabled
                handleClick={btnSkillAreaManage}
            />
            <Buttons
                text="管理員工技能"
                disabled={!edit_skill_tree} // 無編輯技能樹權限就 disabled
                handleClick={btnManageMemberSkill}
            />
        </div>

    );

};

export default Header;
