import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Row, Col } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Buttons from '../../components/Buttons';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';

const { priceWithCommas } = moon;

// 表格欄位項目
const columnMenus = [
    {
        code: 'id',
        name: '專案編號',
    },
    {
        code: 'name',
        name: '專案名稱',
    },
    {
        code: 'status',
        name: '專案狀態',
    },
    {
        code: 'budget',
        name: '預算 (未稅)',
    },
    {
        code: 'profit',
        name: '利潤',
    },
    {
        code: 'manager',
        name: 'PM',
    },
    {
        code: 'managerAgent',
        name: 'PA',
    },
    {
        code: 'notes',
        name: '備註',
    },
    {
        code: 'startDate',
        name: '開始時間',
    },
    {
        code: 'deadline',
        name: 'Deadline',
    },
    {
        code: 'endDate',
        name: '結束時間',
    },
];

const dropdownMenus = (data) => data.map(({ code, name }) => (

    <Checkbox
        key={code}
        name={code}
        onChange={({ target }) => console.log('e:', target.name)}
    >
        {name}
    </Checkbox>

));

const ProjectsHeader = ({ isSearch }) => {

    // Context
    const { lightboxDispatch } = useContext(GlobalContext);
    const {
        searchResult: { budgetTotal, profitTotal },
    } = useContext(ProjectContext);

    // State
    const [visible, setVisible] = useState(false);

    // 設定表格項目事件
    const handleClick = () => setVisible(!visible);

    // 新增專案
    const btnCreateProject = () => lightboxDispatch({ type: 'SHOW', currEvent: 'createProject' });

    return (

        <Row className="project-header">
            {
                isSearch &&
                    <Col
                        flex="1"
                        className="project-summary"
                    >
                        總計:
                        <span className="summary-text">
                            <span>{priceWithCommas(budgetTotal)} (預算)</span>
                            /
                            <span className={(profitTotal > 0) ? 'greate-text' : 'warning-text'}>{priceWithCommas(profitTotal)} (利潤)</span>
                        </span>
                    </Col>
            }

            <Col
                flex="1"
                className="event"
            >
                {
                    false &&
                        <span className="settings">
                            <SettingOutlined
                                className="setting-tool"
                                onClick={handleClick}
                            />
                            {
                                // 表格欄位設定
                                visible &&
                                    <div className="setting-coloumns-wrap">{dropdownMenus(columnMenus)}</div>
                            }
                        </span>
                }

                <Buttons
                    text="新增專案"
                    handleClick={btnCreateProject}
                />
            </Col>
        </Row>

    );

};

ProjectsHeader.propTypes = {
    searchOpts: PropTypes.object,
};

export {
    ProjectsHeader as default,
};
