import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Buttons from '../../components/Buttons';
import { Rangepickers } from '../../components/Datepickers';
import moonConst from '../../lib/moon.const';

const { startDateText, endDateText } = moonConst;
const placeholder = [startDateText, endDateText];

const TimelineDataSearch = ({
    startDate,
    endDate,
    handleRangepicker,
    handleSearchClear,
}) => {

    return (

        <Fragment>
            <label>
                <span className="title">查詢區間：</span>
                <Rangepickers
                    placeholder={placeholder}
                    value={[startDate, endDate]}
                    allowClear={true}
                    allowEmpty={[true, true]}
                    handleChange={handleRangepicker}
                />
            </label>

            <span>
                <Buttons
                    text="取消"
                    type="secondary"
                    onClick={handleSearchClear}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </span>
        </Fragment>

    );

};

TimelineDataSearch.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    handleRangepicker: PropTypes.func.isRequired,
    handleSearchClear: PropTypes.func,
};

export {
    TimelineDataSearch as default,
};
