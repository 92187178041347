import React, { createContext, useReducer } from 'react';
import Service from '../lib/moon.service';

import {
    globalReducer,
    formStorageReducer,
    loadingReducer,
    lightboxReducer,
    popConfirmReducer,
} from './global.reducer';

const path = window.location.pathname.split('/');

// Global
const globalInitialState = {
    page: path[2],
    hasCreateBtn: false,

    // 全域資料
    user: {},
    hasAuth: {},
    workLogTypes: [],
    dayOffTypes: [],
    projectStatus: [],
    departments: {},
    groups: [], // 所有組別
    roles: [],
    updateCalendar: '',

    workLogRemind: {},
    allMembers: [],
};

// Form values
const formStorageInitialState = {
    formStorageData: {},
};

// Loading
const loadingInitialState = {
    isLoading: false,
    error: false,
};

// Lightbox
const lightboxInitialState = {
    visible: false,
    currEvent: '',
};

// PopConfirm
const popConfirmInitialState = {
    isShow: false,
};

// Create Context
const GlobalContext = createContext(null);

// Provider
const GlobalProvider = ({ children }) => {

    const [globalState, globalDispatch] = useReducer(globalReducer, globalInitialState);
    const [formStorageState, formStorageDispatch] = useReducer(formStorageReducer, formStorageInitialState);
    const [loadingState, loadingDispatch] = useReducer(loadingReducer, loadingInitialState);
    const [lightboxState, lightboxDispatch] = useReducer(lightboxReducer, lightboxInitialState);
    const [popConfirmState, popConfirmDispatch] = useReducer(popConfirmReducer, popConfirmInitialState);
    const {
        page,
        hasCreateBtn,

        // 全域資料
        user,
        hasAuth,
        workLogTypes,
        dayOffTypes,
        projectStatus,
        departments,
        groups,
        roles,
        updateCalendar,

        workLogRemind,
        allMembers,
    } = globalState;

    const { formStorageData } = formStorageState;
    const { isLoading } = loadingState;
    const { visible, currEvent } = lightboxState;
    const { isShow } = popConfirmState;
    const { Provider } = GlobalContext;

    // 取得全域資料
    const getGlobalData = () => {

        Service.global()
            .then((resData) => globalDispatch({ type: 'global_data', payload: resData }));

    };

    // 取得當日已填、剩餘工時
    const getWorkLogRemind = (reqData) => {

        Service.workLogRemind(reqData)
            .then((resData) => globalDispatch({ type: 'worklog_remind', payload: resData }));

    };

    // 取得指派人列表
    const getAllMembers = () => {

        Service.getAllMembers()
            .then(({ lists }) => globalDispatch({ type: 'assignee_opts', payload: lists }));

    };

    return (

        <Provider value={{
            page,
            hasCreateBtn,

            // 全域資料
            user,
            hasAuth,
            workLogTypes,
            dayOffTypes,
            projectStatus,
            departments,
            groups,
            roles,
            updateCalendar,
            getGlobalData,

            // 取得當日已登錄時數
            workLogRemind,
            getWorkLogRemind,

            // 取得所有人員與部門
            allMembers,
            getAllMembers,

            // Form 表單暫存
            formStorageData,

            // Loading
            isLoading,

            // Lightbox
            visible,
            currEvent,

            // PopConfirm
            isShow,

            // Dispatch
            globalDispatch,
            formStorageDispatch,
            loadingDispatch,
            lightboxDispatch,
            popConfirmDispatch,
        }}>
            {children}
        </Provider>

    );

};

export {
    GlobalContext,
    GlobalProvider,
};
