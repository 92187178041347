import React, { Fragment, useState, useEffect } from 'react';
import { Tag, Tooltip } from 'antd';

import {
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';

import { useForm } from 'react-hook-form';

import './WorkLogType.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import Buttons from '../../components/Buttons';
import Prompt from '../../components/Prompt';
import LightboxForm from '../../components/LightboxForm';
import Tables from '../../components/Tables';

import WorkLogTypeForm from './WorkLogTypeForm';
import Service from '../../lib/moon.service';
import moon from '../../lib/moon';
import PMB from '../../lib/moon.pmb';

const { renderWithoutValue } = moon;
const { tagStyle } = PMB;

// 為了 antd 4 的 icon 寫法而做的 mapping fn
const iconConvertComponent = (param) => ({
    false: CloseOutlined,
    true: CheckOutlined,
}[param]);

// 建立 Icon component
const renderIcon = (param) => {

    const IconComp = iconConvertComponent(param);
    return <IconComp />;

};

// 表格欄位
const columnData = ({ btnUpdate, handleDelete }) => ([
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
    },
    {
        title: '名稱 (單字)',
        dataIndex: 'name',
        className: 'col-name',
        width: 160,
        render: (name, { colorCode, colorText, shortHand }) => (

            <Fragment>
                <span>{name}</span>
                <Tooltip
                    title={colorCode}
                    placement="rightTop"
                    overlayClassName="colorCodeWrap"
                >
                    <Tag
                        className="tagsWrap"
                        color={colorCode}
                        style={tagStyle(colorText)}
                    >
                        {shortHand}
                    </Tag>
                </Tooltip>
            </Fragment>

        ),
    },
    {
        title: '文字顏色',
        dataIndex: 'colorText',
        className: 'col-colorText',
        width: 110,
        render: (colorText) => (

            <Fragment>
                {
                    colorText &&
                        <Tooltip
                            title={colorText}
                            placement="rightTop"
                            overlayClassName="colorCodeWrap"
                        >
                            <Tag
                                className={`tagsWrap ${colorText === '#FFFFFF' ? 'isWhite' : ''}`}
                                color={colorText}
                            />
                        </Tooltip>
                }
            </Fragment>

        ),
    },
    {
        title: '代碼',
        dataIndex: 'code',
        className: 'col-code',
        width: 70,
    },
    {
        title: '類型加權',
        dataIndex: 'weight',
        render: (weight) => renderWithoutValue(weight),
    },
    {
        title: '是否為休假',
        dataIndex: 'isLeave',
        render: (isLeave) => renderIcon(isLeave),
    },
    {
        title: '是否納入年假計算',
        dataIndex: 'isAnnualLeave',
        render: (isAnnualLeave) => renderIcon(isAnnualLeave),
    },
    {
        title: '⅓、⅔',
        dataIndex: 'isOneThird',
        render: (isOneThird) => renderIcon(isOneThird),
    },
    {
        title: '¼、¾',
        dataIndex: 'isOneQuarter',
        render: (isOneQuarter) => renderIcon(isOneQuarter),
    },
    {
        title: '½',
        dataIndex: 'isHalf',
        render: (isHalf) => renderIcon(isHalf),
    },
    {
        title: '1.5',
        dataIndex: 'isOneHalf',
        render: (isOneHalf) => renderIcon(isOneHalf),
    },
    {
        title: '2',
        dataIndex: 'isTwo',
        render: (isTwo) => renderIcon(isTwo),
    },
    {
        title: '在總表中編輯',
        dataIndex: 'enableEditSheet',
        render: (enableEditSheet) => renderIcon(enableEditSheet),
    },
    {
        title: '在專案中編輯',
        dataIndex: 'enableEditProject',
        render: (enableEditProject) => renderIcon(enableEditProject),
    },
    {
        title: '優先權',
        dataIndex: 'priority',
        render: (priority) => renderWithoutValue(priority),
    },
    {
        title: '操作',
        dataIndex: '',
        className: 'col-actions',
        width: 200,
        render: (data) => (

            <Fragment>
                <Buttons
                    type="secondary"
                    text="編輯"
                    handleClick={(e) => btnUpdate(data, e)}
                />
                <Buttons
                    type="danger"
                    text="刪除"
                    handleClick={(e) => handleDelete(data, e)}
                />
            </Fragment>

        ),
    },
]);

// Service
async function fetchData () {

    return await Service.workLogTypeList();

}

const WorkLogType = () => {

    // State
    const [visible, setVisible] = useState(false);
    const [currBtnType, setCurrBtnType] = useState('');

    // Data
    const [lists, setLists] = useState([]);
    const [storage, setStorage] = useState({});

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    useEffect(() => {

        document.title = '登錄工時與假別';

        fetchData()
            .then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = ({ lists }) => setLists(lists);

    // 顯示 Modal
    const showModal = () => setVisible(true);

    // 隱藏 Modal
    const hideModal = () => {

        setVisible(false);
        setStorage({});

    };

    // 新增按鈕
    const btnCreate = () => {

        setCurrBtnType('create');
        setStorage({
            isLeave: false,
            isAnnualLeave: false,
            isOneThird: false,
            isOneQuarter: false,
            isHalf: false,
            isOneHalf: false,
            isTwo: false,
            enableEditSheet: false,
            enableEditProject: false,
        });
        showModal();

    };

    // 編輯按鈕
    const btnUpdate = (obj) => {

        setCurrBtnType('update');
        setStorage({ ...storage, ...obj });
        showModal();

    };

    // 刪除
    const handleDelete = ({ id, name }) => {

        Prompt('confirm', {
            title: (
                <Fragment>
                    刪除
                    <span className="small-text">(ID: {id})</span>
                </Fragment>
            ),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.workLogTypeDelete({ id })
                    .then(() => {

                        Prompt('success', {
                            callback: () => {

                                setLists(lists.filter((obj) => obj.id !== id));

                            },
                        });

                    });

            },
        });

    };

    // 當前 Checkbox
    const handleChange = ({ target: { name, checked, value }}) => {

        setStorage({
            ...storage,
            [name]: (name === 'colorCode' || name === 'colorText') ? value : checked,
        });

    };

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        const config = {
            create: 'workLogTypeCreate',
            update: 'workLogTypeUpdate',
        }[currBtnType];

        reqData = {
            ...storage,
            ...reqData,
            priority: +reqData.priority,
            weight: +reqData.weight,
        };

        Service[config](reqData)
            .then((data) => {

                hideModal();

                Prompt('success', {
                    callback: () => {

                        // 編輯
                        if (currBtnType === 'update') {

                            setLists(lists.map((obj) => {

                                return (obj.id === id) ? obj = { ...data } : obj;

                            }));

                        }

                        // 新增
                        if (currBtnType === 'create') {

                            setLists([
                                ...lists,
                                { ...data },
                            ]);

                        }

                    },
                });

            });

    };

    const { id } = storage;

    return (

        <Fragment>

            <Breadcrumbs />

            <Buttons
                text="新增"
                icon={<PlusOutlined />}
                className="btn-up"
                handleClick={btnCreate}
            />

            <Tables
                className="workLogTypeWrap"
                columns={columnData({ btnUpdate, handleDelete })}
                data={lists}
                rowKey="id"
                showPage={false}
            />

            {
                // Lightbox (新增、編輯)
                visible &&
                    <LightboxForm
                        title={
                            currBtnType === 'update' ? (

                                <Fragment>
                                    編輯
                                    <span className="small-text">(ID: {id})</span>
                                </Fragment>

                            ) : '新增'
                        }
                        visible={visible}
                        className="workLogTypeLightbox"
                        handleCancel={hideModal}
                    >
                        <WorkLogTypeForm
                            storage={storage}
                            register={register}
                            errors={errors}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            handleReqData={handleReqData}
                            hideModal={hideModal}
                        />
                    </LightboxForm>
            }

        </Fragment>

    );

};

export {
    WorkLogType as default,
};
