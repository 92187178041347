// Global
const globalReducer = (state, { type, payload }) => {

    switch (type) {
        case 'PAGE':
            return {
                ...state,
                page: payload,
            };

        case 'has_create_button':
            return {
                ...state,
                hasCreateBtn: payload,
            };

        case 'global_data':
            return {
                ...state,
                user: payload.user,
                hasAuth: { ...payload.user.authority },
                workLogTypes: payload.workLogTypes,
                dayOffTypes: payload.dayOffTypes,
                projectStatus: payload.projectStatus,
                departments: payload.groups.reduce((acc, { departmentId, departmentName }) => {

                    acc[departmentId] = departmentName;
                    return acc;

                }, {}),
                groups: payload.groups,
                roles: payload.roles,
                updateCalendar: payload.scheduleUpdateTime,
            };

        case 'worklog_remind':
            return {
                ...state,
                workLogRemind: { ...payload },
            };

        case 'assignee_opts':
            return {
                ...state,
                allMembers: payload,
            };

        default:
            return {
                ...state,
                hasCreateBtn: false,
            };
    }

};

// Form Fields
const formStorageReducer = (state, { type, payload }) => {

    switch (type) {
        case 'COLLECT':
            return {
                formStorageData: payload,
            };

        case 'CLEAR':
            return {
                formStorageData: {},
            };

        default:
            return state;
    }

};

// Loading
const loadingReducer = (state, { type, payload }) => {

    // console.log('type:', type);
    // console.log('payload:', payload);

    switch (type) {
        case 'loading':
            return { ...state, isLoading: payload };

        case 'success':
            return { ...state, isLoading: false };

        default:
            return state;
    }

};

// Lightbox
const lightboxReducer = (state, { type, currEvent }) => {

    switch (type) {
        case 'SHOW':
            return { visible: true, currEvent };

        case 'HIDE':
            return { visible: false, currEvent: '' };

        default:
            return { ...state, currEvent };
    }

};

// PopConfirm
const popConfirmReducer = (state, { type }) => {

    switch (type) {
        case 'SHOW':
            return { isShow: true };

        case 'HIDE':
            return { isShow: false };

        default:
            return state;
    }

};

export {
    globalReducer,
    formStorageReducer,
    loadingReducer,
    lightboxReducer,
    popConfirmReducer,
};
