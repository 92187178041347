import React, { useState, useContext } from 'react';
import { Checkbox, Tooltip } from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons';

import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import Prompt from '../../components/Prompt';
import { OtherErrorMesg } from '../../components/LightboxForm';
import { EmptyData } from '../../containers/PageBroken';

import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import PMB from '../../lib/moon.pmb';

const { checkReqData } = PMB;

// 整理收集 checkbox 結構
const collectCheckbox = (checkboxes, isChecked = false) => checkboxes.reduce((acc, { id }) => {

    acc[id] = isChecked;
    return acc;

}, {});

// 該技能人數 (先過濾有領域但未綁定技能時，造成 skill id 是 null 情境)
const calculateMemberTotal = (data) => data.filter(({ id }) => id).reduce((acc, { id, members }) => {

    acc[id] = members.length;
    return acc;

}, {});

// 每筆項目
const Item = ({
    data: { areaId, id, name, score },
    collectCheckboxes,
    handleChangeCheckbox,
    btnUpdateSkill,
    handleRemoveSkill,
    currSkill,
    handleChangeInput,
    handleSubmitSkill,
    handleCancel,
    totalMembers,
}) => (

    currSkill[id] ? (

        <SkillCheckboxForm
            value={name}
            handleChangeInput={handleChangeInput}
            handleSubmitSkill={handleSubmitSkill}
            handleCancel={handleCancel}
        />

    ) : (

        <div className="skill-items">
            <Checkbox
                name="skills"
                checked={!!collectCheckboxes[id]}
                onChange={(e) => handleChangeCheckbox(id, e)}
            >
                <span>{name}</span>
                <span className="small-text">({totalMembers[id] || 0}人)</span>
            </Checkbox>

            <span className="btn-actions">
                {
                    !totalMembers[id] &&
                        <Tooltip
                            title="刪除"
                            placement="top"
                            overlayClassName="tooltip"
                        >
                            <CloseOutlined onClick={() => handleRemoveSkill({ areaId, id, name })} />
                        </Tooltip>
                }

                <Tooltip
                    title="編輯"
                    placement="top"
                    overlayClassName="tooltip"
                >
                    <EditOutlined onClick={() => btnUpdateSkill({ id, name })} />
                </Tooltip>
            </span>
        </div>

    )

);

// 技能表單
const SkillCheckboxForm = ({
    value,
    handleChangeInput,
    handleSubmitSkill,
    handleCancel,
}) => (

    <div className="skillForm">
        <Checkbox />

        <input
            type="text"
            name="name"
            defaultValue={value}
            onChange={handleChangeInput}
        />

        <span className="btn-actions">
            <Buttons
                text="送出"
                handleClick={handleSubmitSkill}
            />
            <Buttons
                text="取消"
                type="secondary"
                handleClick={handleCancel}
            />
        </span>
    </div>

);

const ManageAreaSkillForm = () => {

    // Context
    const {
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        areas,
        skills,
        skillAreaList,
        manageAreaSkill,
        createSkill,
        updateSkill,
        removeSkill,
    } = useContext(SkillContext);

    // React Hook Form
    const { handleSubmit, register } = useForm();

    // State
    const [collectCheckboxes, setCollectCheckboxes] = useState({});
    const [visible, setVisible] = useState(false);
    const [currSkill, setCurrSkill] = useState({});
    const [currEvent, setCurrEvent] = useState('');
    const [storage, setStorage] = useState({});
    const [showError, setShowError] = useState(false);

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });
        setVisible(false);
        setShowError(false);

    };

    // 領域 opt
    const handleChangeAreaOpt = ({ target }) => {

        // 先找出對應的 id
        const filterData = [...skills].filter(({ areaId }) => (areaId === +target.value));

        setCollectCheckboxes({
            ...collectCheckbox(filterData, true),
        });

    };

    // Checkbox 事件
    const handleChangeCheckbox = (id, { target }) => {

        setCollectCheckboxes({
            ...collectCheckboxes,
            [id]: target.checked,
        });

    };

    // 新增技能按鈕
    const btnCreateSkill = () => {

        setCurrEvent('createSkill');
        setVisible(true);
        setCurrSkill({}); // 還原編輯的 form

    };

    // 編輯技能按鈕
    const btnUpdateSkill = (reqData) => {

        setCurrEvent('updateSkill');
        setVisible(false); // 還原新增的 form
        setCurrSkill({ [reqData.id]: true });
        setStorage({ ...storage, ...reqData });

    };

    // 刪除技能按鈕
    const handleRemoveSkill = (reqData) => {

        if (reqData.areaId === 1) {

            Prompt('warning', {
                title: '警告',
                mesg: '預設條件不允許刪除',
            });

            return;

        }

        removeSkill(reqData);

    };

    // Input 事件
    const handleChangeInput = ({ target }) => setStorage({ ...storage, name: target.value });

    // 清除與還原
    const resetSkillForm = () => {

        setCurrEvent('');
        setVisible(false); // 新增技能
        setCurrSkill({}); // 編輯技能
        setStorage({});

    };

    // 技能表單送出
    const handleSubmitSkill = () => {

        // 未輸入阻擋
        if (!storage.name) return;

        if (currEvent === 'updateSkill') updateSkill(storage);
        else createSkill(storage);

        resetSkillForm();

    };

    // 取消按鈕
    const handleCancel = () => resetSkillForm();

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...reqData,
            areaId: +reqData.areaId || null,
            skills: Object.keys(collectCheckboxes).filter((key) => collectCheckboxes[key]).map((key) => +key),
        };

        // 檢查是否填寫
        if (checkReqData(reqData)) {

            setShowError(checkReqData(reqData));
            return;
        }

        manageAreaSkill(reqData);

    };

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <div className="row">
                <div className="title">領域</div>
                <div className="field noBorder">
                    <select
                        name="areaId"
                        ref={register}
                        onChange={handleChangeAreaOpt}
                    >
                        <option value="">請選擇</option>
                        {
                            areas.map(({ id, name }) => (

                                <option key={id} value={id}>{name}</option>

                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="title">技能</div>
                <div className="skill-wrap">
                    <div className="skill-action">
                        {/* <div className="search">
                            <input type="text"/>
                        </div> */}
                        <PlusOutlined onClick={btnCreateSkill} />
                    </div>

                    <div className="skill-item-wrap">
                        {
                            visible &&
                                <SkillCheckboxForm
                                    currSkill={currSkill}
                                    handleChangeInput={handleChangeInput}
                                    handleSubmitSkill={handleSubmitSkill}
                                    handleCancel={handleCancel}
                                />
                        }

                        {
                            skills.length ? (

                                skills.map((obj, idx) => (

                                    <Item
                                        key={idx}
                                        data={obj}
                                        currSkill={currSkill}
                                        collectCheckboxes={collectCheckboxes}
                                        handleChangeCheckbox={handleChangeCheckbox}
                                        btnUpdateSkill={btnUpdateSkill}
                                        handleRemoveSkill={handleRemoveSkill}
                                        handleChangeInput={handleChangeInput}
                                        handleSubmitSkill={handleSubmitSkill}
                                        handleCancel={handleCancel}
                                        totalMembers={calculateMemberTotal(skillAreaList)}
                                    />

                                ))

                            ) : <EmptyData />
                        }
                    </div>
                </div>

                {showError && <OtherErrorMesg />}
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default ManageAreaSkillForm;
