import React, { useState, useContext } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';

import Buttons from '../../components/Buttons';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import PMB from '../../lib/moon.pmb';

const { collectCheckboxWithNumber } = PMB;

// 已勾選的 checkboxes
const handleToChecked = (checkboxes) => checkboxes.reduce((acc, { id }) => {

    acc[id] = true;
    return acc;

}, {});

const Item = ({
    data: { id, name },
    getKey,
    selected,
    handleChangeCheckboxes,
}) => (

    <Checkbox
        name={id}
        className="checkbox-item"
        data-key={getKey}
        defaultChecked={selected}
        onChange={(e) => handleChangeCheckboxes(getKey, e)}
    >
        <span>{name}</span>
    </Checkbox>

);

const ManageLabel = () => {

    // Context
    const {
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        features,
        tools,
        personalSkill: {
            userId,
            features: personalFeatures,
            tools: personalTools,
        },
        manageMemberLabel,
    } = useContext(SkillContext);

    // State
    const [collectCheckboxes, setCollectCheckboxes] = useState({
        feature: { ...handleToChecked(personalFeatures) },
        tool: { ...handleToChecked(personalTools) },
    });

    // React Hook Form
    const { handleSubmit } = useForm();

    // 隱藏 Modal
    const hideModal = () => lightboxDispatch({ type: 'HIDE' });

    // Checkbox
    const handleChangeCheckboxes = (getKey, { target: { name, checked } }) => {

        setCollectCheckboxes({
            ...collectCheckboxes,
            [getKey]: {
                ...collectCheckboxes[getKey],
                [name]: checked,
            },
        });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            userId,
            features: collectCheckboxWithNumber(collectCheckboxes.feature) || [],
            tools: collectCheckboxWithNumber(collectCheckboxes.tool) || [],
        };

        manageMemberLabel(reqData);

    };

    return (

        <form
            className="form-label"
            onSubmit={handleSubmit(handleReqData)}
        >
            <div className="row-label-item">
                <p>特質</p>
                <div className="item-wrap">
                    {
                        features.map((obj, idx) => (

                            <Item
                                key={idx}
                                data={obj}
                                getKey="feature"
                                selected={collectCheckboxes.feature[obj.id]}
                                handleChangeCheckboxes={handleChangeCheckboxes}
                            />

                        ))
                    }
                </div>
            </div>

            <div className="row-label-item">
                <p>擅長工具</p>
                <div className="item-wrap">
                    {
                        tools.map((obj, idx) => (

                            <Item
                                key={idx}
                                data={obj}
                                getKey="tool"
                                selected={collectCheckboxes.tool[obj.id]}
                                handleChangeCheckboxes={handleChangeCheckboxes}
                            />

                        ))
                    }
                </div>
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default ManageLabel;
