import React, { useContext, Fragment } from 'react';
import { Tooltip, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { HomeStore } from './Home';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const { workLogHour } = moonConst;
const { tagStyle } = PMB;

const DayoffList = ({
    dataList: {
        colorCode,
        colorText,
        creator,
        groupName,
        departmentName,
        hours,
        nameChinese,
        shortHandText,
        userId,
    },
}) => (

    <div className="todayDayoffWrap">
        <span>
            <Link
                to={`/myProject/${userId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
            >
                {nameChinese}
            </Link>
        </span>

        <div className="group-name">{groupName} ({departmentName})</div>

        <span className="leave-info">
            <Tooltip
                title={<Fragment><EditOutlined /> 由{creator}更新</Fragment>}
                placement="left"
                overlayClassName="tooltip todayDayoffTooltip"
            >
                <Tag
                    className="tagsWrap"
                    color={colorCode}
                    style={tagStyle(colorText)}
                >
                    {shortHandText}
                </Tag>

                <span>{`x ${workLogHour[hours]}日`}</span>
            </Tooltip>
        </span>
    </div>

);

const TodayDayOff = () => {

    // Context
    const { dayOffs } = useContext(HomeStore);

    return (

        <div className="todayDayOff">
            {
                dayOffs.map((obj, idx) => (

                    <DayoffList
                        key={idx}
                        dataList={obj}
                    />

                ))
            }
        </div>

    );

};

export default TodayDayOff;
