import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Radio, Tooltip, message } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import './CalendarSetting.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import Buttons from '../../components/Buttons';
import Datepickers from '../../components/Datepickers';
import Prompt from '../../components/Prompt';
import LightboxForm, { FormRow } from '../../components/LightboxForm';
import CalendarTool from '../../components/CalendarTool';
import { Store } from '../../Wrap';
import Service from '../../lib/moon.service';

const RadioGroup = Radio.Group;

const Calendar = () => {

    // State
    const [visible, setVisible] = useState(false);

    // Data
    const [storage, setStorage] = useState({
        date: '',
        isHoliday: false,
    });
    const [datasetStorage, setDatasetStorage] = useState({ isHoliday: false });

    // Context
    const { calendarCallback } = useContext(Store);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    useEffect(() => {

        document.title = '行事曆';

    });

    // 顯示 Modal
    const showModal = () => setVisible(true);

    // 隱藏 Modal
    const hideModal = () => {

        setVisible(false);
        setDatasetStorage({ isHoliday: false });

    };

    // 行事曆選取日期
    const handleSelectedDate = (date) => {

        setStorage({
            ...storage,
            date: moment(date).format('YYYY-MM-DD'),
        });

    };

    // 行事曆點擊事件
    const handleClickDate = ({ currentTarget }) => {

        const { dataset } = currentTarget;

        // dataset 有值才塞 obj
        if (dataset.name) {

            setDatasetStorage({
                ...datasetStorage,
                calendarId: +dataset.id,
                name: dataset.name,
                isHoliday: +dataset.holiday,
            });

        }

        showModal();

    };

    // TitleBar
    const renderTitleBar = ({ calendarId, date }) => (

        <Fragment>
            {`${calendarId ? '編輯' : '新增'}行事曆`}
            {
                !!calendarId &&
                    <Tooltip
                        title="刪除節日"
                        placement="bottom"
                        overlayClassName="tooltip"
                    >
                        <i
                            className="fas fa-trash-alt btn-delete"
                            onClick={(e) => handleDelete({ calendarId, date })}
                        />
                    </Tooltip>
            }
        </Fragment>

    );

    // DatePicker 選取日期事件
    const handleDatePickerChange = (date) => {

        setStorage({
            ...storage,
            date: date ? date.format('YYYY-MM-DD') : '',
        });

    };

    // Radio 事件
    const handleRadioChange = ({ target: { value } }) => {

        setDatasetStorage({
            ...datasetStorage,
            isHoliday: value,
        });

    };

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        // Service Key
        const config = datasetStorage.calendarId ? 'calendarUpdate' : 'calendarCreate';

        reqData = {
            ...reqData,
            ...storage,
            isHoliday: datasetStorage.isHoliday,
        };

        // 編輯要多送 ID
        if (datasetStorage.calendarId) reqData.calendarId = datasetStorage.calendarId;

        Service[config](reqData)
            .then(() => {

                hideModal();
                Prompt('success', {
                    callback: () => calendarCallback({ year: moment(reqData.date).year(), state: true }),
                });

            });

    };

    // 刪除
    const handleDelete = ({ calendarId, date }) => {

        const key = 'updatable';
        message.loading({ key, content: '刪除中...' });
        Service.calendarDelete({ calendarId })
            .then(() => {

                hideModal();

                message.success({
                    content: '刪除成功',
                    key,
                    duration: 2,
                });

                calendarCallback({ year: moment(date).year(), state: true });

            });

    };

    const {
        calendarId,
        name,
        isHoliday,
    } = datasetStorage;

    return (

        <Fragment>

            <Breadcrumbs />

            <div className="calendar-setting">

                <CalendarTool
                    selectedDate={storage.date}
                    handleSelected={handleSelectedDate}
                    handleClickDate={handleClickDate}
                />

                {
                    visible &&
                        <LightboxForm
                            title={renderTitleBar({ calendarId, date: storage.date })}
                            visible={visible}
                            className="calendarLightbox"
                            handleCancel={hideModal}
                        >
                            <form onSubmit={handleSubmit(handleReqData)}>
                                <div className="items">
                                    {
                                        calendarId > 0 ? (

                                            // 編輯

                                            // <FormRow
                                            //     labelTitle="日期 (YYYY/MM/DD)"
                                            //     className="readOnly"
                                            //     notes="無法更改日期"
                                            // >
                                            //     <input
                                            //         type="text"
                                            //         name="date"
                                            //         defaultValue={moment(storage.date).format('YYYY/MM/DD')}
                                            //         ref={register}
                                            //         readOnly={true}
                                            //     />
                                            // </FormRow>
                                            // 之前不知道為什麼另外在編輯模式關掉日期選擇，所以先註解掉

                                            <div className="row">
                                                <div className="title">日期 (YYYY/MM/DD)</div>

                                                <Datepickers
                                                  name="date"
                                                  defaultDate={storage.date ? moment(storage.date) : null}
                                                  handleChange={handleDatePickerChange}
                                                />
                                            </div>

                                        ) : (

                                            // 新增
                                            <div className="row">
                                                <div className="title">日期 (YYYY/MM/DD)</div>

                                                <Datepickers
                                                    name="date"
                                                    defaultDate={storage.date ? moment(storage.date) : null}
                                                    handleChange={handleDatePickerChange}
                                                />
                                            </div>

                                        )
                                    }

                                    <FormRow
                                        labelTitle="名稱"
                                        required={true}
                                        error={errors.name && true}
                                        {...errors.name?.type === 'maxLength' && { errorMesg: '含空格不得超過 10 個字' }}
                                    >
                                        <input
                                            type="text"
                                            name="name"
                                            defaultValue={name}
                                            ref={register({ required: true, maxLength: 10 })}
                                            placeholder="限制 10 個字內"
                                        />
                                    </FormRow>
                                </div>

                                <FormRow
                                    className="form-radios"
                                >
                                    <span className="form-calendar">是否放假？</span>

                                    <RadioGroup
                                        name="isHoliday"
                                        defaultValue={isHoliday ? 1 : 0}
                                        onChange={handleRadioChange}
                                    >
                                        <Radio value={1}>是</Radio>
                                        <Radio value={0}>否</Radio>
                                    </RadioGroup>
                                </FormRow>

                                <div className="row row-btns" style={{display: 'flex', justifyContent: 'center'}}>
                                    {calendarId > 0 &&
                                      <Buttons
                                        type="danger"
                                        text="刪除"
                                        onClick={() => handleDelete({ calendarId, date: storage.date })}
                                        ghost
                                      />
                                    }
                                    <Buttons
                                        text="取消"
                                        type="secondary"
                                        handleClick={hideModal}
                                    />
                                    <Buttons
                                        text="送出"
                                        htmlType="submit"
                                    />
                                </div>
                            </form>
                        </LightboxForm>
                }

            </div>

        </Fragment>

    );

};

export {
    Calendar as default,
};
