import React, {
    Fragment,
    useEffect,
    useContext,
} from 'react';
import { Row, Col } from 'antd';

import './Members.scss';
import Buttons from '../../components/Buttons';
import BackToTop from '../../components/BackToTop';
import LightboxForm from '../../components/LightboxForm';
import MemberForm from '../../components/MemberForm';

import Search from './Search';
import List from './List';

import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moonConst from '../../lib/moon.const';

const { lightboxTitle } = moonConst;

const Members = () => {

    // Context
    const {
        hasAuth: { view_all_members }, // 觀看全部成員資料
        visible,
        currEvent,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        searchResult,
        getMemberList,
        formStorageDispatch,
    } = useContext(MemberContext);

    useEffect(() => {

        document.title = '所有員工';

        // 列表
        getMemberList();

    }, []);

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 新增按鈕
    const btnCreate = () => lightboxDispatch({ type: 'SHOW', currEvent: 'createMember' });

    return (

        <Fragment>
            {
                // 新增按鈕
                view_all_members &&
                    <div className="members-header">
                        {
                            searchResult.isSearch && <span>{`共 ${searchResult.list.length} 筆資料`}</span>
                        }

                        <Buttons
                            text="新增員工"
                            handleClick={btnCreate}
                        />
                    </div>
            }

            <Row gutter={20}>
                <Col
                    flex="0 0 220px"
                    className="column column-left"
                >
                    <Search />
                </Col>

                <Col flex="1">
                    <List />
                </Col>
            </Row>

            <BackToTop />

            {
                // Lightbox (新增)
                visible &&
                    <LightboxForm
                        width={680}
                        title={lightboxTitle[currEvent]}
                        visible={visible}
                        handleCancel={hideModal}
                    >
                        <MemberForm />
                    </LightboxForm>
            }
        </Fragment>

    );

};

export default Members;
