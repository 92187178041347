import React, { Fragment } from 'react';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';

const ProjectSettingForm = ({
    storage: {
        name,
        priority,
        colorCode,
        colorText,
        code,
    },
    currListsType,
    handleChange,
    hideModal,
    handleSubmit,
    handleReqData,
    register,
    errors,
}) => {

    // 專案狀態
    const renderLayoutStatus = () => (

        <Fragment>
            <div className="items">
                <FormRow
                    labelTitle="名稱"
                    required={true}
                    error={errors.name && true}
                >
                    <input
                        type="text"
                        name="name"
                        defaultValue={name}
                        ref={register({ required: true })}
                    />
                </FormRow>

                <FormRow
                    labelTitle="優先權"
                    required={true}
                    error={errors.priority && true}
                    notes="數字越大，排列越後面"
                >
                    <input
                        type="number"
                        name="priority"
                        defaultValue={priority}
                        ref={register({ required: true })}
                    />
                </FormRow>
            </div>

            <div className="items">
                <FormRow
                    labelTitle="色碼"
                    className="colorWrap"
                    required={true}
                    error={errors.colorCode && true}
                >
                    <input
                        type="color"
                        name="colorCode"
                        defaultValue={colorCode}
                        onChange={handleChange}
                        ref={register({ required: true })}
                    />
                </FormRow>

                <FormRow
                    labelTitle="文字色碼"
                    className="colorWrap"
                    required={true}
                    error={errors.colorText && true}
                >
                    <input
                        type="color"
                        name="colorText"
                        defaultValue={colorText}
                        onChange={handleChange}
                        ref={register({ required: true })}
                    />
                </FormRow>
            </div>

            <FormRow
                labelTitle="代碼"
                required={true}
                error={errors.code && true}
                notes="若為組合字請用駝峰式命名，Ex. needToConfirm"
            >
                <input
                    type="text"
                    name="code"
                    defaultValue={code}
                    placeholder="代碼不能重複"
                    ref={register({ required: true })}
                />
            </FormRow>
        </Fragment>

    );

    // 專案類型
    const renderLayoutType = () => (

        <Fragment>
            <FormRow
                labelTitle="名稱"
                required={true}
                error={errors.name && true}
            >
                <input
                    type="text"
                    name="name"
                    defaultValue={name}
                    ref={register({ required: true })}
                />
            </FormRow>

            <FormRow
                labelTitle="優先權"
                required={true}
                error={errors.priority && true}
                notes="數字越大，排列越後面"
            >
                <input
                    type="number"
                    name="priority"
                    defaultValue={priority}
                    ref={register({ required: true })}
                />
            </FormRow>
        </Fragment>

    );

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            {
                currListsType === 'status' ? renderLayoutStatus() : renderLayoutType()
            }

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export {
    ProjectSettingForm as default,
};
