import moment from 'moment';

const Moon = {

    // isMobile: () => window.screen.availWidth <= moonConst.mobileWidth,

    /**
     * @author Betty
     * @param  {number} price - 金額
     * @param  {number} fixed - 位數
     * @returns {string}}
     */
    priceWithCommas: (price, fixed) => {

        let priceFormat = '';

        if (price == null) price = '';

        if (fixed != null && !isNaN(parseFloat(price)))
            price = parseFloat(price.toString().replace(/,/g, '')).toFixed(fixed);

        // 千分位處理
        priceFormat = Math.round(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return (price < 0) ? `-$${priceFormat.replace(/-/g, '')}` : `$${priceFormat}`;

    },

    /**
     * @author Betty
     * @param {number} price - 金額
     * @returns {string}
     */
    priceWithoutCommas: (price) => price.toString().replace(/,/g, ''),

    /**
     * @author Betty
     * @param {number} hour - 時數
     * @return {number}
     */
    transferHourFormat: (hour) => hour ? hour.toFixed(1) : 0,

    /**
     * @author Betty
     * @param {string} date - 西元日期
     * @return {string}
     * YYYY-MM-DD to YYYY/MM/DD
     */
    transferDateFormat: (date) => date ? moment(date).format('YYYY/MM/DD') : '--',

    /**
     * @author Betty
     * @param {object} year, month, day - 民國日期
     * @return {string}
     */
    transferBirthdayFormat: ({ year, month, day }) => year ? `${year}/${month}/${day}` : '--',

    /**
     * @author Betty
     * @param {string} num - 手機號碼
     * @returns {string}
     * Notes: 看之後有沒有需要，不需要再用回原始格式
     */
    phoneNumFormat: (num) => (num.replace(/-/gi, '')),

    /**
     * @author Betty
     * @param  {number} num1 - 小數點數字
     * @param  {number} num2 - 小數點數字
     * @returns {number}
     */
    mul: (num1, num2) => {

        let m = 0,
            r1, r2,
            s1 = num1.toString(),
            s2 = num2.toString();

        try {
            m += s1.split('.')[1].length;
        } catch (e) {
            m += 0;
        }
        try {
            m += s2.split('.')[1].length;
        } catch (e) {
            m += 0;
        }

        r1 = +(s1.replace('.', ''));
        r2 = +(s2.replace('.', ''));

        return r1 * r2 / Math.pow(10, m);

    },

    minus: (num1, num2) => {

        let m = 0,
            r1 = num1.toString().split('.')[1],
            r2 = num2.toString().split('.')[1],
            s1 = r1 ? r1.length : 0,
            s2 = r2 ? r2.length : 0,
            max = Math.max(s1, s2),
            pow = Math.pow(10, max);

        return ((num1 * pow) - (num2 * pow)) / pow;

    },

    add: (num1, num2) => {

        let r1,
            r2,
            m;

        try {

            r1 = num1.toString().split('.')[1].length;

        }
        catch (e) {

            r1 = 0;

        }

        try {

            r2 = num2.toString().split('.')[1].length;

        }
        catch (e) {

            r2 = 0;

        }

        m = Math.max(r1, r2);
        return +parseFloat(num1 + num2).toFixed(m);

    },

    /**
     * @author Betty
     * @param {number} timer - 計時器
     * @returns {promise}
     */
    deferToStuff: (timer = 1000) => new Promise((resolve) => setTimeout(resolve, timer)),

    /**
     * @author Betty
     * @param {object} object
     * @returns {boolean}
     */
    checkObjLength: (object) => !!Object.entries(object).length,

    debounce: (callback, wait = 250) => {

        let timeout;

        return (...args) => {

            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);

        };

    },

    /**
     * @author Betty
     * @param {string} param1 - 字串
     * @return {string}
     */
    renderWithoutValue: (value) => value ? value : '--',

};

export default Moon;
