import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';

// Checkbox Left
const otherSetting = [
    {
        key: 'isOneThird',
        label: '⅓、⅔',
    },
    {
        key: 'isOneQuarter',
        label: '¼、¾',
    },
    {
        key: 'isHalf',
        label: '½',
    },
    {
        key: 'isOneHalf',
        label: '1.5',
    },
    {
        key: 'isTwo',
        label: '2',
    },
];

// Checkbox Right
const times = [
    {
        key: 'isLeave',
        label: '列為休假',
    },
    {
        key: 'isAnnualLeave',
        label: '納入年假計算',
    },
    {
        key: 'enableEditSheet',
        label: '在總表中編輯',
    },
    {
        key: 'enableEditProject',
        label: '在專案中編輯 (不為休假)',
    },
];

const WorkLogTypeForm = ({
    storage,
    register,
    errors,
    handleChange,
    handleSubmit,
    handleReqData,
    hideModal,
}) => {

    const {
        priority,
        name,
        shortHand,
        colorCode,
        colorText,
        code,
        weight,
    } = storage;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <div className="items">
                <FormRow
                    labelTitle="名稱"
                    required={true}
                    error={errors.name && true}
                >
                    <input
                        type="text"
                        name="name"
                        defaultValue={name}
                        ref={register({ required: true })}
                    />
                </FormRow>

                <FormRow labelTitle="顯示單字">
                    <input
                        type="text"
                        name="shortHand"
                        defaultValue={shortHand}
                        ref={register}
                    />
                </FormRow>
            </div>

            <div className="items">
                <FormRow
                    labelTitle="色碼"
                    className="colorWrap"
                    required={true}
                    error={errors.colorCode && true}
                >
                    <input
                        type="color"
                        name="colorCode"
                        defaultValue={colorCode}
                        onChange={handleChange}
                        ref={register({ required: true })}
                    />
                </FormRow>

                <FormRow
                    labelTitle="文字色碼"
                    className="colorWrap"
                    required={true}
                    error={errors.colorText && true}
                >
                    <input
                        type="color"
                        name="colorText"
                        defaultValue={colorText}
                        onChange={handleChange}
                        ref={register({ required: true })}
                    />
                </FormRow>
            </div>

            <FormRow
                labelTitle="代碼"
                required={true}
                error={errors.code && true}
                notes="若為組合字請用駝峰式命名，Ex. needToConfirm"
            >
                <input
                    type="text"
                    name="code"
                    defaultValue={code}
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="items">
                <FormRow labelTitle="類型加權">
                    <input
                        type="number"
                        name="weight"
                        defaultValue={weight}
                        ref={register}
                    />
                </FormRow>

                <FormRow
                    labelTitle="優先權"
                    notes="數字越大，排列越後面"
                >
                    <input
                        type="number"
                        name="priority"
                        defaultValue={priority}
                        ref={register}
                    />
                </FormRow>
            </div>

            <div className="items items-choose">
                <div className="cell">
                    <h4>其他</h4>
                    {
                        times.map(({ key, label }) => (

                            <div
                                key={key}
                                className="checkboxWrap"
                            >
                                <Checkbox
                                    name={key}
                                    onChange={handleChange}
                                    defaultChecked={!!storage[key]}
                                >
                                    {label}
                                </Checkbox>
                            </div>

                        ))
                    }
                </div>

                <div className="cell">
                    <h4>時數</h4>
                    {
                        otherSetting.map(({ key, label }) => (

                            <div
                                key={key}
                                className="checkboxWrap"
                            >
                                <Checkbox
                                    name={key}
                                    onChange={handleChange}
                                    {...(storage[key] ? { checked: storage[key] } : {})}
                                >
                                    {label}
                                </Checkbox>
                            </div>

                        ))
                    }
                </div>
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="default"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

}

WorkLogTypeForm.defaultProps = {
    storage: {},
    errors: {},
};

WorkLogTypeForm.propTypes = {
    storage: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleReqData: PropTypes.func,
    hideModal: PropTypes.func,
};

export default WorkLogTypeForm;
