import PMB from './moon.pmb';
import moonConst from './moon.const';

const { pmbHost, apiHost } = moonConst;

const Service = {
    login: ({ reqData, headers }) => PMB.serviceProxy('/login', reqData, {
        headers: { ...headers },
    }),

    /**
     * 全域
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/common
     */
    global: (reqData) => PMB.serviceProxy('/web_prerequest_info', reqData),

    // 取得所有員工: 專案、休假人員 form 表單會用到
    getAllMembers: (reqData) => PMB.serviceProxy('/web_departments_atwork_staffs', reqData),


    /**
     * 行事曆列表
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/00_%E6%A8%A1%E7%B5%84/calendar
     */
    calendar: (reqData) => PMB.serviceProxy('/web_schedules/list', reqData),

    // [設定頁] 新增行事曆
    calendarCreate: (reqData) => PMB.serviceProxy('/web_schedules/create', reqData),

    // [設定頁] 編輯行事曆
    calendarUpdate: (reqData) => PMB.serviceProxy('/web_schedules/update', reqData),

    // [設定頁] 刪除行事曆
    calendarDelete: (reqData) => PMB.serviceProxy('/web_schedules/delete', reqData),


    /**
     * index: 首頁、看板
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/01_Home_%E9%A6%96%E9%A0%81/dashboard
     */
    dashboard: (reqData) => PMB.serviceProxy('/web_dashboard', reqData),

    // 切換月份取得當月工時子項目
    aMonthWorkLogList: (reqData) => PMB.serviceProxy('/web_work_logs_a_month', reqData),

    // 看板: 取得當日工時詳細
    aDayWorkLogList: (reqData) => PMB.serviceProxy('/web_work_logs_a_day', reqData),

    // 編輯假與工時
    aDayUpdateWorkLog: (reqData) => PMB.serviceProxy('/web_work_logs/update', reqData),

    // 刪除假與工時
    aDayDeleteWorkLog: (reqData) => PMB.serviceProxy('/web_work_logs/delete', reqData),

    /**
     * index: 填工時
     * gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/01_Home_%E9%A6%96%E9%A0%81/work_log
     */
    fillWorkLog: (reqData) => PMB.serviceProxy('/web_work_logs/create', reqData),


    /**
     * profile: 個人資料
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/02_Member_%E6%88%90%E5%93%A1/1.1_Profile_%E5%80%8B%E4%BA%BA%E8%B3%87%E6%96%99/profile
     */
    profile: (reqData) => PMB.serviceProxy('/web_member_profile', reqData),

    // 編輯個人資料
    updateProfile: (reqData) => PMB.serviceProxy('/web_member_profile/update', reqData),

    // 組別異動記錄列表
    groupHistory: (reqData) => PMB.serviceProxy('/web_member_groups', reqData),
    createGroupHistory: (reqData) => PMB.serviceProxy('/web_member_groups/create', reqData),
    removeGroupHistory: (reqData) => PMB.serviceProxy('/web_member_groups/delete', reqData),


    /**
     * 個人薪資
     * gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/02_Member_%E6%88%90%E5%93%A1/1.1_Profile_%E5%80%8B%E4%BA%BA%E8%B3%87%E6%96%99/salary
     */
    salary: (reqData) => PMB.serviceProxy('/web_member_salaries', reqData),

    // 新增薪資
    createSalary: (reqData) => PMB.serviceProxy('/web_member_salaries/create', reqData),

    // 刪除薪資
    removeSalary: (reqData) => PMB.serviceProxy('/web_member_salaries/delete', reqData),


    /**
     * MemberLists: 所有員工
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/02_Member_%E6%88%90%E5%93%A1/1.2_Members_%E6%89%80%E6%9C%89%E5%93%A1%E5%B7%A5/member_lists
     */
    memberList: (reqData) => PMB.serviceProxy('/web_members', reqData),

    // 新增員工
    memberCreate: (reqData) => PMB.serviceProxy('/web_members/create', reqData),


    /**
     * DayoffList: 每日休假清單
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/02_Member_%E6%88%90%E5%93%A1/1.3_Dayoff_%E8%AB%8B%E5%81%87%E4%BA%BA%E5%93%A1/calendar_members
     */
    dayoffLists: (reqData) => PMB.serviceProxy('/web_dayoff', reqData),

    // 休假: 所有員工清單
    dayoffMemberList: (reqData) => PMB.serviceProxy('/web_dayoff_day/list', reqData),

    // 新增假 (登錄)
    createDayoff: (reqData) => PMB.serviceProxy('/web_dayoff_day/create', reqData),

    // 編輯假
    updateDayoff: (reqData) => PMB.serviceProxy('/web_dayoff_day/update', reqData),

    // 刪除假
    removeDayoff: (reqData) => PMB.serviceProxy('/web_dayoff_day/delete', reqData),


    /**
     * LazyList: 閒置清單
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/02_Member_%E6%88%90%E5%93%A1/1.4_LazyLists%E9%96%92%E7%BD%AE%E6%B8%85%E5%96%AE/lazy_lists
     */
    lazyList: (reqData) => PMB.serviceProxy('/web_lazy_lists', reqData),


    /**
     * Project & MyProject
     * 所有專案: http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/03_Project_%E5%B0%88%E6%A1%88/project_list_all
     * 個人專案: http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/03_Project_%E5%B0%88%E6%A1%88/project_list_personal
     */
    projectList: (reqData) => PMB.serviceProxy('/web_projects/list', reqData),

    // 取得當前專案所有項目
    toDoList: (reqData) => PMB.serviceProxy('/web_personal_project_tasks/list', reqData),

    // 取得專案詳細
    projectDetail: (reqData) => PMB.serviceProxy('/web_projects', reqData),

    // 新增專案
    createProject: (reqData) => PMB.serviceProxy('/web_projects/create', reqData),

    // 編輯專案 (列表)
    updateProjectList: (reqData) => PMB.serviceProxy('/web_project_list/update', reqData),

    // 編輯專案 (詳細)
    updateProject: (reqData) => PMB.serviceProxy('/web_projects/update', reqData),

    // 刪除專案
    removeProject: (reqData) => PMB.serviceProxy('/web_projects/delete', reqData),

    // 新增任務
    createTask: (reqData) => PMB.serviceProxy('/web_task_groups/create', reqData),

    // 編輯任務
    updateTask: (reqData) => PMB.serviceProxy('/web_task_groups/update', reqData),

    // 刪除任務
    removeTask: (reqData) => PMB.serviceProxy('/web_task_groups/delete', reqData),

    // 新增項目
    createToDoList: (reqData) => PMB.serviceProxy('/web_tasks/create', reqData),

    // 編輯項目
    updateToDoList: (reqData) => PMB.serviceProxy('/web_tasks/update', reqData),

    // 刪除項目
    removeToDoList: (reqData) => PMB.serviceProxy('/web_tasks/delete', reqData),

    // 搜尋項目
    searchToDoList: (reqData) => PMB.serviceProxy('/web_project_tasks_search', reqData),

    // 取得過濾指派人篩選項目
    getFilterAssigneeOpts: (reqData) => PMB.serviceProxy('/web_task_assignee_search_options', reqData),

    // 過濾指派人
    filterAssignee: (reqData) => PMB.serviceProxy('/web_task_assignee_search', reqData),

    // 取得表單所有 Options
    getProjectFormOpts: (reqData) => PMB.serviceProxy('/web_project_options', reqData),

    // 登錄工時歷史紀錄
    workLogHistoryList: (reqData) => PMB.serviceProxy('/web_tasks_logs/list', reqData),

    // 專案: 取得當日工時詳細
    aDayWorkLogHistoryList: (reqData) => PMB.serviceProxy('/web_tasks_logs_a_day', reqData),

    // 取得當日已填、剩餘工時
    workLogRemind: (reqData) => PMB.serviceProxy('/web_remaining_work_time', reqData),

    // 填工時
    createWorkLog: (reqData) => PMB.serviceProxy('/web_tasks_logs/create', reqData),

    // 編輯工時
    updateWorkLog: (reqData) => PMB.serviceProxy('/web_tasks_logs/update', reqData),

    // 刪除工時
    removeWorkLog: (reqData) => PMB.serviceProxy('/web_tasks_logs/delete', reqData),

    // 另計支出 List
    otherFeeList: (reqData) => PMB.serviceProxy('/web_project_deductions/list', reqData),

    // 新增另計支出
    createOtherFee: (reqData) => PMB.serviceProxy('/web_project_deductions/create', reqData),

    // 編輯另計支出
    updateOtherFee: (reqData) => PMB.serviceProxy('/web_project_deductions/update', reqData),

    // 刪除另計支出
    removeOtherFee: (reqData) => PMB.serviceProxy('/web_project_deductions/delete', reqData),

    // 進階搜尋
    projectSearch: (reqData) => PMB.serviceProxy('/web_projects_search', reqData),

    // 上傳檔案
    uploadFile: (reqData) => PMB.serviceProxy('/web_project_files/upload', reqData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),

    // [GET] 取得檔案內容 (預覽檔案)
    previewFile: (reqData) => `${apiHost ? apiHost : pmbHost}/api/web_project_files/upload?id=${reqData}`,

    // 刪除檔案
    removeFile: (reqData) => PMB.serviceProxy('/web_project_files/delete', reqData),

    // 專案: 下載 excel
    downloadExcelWorklog: (reqData) => `${apiHost ? apiHost : pmbHost + '/api'}/web_excel_files?${reqData}`,
    downloadExcelCredit: (reqData) => `${apiHost ? apiHost : pmbHost + '/api'}/web_credit_list?${reqData}`,

    // 新增小組預算
    createGroupBudget: (reqData) => PMB.serviceProxy('/web_department_budget/create', reqData),

    // 編輯小組預算
    updateGroupBudget: (reqData) => PMB.serviceProxy('/web_department_budget/update', reqData),

    // 刪除小組預算
    removeGroupBudget: (reqData) => PMB.serviceProxy('/web_department_budget/delete', reqData),


    /**
     * 專案時間軸
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/03_Project_%E5%B0%88%E6%A1%88/project_timeline
     */
    projectGroupBudgetCost: (reqData) => PMB.serviceProxy('/web_project_hr_cost', reqData),
    projectTimelineDataList: (reqData) => PMB.serviceProxy('/web_project_timeline', reqData),
    projectTimelineDataSearch: (reqData) => PMB.serviceProxy('/web_project_timeline_search', reqData),


    /**
     * 個人專案時間軸
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/03_Project_%E5%B0%88%E6%A1%88/project_timeline_personal
     */
    projectOwnCost: (reqData) => PMB.serviceProxy('/web_personal_project_cost', reqData),
    projectOwnTimelineDataSearch: (reqData) => PMB.serviceProxy('/web_personal_project_timeline_search', reqData),


    /**
     * 技能樹
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/area_skill_list
     */
    skillAreaList: (reqData) => PMB.serviceProxy('/web_areas_skills_members', reqData),

    // http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/areas
    // 領域新增、編輯、刪除
    createArea: (reqData) => PMB.serviceProxy('/web_areas/create', reqData),
    updateArea: (reqData) => PMB.serviceProxy('/web_areas/update', reqData),
    removeArea: (reqData) => PMB.serviceProxy('/web_areas/delete', reqData),

    // http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/skills
    // 技能新增、編輯、刪除；領域與技能綁定
    manageAreaSkill: (reqData) => PMB.serviceProxy('/web_area_skills/update', reqData),
    createSkill: (reqData) => PMB.serviceProxy('/web_skills/create', reqData),
    updateSkill: (reqData) => PMB.serviceProxy('/web_skills/update', reqData),
    removeSkill: (reqData) => PMB.serviceProxy('/web_skills/delete', reqData),

    // 取得員工技能評分列表
    personalSkillScoreList: (reqData) => PMB.serviceProxy('/web_member_skills', reqData),
    updatePersonalSkillScore: (reqData) => PMB.serviceProxy('/web_member_skills/update', reqData),

    // http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/member_skill_list
    // 所有員工技能評分
    skillMemberList: (reqData) => PMB.serviceProxy('/web_member_skills/list', reqData),
    skillMemberSearch: (reqData) => PMB.serviceProxy('/web_member_skills_search', reqData),

    /**
     * 特質：允許多筆新增、編輯、刪除
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/settings
     */
    createFeature: (reqData) => PMB.serviceProxy('/web_features/create', reqData),
    updateFeature: (reqData) => PMB.serviceProxy('/web_features/update', reqData),
    removeFeature: (reqData) => PMB.serviceProxy('/web_features/delete', reqData),

    /**
     * 擅長工具：允許多筆新增、編輯、刪除
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/settings
     */
    createTool: (reqData) => PMB.serviceProxy('/web_tools/create', reqData),
    updateTool: (reqData) => PMB.serviceProxy('/web_tools/update', reqData),
    removeTool: (reqData) => PMB.serviceProxy('/web_tools/delete', reqData),

    /**
     * 綁定標籤
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/-/wikis/05_%E6%8A%80%E8%83%BD%E6%A8%B9/area_skill_list
     */
    manageMemberLabel: (reqData) => PMB.serviceProxy('/web_member_features_tools/update', reqData),

    //
    groupsRolesSkillsList: (reqData) => PMB.serviceProxy('/web_departments_roles_skills', reqData),

    // 角色
    roleCreate: (reqData) => PMB.serviceProxy('/web_roles/create', reqData),
    roleUpdate: (reqData) => PMB.serviceProxy('/web_roles/update', reqData),
    roleDelete: (reqData) => PMB.serviceProxy('/web_roles/delete', reqData),

    // 技能
    skillCreate: (reqData) => PMB.serviceProxy('/web_skills/create', reqData),
    skillUpdate: (reqData) => PMB.serviceProxy('/web_skills/update', reqData),
    skillDelete: (reqData) => PMB.serviceProxy('/web_skills/delete', reqData),


    /**
     * 專案設定 (包括狀態與類別)
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/04_Affair_%E7%B8%BD%E5%8B%99/4.2_Setting_%E8%A8%AD%E5%AE%9A/project_setting
     */
    projectSettingList: (reqData) => PMB.serviceProxy('/web_project_status_and_types', reqData),
    projectStatusCreate: (reqData) => PMB.serviceProxy('/web_project_status/create', reqData),
    projectStatusUpdate: (reqData) => PMB.serviceProxy('/web_project_status/update', reqData),
    projectStatusDelete: (reqData) => PMB.serviceProxy('/web_project_status/delete', reqData),
    projectTypesCreate: (reqData) => PMB.serviceProxy('/web_project_types/create', reqData),
    projectTypesUpdate: (reqData) => PMB.serviceProxy('/web_project_types/update', reqData),
    projectTypesDelete: (reqData) => PMB.serviceProxy('/web_project_types/delete', reqData),


    /**
     * 權限
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/04_Affair_%E7%B8%BD%E5%8B%99/4.2_Setting_%E8%A8%AD%E5%AE%9A/authority
     */
    authorityList: (reqData) => PMB.serviceProxy('/web_authorities/list', reqData),
    authorityUpdate: (reqData) => PMB.serviceProxy('/web_authorities/update', reqData),


    /**
     * 登錄工時與假別
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/04_Affair_%E7%B8%BD%E5%8B%99/4.2_Setting_%E8%A8%AD%E5%AE%9A/work_log_type
     */
    workLogTypeList: (reqData) => PMB.serviceProxy('/web_work_log_type/list', reqData),
    workLogTypeCreate: (reqData) => PMB.serviceProxy('/web_work_log_type/create', reqData),
    workLogTypeUpdate: (reqData) => PMB.serviceProxy('/web_work_log_type/update', reqData),
    workLogTypeDelete: (reqData) => PMB.serviceProxy('/web_work_log_type/delete', reqData),


    // 組織架構
    organize: (reqData) => PMB.serviceProxy('/web_organizations/list', reqData),
    organizeList: (reqData) => PMB.serviceProxy('/web_organizations_all', reqData),
    treeList: (reqData) => PMB.serviceProxy('/web_companies_hierarchy', reqData),
    companyCreate: (reqData) => PMB.serviceProxy('/web_companies/create', reqData),
    companyUpdate: (reqData) => PMB.serviceProxy('/web_companies/update', reqData),

    departmentCreate: (reqData) => PMB.serviceProxy('/web_departments/create', reqData),
    departmentUpdate: (reqData) => PMB.serviceProxy('/web_departments/update', reqData),
    departmentDelete: (reqData) => PMB.serviceProxy('/web_departments/delete', reqData),

    groupCreate: (reqData) => PMB.serviceProxy('/web_groups/create', reqData),
    groupUpdate: (reqData) => PMB.serviceProxy('/web_groups/update', reqData),
    groupDelete: (reqData) => PMB.serviceProxy('/web_groups/delete', reqData),


    /**
     * 工作天數與成本
     * http://gitlab.moonshine.tw/md-rd/react-pmb-v1/wikis/04_Affair_%E7%B8%BD%E5%8B%99/4.2_Setting_%E8%A8%AD%E5%AE%9A/work_day_setting
     */
    costRatioList: (reqData) => PMB.serviceProxy('/web_cost_ratio_setting/list', reqData),

    // 編輯工作天數與成本
    updateCostRatio: (reqData) => PMB.serviceProxy('/web_cost_ratio_setting/update', reqData),

};

export default Service;
