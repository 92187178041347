import React, { Fragment, useContext } from 'react';
import { Radio } from 'antd';
import moment from 'moment';

import { FormRow } from '../../components/LightboxForm';
import Tags from '../../components/Tags';

import moonConst from '../../lib/moon.const';
import { GlobalContext } from '../../context/global.state';

const RadioGroup = Radio.Group;
const { workLogHour } = moonConst;

const LeaveTypeForm = ({
    storage,
    selectedDate,
    currWorkLogType,
    handleWorkLogTypeChange,
    handleWorkLogHoursChange
}) => {

    // Context
    const { dayOffTypes } = useContext(GlobalContext);

    // 過濾假別時數
    const filterHourOfLeaveType = dayOffTypes.filter(({ code }) => code === currWorkLogType)[0] || '';

    const { workLogType, workLogHours } = storage;

    return (

        <Fragment>

            <FormRow
                labelTitle="日期 (YYYY/MM/DD)"
                className="readOnly"
                notes="無法更改日期"
            >
                <input
                    type="text"
                    name="leaveStartDate"
                    defaultValue={moment(selectedDate).format('YYYY/MM/DD')}
                    readOnly={true}
                />
            </FormRow>

            <div className="leaveTypeWrap leaveType">
                <h4 className="title isRequired">假別</h4>

                <RadioGroup
                    name="leaveType"
                    className="form-radios"
                    defaultValue={workLogType}
                    onChange={handleWorkLogTypeChange}
                >
                    {
                        dayOffTypes.map(({
                            id,
                            code,
                            name,
                            shortHandText,
                            colorCode,
                            colorText,
                        }) => (

                            <Radio
                                key={id}
                                value={code}
                            >
                                <span>{name}</span>

                                <Tags
                                    color={colorCode}
                                    textColor={colorText}
                                    text={shortHandText}
                                />
                            </Radio>

                        ))
                    }
                </RadioGroup>
            </div>

            <div className="leaveTypeWrap leaveHour">
                <h4 className="title isRequired">時數</h4>

                <RadioGroup
                    name="leaveHours"
                    className="form-radios"
                    defaultValue={workLogHours}
                    onChange={handleWorkLogHoursChange}
                >
                    {
                        filterHourOfLeaveType.provideHours.map((hour) => (

                            <Radio
                                key={hour}
                                value={hour}
                            >
                                {workLogHour[hour]}日
                            </Radio>

                        ))
                    }
                </RadioGroup>
            </div>
        </Fragment>

    );

};

export {
    LeaveTypeForm as default,
};
