import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import Buttons from '../../components/Buttons';
import { Rangepickers } from '../../components/Datepickers';
import moonConst from '../../lib/moon.const';

const { startDateText, endDateText } = moonConst;
const placeholder = [startDateText, endDateText];

//
const TimelineDataSearch = ({
    selected,
    groups,
    startDate,
    endDate,
    handleRangepicker,
    handleCheckboxChange,
    handleSearchClear,
}) => {

    return (

        <Fragment>
            <label>
                <span className="title">查詢區間：</span>
                <Rangepickers
                    placeholder={placeholder}
                    value={[startDate, endDate]}
                    allowClear={true}
                    allowEmpty={[true, true]}
                    handleChange={handleRangepicker}
                />
            </label>

            <span className="checkboxesWrap">
                {
                    groups.map(({ groupId, groupName }) => (

                        <Checkbox
                            key={groupId}
                            name={groupId}
                            className="checkbox-item"
                            checked={Object.entries(selected).length ? selected[groupId] : false}
                            onChange={handleCheckboxChange}
                        >
                            {groupName}
                        </Checkbox>

                    ))
                }
            </span>

            <span>
                <Buttons
                    text="取消"
                    type="secondary"
                    onClick={handleSearchClear}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </span>
        </Fragment>

    );

};

TimelineDataSearch.defaultProps = {
    groups: [],
};

TimelineDataSearch.propTypes = {
    selected: PropTypes.object,
    groups: PropTypes.array.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    handleRangepicker: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleSearchClear: PropTypes.func,
};

export default TimelineDataSearch;
