import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import PMB from '../lib/moon.pmb';

const { tagStyle } = PMB;

const Tags = ({
    color,
    textColor,
    text,
    className,
}) => (

    <Tag
        className={`tagsWrap ${className && className}`}
        color={color}
        style={tagStyle(textColor)}
    >
        {text}
    </Tag>

);

Tags.defaultProps = {
    color: '#000',
    textColor: '',
    text: '',
    className: '',
};

Tags.propTypes = {
    color: PropTypes.string,
    textColor: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    className: PropTypes.string,
};

export {
    Tags as default,
};
