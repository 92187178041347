export const skillReducer = (state, { type, payload }) => {

    switch (type) {
        case 'skill_area_list':
            return {
                ...state,
                ...payload,
            };

        case 'create_area':
            return {
                ...state,
                areas: [...state.areas, { ...payload }],
                skillAreaList: [
                    ...state.skillAreaList,
                    {
                        // 給預設子層
                        areaId: payload.id,
                        id: null,
                        name: '',
                        members: [],
                    },
                ],
            };

        case 'update_area':
            return {
                ...state,
                areas: state.areas.map((obj) => (obj.id === payload.id) ? obj = payload : obj),
            };

        case 'remove_area':
            return {
                ...state,
                areas: state.areas.filter((obj) => obj.id !== payload),
                skillAreaList: state.skillAreaList.filter((obj) => obj.areaId !== payload),
            };

        case 'personal_skill_score':
            return {
                ...state,
                personalSkill: { ...state.personalSkill, ...payload },
            };

        // 新增、編輯技能評分
        case 'score_skill':
            return {
                ...state,
                skillAreaList: payload.lists,
                personalSkill: {
                    ...state.personalSkill,
                    // skills 從 reqData 來
                    skills: state.personalSkill.skills.map((obj) => {

                        obj.score = payload.skills[obj.id];
                        return obj;

                    }),
                },
            };

        // 管理技能與領域 (綁定)
        case 'manage_area_skill':
            return {
                ...state,
                skills: payload.skills,
                skillAreaList: payload.lists,
            };

        case 'create_skill':
            return {
                ...state,
                skills: [{ ...payload }, ...state.skills],
                skillAreaList: [{ ...payload, members: [] }, ...state.skillAreaList],
            };

        case 'update_skill':
            return {
                ...state,
                skills: state.skills.map((obj) => {

                    if (obj.id === payload.id) obj = payload;
                    return obj;

                }),
                skillAreaList: state.skillAreaList.map((obj) => {

                    if (obj.id === payload.id) obj = { ...obj, ...payload };
                    return obj;

                }),
            };

        case 'remove_skill':
            return {
                ...state,
                skills: state.skills.filter(({ id }) => id !== payload),
                skillAreaList: state.skillAreaList.filter(({ id }) => id !== payload),
            };

        // 選取員工技能 (新增、編輯) 後，丟給下個畫面打分數
        case 'manage_skill_member':
            return {
                ...state,
                personalSkill: payload,
            };

        case 'skill_member_list':
            return {
                ...state,
                skillMemberList: payload,
            };

        case 'skill_member_list_search':
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    ...payload,
                },
            };

        // 設定 > 特質
        case 'create_feature':
        case 'update_feature':
        case 'remove_feature':
            return {
                ...state,
                features: [...payload],
            };

        // 設定 > 擅長工具
        case 'create_tool':
        case 'update_tool':
        case 'remove_tool':
            return {
                ...state,
                tools: [...payload],
            };

        // 管理員工標籤 (特質、擅長工具)
        case 'manage_member_label':
            return {
                ...state,
                personalSkill: {
                    ...state.personalSkill,
                    ...payload,
                },
            };

        default:
            return state;
    }

};
