import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';

const TaskForm = () => {

    // Context
    const {
        currEvent,
        formStorageData,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createTask,
        updateTask,
    } = useContext(ProjectContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => lightboxDispatch({ type: 'HIDE' });

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        reqData = {
            ...formStorageData,
            ...reqData,
        };

        if (currEvent === 'updateTask') updateTask(reqData);
        else createTask(reqData);

    };

    const { name } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <FormRow
                labelTitle="任務名稱"
                required={true}
                error={errors.name && true}
            >
                <input
                    type="text"
                    name="name"
                    defaultValue={name}
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default TaskForm;
