import React, { Fragment, useContext } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CalendarTool from '../../components/CalendarTool';
import Tables from '../../components/Tables';
import Tags from '../../components/Tags';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const { transferHourFormat } = moon;
const { today } = moonConst;
const { mappingText } = PMB;

const WorkLogList = ({ data, userId }) => {

    // Context
    const {
        workLogTypes,
        dayOffTypes,
        getWorkLogRemind,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        workLog: {
            aDay,
            aMonthSummary,
            totalHours,
            lists,
        },
        aDayWorkLogList,
        removeWorkLog,
        projectDispatch,
    } = useContext(ProjectContext);

    // 表格欄位
    const columns = [
        {
            title: '種類',
            dataIndex: 'code',
            align: 'center',
            render: (code, { colorCode, colorText, isLeave }) => (

                <Tags
                    color={colorCode}
                    textColor={colorText || '#000'}
                    text={(isLeave ? mappingText(dayOffTypes, code)?.name : mappingText(workLogTypes, code)?.name) || '閒置'}
                />

            ),
        },
        {
            title: '時數 (分鐘)',
            dataIndex: 'minutes',
            align: 'center',
        },
        {
            title: '建立者',
            dataIndex: 'creator',
            className: 'col-creator',
            render: (creator, { creatorId }) => (

                <Link
                    to={`/myProject/${creatorId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {creator || '--'}
                </Link>

            ),
        },
        {
            title: '登錄時間',
            dataIndex: '',
            render: ({ createTime, updateTime }) => `${moment(updateTime ? updateTime : createTime).format('YYYY/MM/DD')} ${updateTime ? '更新' : '建立'}`,
        },
        {
            title: '操作',
            dataIndex: '',
            width: 120,
            className: 'col-action',
            render: (data) => (

                (data.isEditable && !data.isLeave) &&
                    <Fragment>
                        <span
                            className="btn-update"
                            onClick={() => btnUpdate(data)}
                        >
                            <EditOutlined /> 編輯
                        </span>
                        <span
                            className="btn-delete"
                            onClick={() => removeWorkLog({ id: data.id }, data.projectId)}
                        >
                            <DeleteOutlined /> 刪除
                        </span>
                    </Fragment>

            ),
        },
    ];

    // 工時編輯按鈕
    const btnUpdate = ({ id, code, minutes }) => {

        getWorkLogRemind({ userId });

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                ...formStorageData,
                id, // 工時 ID
                name: data.name,
                type: code,
                minutes,
                startDate: (formStorageData.selectedDate || today),
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateWorkLog' });

    };

    // 行事曆選取日期
    const handleSelectedDate = (date) => {

        const getDate = moment(date).format('YYYY-MM-DD');

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                ...formStorageData,
                selectedDate: getDate,
            },
        });

        // 若找不到子項目就不做任何事，並將 aDay 預設為空
        if (!lists[getDate]?.length) {

            projectDispatch({ type: 'worklog_list_a_day', payload: [] });
            return;
        }

        aDayWorkLogList({
            date: getDate,
            taskId: data.id, // 項目ID
        });

    };

    const { id, name } = data;
    const { selectedDate } = formStorageData;

    return (

        <div className="workLogListWrap">

            <h2>
                <span className="title">{name}</span>
                <span className="small-text">(項目 ID: {id})</span>
            </h2>

            {
                aMonthSummary &&
                    <span className="total-hours">
                        {transferHourFormat(aMonthSummary[moment(selectedDate ? selectedDate : today).format('YYYY-MM')])}h (月) / {transferHourFormat(totalHours)}h (總)
                    </span>
            }

            <CalendarTool
                showSelectedDateBox={false}
                selectedDate={selectedDate}
                subItemType="simple"
                subItems={lists}
                withoutText={true}
                handleSelected={handleSelectedDate}
            />

            <Tables
                className="aDayWrap"
                columns={columns}
                data={aDay}
                emptyText="這天沒有資料"
                rowKey="id"
                showTitle={true}
                showPage={false}
            />

            <div className="small-text selected-date">選取日期: {moment(selectedDate ? selectedDate : today).format('YYYY/MM/DD (ddd)')}</div>

        </div>

    );

};

export default WorkLogList;
