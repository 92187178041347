import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './Tab.scss';

const { TabPane } = Tabs;

const Tab = ({
    type,
    className,
    tabData,
    activeKey,
    onChange,
    onEdit,
}) => (

    <Tabs
        type={type}
        className={`tabWrap ${className && className}`}
        onChange={onChange}
        onEdit={onEdit}
        activeKey={activeKey}
    >
        {
            Object.keys(tabData).map((obj) => {

                const item = tabData[obj];

                return (

                    <TabPane
                        key={obj}
                        tab={item.name}
                        className={item.className}
                        closeIcon={<EditOutlined />}
                    >
                        {item.component}
                    </TabPane>

                );
            })
        }
    </Tabs>

);

Tab.defaultProps = {
    type: 'card',
    className: '',
    tabData: {},
};

Tab.propTypes = {
    type: PropTypes.string,
    tabData: PropTypes.object.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export {
    Tab as default,
};

// Example
// const tabData = {
//     profile: {
//         name: '關於我',
//         component: <Component1 />,
//     },
//     salary: {
//         name: '薪資',
//         component: <Component2 />,
//     },
// };
