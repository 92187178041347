import React, {
    createContext,
    useState,
    useEffect,
} from 'react';

import { Layout } from 'antd';

// CSS
import './utils/global.scss';

// Global
import { GlobalProvider } from './context/global.state';
import moonConst from './lib/moon.const';
import Service from './lib/moon.service';

// 左側
import Navbar from './containers/Navbar';

// 右側
import Content from './Content';

const { thisYear } = moonConst;

const styles = {
    minHeight: '100vh',
};

// Context
const Store = createContext({
    isEmployee: 0,
    user: {},
    hasAuth: {},
    workLogTypes: [],
    dayOffTypes: [],
    projectStatus: [],
    departments: [],
    calendarList: [],
});

// Service: 行事曆
async function fetchDataCalendar (year) {

    return await Service.calendar({ year: +year });

};

const Wrap = () => {

    // 行事曆
    const [calendarList, setCalendarList] = useState(
        (localStorage.getItem(`calendar_${thisYear}`) && JSON.parse(localStorage.getItem(`calendar_${thisYear}`))) || []
    );

    // 取得 Global 資料
    useEffect(() => {

        // 行事曆，若 localStorage 沒有東西就送 ajax
        if (!localStorage.getItem(`calendar_${thisYear}`)) handleFetchDataCalendar(thisYear);

    }, []);

    // 處理行事曆 ajax 回傳
    const handleFetchDataCalendar = (year) => {

        fetchDataCalendar(year)
            .then(({ lists }) => {

                localStorage.setItem(`calendar_${year}`, JSON.stringify(lists));
                setCalendarList(lists);

            });

    };

    // CalendarSetting 頁更新後的 callback
    const calendarCallback = ({ year, state = false }) => {

        // 需被更新
        if (state) {

            // 更新原有的 localStorage
            localStorage.removeItem(`calendar_${year}`);
            handleFetchDataCalendar(year);

        }
        else {

            // 直接取 localStorage
            setCalendarList(JSON.parse(localStorage.getItem(`calendar_${year}`)));

        }

    };

    const { Provider } = Store;

    return (

        <GlobalProvider>
            <Provider value={{
                calendarList,
                calendarCallback,
            }}>
                <Layout style={styles}>
                    <Navbar />
                    <Layout className="content">
                        <Content />
                    </Layout>
                </Layout>
            </Provider>
        </GlobalProvider>

    );

};

export {
    Wrap as default,
    Store,
};
