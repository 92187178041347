import React from 'react';
import PropTypes from 'prop-types';
import './SearchItems.scss';

const SearchItems = (props) => {

    const {
        title,
        children,
        className,
        showToggleAll,
        handleToggleAll,
    } = props;

    return (

        <div className="checkboxLabel">
            <div className="title">
                {title}
                {
                    showToggleAll &&
                        <span
                            className="event toggle-all"
                            onClick={handleToggleAll}
                        >
                            全選/取消
                        </span>
                }
            </div>
            <div className={`checkboxesContainer ${className ? className : ''}`}>{children}</div>
        </div>

    );

};

SearchItems.defaultProps = {
    className: '',
    showToggleAll: false,
};

SearchItems.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    showToggleAll: PropTypes.bool,
    handleToggleAll: PropTypes.func,
};

export {
    SearchItems as default,
};
