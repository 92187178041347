import React, {
    Fragment,
    useEffect,
    useContext,
} from 'react';

import { Row, Col } from 'antd';

import './Projects.scss';
import Loading from '../../components/Loading';
import LightboxForm from '../../components/LightboxForm';
import BackToTop from '../../components/BackToTop';

import ProjectsHeader from './ProjectsHeader';
import List from './List';
import Search from './Search';
import ProjectForm from '../../components/project/ProjectForm';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';

const Projects = () => {

    // Context
    const {
        hasAuth: { edit_all_projects },
        isLoading,
        currEvent,
        visible,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        searchResult: { isSearch },
        getProjectList,
    } = useContext(ProjectContext);

    useEffect(() => {

        document.title = '所有專案';

        // 列表
        getProjectList();

    }, []);

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    return (

        <Fragment>
            <div className="projects">
                {
                    // [編輯全部專案資料] 有權限才顯示
                    edit_all_projects && <ProjectsHeader isSearch={isSearch} />
                }

                <Row gutter={20}>
                    <Col
                        flex="0 0 220px"
                        className="column column-left"
                    >
                        <Search />
                    </Col>

                    <Col flex="1">
                        {
                            isLoading ? <Loading /> : <List isSearch={isSearch} />
                        }
                    </Col>
                </Row>
            </div>

            <BackToTop />

            {
                // Lightbox (新增、編輯 => 專案/任務/項目)
                visible &&
                    <LightboxForm
                        width={680}
                        title={
                            <Fragment>
                                {currEvent === 'updateProject' ? '編輯' : '新增'}專案
                                {
                                    currEvent === 'updateProject' &&
                                        <span className="small-text">(ID: {formStorageData.id})</span>
                                }
                            </Fragment>
                        }
                        visible={visible}
                        handleCancel={hideModal}
                    >
                        <ProjectForm />
                    </LightboxForm>
            }
        </Fragment>

    );

};

export default Projects;
