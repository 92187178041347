import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import Tables from '../../components/Tables';
import moon from '../../lib/moon';

const { Summary } = Table;
const { priceWithCommas, add } = moon;

// 總計 - 個人專案所有項目總時數與總成本
const renderProjectListCostSummary = (data) => {

    const summary = data.reduce((obj, { hours, cost, totalHours, totalCost }) => {

        obj.hours = obj.hours || 0;
        obj.hours = add(obj.hours, hours);

        obj.cost = obj.cost || 0;
        obj.cost = add(obj.cost, cost);

        obj.totalHours = obj.totalHours || 0;
        obj.totalHours = add(obj.totalHours, totalHours);

        obj.totalCost = obj.totalCost || 0;
        obj.totalCost = add(obj.totalCost, totalCost);

        return obj;

    }, {});

    const { hours, cost, totalHours, totalCost } = summary;

    return (

        <Summary.Row className="table-summary">
            <Summary.Cell className="summary-label">總計</Summary.Cell>
            <Summary.Cell className="total-hours">{hours || 0}h / {totalHours || 0}h</Summary.Cell>
            <Summary.Cell>{priceWithCommas(cost)} / {priceWithCommas(totalCost)}</Summary.Cell>
        </Summary.Row>

    );

};

// 總計 - 被指派項目總時數與總成本
const renderToDoListCostSummary = (data) => {

    const summary = data.reduce((obj, { cost, hours }) => {

        obj.cost = obj.cost || 0;
        obj.cost = add(obj.cost, cost);

        obj.hours = obj.hours || 0;
        obj.hours = add(obj.hours, hours);

        return obj;

    }, {});

    const { cost, hours } = summary;

    return (

        <Summary.Row className="table-summary">
            <Summary.Cell className="summary-label">總計</Summary.Cell>
            <Summary.Cell></Summary.Cell>
            <Summary.Cell>{hours || 0}h</Summary.Cell>
            <Summary.Cell>{priceWithCommas(cost)}</Summary.Cell>
        </Summary.Row>

    );

};

const TimelineDataOwnProjectList = ({ ownProjectList, ownToDoList }) => {

    // 表格欄位 - 個人專案總時數與總成本表
    const projectColumns = [
        {
            title: '專案名稱',
            dataIndex: 'name',
            render: (name, { id, colorCode, statusName }) => (

                <Link
                    to={`/projects/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span
                        className="project-status"
                        style={{ backgroundColor: colorCode }}
                    >
                        {statusName}
                    </span>
                    {name}
                </Link>

            ),
        },
        {
            title: <Fragment>時數(月)<br/>/ 總時數</Fragment>,
            dataIndex: 'hours',
            render: (hours, { totalHours }) => `${hours}h / ${totalHours}h`,
        },
        {
            title: <Fragment>成本(月)<br/>/ 總成本</Fragment>,
            dataIndex: 'cost',
            align: 'right',
            render: (cost, { totalCost }) => `${priceWithCommas(cost)} / ${priceWithCommas(totalCost)}`,
        },
    ];

    // 表格欄位 - 被指派項目
    const toDoColumns = [
        {
            title: '專案名稱',
            dataIndex: 'projectName',
            width: 180,
            render: (projectName, { pid, colorCode, statusName }) => (

                <Link
                    to={`/projects/${pid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span
                        className="project-status"
                        style={{ backgroundColor: colorCode }}
                    >
                        {statusName}
                    </span>
                    {projectName}
                </Link>

            ),
        },
        {
            title: '項目名稱',
            dataIndex: 'taskName',
        },
        {
            title: '總時數',
            dataIndex: 'hours',
            align: 'right',
            render: (hours) => `${hours || 0}h`,
        },
        {
            title: '總成本',
            dataIndex: 'cost',
            align: 'right',
            render: (cost) => priceWithCommas(cost),
        },
    ];

    return (

        <Fragment>
            <Tables
                className="timelineDataTable timelineData-project-list"
                columns={projectColumns}
                data={ownProjectList}
                rowKey="id"
                showTitle={true}
                showPage={false}
                summary={renderProjectListCostSummary}
            />

            <Tables
                className="timelineDataTable timelineData-todo-list"
                columns={toDoColumns}
                data={ownToDoList}
                rowKey="id"
                showTitle={true}
                showPage={false}
                summary={renderToDoListCostSummary}
            />
        </Fragment>

    );

};

TimelineDataOwnProjectList.defaultProps = {
    ownProjectList: [],
    ownToDoList: [],
};

TimelineDataOwnProjectList.propTypes = {
    ownProjectList: PropTypes.array.isRequired,
    ownToDoList: PropTypes.array.isRequired,
};

export {
    TimelineDataOwnProjectList as default,
};
