import React, { Fragment } from 'react';
import { Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// Tooltip
const Tooltips = ({ title, children }) => (

    <Tooltip
        title={title}
        placement="top"
        overlayClassName="tooltip"
    >
        {children}
    </Tooltip>

);

//
const TreeAction = ({
    data,
    btnUpdateDepartment,
    btnDeleteDepartment,
    btnCreateGroup,
    btnUpdateGroup,
    btnDeleteGroup,
}) => (

    <div className="row-title">
        <span className="text">
            {data.title}
            {
                !!data.children &&
                    <span>
                        (
                            <span
                                className="btn-createGroup"
                                onClick={() => btnCreateGroup(data)}
                            >
                                新增組別
                            </span>
                        )
                    </span>
            }
        </span>
        <span className="action">
            {
                // 部門
                data.children ? (

                    <Fragment>
                        {
                            !data.children.length &&
                                <Tooltips title="刪除部門">
                                    <span onClick={() => btnDeleteDepartment(data)}>
                                        <DeleteOutlined />
                                    </span>
                                </Tooltips>
                        }

                        {
                            data.isDelete &&
                                <Tooltips title="編輯部門">
                                    <span onClick={() => btnUpdateDepartment(data)}>
                                        <EditOutlined />
                                    </span>
                                </Tooltips>
                        }
                    </Fragment>

                ) : (

                    // 組別
                    <Fragment>
                        {
                            data.isDelete &&
                                <Tooltips title="刪除組別">
                                    <span onClick={() => btnDeleteGroup(data)}>
                                        <DeleteOutlined />
                                    </span>
                                </Tooltips>
                        }
                        <Tooltips title="編輯組別">
                            <span onClick={() => btnUpdateGroup(data)}>
                                <EditOutlined />
                            </span>
                        </Tooltips>
                    </Fragment>

                )
            }
        </span>
    </div>

);

export default TreeAction;
