import React, {
    Fragment,
    useEffect,
    useContext,
} from 'react';

import { PlusOutlined } from '@ant-design/icons';

import './SingleDetail.scss';
import LightboxForm from '../../components/LightboxForm';
import BackToTop from '../../components/BackToTop';

import Detail from './Detail';
import ProjectForm from '../../components/project/ProjectForm';
import TaskForm from '../../components/project/TaskForm';
import ToDoListForm from '../../components/project/ToDoListForm';
import WorkLogForm from '../../components/project/WorkLogForm';
import GroupBudgetForm from '../../components/project/GroupBudgetForm';
import FilterAssigneeForm from '../../components/project/FilterAssigneeForm';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moonConst from '../../lib/moon.const';

const { lightboxTitle } = moonConst;

const SingleDetail = (props) => {

    const { match } = props;
    const { params } = match;
    const pid = +params.id;

    // Context
    const {
        visible,
        currEvent,
        globalDispatch,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        detail,
        projectDetail,
    } = useContext(ProjectContext);

    useEffect(() => {

        // Betty: 先暫時寫死
        globalDispatch({ type: 'PAGE', payload: 'detail' });
        globalDispatch({ type: 'has_create_button', payload: true });
        projectDetail(pid);

    }, []);

    // 必要
    document.title = detail ? `P${detail.id}: ${detail.name}` : '專案詳細';

    // 顯示 Modal
    const showModal = (currEvent) => lightboxDispatch({ type: 'SHOW', currEvent });

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 新增專案
    const btnCreateProject = () => showModal('createProject');

    // 編輯專案
    const btnUpdateProject = ({
        id,
        name,
        typeId,
        code,
        directorId,
        producerId,
        managerId,
        managerAssistantId,
        leaderId,
        extraPMId,
        startDate,
        endDate,
        deadline,
        notes,
        budget,
        budgetTax,
        paymentStatus,
        companyId,
        isContract,
    }) => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id,
                name,
                typeId,
                code,
                directorId,
                producerId,
                managerId,
                managerAssistantId,
                leaderId,
                extraPMId,
                startDate,
                endDate,
                deadline,
                notes,
                budget,
                budgetTax,
                paymentStatus,
                companyId,
                isContract,
            },
        });

        showModal('updateProject');

    };

    // Lightbox Title
    const renderLightboxTitle = () => {

        switch (currEvent) {
            case 'filterAssignee':
                return '過濾指派人';

            default:
                return lightboxTitle[currEvent];
        }

    };

    // Lightbox 身體
    const renderLightboxContent = () => {

        switch (currEvent) {
            case 'createTask':
            case 'updateTask':
                return <TaskForm />;

            case 'createToDoList':
            case 'updateToDoList':
                return <ToDoListForm />;

            case 'createWorkLog':
            case 'updateWorkLog':
                return <WorkLogForm />;

            case 'createGroupBudget':
            case 'updateGroupBudget':
                return <GroupBudgetForm />;

            case 'filterAssignee':
                return <FilterAssigneeForm />

            default:
                return <ProjectForm />;
        }

    };

    return (

        <Fragment>
            <div className="projectContentWrap content-type-single">
                <Detail
                    handleUpdateProject={btnUpdateProject}
                />

                <PlusOutlined
                    title="新增專案"
                    className="btn-create"
                    onClick={btnCreateProject}
                />
            </div>

            <BackToTop />

            {
                // Lightbox
                visible &&
                    <LightboxForm
                        title={renderLightboxTitle()}
                        visible={visible}
                        handleCancel={hideModal}
                        {
                            ...(currEvent === 'createProject' || currEvent === 'updateProject') ? { width: 680 } : null
                        }
                    >
                        {renderLightboxContent()}
                    </LightboxForm>
            }
        </Fragment>

    );

};

export {
    SingleDetail as default,
};
