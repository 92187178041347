import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import Tags from '../../components/Tags';
import Tables from '../../components/Tables';
import PopConfirm from '../../components/PopConfirm';
import Buttons from '../../components/Buttons';
import GroupTimeLine from '../../components/GroupTimeLine';

import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const {
    transferDateFormat,
    priceWithCommas,
    renderWithoutValue,
} = moon;

const { memberWorkStatus, yesOrNo } = moonConst;
const { antdTableFilter } = PMB;

// 過濾在職員工
const filterAtWorkMembers = (data) => data.filter(({ workStatus }) => workStatus === 'atWork');

const List = ({ message }) => {

    // Context
    const {
        isShow,
        hasAuth: { view_all_members, edit_all_members }, // 觀看全部成員資料
        groups,
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        memberList,
        searchResult,
        formStorageDispatch,
        getGroupHistory,
    } = useContext(MemberContext);

    // State
    const [uid, setUid] = useState(null);

    // 表格欄位
    const columns = [
        {
            title: '系統編號 (員編)',
            dataIndex: 'userId',
            className: 'col-userId',
            sorter: (a, b) => a.userId - b.userId,
            render: (userId, { employeeId }) => (

                view_all_members ? (

                    <Fragment>
                        <Link
                            to={`/profile/${userId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {userId}
                        </Link>
                        <span className="small-text">({employeeId})</span>
                    </Fragment>

                ) : <span>{userId} <span className="small-text">({employeeId})</span></span>

            ),
        },
        {
            title: '姓名',
            dataIndex: 'nameChinese',
            className: 'col-names',
            render: (nameChinese, { userId }) => (

                <Link
                    to={`/myProject/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    {nameChinese}
                </Link>

            ),
        },
        {
            title: '組別',
            dataIndex: 'groupName',
            className: 'col-groups',
            filters: antdTableFilter(groups),
            onFilter: (value, data) => (String(data.groupId).indexOf(String(value)) === 0),
            render: (groupName, { userId, nameChinese }) => (

                <div className="groups-column">
                    <span className={groupName ? '' : 'warning-text'}>{groupName}</span>
                    {
                        edit_all_members &&
                            <PopConfirm
                                visible={(userId === uid) && isShow}
                                title={
                                    <GroupTimeLine message={
                                        <span className="small-text">({userId}: {nameChinese})</span>
                                    } />
                                }
                            >
                                <Buttons
                                    text="異動紀錄"
                                    className="btn-groupUpdate"
                                    onClick={() => btnGroupHistory(userId)}
                                />
                            </PopConfirm>
                    }
                </div>
            ),
        },
        {
            title: '部門',
            dataIndex: 'departmentName',
            render: (departmentName) => <span className={departmentName ? '' : 'warning-text'}>{departmentName}</span>,
        },
        {
            title: '在職狀態',
            dataIndex: 'workStatus',
            render: (workStatus) => (

                <Tags
                    color={`#${memberWorkStatus[workStatus].color}`}
                    text={memberWorkStatus[workStatus].text}
                />

            ),
        },
        {
            title: '職稱',
            dataIndex: 'position',
            render: (position) => renderWithoutValue(position),
        },
        {
            title: '角色',
            dataIndex: 'roleName',
            render: (roleName) => renderWithoutValue(roleName),
        },
        {
            title: '可指派為專案負責人',
            width: 120,
            dataIndex: 'isExtraPM',
            align: 'center',
            render: (isExtraPM) => <span className={isExtraPM ? 'is-extra-pm' : ''}>{yesOrNo[isExtraPM]}</span>,
        },
        {
            title: '操作',
            className: 'col-action',
            render: (data) => (

                view_all_members ? (

                    <Fragment>
                        <span onClick={(e) => btnUpdate(data, e)}>編輯</span>
                        <Link
                            to={`/profile/${data.userId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="view-more"
                            onClick={(e) => e.stopPropagation()}
                        >
                            看詳細
                        </Link>
                    </Fragment>

                ) : '--'

            ),
        },
    ];

    // 展開內容
    const renderExpandData = ({
        employeeId,
        nameChinese,
        email,
        departmentName,
        groupName,
        roleName,
        position,
        salary,
        firstDate,
    }) => (

        <div className="expandDetailWrap">
            <Row gutter={20}>
                <Col flex="1">
                    <p>
                        <span className="label">員工編號</span>
                        <span>{employeeId}</span>
                    </p>
                    <p>
                        <span className="label">姓名</span>
                        <span>{nameChinese}</span>
                    </p>
                    <p>
                        <span className="label">Email</span>
                        <span>{renderWithoutValue(email)}</span>
                    </p>
                </Col>

                <Col flex="1">
                    <p>
                        <span className="label">部門</span>
                        <span>{renderWithoutValue(departmentName)}</span>
                    </p>
                    <p>
                        <span className="label">組別</span>
                        <span>{renderWithoutValue(groupName)}</span>
                    </p>
                    <p>
                        <span className="label">角色</span>
                        <span>{renderWithoutValue(roleName)}</span>
                    </p>
                    <p>
                        <span className="label">職稱</span>
                        <span>{renderWithoutValue(position)}</span>
                    </p>
                </Col>

                <Col flex="1">
                    <p>
                        <span className="label">薪資</span>
                        <span>{priceWithCommas(salary)}</span>
                    </p>
                    <p>
                        <span className="label">到職日</span>
                        <span>{transferDateFormat(firstDate)}</span>
                    </p>
                </Col>
            </Row>
        </div>

    );

    // 編輯按鈕
    const btnUpdate = ({
        userId,
        employeeId,
        nameChinese,
        email,
        groupId,
        roleId,
        position,
        workStatus,
        firstDate,
        isExtraPM,
    }, e) => {

        e.stopPropagation();
        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                userId,
                employeeId,
                nameChinese,
                email,
                groupId,
                roleId,
                position,
                workStatus,
                firstDate,
                isExtraPM,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateMember' });

    };

    // 組別異動記錄列表
    const btnGroupHistory = (userId) => {

        setUid(userId);
        formStorageDispatch({ type: 'COLLECT', payload: { userId } });
        popConfirmDispatch({ type: 'SHOW' });

        if (uid === userId) return;
        getGroupHistory({ userId });

    };

    return (

        <Tables
            className="member-list"
            rowClassName={view_all_members ? 'has-expand' : ''}
            columns={columns}
            data={searchResult.isSearch ? searchResult.list : filterAtWorkMembers(memberList)}
            rowKey="userId"
            pageSize={50}
            {...message ? { emptyText: message } : null}
            {
                // 有權限之人可以看到 detail (expand data)
                ...view_all_members ? {
                    expandData: {
                        expandedRowRender: (data) => renderExpandData(data),
                    }
                } : {}
            }
        />

    );

};

List.defaultProps = {
    message: '查無此人...',
};

List.propTypes = {
    handleCopyToClipBoard: PropTypes.func,
    message: PropTypes.string,
};

export default List;
