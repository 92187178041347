import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { GlobalContext } from '../context/global.state';

const PopConfirm = ({
    title,
    visible,
    className,
    onCancel,
    children,
}) => {

    // Context
    const { popConfirmDispatch } = useContext(GlobalContext);

    // cancel
    const handleCancel = () => popConfirmDispatch({ type: 'HIDE' });

    return (

        <Popconfirm
            title={title}
            overlayClassName={`detail-tip ${className}`}
            placement="rightTop"
            visible={visible}
            cancelText="關閉"
            cancelButtonProps={{
                className: 'btn-close'
            }}
            okButtonProps={{
                className: 'btn-ok'
            }}
            onCancel={onCancel ? onCancel : handleCancel}
        >
            {children}
        </Popconfirm>

    );
};

PopConfirm.defaultProps = {
    visible: false,
};

PopConfirm.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    visible: PropTypes.bool, // Notes: 要調整
    className: PropTypes.string,
    onCancel: PropTypes.func, // Notes: 要調整
};

export default PopConfirm;
