import React, { useContext, useState } from 'react';
import { AutoComplete, message } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Buttons from './Buttons';
import Datepickers from './Datepickers';

import { GlobalContext } from '../context/global.state';
import { MemberContext } from '../context/member/member.state';

// 第一階段整理: project id 當 key
const arrangeList = (data) => data.reduce((acc, {
    id,
    name,
    departmentId,
    departmentName,
}) => {

    acc[departmentId] = acc[departmentId] || {};
    acc[departmentId].name = departmentName;
    acc[departmentId].groups = acc[departmentId].groups || [];
    acc[departmentId].groups.push({
        label: name,
        value: `${id}_${name}_${departmentName}`,
    });

    return acc;

}, {});

// 整理成 Ant Design 的結構
const antdAutoCompleteOpts = (data) => Object.keys(data).reduce((acc, curr) => {

    const obj = data[curr];

    acc.push({
        label: obj.name,
        options: obj.groups,
    });

    return acc;

}, []);

// Autocomplete filter 事件
const handleFilterOption = (inputVal, option) => {

    const regex = new RegExp(`${inputVal}`, 'g');
    return regex.test(option.value);

};

//
const GroupTimeLineForm = () => {

    // Context
    const { groups } = useContext(GlobalContext);
    const { createGroupHistory, formStorageData } = useContext(MemberContext);

    // React Hook Form
    const { handleSubmit } = useForm();

    // State
    const [effectiveDate, setEffectiveDate] = useState(null);
    const [selected, setSelected] = useState('');

    // 生效日
    const handleDateChange = (date) => {

        setEffectiveDate(moment(date).format('YYYY-MM-DD'));

    };

    // Autocomplete change 事件: 給畫面用
    const handleChangeOption = (value) => setSelected(value);

    // Reset
    const handleResetForm = () => {

        setSelected('');
        setEffectiveDate(null);

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...reqData,
            userId: formStorageData.userId,
            groupId: +selected.split('_')[0],
            effectiveDate,
        };

        createGroupHistory(reqData)
            .finally(() => {

                handleResetForm();
                message.success('更新成功!');

            });

    };

    return (

        <form onSubmit={handleSubmit(handleReqData)} className="groupHistoryForm">
            <input type="text" hidden="hidden" />

            <div className="filter-opts">
                <AutoComplete
                    allowClear={true}
                    placeholder="請輸入組別或部門"
                    options={antdAutoCompleteOpts(arrangeList(groups))}
                    filterOption={!!selected && handleFilterOption}
                    value={selected ? selected.split('_')[1] : ''}
                    onChange={handleChangeOption}
                />
            </div>

            <div className="effective-date">
                <Datepickers
                    name="effectiveDate"
                    value={effectiveDate ? moment(effectiveDate) : null}
                    handleChange={handleDateChange}
                    allowClear
                />
            </div>

            <div className="actions">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={handleResetForm}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default GroupTimeLineForm;
