import React, { Fragment, useState, useEffect } from 'react';
import { Collapse, Tooltip, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

import './ProjectSetting.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import Buttons from '../../components/Buttons';
import Prompt from '../../components/Prompt';
import LightboxForm from '../../components/LightboxForm';
import Collapses from '../../components/Collapses';
import Tables from '../../components/Tables';

import ProjectSettingForm from './ProjectSettingForm';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';
import PMB from '../../lib/moon.pmb';

const { Panel } = Collapse;
const { actionText } = moonConst;
const { tagStyle } = PMB;

// Service
async function fetchData () {

    return await Service.projectSettingList();

}

// 表格欄位
const columnDataStatus = ({ btnUpdate }) => ([
    {
        title: 'ID',
        dataIndex: 'id',
        width: 120,
        align: 'center',
    },
    {
        title: '名稱',
        dataIndex: 'name',
        className: 'col-name',
        width: 200,
        render: (name, { colorCode, colorText }) => (

            <Fragment>
                {name}

                <Tooltip
                    title={colorCode}
                    placement="rightTop"
                    overlayClassName="colorCodeWrap"
                >
                    <Tag
                        className="tagsWrap"
                        color={colorCode}
                        style={tagStyle(colorText)}
                    >
                        {name}
                    </Tag>
                </Tooltip>
            </Fragment>

        )
    },
    {
        title: '文字顏色',
        dataIndex: 'colorText',
        className: 'col-colorText',
        width: 110,
        render: (colorText) => (

            <Fragment>
                {
                    colorText &&
                        <Tooltip
                            title={colorText}
                            placement="rightTop"
                            overlayClassName="colorCodeWrap"
                        >
                            <Tag
                                className="tagsWrap"
                                color={colorText}
                            />
                        </Tooltip>
                }
            </Fragment>

        ),
    },
    {
        title: '代碼',
        dataIndex: 'code',
        width: 110,
    },
    {
        title: '優先權',
        dataIndex: 'priority',
        className: 'col-priority',
        render: (priority) => <div>{priority}</div>
    },
    {
        title: '操作',
        dataIndex: '',
        width: 200,
        render: (data) => (

            <Buttons
                type="secondary"
                text="編輯"
                handleClick={(e) => btnUpdate(data, 'status', e)}
            />
        ),
    },
]);

const columnDataTypes = ({ btnUpdate, handleDelete }) => ([
    {
        title: 'ID',
        dataIndex: 'id',
        width: 120,
        align: 'center',
    },
    {
        title: '名稱',
        dataIndex: 'name',
        width: 200,
    },
    {
        title: '優先權',
        dataIndex: 'priority',
        className: 'col-priority',
        render: (priority) => <div>{priority}</div>
    },
    {
        title: '操作',
        dataIndex: '',
        width: 200,
        render: (data) => (

            <Fragment>
                <Buttons
                    type="secondary"
                    text="編輯"
                    handleClick={(e) => btnUpdate(data, 'type', e)}
                />
                <Buttons
                    type="danger"
                    text="刪除"
                    handleClick={(e) => handleDelete(data, e)}
                />
            </Fragment>

        ),
    },
]);

const ProjectSetting = () => {

    // State
    const [visible, setVisible] = useState(false);
    const [currBtnType, setCurrBtnType] = useState('');
    const [currListsType, setCurrListsType] = useState('');

    // Data
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [storage, setStorage] = useState({});

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    useEffect(() => {

        document.title = '專案設定';

        fetchData()
            .then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = ({ projectStatus, projectTypes }) => {

        setStatus(projectStatus);
        setTypes(projectTypes);

    };

    // 顯示 Modal
    const showModal = () => setVisible(true);

    // 隱藏 Modal
    const hideModal = () => {

        setVisible(false);
        setStorage({});

    };

    // 建立 Lightbox title
    const renderLightboxTitle = ({ type, id }) => (

        <Fragment>
            {actionText[type]}{currListsType === 'status' ? '狀態' : '類型'}
            <span className="small-text">(ID: {id})</span>
        </Fragment>

    );

    // 建立新增按鈕
    const renderCreateBtn = (type) => (

        <div
            className="btn-create"
            onClick={(e) => btnCreate(e, type)}
        >
            <PlusOutlined />
        </div>

    );

    // 新增按鈕
    const btnCreate = (e, type) => {

        e.stopPropagation();
        setCurrListsType(type);
        setCurrBtnType('create');
        showModal();

    };

    // 編輯按鈕
    const btnUpdate = (obj, listType) => {

        setCurrListsType(listType);
        setCurrBtnType('update');
        setStorage(obj);
        showModal();

    };

    // 刪除
    const handleDelete = ({ id, name }) => {

        Prompt('confirm', {
            title: renderLightboxTitle({ type: 'delete', id }),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.projectTypesDelete({ id })
                    .then(() => {

                        Prompt('success', {
                            callback: () => {

                                setTypes(types.filter((obj) => obj.id !== id));

                            },
                        });

                    });

            },
        });

    };

    // 單一 checkbox
    const handleChange = ({ target: { name, value }}) => {

        setStorage({
            ...storage,
            [name]: value.replace('#', ''),
        });

    };

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        reqData = {
            ...storage,
            ...reqData,
            priority: +reqData.priority,
        };

        const config = {
            create: currListsType === 'status' ? 'projectStatusCreate' : 'projectTypesCreate',
            update: currListsType === 'status' ? 'projectStatusUpdate' : 'projectTypesUpdate',
        }[currBtnType];

        Service[config](reqData)
            .then((data) => {

                hideModal();

                Prompt('success', {
                    callback: () => {

                        // 編輯
                        if (currBtnType === 'update') {

                            // 專案狀態
                            if (currListsType === 'status') {

                                setStatus(status.map((obj) => {

                                    return (obj.id === id) ? obj = { ...reqData } : obj;

                                }));

                            }

                            // 專案類型
                            if (currListsType === 'type') {

                                setTypes(types.map((obj) => {

                                    return (obj.id === id) ? obj = { ...reqData } : obj;

                                }));

                            }

                        }

                        // 新增
                        if (currBtnType === 'create') {

                            // 專案狀態
                            if (currListsType === 'status') {

                                setStatus([
                                    ...status,
                                    { ...data },
                                ]);
                            }

                            // 專案類型
                            if (currListsType === 'type') {

                                setTypes([
                                    ...types,
                                    { ...data },
                                ]);
                            }

                        }

                    },
                });

            });

    };

    const { id } = storage;

    return (

        <Fragment>

            <Breadcrumbs />

            <div className="projectSetting">

                <Collapses
                    defaultKey={['projectStatus', 'projectTypes']}
                    withTopSpace={true}
                >
                    <Panel
                        key="projectStatus"
                        className="panelWrap"
                        header="專案狀態"
                        extra={renderCreateBtn('status')}
                    >
                        <Tables
                            columns={columnDataStatus({ btnUpdate })}
                            data={status}
                            rowKey="id"
                            showTitle={true}
                            showPage={false}
                        />
                    </Panel>

                    <Panel
                        key="projectTypes"
                        className="panelWrap"
                        header="專案類型"
                        extra={renderCreateBtn('type')}
                    >
                        <Tables
                            columns={columnDataTypes({ btnUpdate, handleDelete })}
                            data={types}
                            rowKey="id"
                            showTitle={true}
                            showPage={false}
                        />
                    </Panel>
                </Collapses>

                {
                    // Lightbox (新增、編輯)
                    visible &&
                        <LightboxForm
                            title={
                                currBtnType === 'update' ? renderLightboxTitle({ type: currBtnType, id }) : `新增${currListsType === 'status' ? '狀態' : '類型'}`
                            }
                            visible={visible}
                            handleCancel={hideModal}
                        >
                            <ProjectSettingForm
                                storage={storage}
                                currListsType={currListsType}
                                handleChange={handleChange}
                                hideModal={hideModal}
                                handleSubmit={handleSubmit}
                                handleReqData={handleReqData}
                                register={register}
                                errors={errors}
                            />
                        </LightboxForm>
                }

            </div>

        </Fragment>

    );

};

export {
    ProjectSetting as default,
};
