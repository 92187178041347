import React, { useState, useContext } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Tags from '../../components/Tags';
import AdvancedSearch from '../../components/AdvancedSearch';
import SearchItems from '../../components/SearchItems';
import { Rangepickers } from '../../components/Datepickers';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';

const { startDateText, endDateText, memberWorkStatus } = moonConst;
const placeholder = [startDateText, endDateText];

// 處理日期格式
const dateFormat = (date) => (date === null) ? null : moment(date).format('YYYY-MM-DD');

// 過濾已選取 checkboxes
const filterSelectedCheckboxes = (selected, isStr = false) => {

    let array = [];

    if (selected) {

        array = Object.keys(selected).filter((key) => selected[key]);

        if (!isStr) array = array.flatMap((obj) => +obj);

    }

    return array;

};

// 整理全選資料
const arrangeCheckboxesData = ({ type, data, checked = false }) => data.reduce((obj, param) => {

    const key = (type === 'status') ? param.code : param.id;

    obj[key] = checked;
    return obj;

}, {});

const Search = () => {

    // Context
    const { projectStatus } = useContext(GlobalContext);
    const {
        formOpts: {
            directors,
            companies,
            producers,
            managers,
            managerAssistants,
            CGLeads,
            projectCoordinators,
            extraPMs,
        },
        projectDispatch,
    } = useContext(ProjectContext);

    // State
    const [keyword, setKeyword] = useState('');
    const [deadline, setDeadline] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [isCheckedAll, setIsCheckedAll] = useState({
        status: false,
        company: false,
        director: false,
        producer: false,
        manager: false,
        managerAssistant: false,
        CGLead: false,
        coordinator: false,
        extraPM: false,
    });

    // React Hook Form
    const {
        handleSubmit,
        register,
        reset,
    } = useForm();

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setKeyword('');
        resetRangePickerValue();
        resetSelected();
        projectDispatch({ type: 'project_list_search', payload: { isSearch: false } });

    };

    // 還原 selected checkboxes
    const resetSelected = () => {

        let obj = { ...isCheckedAll };

        // 清除所有已被勾選的 checkboxes
        for (const i in obj) {

            obj[i] = false;

        }

        setSelectedCheckboxes({});

        // 還原全選狀態
        setIsCheckedAll({ ...obj });

    };

    // 還原 RangePicker
    const resetRangePickerValue = () => {

        setDeadline(null);
        setStartDate(null);
        setEndDate(null);

    };

    // 搜尋: Rangepicker
    const handleSearchRangepicker = (dates, dateString, type) => {

        if (!dates) {

            resetRangePickerValue();
            return;

        }

        switch (type) {
            case 'start':
                setStartDate(dates);
                break;

            case 'end':
                setEndDate(dates);
                break;

            default:
                setDeadline(dates);
                break;
        }

    };

    // 搜尋: checkbox 全選
    const handleCheckedAllToggle = (type) => {

        const config = {
            status: projectStatus,
            company: companies,
            director: directors,
            producer: producers,
            manager: managers,
            managerAssistant: managerAssistants,
            CGLead: CGLeads,
            coordinator: projectCoordinators,
            extraPM: extraPMs,
        };

        // 全選狀態
        let new_isCheckedAll = {
            ...isCheckedAll,
            [type]: !isCheckedAll[type],
        };
        setIsCheckedAll(new_isCheckedAll);

        // 已選取
        let new_selectedCheckboxes = {
            ...selectedCheckboxes,
            [type]: arrangeCheckboxesData({ type, data: config[type], checked: !isCheckedAll[type] })
        };
        setSelectedCheckboxes(new_selectedCheckboxes);
    };

    // 搜尋: checkbox 事件
    const handleCheckboxChange = (type, { target }) => {

        const { name, checked } = target;

        let new_selectedCheckboxes = {
            ...selectedCheckboxes,
            [type]: {
                ...selectedCheckboxes[type],
                [name]: checked,
            }
        }
        setSelectedCheckboxes(new_selectedCheckboxes);

    }

    // 搜尋: 送資料
    const handleSearchReqData = (reqData) => {

        reqData = {
            ...keyword,
            ...reqData,
            minDeadline: deadline ? dateFormat(deadline[0]) : null,
            maxDeadline: deadline ? dateFormat(deadline[1]) : null,
            minStartDate: startDate ? dateFormat(startDate[0]) : null,
            maxStartDate: startDate ? dateFormat(startDate[1]) : null,
            minEndDate: endDate ? dateFormat(endDate[0]) : null,
            maxEndDate: endDate ? dateFormat(endDate[1]) : null,
            status: filterSelectedCheckboxes(selectedCheckboxes['status'], true),
            directors: filterSelectedCheckboxes(selectedCheckboxes['director']),
            producers: filterSelectedCheckboxes(selectedCheckboxes['producer']),
            managers: filterSelectedCheckboxes(selectedCheckboxes['manager']),
            companies: filterSelectedCheckboxes(selectedCheckboxes['company']),
            managerAssistants: filterSelectedCheckboxes(selectedCheckboxes['managerAssistant']),
            CGLeads: filterSelectedCheckboxes(selectedCheckboxes['CGLead']),
            projectCoordinators: filterSelectedCheckboxes(selectedCheckboxes['coordinator']),
            extraPMs: filterSelectedCheckboxes(selectedCheckboxes['extraPM']),
        };

        Service.projectSearch(reqData)
            .then((data) => projectDispatch({ type: 'project_list_search', payload: { ...data, isSearch: true } }));

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <AdvancedSearch
            keyword={keyword}
            searchQueryPlaceholder="專案名稱"
            formCheck={{
                register,
                handleClear: handleSearchClear,
                handleSubmit: handleSubmit(handleSearchReqData),
            }}
        >
            {/* Deadline */}
            <SearchItems title="Deadline">
                <Rangepickers
                    placeholder={placeholder}
                    value={deadline}
                    allowClear={true}
                    allowEmpty={[true, true]}
                    handleChange={(date, dateString) => handleSearchRangepicker(date, dateString, 'deadline')}
                />
            </SearchItems>

            {/* 專案起始日 */}
            <SearchItems title="專案起始日">
                <Rangepickers
                    placeholder={placeholder}
                    value={startDate}
                    allowClear={true}
                    allowEmpty={[true, true]}
                    handleChange={(date, dateString) => handleSearchRangepicker(date, dateString, 'start')}
                />
            </SearchItems>

            {/* 專案結束日 */}
            <SearchItems title="專案結束日">
                <Rangepickers
                    placeholder={placeholder}
                    value={endDate}
                    allowClear={true}
                    allowEmpty={[true, true]}
                    handleChange={(date, dateString) => handleSearchRangepicker(date, dateString, 'end')}
                />
            </SearchItems>

            {/* 專案狀態 */}
            <SearchItems
                className="checkboxLists"
                title="專案狀態"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('status', e)}
            >
                {
                    projectStatus.map(({ code, colorCode, colorText, name }) => (
                        <Checkbox
                            key={code}
                            name={code}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['status'] && selectedCheckboxes['status'][code]) ||
                                    (isCheckedAll['status'] && !selectedCheckboxes['status'][code]) ? selectedCheckboxes['status'][code] : isCheckedAll['status']
                            }
                            onChange={(e) => handleCheckboxChange('status', e)}
                        >
                            <Tags
                                color={colorCode}
                                textColor={colorText}
                                text={name}
                            />
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 所屬公司 */}
            <SearchItems
                className="checkboxLists"
                title="所屬公司"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('company', e)}>
                {
                    companies && companies.map(({ id, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={(selectedCheckboxes['company'] && selectedCheckboxes['company'][id]) ||
                                (isCheckedAll['company'] && !isCheckedAll['company'][id]) ? selectedCheckboxes['company'][id] : isCheckedAll['company']}
                            onChange={(e) => handleCheckboxChange('company', e)}>{name}
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* Directors */}
            <SearchItems
                className="checkboxLists"
                title="Directors"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('director', e)}
            >
                {
                    directors && directors.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['director'] && selectedCheckboxes['director'][id]) ||
                                    (isCheckedAll['director'] && !selectedCheckboxes['director'][id]) ? selectedCheckboxes['director'][id] : isCheckedAll['director']
                            }
                            onChange={(e) => handleCheckboxChange('director', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* Producers */}
            <SearchItems
                className="checkboxLists"
                title="Producers"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('producer', e)}
            >
                {
                    producers && producers.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['producer'] && selectedCheckboxes['producer'][id]) ||
                                    (isCheckedAll['producer'] && !selectedCheckboxes['producer'][id]) ? selectedCheckboxes['producer'][id] : isCheckedAll['producer']
                            }
                            onChange={(e) => handleCheckboxChange('producer', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* PMs */}
            <SearchItems
                className="checkboxLists"
                title="PMs"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('manager', e)}
            >
                {
                    managers && managers.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['manager'] && selectedCheckboxes['manager'][id]) ||
                                    (isCheckedAll['manager'] && !selectedCheckboxes['manager'][id]) ? selectedCheckboxes['manager'][id] : isCheckedAll['manager']
                            }
                            onChange={(e) => handleCheckboxChange('manager', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* PAs */}
            <SearchItems
                className="checkboxLists"
                title="PAs"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('managerAssistant', e)}
            >
                {
                    managerAssistants && managerAssistants.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['managerAssistant'] && selectedCheckboxes['managerAssistant'][id]) ||
                                    (isCheckedAll['managerAssistant'] && !selectedCheckboxes['managerAssistant'][id]) ? selectedCheckboxes['managerAssistant'][id] : isCheckedAll['managerAssistant']
                            }
                            onChange={(e) => handleCheckboxChange('managerAssistant', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 其他指派人 */}
            <SearchItems
                className="checkboxLists"
                title="其他指派人"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('extraPM', e)}
            >
                {
                    extraPMs && extraPMs.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['extraPM'] && selectedCheckboxes['extraPM'][id]) ||
                                    (isCheckedAll['extraPM'] && !selectedCheckboxes['extraPM'][id]) ? selectedCheckboxes['extraPM'][id] : isCheckedAll['extraPM']
                            }
                            onChange={(e) => handleCheckboxChange('extraPM', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* CGLeads */}
            <SearchItems
                className="checkboxLists"
                title="CGLeads"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('CGLead', e)}
            >
                {
                    CGLeads && CGLeads.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['CGLead'] && selectedCheckboxes['CGLead'][id]) ||
                                    (isCheckedAll['CGLead'] && !selectedCheckboxes['CGLead'][id]) ? selectedCheckboxes['CGLead'][id] : isCheckedAll['CGLead']
                            }
                            onChange={(e) => handleCheckboxChange('CGLead', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* PCs */}
            <SearchItems
                className="checkboxLists"
                title="PCs"
                showToggleAll={true}
                handleToggleAll={(e) => handleCheckedAllToggle('coordinator', e)}
            >
                {
                    projectCoordinators && projectCoordinators.map(({ id, nameChinese, workStatus }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['coordinator'] && selectedCheckboxes['coordinator'][id]) ||
                                    (isCheckedAll['coordinator'] && !selectedCheckboxes['coordinator'][id]) ? selectedCheckboxes['coordinator'][id] : isCheckedAll['coordinator']
                            }
                            onChange={(e) => handleCheckboxChange('coordinator', e)}
                        >
                            {nameChinese}
                            {
                                !(workStatus === 'atWork') &&
                                <span className={`work-status is-${workStatus}`}>({memberWorkStatus[workStatus].text})</span>
                            }
                        </Checkbox>
                    ))
                }
            </SearchItems>
        </AdvancedSearch>

    );

};

export default Search;
