import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Popconfirm, Tooltip } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    ScheduleOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';

import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import Tables from '../../components/Tables';
import FilesDownload from '../../components/project/FilesDownload';
import WorkLogList from '../projectDetail/WorkLogList';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';

const { priceWithCommas, transferHourFormat } = moon;
const { today } = moonConst;

// 整理成 Ant Design 要的格式
const handleProjectStatus = (status) => status.reduce((arr, { code, name }) => {

    const obj = {
        text: name,
        value: code,
    };

    arr.push(obj);
    return arr;

}, []);

const List = ({ userId }) => {

    // Context
    const {
        projectStatus,
        hasAuth: { edit_all_projects },
        allMembers,
        getAllMembers,
        getWorkLogRemind,
        isShow,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        projectList,
        myToDo,
        removeProject,
        myToDoList,
        workLogList,
        removeToDoList,
    } = useContext(ProjectContext);

    // State
    const [storage, setStorage] = useState({});

    // 表格欄位
    const columns = [
        {
            title: '專案名稱',
            dataIndex: 'name',
            render: (name, { id }) => (

                <Link
                    to={`/projects/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    P{id}: {name}
                </Link>

            ),
        },
        {
            title: '專案狀態',
            render: ({ colorCode, statusName }) => (

                <Tags
                    color={colorCode}
                    textColor="#000"
                    text={statusName}
                />

            ),
            filters: handleProjectStatus(projectStatus),
            onFilter: (value, { code }) => (code.indexOf(value) === 0),
        },
        {
            title: '監製',
            render: ({ producerId, producerName }) => (

                producerId ? (

                    <Link
                        to={`/myProject/${producerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {producerName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PM',
            render: ({ managerId, managerName }) => (

                managerId ? (

                    <Link
                        to={`/myProject/${managerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {managerName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PA',
            render: ({ managerAssistantId, managerAssistantName }) => (

                managerAssistantId ? (

                    <Link
                        to={`/myProject/${managerAssistantId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {managerAssistantName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PC',
            render: ({ projectCoordinatorId, projectCoordinatorName }) => (

                projectCoordinatorId ? (

                    <Link
                        to={`/myProject/${projectCoordinatorId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {projectCoordinatorName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'CG Lead',
            render: ({ CGLeadId, CGLeadName }) => (

                CGLeadId ? (

                    <Link
                        to={`/myProject/${CGLeadId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {CGLeadName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: '其他指派人',
            render: ({ extraPMId, extraPMName }) => (

                extraPMId ? (

                    <Link
                        to={`/myProject/${extraPMId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {extraPMName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: '操作',
            width: 150,
            className: 'col-action',
            render: (data) => (

                edit_all_projects ? (

                    <Fragment>
                        <Buttons
                            type="secondary"
                            text="編輯"
                            handleClick={(e) => btnUpdateProject(data, e)}
                        />
                        <Buttons
                            type="danger"
                            text="刪除"
                            handleClick={(e) => removeProject(data, e)}
                        />
                        <FilesDownload
                            worklog={(e) => btnDownloadExcel(e, data)}
                        />
                    </Fragment>

                ) : (

                    <FilesDownload
                        worklog={(e) => btnDownloadExcel(e, data)}
                    />

                )

            ),
        },
    ];

    // 展開內容
    const renderExpandData = ({ id }) => (

        <div className="toDoListWrap">
            {
                myToDo[id] && myToDo[id].map(({
                    id,
                    name,
                    assigneeId,
                    assignee,
                    totalHours,
                    cost,
                    extraCost,
                    taskGroupName,
                }) => (

                    <Row
                        key={id}
                        className="item-wrap"
                    >
                        <Col flex="1">
                            {taskGroupName && `${taskGroupName} > `}
                            {name}
                        </Col>

                        <Col
                            span={2}
                            className="col-hours"
                        >
                            <Tooltip
                                title="工時"
                                placement="top"
                                overlayClassName="tooltip"
                            >
                                {transferHourFormat(totalHours) || 0}h
                            </Tooltip>
                        </Col>

                        <Col
                            span={3}
                            className="col-totalCost"
                        >
                            <Tooltip
                                title="人力"
                                placement="top"
                                overlayClassName="tooltip"
                            >
                                {priceWithCommas(cost)}
                            </Tooltip>
                        </Col>

                        <Col
                            span={3}
                            className="col-totalCost"
                        >
                            <Tooltip
                                title="超時"
                                placement="top"
                                overlayClassName="tooltip"
                            >
                                {priceWithCommas(extraCost)}
                            </Tooltip>
                        </Col>

                        <Col
                            span={10}
                            className="btn-actions"
                        >
                            {
                                !cost &&
                                    <span
                                        className="btn-delete"
                                        onClick={() => removeToDoList({ id, name })}
                                    >
                                        <DeleteOutlined />刪除
                                    </span>
                            }

                            <span
                                className="btn-update"
                                onClick={() => btnUpdateToDoList({
                                    id,
                                    name,
                                    assigneeId,
                                    assignee,
                                    hasCost: (cost > 0),
                                })}
                            >
                                <EditOutlined />編輯
                            </span>

                            <span
                                className="btn-workLog"
                                onClick={() => btnCreateWorkLog({ id, name })}
                            >
                                <ScheduleOutlined />填工時
                            </span>

                            <Popconfirm
                                title={
                                    <WorkLogList
                                        data={storage}
                                        userId={userId}
                                    />
                                }
                                overlayClassName="detail-tip worklog-detail-tip"
                                placement="right"
                                visible={(formStorageData.taskId === id) && isShow}
                                cancelButtonProps={{
                                    className: 'btn-close'
                                }}
                                cancelText="關閉"
                                onCancel={handleClose}
                            >
                                <span
                                    className="btn-list"
                                    onClick={() => btnWorkLogList({ id, name })}
                                >
                                    <UnorderedListOutlined />看詳細
                                </span>
                            </Popconfirm>
                        </Col>
                    </Row>

                ))
            }
        </div>

    );

    // 關閉 Detail Tip 區塊
    const handleClose = () => {

        // Betty: antd 問題，無法還原畫面
        // 還原成今日
        formStorageDispatch({
            type: 'CLEAR',
            payload: {
                ...formStorageData,
                selectedDate: today,
            },
        });

        setStorage({});
        popConfirmDispatch({ type: 'HIDE' });

    };

    // 編輯專案
    const btnUpdateProject = ({
        id,
        name,
        typeId,
        code,
        directorId,
        producerId,
        managerId,
        managerAssistantId,
        CGLeadId,
        projectCoordinatorId,
        leaderId,
        extraPMId,
        startDate,
        endDate,
        deadline,
        notes,
        budget,
        budgetTax,
        paymentStatus,
        companyId,
        isContract,
    }, e) => {

        e.stopPropagation();

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id,
                name,
                typeId,
                code,
                directorId,
                producerId,
                managerId,
                managerAssistantId,
                CGLeadId,
                projectCoordinatorId,
                leaderId,
                extraPMId,
                startDate,
                endDate,
                deadline,
                notes,
                budget,
                budgetTax,
                paymentStatus,
                companyId,
                isContract,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateProject'});

    };

    // Excel
    const btnDownloadExcel = (e, { id }) => {

        e.stopPropagation();
        window.open(Service.downloadExcelWorklog(`userId=${userId}&projectId=${id}`))

    };

    // 取得當前專案所有項目
    const handleDoDoLists = (expanded, { id }) => {

        if (!expanded || myToDo[id]) return;
        myToDoList(userId, id);

    };

    // 編輯項目按鈕
    const btnUpdateToDoList = ({
        id,
        name,
        assigneeId,
        assignee,
        hasCost,
    }) => {

        if (!allMembers.length) getAllMembers();

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id, // 項目 ID
                name,
                assigneeId,
                assignee,
                hasCost,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateToDoList' });

    };

    // 看詳細 (工時列表)
    const btnWorkLogList = ({ id, name }) => {

        const reqData = { taskId: id, userId };

        formStorageDispatch({ type: 'COLLECT', payload: { taskId: id }});
        setStorage({ id, name });
        workLogList(reqData);
        popConfirmDispatch({ type: 'SHOW' });

    };

    // 新增工時按鈕
    const btnCreateWorkLog = ({ id, name }) => {

        getWorkLogRemind({ userId });

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                taskId: id,
                name,
                type: '',
                minutes: null,
                assigneeId: userId,
                startDate: '',
                endDate: '',
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'createWorkLog' });
        popConfirmDispatch({ type: 'HIDE' });

    };

    return (

        <Tables
            className="project-lists myProject-lists"
            columns={columns}
            data={projectList.lists}
            rowKey="id"
            showTitle={true}
            pageSize={50}
            expandData={{
                expandRowByClick: true,
                rowExpandable: () => true,
                expandedRowRender: renderExpandData,
            }}
            onExpand={handleDoDoLists}
        />

    );

};

List.propTypes = {
    userId: PropTypes.number.isRequired,
};

export default List;
