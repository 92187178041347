import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import Tables from '../../components/Tables';
import FilesDownload from '../../components/project/FilesDownload';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { transferDateFormat, priceWithCommas } = moon;

// 展開內容
const renderExpandData = ({ notes }) => <div className="expandDetailWrap">備註: {notes}</div>;

const List = ({ isSearch }) => {

    // Context
    const {
        hasAuth: { edit_all_projects },
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        projectList: { lists },
        searchResult,
        removeProject,
    } = useContext(ProjectContext);

    // 表格欄位
    const columns = [
        {
            title: '專案名稱',
            dataIndex: 'name',
            width: 240,
            render: (name, { id }) => (

                <Link
                    to={`/projects/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    P{id}: {name}
                </Link>

            ),
        },
        {
            title: '專案狀態',
            dataIndex: '',
            render: ({ colorCode, statusName }) => (

                <Tags
                    color={colorCode}
                    textColor="#000"
                    text={statusName}
                />

            ),
        },
        {
            title: '預算 (未稅)',
            dataIndex: 'budget',
            render: (budget) => priceWithCommas(budget),
        },
        {
            title: '利潤',
            dataIndex: 'profit',
            render: (profit) => <span className={(profit < 0) ? 'warning-text' : ''}>{priceWithCommas(profit)}</span>,
        },
        {
            title: '監製',
            render: ({ producerId, producerName }) => (

                producerId ? (

                    <Link
                        to={`/myProject/${producerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {producerName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PM',
            render: ({ managerId, managerName }) => (

                managerId ? (

                    <Link
                        to={`/myProject/${managerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="user"
                    >
                        {managerName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PA',
            render: ({ managerAssistantId, managerAssistantName }) => (

                managerAssistantId ? (

                    <Link
                        to={`/myProject/${managerAssistantId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="user"
                    >
                        {managerAssistantName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'PC',
            render: ({ projectCoordinatorId, projectCoordinatorName }) => (

                projectCoordinatorId ? (

                    <Link
                        to={`/myProject/${projectCoordinatorId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="user"
                    >
                        {projectCoordinatorName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: 'CG Lead',
            render: ({ CGLeadId, CGLeadName }) => (

                CGLeadId ? (

                    <Link
                        to={`/myProject/${CGLeadId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="user"
                    >
                        {CGLeadName}
                    </Link>

                ) : '--'

            ),
        },
        {
            title: '所屬公司',
            render: ({ companyName }) => (

                companyName ? companyName : '--'

            ),
        },
        {
            title: '開始時間',
            dataIndex: 'startDate',
            className: 'col-date',
            render: (startDate) => transferDateFormat(startDate),
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            className: 'col-date',
            render: (deadline) => transferDateFormat(deadline),
        },
        {
            title: '結束時間',
            dataIndex: 'endDate',
            className: 'col-date',
            render: (endDate) => transferDateFormat(endDate),
        },
        {
            title: '操作',
            dataIndex: '',
            width: 150,
            className: 'col-action',
            render: (data) => (

                edit_all_projects ? (

                    <Fragment>
                        <Buttons
                            type="secondary"
                            text="編輯"
                            handleClick={(e) => btnUpdateProject(data, e)}
                        />
                        <Buttons
                            type="danger"
                            text="刪除"
                            handleClick={(e) => removeProject(data, e)}
                        />
                        <FilesDownload
                            worklog={() => btnDownloadExcel(data)}
                            credit={() => btnDownloadExcel(data, 'credit')}
                        />
                    </Fragment>

                ) : (

                    <FilesDownload
                        worklog={() => btnDownloadExcel(data)}
                        credit={() => btnDownloadExcel(data, 'credit')}
                    />

                )

            ),
        },
    ];

    // 編輯專案
    const btnUpdateProject = ({
        id,
        name,
        typeId,
        code,
        directorId,
        producerId,
        managerId,
        managerAssistantId,
        CGLeadId,
        projectCoordinatorId,
        leaderId,
        extraPMId,
        startDate,
        endDate,
        deadline,
        notes,
        budget,
        budgetTax,
        paymentStatus,
        companyId,
        isContract,
    }) => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                id,
                name,
                typeId,
                code,
                directorId,
                producerId,
                managerId,
                managerAssistantId,
                CGLeadId,
                projectCoordinatorId,
                leaderId,
                extraPMId,
                startDate,
                endDate,
                deadline,
                notes,
                budget,
                budgetTax,
                paymentStatus,
                companyId,
                isContract,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateProject' });

    };

    // Excel
    const btnDownloadExcel = ({ id }, type) => {

        const serviceKey = (type === 'credit') ? 'downloadExcelCredit' : 'downloadExcelWorklog';
        window.open(Service[serviceKey](`projectId=${id}`));

    };

    return (

        <Tables
            className="project-lists"
            columns={columns}
            data={isSearch ? searchResult.lists : lists}
            rowKey="id"
            showTitle={true}
            pageSize={50}
            expandData={{
                expandedRowRender: (data) => renderExpandData(data),
                rowExpandable: ({ notes }) => !!notes.length
            }}
        />

    );

};

List.propTypes = {
    btnUpdate: PropTypes.func,
};

export default List;
