import React, { useEffect, useContext } from 'react';
import './Profile.scss';
import Tab from '../../components/Tab';
import LightboxForm from '../../components/LightboxForm';
import MemberForm from '../../components/MemberForm';

import ProfileHeader from './ProfileHeader';
import About from './About';
import Salary from './Salary';
import SalaryForm from './SalaryForm';

import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moonConst from '../../lib/moon.const';

const { lightboxTitle } = moonConst;

const Profile = (props) => {

    const { match } = props;
    const { params } = match;
    const userId = +params.userId;

    // Context
    const {
        hasAuth: { edit_salary },
        visible,
        currEvent,
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        salary,
        getProfile,
        getSalary,
        formStorageDispatch,
    } = useContext(MemberContext);

    useEffect(() => {

        document.title = '個人資料';

        // 取個人資料
        getProfile({ userId });

    }, [userId]);

    // Tab 點擊事件
    const handleClick = (e) => {

        popConfirmDispatch({ type: 'HIDE' });

        // 若已送過 Request 就不再打 ajax
        if (salary.length > 0) return;

        // 點擊薪資 Tab 才觸發 Request
        if (e === 'salary') getSalary({ userId });

    };

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // Lightbox 身體
    const renderLightboxContent = () => {

        if (currEvent === 'createSalary') return <SalaryForm />;
        else return <MemberForm />;

    };

    return (

        <div className="profileWrap">

            <section className="profile-header">
                <ProfileHeader />
            </section>

            <section className="profile-content">
                <Tab
                    className="profileTab"
                    onChange={handleClick}
                    tabData={{
                        profile: {
                            name: '關於我',
                            component: <About />,
                        },
                        // 有權限才顯示薪資 tab
                        ...edit_salary && {
                            salary: {
                                name: '薪資',
                                component: <Salary userId={userId} />,
                            }
                        }
                    }}
                />

                {
                    // Lightbox (編輯個人資料、新增薪資)
                    visible &&
                        <LightboxForm
                            width={currEvent === 'updateMember' ? 680 : 560}
                            title={lightboxTitle[currEvent]}
                            visible={visible}
                            handleCancel={hideModal}
                        >
                            {renderLightboxContent()}
                        </LightboxForm>
                }
            </section>

        </div>

    );

};

export default Profile;
