import React, { Fragment, useContext } from 'react';
import { Row, Col, Tooltip, Collapse } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusSquareOutlined,
    MinusSquareOutlined,
} from '@ant-design/icons';

import moon from '../../lib/moon';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';

const { Panel } = Collapse;
const { priceWithCommas } = moon;

// 收合 icon
const renderExpandIcon = ({ isActive }) => isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />;

// 小組預算列表
const GroupBudget = ({
    data: {
        id,
        groupId,
        groupName,
        budget,
        cost,
        extraCost,
        balance,
    },
}) => {

    // Context
    const {
        hasAuth: { edit_all_projects },
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        detail: { id: pid },
        removeGroupBudget,
    } = useContext(ProjectContext);

    // 編輯小組預算按鈕
    const btnUpdate  = (e, { id, groupId, budget }) => {

        e.stopPropagation();
        formStorageDispatch({ type: 'COLLECT', payload: { pid, id, groupId, budget }});
        lightboxDispatch({ type: 'SHOW', currEvent: 'updateGroupBudget' });

    };

    // 刪除小組預算按鈕
    const btnRemove  = (e, id) => {

        e.stopPropagation();
        removeGroupBudget({ pid, id });

    };

    return (

        <Collapse
            key={id}
            className={`group-budget-list ${(edit_all_projects && id) ? 'groupBudget-editable' : ''}`}
            expandIcon={renderExpandIcon}
            ghost
        >
            <Panel
                key={id}
                header={
                    <Fragment>
                        {groupName}
                        {
                            id ? '' : <span className="warning-text">(有成本但無預算不得修改)</span>
                        }
                    </Fragment>
                }
                extra={
                    <Fragment>
                        <Tooltip
                            title="預算"
                            placement="top"
                            overlayClassName="tooltip"
                        >
                            {priceWithCommas(budget)}
                        </Tooltip>

                        {
                            edit_all_projects &&
                                <span className="action">
                                    {
                                        id && (

                                            <Fragment>
                                                <EditOutlined onClick={(e) => btnUpdate(e, { id, groupId, budget })} />
                                                <DeleteOutlined onClick={(e) => btnRemove(e, id)} />
                                            </Fragment>

                                        )
                                    }
                                </span>
                        }
                    </Fragment>
                }
            >
                <Row>
                    <Col flex="1" className="label">人力</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        {cost ? priceWithCommas(cost) : '$0'}
                    </Col>
                </Row>

                <Row>
                    <Col flex="1" className="label">超時 (不列入計算)</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        <span className="extra-cost">{extraCost ? priceWithCommas(extraCost) : '$0'}</span>
                    </Col>
                </Row>

                <Row className="summary">
                    <Col flex="1" className="label">小計</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        <span className={balance > 0 ? '' : 'warning-text'}>{priceWithCommas(balance)}</span>
                    </Col>
                </Row>
            </Panel>
        </Collapse>

    );

};

//
const DetailGroupBudget = () => {

    // Context
    const {
        hasAuth: { edit_all_projects },
    } = useContext(GlobalContext);
    const { groupBudget } = useContext(ProjectContext);

    return (

        <div className={edit_all_projects ? 'hasAuth' : ''}>
            <div className="groupBudgetWrap">
                {
                    groupBudget?.lists.map((obj, idx) => (

                        <GroupBudget
                            key={idx}
                            data={obj}
                        />

                    ))
                }
            </div>

            <div className={`group-budget-total ${edit_all_projects ? 'groupBudget-editable' : ''}`}>
                <Row className="budget">
                    <Col flex="1">總小組預算</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        {priceWithCommas(groupBudget.budget)}
                    </Col>
                </Row>
                <Row className="cost">
                    <Col flex="1">總人力</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        {priceWithCommas(groupBudget.cost)}
                    </Col>
                </Row>
                <Row className="extraCost">
                    <Col flex="1">總超時 (不列入計算)</Col>
                    <Col
                        flex="1"
                        className="price"
                    >
                        <span className="extra-cost">{priceWithCommas(groupBudget.extraCost)}</span>
                    </Col>
                </Row>
                <Row className="balance">
                    <Col flex="1">總計</Col>
                    <Col
                        flex="1"
                        className={`price ${groupBudget.balance > 0 ? '' : 'warning-text'}`}
                    >
                        {priceWithCommas(groupBudget.balance)}
                    </Col>
                </Row>
            </div>
        </div>

    );

};

export default DetailGroupBudget;
