import React, { useContext, useState } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Buttons from './Buttons';
import Datepickers from './Datepickers';
import { FormRow, ErrorMesg } from './LightboxForm';

import { GlobalContext } from '../context/global.state';
import { MemberContext } from '../context/member/member.state';
import moonConst from '../lib/moon.const';

const { memberWorkStatus } = moonConst;

const MemberForm = () => {

    // Context
    const {
        groups,
        roles,
        currEvent,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createMember,
        updateMember,
        formStorageData,
        formStorageDispatch,
    } = useContext(MemberContext);

    // State
    const [storage, setStorage] = useState({
        firstDate: formStorageData.firstDate ? formStorageData.firstDate : '',
        isExtraPM: false,
    });
    const [firstDateError, setFirstDateError] = useState(false);

    // React Hook Form - 搜尋
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 到職日 (西元)
    const handleDateChange = (date) => {

        setStorage({
            ...storage,
            firstDate: date.format('YYYY-MM-DD'),
        });

        setFirstDateError(false);

    };

    // Checkbox
    const handleCheckboxChange = ({ target }) => {

        setStorage({
            ...storage,
            isExtraPM: target.checked,
        });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...formStorageData,
            ...storage,
            ...reqData,
            employeeId: reqData.employeeId,
            groupId: +reqData.groupId,
            roleId: +reqData.roleId,
        };

        // 檢查到職日
        if (reqData.firstDate === '') {

            setFirstDateError(true);
            return;

        }

        if (currEvent === 'updateMember') updateMember(reqData);
        else createMember(reqData);

    };

    const {
        employeeId,
        nameChinese,
        email,
        groupId,
        roleId,
        position,
        workStatus,
        firstDate,
        isExtraPM,
    } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <div className="items">
                <FormRow
                    labelTitle="員工編號"
                    required={true}
                    error={errors.employeeId && true}
                    {...errors.employeeId?.type === 'maxLength' && { errorMesg: '不得超過8個字' }}
                >
                    <input
                        type="text"
                        name="employeeId"
                        placeholder="最多8個字"
                        defaultValue={employeeId}
                        ref={register({ required: true, maxLength: 8 })}
                    />
                </FormRow>

                <FormRow
                    labelTitle="姓名"
                    required={true}
                    error={errors.nameChinese && true}
                    {...errors.nameChinese?.type === 'maxLength' && { errorMesg: '不得超過6個字' }}
                >
                    <input
                        type="text"
                        name="nameChinese"
                        placeholder="最多6個字"
                        defaultValue={nameChinese}
                        ref={register({ required: true, maxLength: 6 })}
                    />
                </FormRow>
            </div>

            <FormRow
                labelTitle="Email"
                required={true}
                error={errors.email && true}
            >
                <input
                    type="text"
                    name="email"
                    defaultValue={email}
                    ref={register({ required: true })}
                />
            </FormRow>

            <div className="items">
                <div className={`row ${errors.groupId ? 'hasError' : ''}`}>
                    <div className="title isRequired">隸屬組別 (必填)</div>
                    <div className={`field noBorder ${(currEvent === 'updateMember') ? 'disabled' : ''}`}>
                        <select
                            name="groupId"
                            defaultValue={groupId}
                            ref={register}
                            {...(currEvent === 'updateMember') && { disabled: true }}
                        >
                            {
                                groups.map(({ id, name }) => (
                                    <option
                                        key={id}
                                        value={id}
                                    >
                                        {name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    {errors.groupId && <ErrorMesg />}
                </div>

                <div className={`row ${errors.roleId ? 'hasError' : ''}`}>
                    <div className="title isRequired">所屬角色 (必填)</div>
                    <div className="field noBorder">
                        <select
                            name="roleId"
                            defaultValue={roleId}
                            ref={register({ required: true })}
                        >
                            <option value="">請選擇</option>

                            {
                                roles.map((opt) => (
                                    <option
                                        key={opt.id}
                                        value={opt.id}
                                    >
                                        {opt.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    {errors.roleId && <ErrorMesg />}
                </div>
            </div>

            <div className="items">
                <FormRow
                    labelTitle="職稱"
                >
                    <input
                        type="text"
                        name="position"
                        defaultValue={position}
                        ref={register}
                    />
                </FormRow>

                <div className={`row ${firstDateError && 'hasError'}`}>
                    <div className="title isRequired">到職日 (必填)</div>

                    <Datepickers
                        name="firstDate"
                        defaultDate={firstDate ? moment(firstDate) : null}
                        handleChange={handleDateChange}
                    />

                    {firstDateError && <ErrorMesg />}
                </div>

                <div className="row">
                    <div className="title">在職狀態</div>
                    <div className="field noBorder">
                        <select
                            name="workStatus"
                            defaultValue={workStatus}
                            ref={register}
                        >
                            {
                                Object.keys(memberWorkStatus).map((opt) =>

                                    <option
                                        key={opt}
                                        value={opt}
                                    >
                                        {memberWorkStatus[opt].text}
                                    </option>

                                )
                            }
                        </select>
                    </div>
                </div>
            </div>

            <Checkbox
                name="isExtraPM"
                className="checkbox-item"
                defaultChecked={isExtraPM}
                onChange={handleCheckboxChange}
            >
                可指派為專案負責人
            </Checkbox>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="default"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default MemberForm;
