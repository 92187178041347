import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col, Tooltip, Popconfirm, Popover, Button } from "antd";
import Highlighter from "react-highlight-words";
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ScheduleOutlined,
  UnorderedListOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Buttons from "../../components/Buttons";
import Tables from "../../components/Tables";

import { GlobalContext } from "../../context/global.state";
import { ProjectContext } from "../../context/project/project.state";
import WorkLogList from "./WorkLogList";
import moon from "../../lib/moon";
import moonConst from "../../lib/moon.const";

const { priceWithCommas, transferHourFormat, renderWithoutValue } = moon;
const { today } = moonConst;

// antd 格式
const antdTableFilter = (data) =>
  data &&
  data.reduce((acc, { groupId, groupName }) => {
    const obj = { text: groupName, value: groupId };

    // 檢查是否重複
    if (!acc.some((obj) => obj.value === groupId)) acc.push(obj);
    return acc;
  }, []);

// Mapping
const mappingSelected = (selected, allData) =>
  selected.reduce((acc, curr) => {
    // 先找到對應的
    let temp = allData.find((obj) => obj.groupId === curr);
    acc[curr] = temp?.groupName;
    return acc;
  }, {});

// Tooltip
// const ToolTips = ({ title, children }) => (
//   <Tooltip title={title} placement="top" overlayClassName="tooltip">
//     {children}
//   </Tooltip>
// );

const ToDoList = () => {
  // Context
  const {
    allMembers,
    getWorkLogRemind,
    getAllMembers,
    isShow,
    formStorageData,
    formStorageDispatch,
    lightboxDispatch,
    popConfirmDispatch,
    hasAuth: { view_personal_cost },
  } = useContext(GlobalContext);

  const {
    detail,
    taskList,
    workLogList,
    removeToDoList,
    groupBudget,
    searchToDoList,
    searchResult,
    projectDispatch,
  } = useContext(ProjectContext);

  // State
  const [storage, setStorage] = useState({});
  const [inputVal, setInputVal] = useState("");
  const [selected, setSelected] = useState({});

  // React Hook Form
  const { handleSubmit, register, reset } = useForm();

  const columnData = searchResult.isSearch ? searchResult.tasks : taskList;
  let columns = [
    {
      title: "項目名稱",
      dataIndex: "name",
      className: "col-taskName",
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : null }} />
      ),
      filterDropdown: () => (
        <form className="searchTaskForm" onSubmit={handleSubmit(handleReqData)}>
          <div className="row search-query">
            <div className="field">
              <input type="text" name="query" ref={register} />
            </div>
          </div>
          <div className="row row-btns">
            <Buttons text="搜尋" htmlType="submit" />

            <Buttons text="清除" type="default" onClick={handleResetTask} />
          </div>
        </form>
      ),
      render: (name, { taskGroupName }) => (
        <Fragment>
          {searchResult.isSearch ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[inputVal]}
              textToHighlight={name ? name.toString() : ""}
              autoEscape
            />
          ) : (
            name
          )}

          <div className="small-text">
            群組名稱: {renderWithoutValue(taskGroupName)}
          </div>
        </Fragment>
      ),
    },
    {
      title: "指派人",
      dataIndex: "assignee",
      className: "col-assignee",
      filters: antdTableFilter(groupBudget?.lists),
      sorter: (a, b) => a.assigneeId - b.assigneeId,
      render: (assignee, { assigneeId }) => (
        <div className="assignee">
          <Link
            to={`/myProject/${assigneeId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {assignee}
          </Link>
        </div>
      ),
    },
    {
      title: "工時",
      dataIndex: "totalHours",
      align: "right",
      className: "col-total-hours",
      sorter: (a, b) => a.totalHours - b.totalHours,
      render: (totalHours) => `${transferHourFormat(totalHours) || 0}h`,
    },
    {
      title: "人力",
      dataIndex: "cost",
      className: "col-cost",
      align: "right",
      sorter: (a, b) => a.cost - b.cost,
      render: (cost) => priceWithCommas(cost),
    },
    {
      title: "超時",
      dataIndex: "extraCost",
      className: "col-extra-cost",
      align: "right",
      sorter: (a, b) => a.extraCost - b.extraCost,
      render: (extraCost) => priceWithCommas(extraCost),
    },
    {
      title: "操作",
      dataIndex: "",
      className: "col-action",
      align: "right",
      width: 150,
      render: (data) => (
        <Fragment>
          <Popover
            content={
              <ul>
                {!data.cost && (
                  <li>
                    <span
                      className="pop-delete"
                      onClick={() => removeToDoList(data)}
                    >
                      <DeleteOutlined />
                      <Button type="text" className="btn-delete">
                        刪除
                      </Button>
                    </span>
                  </li>
                )}

                <li>
                  <span
                    className="pop-update"
                    onClick={() => btnUpdateToDoList(data)}
                  >
                    <EditOutlined />
                    <Button type="text" className="btn-edit">
                      編輯
                    </Button>
                  </span>
                </li>

                <li>
                  <span
                    className="pop-workLog"
                    onClick={() => btnCreateWorkLog(data)}
                  >
                    <ScheduleOutlined />
                    <Button type="text" className="btn-worLog">
                      填工時
                    </Button>
                  </span>
                </li>
                <li>
                  <Popconfirm
                    title={
                      <WorkLogList data={storage} userId={data.assigneeId} />
                    }
                    overlayClassName="detail-tip worklog-detail-tip"
                    placement="bottom"
                    open={formStorageData.taskId === data.id && isShow}
                    cancelButtonProps={{
                      className: "btn-close",
                    }}
                    cancelText="關閉"
                    onCancel={handleClose}
                  >
                    <span
                      className="pop-list"
                      onClick={() =>
                        btnWorkLogList({ id: data.id, name: data.name })
                      }
                    >
                      <UnorderedListOutlined />
                      <Button type="text" className="btn-worLog">
                        看詳細
                      </Button>
                    </span>
                  </Popconfirm>
                </li>
              </ul>
            }
          >
            <Button type="dashed" className="btn-menu-actions">
              <MoreOutlined />
            </Button>
          </Popover>
        </Fragment>
      ),
    },
  ];

  // Remove personal costs if empty
  if (!view_personal_cost) {
    console.log(">>> del");
    columns = columns.filter(
      (col) => !["cost", "extraCost"].includes(col.dataIndex)
    );
  }

  useEffect(() => {
    if (!allMembers.length) getAllMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 關閉 Detail Tip 區塊
  const handleClose = () => {
    // Betty: antd 問題，無法還原畫面
    // 還原成今日
    formStorageDispatch({
      type: "CLEAR",
      payload: {
        ...formStorageData,
        selectedDate: today,
      },
    });

    setStorage({});
    popConfirmDispatch({ type: "HIDE" });
  };

  // 新增項目按鈕
  const btnCreateToDoList = () => {
    formStorageDispatch({
      type: "COLLECT",
      payload: { id: detail.id }, // 專案 ID
    });

    lightboxDispatch({ type: "SHOW", currEvent: "createToDoList" });
  };

  // 編輯項目按鈕
  const btnUpdateToDoList = ({ id, name, assigneeId, assignee, hasCost }) => {
    if (!allMembers.length) getAllMembers();

    formStorageDispatch({
      type: "COLLECT",
      payload: {
        id, // 項目 ID
        name,
        assigneeId,
        assignee,
        hasCost,
      },
    });

    lightboxDispatch({ type: "SHOW", currEvent: "updateToDoList" });
  };

  // 看詳細 (工時列表)
  const btnWorkLogList = ({ id, name }) => {
    const reqData = { taskId: id };

    formStorageDispatch({ type: "COLLECT", payload: { taskId: id } });
    setStorage({ id, name });
    workLogList(reqData);
    popConfirmDispatch({ type: "SHOW" });
  };

  // 新增工時按鈕
  const btnCreateWorkLog = ({ id, assigneeId, name }) => {
    getWorkLogRemind({ userId: assigneeId });

    formStorageDispatch({
      type: "COLLECT",
      payload: {
        taskId: id,
        name,
        type: "",
        minutes: null,
        assigneeId,
        startDate: "",
        endDate: "",
      },
    });

    lightboxDispatch({ type: "SHOW", currEvent: "createWorkLog" });
    popConfirmDispatch({ type: "HIDE" });
  };

  // Table change
  const handleTableChange = (pagination, filters, sorter) => {
    setSelected(
      filters?.assignee?.length
        ? mappingSelected(filters.assignee, groupBudget.lists)
        : {}
    );
    if (filters?.assignee?.length)
      searchToDoList({ pid: detail.id, groups: [...filters.assignee] });
    else projectDispatch({ type: "task_search_reset", payload: false });
  };

  // 送資料: 搜尋項目按鈕
  const handleReqData = (reqData) => {
    setInputVal(reqData.query);
    searchToDoList({ pid: detail.id, query: reqData.query });
  };

  // 搜尋項目清除按鈕
  const handleResetTask = () => {
    reset({ query: "" });
    projectDispatch({ type: "task_search_reset", payload: false });
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col flex="1">
          <Buttons
            className="btn-createTask"
            text="新增項目"
            icon={<PlusOutlined />}
            handleClick={btnCreateToDoList}
          />
          {view_personal_cost && (
            <div className="timeline-link">
              <Link
                to={`./${detail?.id}/timeline`}
                target="_blank"
                rel="noopener noreferrer"
                className="look-at-timeline"
              >
                看工時圖表
              </Link>
              <Tooltip
                title="各項目工時與假的圖示"
                placement="right"
                overlayClassName="look-at-timeline-tooltip"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          )}
        </Col>

        <Col flex="1" className="summary">
          <span className="budget">
            總預算 {priceWithCommas(detail?.budget)} /{" "}
          </span>
          <span className="cost">
            總人力成本{" "}
            {priceWithCommas(
              searchResult.isSearch ? searchResult.cost : detail?.cost
            )}{" "}
            /{" "}
          </span>
          <span className="overtime">
            總超時成本{" "}
            {priceWithCommas(
              searchResult.isSearch ? searchResult.extraCost : detail?.extraCost
            )}
          </span>
        </Col>
      </Row>

      <div className="toDoListWrap">
        共 {searchResult.isSearch ? searchResult.tasks.length : taskList.length}{" "}
        筆
        {Object.entries(selected).length
          ? `，篩選組別: ${Object.keys(selected)
              .map((id) => selected[id])
              .join("、")}`
          : null}
        <Tables
          columns={columns}
          data={columnData}
          rowKey="id"
          showPage={false}
          onChange={handleTableChange}
        />
      </div>
    </Fragment>
  );
};

export default ToDoList;
