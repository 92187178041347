import React, { createContext, useReducer, useContext } from 'react';
import Prompt from '../../components/Prompt';
import { GlobalContext } from '../global.state';
import { dayoffReducer } from './dayoff.reducer';
import Service from '../../lib/moon.service';

// Dayoff
const dayoffInitialState = {
    subItems: {},
    aDayList: [],
};

// Create Context
const DayoffContext = createContext(null);

// Provider
const DayoffProvider = ({ children }) => {

    // Context
    const {
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const [dayoffState, dayoffDispatch] = useReducer(dayoffReducer, dayoffInitialState);
    const {
        subItems,
        aDayList,
    } = dayoffState;

    const { Provider } = DayoffContext;

    // 當月所有休假員工
    const aMonthDayoffMember = (reqData) => {

        Service.dayoffLists(reqData)
            .then(({ lists }) => {

                dayoffDispatch({ type: 'member_list_month', payload: lists });

            });

    };

    // 當日所有休假員工
    const aDayDayoffMember = (reqData) => {

        Service.dayoffMemberList(reqData)
            .then(({ lists }) => {

                dayoffDispatch({ type: 'member_list_day', payload: lists });

            });

    };

    // 新增員工休假
    const createDayoff = (reqData) => {

        Service.createDayoff(reqData)
            .then(({ lists }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        dayoffDispatch({ type: 'create_dayoff', payload: lists });

                    },
                });

            });

    };

    // 編輯員工休假
    const updateDayoff = (reqData) => {

        Service.updateDayoff(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        dayoffDispatch({ type: 'update_dayoff', payload: { ...resData, selectedDate: reqData.leaveStartDate }});

                    },
                });

            });

    };

    // 刪除員工休假
    const removeDayoff = (reqData) => {

        Service.removeDayoff({ id: reqData.id })
            .then(() => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        dayoffDispatch({ type: 'remove_dayoff', payload: reqData });

                    },
                });

            });

    };

    return (

        <Provider value={{
            // 所有員工
            subItems,
            aDayList,
            aMonthDayoffMember,
            aDayDayoffMember,
            createDayoff,
            updateDayoff,
            removeDayoff,

            // Dispatch
            dayoffDispatch,
        }}>
            {children}
        </Provider>

    );

};

export {
    DayoffContext,
    DayoffProvider,
};
