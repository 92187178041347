import React, {
    useState,
    useEffect,
    useContext,
    Fragment,
} from 'react';

import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import './Dayoff.scss';
import LightboxForm from '../../components/LightboxForm';
import CalendarTool from '../../components/CalendarTool';

import List from './List';
import LeaveTypeForm from './LeaveTypeForm';

import { GlobalContext } from '../../context/global.state';
import { DayoffContext } from '../../context/dayoff/dayoff.state';
import moonConst from '../../lib/moon.const';

const { today, breadcrumb } = moonConst;

//
const Dayoff = () => {

    // Context
    const {
        hasAuth: { edit_day_off }, // 編輯總表格子(休假)
        dayOffTypes,
        visible,
        currEvent,
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        subItems,
        aMonthDayoffMember,
        aDayDayoffMember,
    } = useContext(DayoffContext);

    // State
    const [selectedDate, setSelectedDate] = useState('');
    const [showDetailTip, setShowDetailTip] = useState(false);

    useEffect(() => {

        document.title = breadcrumb.dayoff;
        aMonthDayoffMember({ date: moment(today).format('YYYY-MM') });

    }, []);

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 行事曆選取日期 (後執行)
    const handleSelectedDate = (date) => setSelectedDate(moment(date).format('YYYY-MM-DD'));

    // 行事曆點擊事件 (先執行)
    const handleClickDate = ({ currentTarget }) => {

        const { dataset } = currentTarget;

        // 阻擋連續點擊同一天送資料情境，但不包括預設值(當日)
        if (dataset.date !== selectedDate) aDayDayoffMember({ date: dataset.date });
        setShowDetailTip(true);
        setSelectedDate(dataset.date);

    };

    // 關閉 Detail tip
    const handleCancelDetailTip = () => setShowDetailTip(false);

    // 取 CalendarTool.js panel change 的 callback
    const panelChangeCallback = (param) => {

        handleCancelDetailTip();
        aMonthDayoffMember({ date: param });

    };

    // 新增按鈕
    const btnCreateDayoff = () => {

        setShowDetailTip(false);
        lightboxDispatch({ type: 'SHOW', currEvent: 'createDayoff' });

        // 塞預設值給假別與時數
        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                code: dayOffTypes[0].code,
                leaveHours: dayOffTypes[0].provideHours[0],
            },
        });

    };

    return (

        <div className="dayoffWrap">
            {
                edit_day_off &&
                    <PlusOutlined
                        title="新增休假人員"
                        className="btn-create"
                        onClick={btnCreateDayoff}
                    />
            }

            <div className="dayoff-calendar">
                <CalendarTool
                    showDetailTip={showDetailTip}
                    selectedDate={selectedDate}
                    subItems={subItems}
                    handleSelected={handleSelectedDate}
                    handleClickDate={handleClickDate}
                    handleCancelDetailTip={handleCancelDetailTip}
                    panelChangeCallback={panelChangeCallback}
                >
                    <List selectedDate={selectedDate} />
                </CalendarTool>
            </div>

            {
                // Lightbox (登錄、編輯)
                visible &&
                    <LightboxForm
                        className="leaveTypeFormWrap"
                        title={
                            <Fragment>
                                {currEvent === 'updateDayoff' ? '編輯' : '新增'}休假人員
                                {
                                    currEvent === 'updateDayoff' &&
                                        <span className="small-text">(uid: {formStorageData.userId}  / {formStorageData.nameChinese})</span>
                                }
                            </Fragment>
                        }
                        visible={visible}
                        handleCancel={hideModal}
                    >
                        <LeaveTypeForm selectedDate={selectedDate} />
                    </LightboxForm>
            }
        </div>

    );

};

export default Dayoff;

/**
 * Notes: 新增、編輯、刪除後，暫且先不更新 web_dayoff，若之後有反應再更新
 */
