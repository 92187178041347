import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import Tags from '../../components/Tags';
import Buttons from '../../components/Buttons';
import PopConfirm from '../../components/PopConfirm';
import Switcher from '../../components/Switcher';
import GroupTimeLine from '../../components/GroupTimeLine';

import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';

const {
    transferDateFormat,
    renderWithoutValue,
} = moon;

const { authorityLabel, memberWorkStatus, yesOrNo } = moonConst;

// 詳細資料
const Basic = () => {

    // Context
    const {
        isShow,
        hasAuth: { edit_all_members }, // 編輯全部成員資料
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        profile: {
            userId,
            employeeId,
            nameChinese,
            email,
            departmentName,
            groupId,
            groupName,
            roleId,
            roleName,
            position,
            workStatus,
            firstDate,
            isExtraPM,
        },
        formStorageDispatch,
        getGroupHistory,
    } = useContext(MemberContext);

    // 編輯按鈕
    const btnUpdate = () => {

        formStorageDispatch({
            type: 'COLLECT',
            payload: {
                userId,
                employeeId,
                nameChinese,
                email,
                groupId,
                roleId,
                position,
                workStatus,
                firstDate,
                isExtraPM,
            },
        });

        lightboxDispatch({ type: 'SHOW', currEvent: 'updateMember' });

    };

    // 組別異動記錄列表
    const btnGroupHistory = () => {

        formStorageDispatch({ type: 'COLLECT', payload: { userId } });
        popConfirmDispatch({ type: 'SHOW' });
        getGroupHistory({ userId });

    };

    return (

        <Fragment>
            <div className="profile-on-top">
                <h2 className="title">詳細資訊</h2>

                {
                    edit_all_members &&
                        <Buttons
                            text="編輯個人資料"
                            className="titleBtn"
                            handleClick={btnUpdate}
                        />
                }
            </div>

            <div className="wrap">
                <p className="row-item">
                    <span className="label">姓名:</span>
                    <span>{renderWithoutValue(nameChinese)}</span>
                </p>

                <p className="row-item">
                    <span className="label">Email:</span>
                    <span>{renderWithoutValue(email)}</span>
                </p>

                <p className="row-item">
                    <span className="label">組別 (部門):</span>
                    <span>{renderWithoutValue(groupName)} ({renderWithoutValue(departmentName)})</span>
                    {
                        edit_all_members &&
                            <PopConfirm
                                visible={isShow}
                                title={<GroupTimeLine />}
                            >
                                <Buttons
                                    text="異動紀錄"
                                    className="btn-groupUpdate"
                                    onClick={btnGroupHistory}
                                />
                            </PopConfirm>
                    }
                </p>

                <p className="row-item">
                    <span className="label">角色:</span>
                    <span>{renderWithoutValue(roleName)}</span>
                </p>

                <p className="row-item">
                    <span className="label">職稱:</span>
                    <span>{renderWithoutValue(position)}</span>
                </p>

                <p className="row-item">
                    <span className="label">可指派為專案負責人:</span>
                    <span>{yesOrNo[isExtraPM]}</span>
                </p>

                {
                    workStatus ? (

                        <p className="row-item">
                            <span className="label">在職狀態:</span>

                            <Tags
                                color={`#${memberWorkStatus[workStatus].color}`}
                                text={memberWorkStatus[workStatus].text}
                            />
                        </p>

                    ) : '--'
                }

                <p className="row-item">
                    <span className="label">到職日:</span>
                    <span>{transferDateFormat(firstDate)}</span>
                </p>
            </div>
        </Fragment>

    );

};

//
const About = () => {

    // Context
    const {
        hasAuth: { edit_authority }, // 編輯權限
    } = useContext(GlobalContext);

    const {
        profile: { authority },
    } = useContext(MemberContext);

    // 變數名稱重複，定義新名稱
    const withAuthority = { ...authority };

    return (

        <div className="personal">

            {/* 詳細資料 */}
            <div className="cell cell-basic">
                <Basic />
            </div>

            {/* 權限 */}
            <div className="cell cell-authority">
                <div>
                    <h2 className="title">
                        擁有權限

                        {
                            // 有編輯權限之人給快捷 link
                            edit_authority &&
                                <Link
                                    to={`/setting/authority`}
                                    rel="noopener noreferrer"
                                    className="edit-authority-link"
                                >
                                    編輯權限
                                </Link>
                        }
                    </h2>

                    <div className="authority">
                        {
                            withAuthority && Object.keys(withAuthority).map((key) => (

                                <Switcher
                                    key={key}
                                    label={authorityLabel[key]}
                                    defaultChecked={!!withAuthority[key]}
                                    disabled={true}
                                />

                            ))
                        }
                    </div>
                </div>
            </div>
        </div>

    );

};

export default About;
