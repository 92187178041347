import React, { Fragment, useState, useEffect } from 'react';

import './CostRatio.scss';
import Breadcrumbs from '../../components/Breadcrumbs';
import Buttons from '../../components/Buttons';
import Tables from '../../components/Tables';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';

const { renderWithoutValue } = moon;

// Service
async function fetchData () {

    return await Service.costRatioList();

}

const CostRatio = () => {

    // State
    const [value, setValue] = useState(0);

    // Data
    const [list, setList] = useState([]);

    useEffect(() => {

        document.title = moonConst.breadcrumb.costRatio;

        fetchData()
            .then(handleFetchData);

    }, []);

    //
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '組別',
            dataIndex: 'name',
            render: (name) => renderWithoutValue(name),
        },
        {
            title: '部門',
            dataIndex: 'departmentName',
            render: (departmentName) => renderWithoutValue(departmentName),
        },
        {
            title: '分公司名稱',
            dataIndex: 'branchName',
            render: (branchName) => renderWithoutValue(branchName),
        },
        {
            title: '權重 (最多到小數兩位)',
            dataIndex: 'costRatio',
            className: 'col-costRatio',
            render: (costRatio, data) => (

                <Fragment>
                    <div className="inputWrap">
                        <input
                            type="number"
                            name="costRatio"
                            defaultValue={costRatio ?? 0}
                            onChange={handleChange}
                        />
                    </div>

                    <Buttons
                        text="更新"
                        handleClick={(e) => btnUpdateCost(data, e)}
                    />
                </Fragment>

            ),
        },
    ];

    // 處理 ajax 回傳
    const handleFetchData = ({ lists }) => setList(lists);

    // input 事件
    const handleChange = ({ target }) => setValue(+target.value);

    // 更新按鈕
    const btnUpdateCost = ({ id }) => {

        Service.updateCostRatio({ id, costRatio: value })
            .then(() => window.location.reload());

    };

    return (

        <div className="costRatioWrap">
            <Breadcrumbs />

            <Tables
                columns={columns}
                data={list}
                rowKey="id"
                showTitle={true}
                showPage={false}
            />
        </div>

    );

};

export default CostRatio;
