import React, { useState, useContext } from 'react';
import { Slider } from 'antd';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import PMB from '../../lib/moon.pmb';

const { handleAreaData } = PMB;

// 每筆項目
const Item = ({
    data: { areaId, id, name, score },
    storage,
    listData,
    handleChangeSlider,
}) => (

    <div className="personal-skill-row">
        <Tags
            color={listData?.color}
            textColor="#000"
            text={listData?.name}
        />

        <span>{name}</span>

        <div className="slider">
            <div className="score-slider">
                <Slider
                    min={0}
                    max={10}
                    marks={{ 5: 5 }}
                    defaultValue={score}
                    onChange={(value) => handleChangeSlider(value, id)}
                />
            </div>
            <span className="small-text">{storage ? storage[id] : score}分</span>
        </div>
    </div>

);

const ScoreSkillForm = () => {

    // Context
    const {
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        areas,
        personalSkill,
        scoreSkill,
    } = useContext(SkillContext);

    // React Hook Form
    const { handleSubmit } = useForm();

    // State
    const [storage, setStorage] = useState(
        personalSkill?.skills && personalSkill.skills.reduce((acc, { id, score }) => {

            acc[id] = score;
            return acc;

        }, {})
    );

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 拉霸
    const handleChangeSlider = (value, id) => setStorage({ ...storage, [id]: value });

    // 送資料
    const handleReqData = (reqData) => {

        const { userId } = personalSkill;

        reqData = {
            ...reqData,
            userId,
            skills: storage,
        };

        scoreSkill(reqData);

    };

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <div className="score-skill-wrap">
                {
                    personalSkill.skills.map((obj, idx) => (

                        <Item
                            key={idx}
                            data={obj}
                            storage={storage}
                            listData={handleAreaData(areas)[obj.areaId]}
                            handleChangeSlider={handleChangeSlider}
                        />

                    ))
                }
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default ScoreSkillForm;
