import React, { useContext, useState } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';
import AdvancedSearch from '../../components/AdvancedSearch';
import SearchItems from '../../components/SearchItems';
import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';
import moonConst from '../../lib/moon.const';

const { memberWorkStatus } = moonConst;

// 正規檢查 Checkbox name
const checkboxRegex = (checkboxes) => new RegExp(`^(${checkboxes.join('|')})$`);

// 過濾已選取 checkboxes
const filterSelectedCheckboxes = (selected) => Object.keys(selected).filter((key) => selected[key]);

// 整理資料
const arrangeCheckboxesData = ({ type, data, checked = false }) => data.reduce((obj, param) => {

    const key = (type === 'group') ? param.id : param;

    obj[key] = checked;
    return obj;

}, {});

const Search = () => {

    // Context
    const { groups } = useContext(GlobalContext);
    const { memberList, memberDispatch } = useContext(MemberContext);

    // State
    const [checkedAllWorkStatus, setCheckedAllWorkStatus] = useState(false);
    const [checkedAllGroup, setCheckedAllGroup] = useState(false);
    const [selectedWorkStatus, setSelectedWorkStatus] = useState({ atWork: true });
    const [selectedGroup, setSelectedGroup] = useState({});
    const [keyword, setKeyword] = useState('');

    // React Hook Form
    const {
        handleSubmit,
        register,
        reset,
    } = useForm();

    // 搜尋: checkbox 全選
    const handleCheckedAllToggle = (type) => {

        if (type === 'group') {

            const targetSelected = {
                ...selectedGroup,
                ...arrangeCheckboxesData({ type, data: groups, checked: !checkedAllGroup }),
            };

            setCheckedAllGroup(!checkedAllGroup);
            setSelectedGroup({ ...targetSelected });

        }
        else {

           const targetSelected = {
                ...selectedWorkStatus,
                ...arrangeCheckboxesData({ type, data: Object.keys(memberWorkStatus), checked: !checkedAllWorkStatus }),
            };

            setCheckedAllWorkStatus(!checkedAllWorkStatus);
            setSelectedWorkStatus({ ...targetSelected });

        }

    };

    // 還原 selected checkboxes
    const resetSelected = () => {

        setSelectedWorkStatus({
            ...arrangeCheckboxesData({ type: 'workStatus', data: Object.keys(memberWorkStatus) }),
            atWork: true, // 預設在職中要被勾選
        });

        setSelectedGroup(arrangeCheckboxesData({ type: 'group', data: groups }));

    };

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        // setIsSearch(false);
        memberDispatch({
            type: 'MEMBER_LIST_SEARCH',
            payload: { isSearch: false, list: [] },
        });

        setKeyword('');
        setCheckedAllWorkStatus(false);
        setCheckedAllGroup(false);
        resetSelected();

    };

    // 搜尋: checkbox 事件
    const handleCheckboxChangeSearch = (type, { target }) => {

        const { name, checked } = target;

        if (type === 'group') {

            setSelectedGroup({
                ...selectedGroup,
                [name]: checked,
            });

        }
        else {

            setSelectedWorkStatus({
                ...selectedWorkStatus,
                [name]: checked,
            });

        }

    };

    // 搜尋: 送資料
    const handleSearchReqData = (reqData) => {

        const listData = [...memberList];

        reqData = {
            ...keyword,
            ...reqData,
            workStatus: filterSelectedCheckboxes(selectedWorkStatus),
            group: filterSelectedCheckboxes(selectedGroup).flatMap((obj) => +obj),
        };

        memberDispatch({
            type: 'MEMBER_LIST_SEARCH',
            payload: {
                isSearch: true,
                list: listData.filter(({ nameChinese, groupId, workStatus }) => {

                    return ((nameChinese.indexOf(reqData.keyword) !== -1) &&
                            (reqData.group.length ? checkboxRegex(reqData.group).test(groupId) : true) &&
                            (reqData.workStatus.length ? checkboxRegex(reqData.workStatus).test(workStatus) : true)
                    );

                }),
            }
        });

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <AdvancedSearch
            keyword={keyword}
            searchQueryPlaceholder="中文姓名"
            formCheck={{
                register,
                handleClear: handleSearchClear,
                handleSubmit: handleSubmit(handleSearchReqData),
            }}
        >
            {/* 在職狀況 */}
            <SearchItems
                className="checkboxLists"
                title="在職狀況"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('workStatus')}
            >
                {
                    Object.keys(memberWorkStatus).map((code) => (
                        <Checkbox
                            key={code}
                            name={code}
                            className="checkbox-item"
                            checked={selectedWorkStatus[code] || ((checkedAllWorkStatus && !selectedWorkStatus[code]) ? selectedWorkStatus[code] : checkedAllWorkStatus)}
                            onChange={(e) => handleCheckboxChangeSearch('workStatus', e)}
                        >
                            {memberWorkStatus[code].text}
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 組別 */}
            <SearchItems
                className="checkboxLists"
                title="組別"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('group')}
            >
                {
                    groups.map(({ id, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={selectedGroup[id] || ((checkedAllGroup && !selectedGroup[id]) ? selectedGroup[id] : checkedAllGroup)}
                            onChange={(e) => handleCheckboxChangeSearch('group', e)}
                        >
                            {name}
                        </Checkbox>
                    ))
                }
            </SearchItems>
        </AdvancedSearch>

    );

};

export default Search;
