import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import './Switcher.scss';

const Switcher = ({
    label,
    defaultChecked,
    disabled,
    handleSwitch,
}) => (

    <div className="authWrap">
        <span>{label}</span>
        <div>
            <Switch
                checkedChildren="開"
                unCheckedChildren="關"
                defaultChecked={defaultChecked}
                disabled={disabled}
                onChange={handleSwitch}
            />
        </div>
    </div>

);

Switcher.defaultProps = {
    label: 'Label 文字',
    defaultChecked: false,
    disabled: false,
};

Switcher.propTypes = {
    label: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    handleSwitch: PropTypes.func,
};

export {
    Switcher as default,
};
