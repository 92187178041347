import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
} from 'react';

import { Row, Col, Checkbox, message } from 'antd';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './Authority.scss';
import Loading from '../../components/Loading';
import Tags from '../../components/Tags';
import Breadcrumbs from '../../components/Breadcrumbs';
import Tables from '../../components/Tables';
import Switcher from '../../components/Switcher';
import BackToTop from '../../components/BackToTop';
import AdvancedSearch from '../../components/AdvancedSearch';
import SearchItems from '../../components/SearchItems';

import { GlobalContext } from '../../context/global.state';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';

const { authorityLabel, memberWorkStatus } = moonConst;

// 過濾已選取 checkboxes
const filterSelectedCheckboxes = (selected) => Object.keys(selected).filter((key) => selected[key]);

// 整理資料
const arrangeCheckboxesData = ({ type, data, checked = false }) => data.reduce((obj, param) => {

    const key = (type === 'department') ? param.id : param;

    obj[key] = checked;
    return obj;

}, {});

// 正規檢查 Checkbox name
const checkboxRegex = (checkboxes) => new RegExp(`^(${checkboxes.join('|')})$`);

// 取得列表
async function fetchData () {

    return await Service.authorityList();

}

const Authority = () => {

    // Context
    const { groups } = useContext(GlobalContext);

    // State
    const [showLoading, setShowLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [checkedAllWorkStatus, setCheckedAllWorkStatus] = useState(false);
    const [selectedWorkStatus, setSelectedWorkStatus] = useState({ atWork: true });
    const [checkedAllGroup, setCheckedAllGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    // Data
    const [lists, setLists] = useState([]);
    const [tempLists, setTempLists] = useState([]);
    const [searchResult, setSearchResult] = useState([]);

    // React Hook Form
    const {
        handleSubmit,
        register,
        reset,
    } = useForm();

    // 表格欄位
    const columns = [
        {
            title: '員工編號',
            dataIndex: 'userId',
            align: 'center',
            render: (userId) => (

                <Link
                    to={`/profile/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {userId}
                </Link>

            ),
        },
        {
            title: '員工姓名',
            dataIndex: 'nameChinese',
            render: (nameChinese, { userId }) => (

                <Link
                    to={`/myProject/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nameChinese}
                </Link>

            ),
        },
        {
            title: '組別',
            dataIndex: 'departmentName',
        },
        {
            title: '組別',
            dataIndex: 'workStatus',
            render: (workStatus) => (

                <Tags
                    color={`#${memberWorkStatus[workStatus].color}`}
                    text={memberWorkStatus[workStatus].text}
                />

            ),
        },
        {
            title: '擁有的權限',
            dataIndex: 'hasAuth',
            width: 900,
            render: (hasAuth, { userId }) => (

                <div className="authority">
                    {
                        Object.keys(hasAuth).map((key) => (

                            <Switcher
                                key={key}
                                label={authorityLabel[key]}
                                defaultChecked={!!hasAuth[key]}
                                handleSwitch={(e) => handleSwitch({ key, userId: userId }, e)}
                            />

                        ))
                    }
                </div>

            ),
        },
    ];

    useEffect(() => {

        document.title = '權限設定';
        fetchData().then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = ({ lists }) => {

        setLists(lists.filter(({ workStatus }) => workStatus === 'atWork'));
        setTempLists(lists);
        setShowLoading(false);

    };

    // Switch 開關事件
    const handleSwitch = ({ key, userId }, e) => {

        Service.authorityUpdate({ userId, [key]: e })
            .then(() => message.success('更新成功', 5));

    };

    // 還原 selected checkboxes
    const resetSelected = () => {

        setSelectedWorkStatus({
            ...arrangeCheckboxesData({ type: 'workStatus', data: Object.keys(memberWorkStatus) }),
            atWork: true, // 預設在職中要被勾選
        });

        setSelectedGroup(arrangeCheckboxesData({ type: 'department', data: groups }));

    };

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setKeyword('');
        setIsSearch(false);
        setSearchResult([]);
        resetSelected();

    };

    // 搜尋: checkbox 全選
    const handleCheckedAllToggle = (type) => {

        if (type === 'department') {

            const targetSelected = {
                ...selectedGroup,
                ...arrangeCheckboxesData({ type, data: groups, checked: !checkedAllGroup }),
            };

            setCheckedAllGroup(!checkedAllGroup);
            setSelectedGroup({ ...targetSelected });

        }
        else {

            const targetSelected = {
                ...selectedWorkStatus,
                ...arrangeCheckboxesData({ type, data: Object.keys(memberWorkStatus), checked: !checkedAllWorkStatus }),
            };

            setCheckedAllWorkStatus(!checkedAllWorkStatus);
            setSelectedWorkStatus({ ...targetSelected });

        }

    };

    // 搜尋: checkbox 事件
    const handleCheckboxChange = (type, { target }) => {

        const { name, checked } = target;

        if (type === 'department') {

            setSelectedGroup({
                ...selectedGroup,
                [name]: checked,
            });

        }
        else {

            setSelectedWorkStatus({
                ...selectedWorkStatus,
                [name]: checked,
            });

        }

    };

    // 搜尋: 送資料
    const handleSearchReqData = (reqData) => {

        reqData = {
            ...keyword,
            ...reqData,
            workStatus: filterSelectedCheckboxes(selectedWorkStatus),
            groups: filterSelectedCheckboxes(selectedGroup).flatMap((obj) => +obj),
        };

        setKeyword(reqData.keyword);
        setIsSearch(true);
        setSearchResult(tempLists.filter(({ nameChinese, workStatus, departmentId }) => {

            return ((nameChinese.indexOf(reqData.keyword) !== -1) &&
                    (reqData.workStatus.length ? checkboxRegex(reqData.workStatus).test(workStatus) : true) &&
                    (reqData.groups.length ? checkboxRegex(reqData.groups).test(departmentId) : true)
            );

        }));

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <Fragment>
            <Breadcrumbs />

            <Row gutter={20}>
                <Col flex="0 0 220px">
                    <AdvancedSearch
                        searchQueryPlaceholder="員工姓名"
                        keyword={keyword}
                        formCheck={{
                            register,
                            handleClear: handleSearchClear,
                            handleSubmit: handleSubmit(handleSearchReqData),
                        }}
                    >
                        <SearchItems
                            className="checkboxLists"
                            title="在職狀況"
                            showToggleAll={true}
                            handleToggleAll={() => handleCheckedAllToggle('workStatus')}
                        >
                            {
                                Object.keys(memberWorkStatus).map((code) => (
                                    <Checkbox
                                        key={code}
                                        name={code}
                                        className="checkbox-item"
                                        checked={selectedWorkStatus[code] || ((checkedAllWorkStatus && !selectedWorkStatus[code]) ? selectedWorkStatus[code] : checkedAllWorkStatus)}
                                        onChange={(e) => handleCheckboxChange('workStatus', e)}
                                    >
                                        {memberWorkStatus[code].text}
                                    </Checkbox>
                                ))
                            }
                        </SearchItems>

                        <SearchItems
                            className="checkboxLists"
                            title="組別"
                            showToggleAll={true}
                            handleToggleAll={() => handleCheckedAllToggle('department')}
                        >
                            {
                                groups.map(({ id, name }) => (
                                    <Checkbox
                                        key={id}
                                        name={id}
                                        className="checkbox-item"
                                        checked={selectedGroup[id] || ((checkedAllGroup && !selectedGroup[id]) ? selectedGroup[id] : checkedAllGroup)}
                                        onChange={(e) => handleCheckboxChange('department', e)}
                                    >
                                        {name}
                                    </Checkbox>
                                ))
                            }
                        </SearchItems>
                    </AdvancedSearch>
                </Col>

                <Col flex="1">
                    {
                        showLoading ? <Loading /> : (

                            <Tables
                                columns={columns}
                                data={isSearch ? searchResult : lists}
                                rowKey="userId"
                                rowClassName="member"
                                showTitle={true}
                            />

                        )
                    }
                </Col>
            </Row>

            <BackToTop />
        </Fragment>

    );

};

export default Authority;
