import React, { useState, useEffect, useContext } from 'react';
import { AutoComplete, Checkbox } from 'antd';
import { useForm } from 'react-hook-form';

import Buttons from '../../components/Buttons';
import Tags from '../../components/Tags';
import { OtherErrorMesg } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import moon from '../../lib/moon';
import PMB from '../../lib/moon.pmb';

const { checkObjLength } = moon;
const { handleAreaData, checkReqData } = PMB;

// 整理成 Ant Design 的結構
const handleMemberList = (data) => data.reduce((acc, {
    departmentName,
    groupName,
    userId,
    nameChinese,
}) => {

    acc.push({
        label: `${nameChinese}@${groupName} (${departmentName})`,
        value: `${String(userId)}_${nameChinese}_${groupName}_${departmentName}`,
    });

    return acc;

}, []);

// 整理員工已有的技能結構
const handleMemeberHasSkillToCheckboxes = (data) => data.reduce((acc, { areaId, id, name, score }) => {

    acc[id] = {
        selected: true,
        areaId,
        name,
        score,
    };

    return acc;

}, {});

// 技能樣式
const Item = ({
    data: { areaId, id, name },
    collectCheckboxes,
    areaTags,
    handleChangeCheckbox,
}) => (

    <div className="skill-items">
        <Checkbox
            name="skills"
            checked={collectCheckboxes[id]?.selected}
            onChange={(e) => handleChangeCheckbox({ areaId, id, name }, e)}
        >
            <Tags
                color={areaTags?.color}
                textColor="#000"
                text={areaTags?.name}
            />
            <span>{name}</span>
        </Checkbox>
    </div>

);

//
const MemberSkillForm = () => {

    // Context
    const { allMembers, getAllMembers, lightboxDispatch } = useContext(GlobalContext);

    const {
        areas,
        skills,
        personalSkill,
        scoreSkill,
        getPersonalSkillScoreList,
        skillDispatch,
    } = useContext(SkillContext);

    useEffect(() => {

        // 取得所有員工
        if (!allMembers.length) getAllMembers();

    }, []);

    // State
    const [selected, setSelected] = useState({
        nameChinese: personalSkill?.nameChinese ? `${personalSkill.userId}_${personalSkill.nameChinese}` : '',
    });

    const [collectCheckboxes, setCollectCheckboxes] = useState({
        ...checkObjLength(personalSkill) && handleMemeberHasSkillToCheckboxes(personalSkill.skills)
    });

    const [showError, setShowError] = useState(false);

    // React Hook Form
    const { handleSubmit } = useForm();

    // 關閉 Modal
    const hideModal = () => {

        setShowError(false);
        lightboxDispatch({ type: 'HIDE' });

    };

    // Autocomplete change 事件
    const handleChangeOption = (value, option) => {

        setSelected({
            ...selected,
            nameChinese: value,
        });

    };

    // Autocomplete select 事件
    const handleSelectedOption = (value) => {

        getPersonalSkillScoreList({ userId: +value.split('_')[0] }).then((resData) => {

            setCollectCheckboxes({ ...handleMemeberHasSkillToCheckboxes(resData.skills) });

        });

    };

    // Autocomplete filter 事件
    const handleFilterOption = (inputVal, option) => {

        const regex = new RegExp(inputVal);
        return regex.test(option.value);

    };

    // Checkbox 事件
    const handleChangeCheckbox = ({ areaId, id, name }, { target }) => {

        setCollectCheckboxes({
            ...collectCheckboxes,
            [id]: {
                selected: target.checked,
                areaId,
                name,
            },
        });

    };

    //
    const dataSendToScoreSkillForm = (reqData) => {

        skillDispatch({
            type: 'manage_skill_member',
            payload: reqData,
        });

        // 先隱藏當前 lightbox 後再秀出評分 lightbox
        if (reqData.skills.length) {

            hideModal();
            lightboxDispatch({ type: 'SHOW', currEvent: 'scoreSkill' });

        }
        else scoreSkill(reqData);

    };

    // 送資料
    const handleReqData = (reqData) => {

        const skills = Object.keys(collectCheckboxes).reduce((acc, key) => {

            // 先整理資料格式後再過濾
            acc.push({
                ...collectCheckboxes[key],
                id: +key,
                score: collectCheckboxes[key].score || 0,
            });

            return acc;

        }, [])
        .filter(({ selected }) => selected);

        reqData = {
            userId: +personalSkill.userId || null,
            nameChinese: personalSkill.nameChinese || '',
            skills,
        };

        // 檢查與顯示錯誤
        setShowError(checkReqData(reqData));
        if (checkReqData(reqData)) return;

        dataSendToScoreSkillForm(reqData);

    };

    return (

        <form
            className="member-skill-form"
            onSubmit={handleSubmit(handleReqData)}
        >
            <div className="row row-name">
                <div className="title isRequired">
                    姓名 (必填)
                    {!!personalSkill?.type && <span className="warning-text">(已有資料不允許更改)</span>}
                </div>
                <div className="field noBorder">
                    <AutoComplete
                        placeholder="可輸入姓名、組別或部門"
                        options={handleMemberList(allMembers)}
                        value={selected.nameChinese.split('_')[1]}
                        disabled={!!personalSkill?.type}
                        onChange={handleChangeOption}
                        onSelect={handleSelectedOption}
                        filterOption={handleFilterOption}
                    />
                    <span className="notice-text">若不確定姓名請向 HR 諮詢</span>
                </div>
            </div>

            <div className="row area-skill">
                <div className="title isRequired">領域與技能 (必填)</div>
                <div className="skill-wrap">
                    <div className="skill-item-wrap">
                        {
                            skills.map((obj) => (

                                <Item
                                    key={obj.id}
                                    data={obj}
                                    collectCheckboxes={collectCheckboxes}
                                    areaTags={handleAreaData(areas)[obj.areaId]}
                                    handleChangeCheckbox={handleChangeCheckbox}
                                />

                            ))
                        }
                    </div>
                </div>

                {showError && <OtherErrorMesg />}
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default MemberSkillForm;
