import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import './TimeLines.scss';

const { Item } = Timeline;

const TimeLines = ({ mode, className, children }) => (

    <Timeline
        mode={mode}
        className={`timelineWrap ${className}`}
    >
        {children}

        {/* 給起始點 */}
        <Item dot={<span className="dot"></span>} />
    </Timeline>

);

TimeLines.defaultProps = {
    mode: 'left',
};

TimeLines.propTypes = {
    mode: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    /**
        <Timeline>
            <Timeline.Item>step1 2015-09-01</Timeline.Item>
            <Timeline.Item>step2 2015-09-01</Timeline.Item>
            <Timeline.Item>step3 2015-09-01</Timeline.Item>
            <Timeline.Item>step4 2015-09-01</Timeline.Item>
        </Timeline>
     */
};

export default TimeLines;
