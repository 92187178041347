import React, { Fragment } from 'react';

import {
    Row,
    Col,
} from 'antd';

import Home from '../pages/dashboard/Home';
import PageBroken from '../containers/PageBroken';

const Index = () => (

    <Fragment>

        {/* <PageBroken /> */}

        <Home />

    </Fragment>

);

export {
    Index as default,
};
