import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FontIcon from './FontIcon';
import moonConst from '../lib/moon.const';
import PMB from '../lib/moon.pmb';

const { getPathname } = PMB;

const items = (list) => list.map((item, idx) => {

    const last = list.indexOf(item) === list.length - 1;

    return last ? (

        <span
            key={idx}
            className="current"
        >
            {moonConst.breadcrumb[item]}
        </span>

    ) : (

        <span key={idx}>
            <Link to={`../${item}`}>{moonConst.breadcrumb[item]}</Link>
            <FontIcon icon={faChevronRight} />
        </span>

    );

});

const Breadcrumbs = () => {

    // 網址比對
    let match = useRouteMatch();
    const { path } = match;

    return (

        <div className="breadcrumbs">
            {items(getPathname(path))}
        </div>

    );

};

export default Breadcrumbs;
