import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import Datepickers from '../../components/Datepickers';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { MemberContext } from '../../context/member/member.state';

export default () => {

    // Context
    const { lightboxDispatch } = useContext(GlobalContext);

    const {
        createSalary,
        formStorageData,
        formStorageDispatch,
    } = useContext(MemberContext);

    // State
    const [effectiveDate, setEffectiveDate] = useState('');

    // React Hook Form - 搜尋
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 生效日
    const handleEffectiveDateChange = (date) => setEffectiveDate(date.format('YYYY-MM-DD'));

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...formStorageData,
            ...reqData,
            amount: +reqData.amount,
            ...effectiveDate && { effectiveDate },
        };

        createSalary(reqData);

    };

    return (

        <form
            className="form-salary"
            onSubmit={handleSubmit(handleReqData)}
            data-type="salary"
        >
            <div className="items">
                <FormRow
                    labelTitle="薪資"
                    required={true}
                    error={errors.amount && true}
                >
                    <input
                        type="number"
                        name="amount"
                        ref={register({ required: true })}
                    />
                </FormRow>

                <div className="row">
                    <div className="title">生效日期 (YYYY/MM/DD)</div>

                    <Datepickers
                        name="effectiveDate"
                        handleChange={handleEffectiveDateChange}
                    />
                </div>
            </div>

            <FormRow
                labelTitle="備註"
                noBorder={true}
                className="textarea salary-notes"
            >
                <textarea
                    name="notes"
                    ref={register}
                />
            </FormRow>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="default"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};
