import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { CheckOutlined, CloseOutlined, DollarOutlined } from '@ant-design/icons';
import Tags from '../../components/Tags';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';

const { priceWithCommas } = moon;
const { yesOrNo, projectPaymentStatus } = moonConst;

// 款項狀態 icon
const paymentStatusConfig = {
    alreadyPaid: <CheckOutlined />,
    recovery: <DollarOutlined />,
    notInvoiced: <CloseOutlined />,
};

// 專案狀態 Tag
const renderTag = ({ text, colorCode, colorText, className }) => (

    <Tags
        className={className}
        color={colorCode}
        textColor={colorText}
        text={text}
    />

);

// 無連結字
const renderTextWithoutLink = (text) => text ? text : '--';

const DetailAccounting = ({
    columnWidth,
    data: {
        budget,
        budgetTax,
        paymentStatus,
        companyName,
        isContract,
    },
}) => (

    <Fragment>
        <Row>
            <Col flex="1">預算(未稅):</Col>
            <Col flex={columnWidth}>{priceWithCommas(budget)}</Col>
        </Row>

        <Row>
            <Col flex="1">預算(含稅):</Col>
            <Col flex={columnWidth}>{priceWithCommas(budgetTax)}</Col>
        </Row>

        <Row>
            <Col flex="1">款項狀態:</Col>
            <Col
                flex={columnWidth}
                className="payment-status"
            >
                {
                    paymentStatus ? (

                        renderTag({
                            className: 'payment-status-tag',
                            colorCode: `#${projectPaymentStatus[paymentStatus].color}`,
                            text: (
                                <Fragment>
                                    {paymentStatusConfig[paymentStatus]}
                                    {projectPaymentStatus[paymentStatus].text}
                                </Fragment>
                            ),
                        })

                    ) : '--'
                }
            </Col>
        </Row>

        <Row>
            <Col flex="1">隸屬公司:</Col>
            <Col flex={columnWidth}>{renderTextWithoutLink(companyName)}</Col>
        </Row>

        <Row>
            <Col flex="1">是否為合約:</Col>
            <Col
                flex={columnWidth}
                className={`row-contract ${isContract ? 'isContract' : ''}`}
            >
                {yesOrNo[isContract]}
            </Col>
        </Row>
    </Fragment>

);

DetailAccounting.defaultProps = {
    columnWidth: '0.8',
};

DetailAccounting.propTypes = {
    columnWidth: PropTypes.string,
    data: PropTypes.object,
};

export {
    DetailAccounting as default,
};
