import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Tables from '../../components/Tables';
import { GlobalContext } from '../../context/global.state';
import PMB from '../../lib/moon.pmb';

const { antdTableFilter } = PMB;

const List = ({ membersData }) => {

    // Context
    const {
        hasAuth: { view_all_members }, // 觀看全部成員資料
        departments,
        groups,
    } = useContext(GlobalContext);

    // 表格欄位
    const columns = [
        {
            title: '編號',
            dataIndex: 'userId',
            width: 100,
            align: 'center',
            render: (userId) => (

                view_all_members ? (

                    <Link
                        to={`/profile/${userId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {userId}
                    </Link>

                ) : <span>{userId}</span>

            ),
        },
        {
            title: '姓名',
            dataIndex: 'nameChinese',
            width: 150,
            render: (nameChinese, { userId }) => (

                <Link
                    to={`/myProject/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nameChinese}
                </Link>

            ),
        },
        {
            title: '組別',
            dataIndex: 'groupName',
            render: (groupName) => <span className={groupName ? '' : 'warning-text'}>{groupName}</span>,
            filters: antdTableFilter(groups),
            onFilter: (value, data) => (String(data.groupId).indexOf(String(value)) === 0),
        },
        {
            title: '部門',
            dataIndex: 'departmentName',
            render: (departmentName) => <span className={departmentName ? '' : 'warning-text'}>{departmentName}</span>,
            // filters: antdTableFilter(departments, 'department'),
            // onFilter: (value, data) => (String(data.departmentId).indexOf(String(value)) === 0),
        },
        {
            title: '閒置日期 (未填工時)',
            dataIndex: 'lazyDate',
            className: 'col-lazyDate',
            render: (lazyDate) => lazyDateMapping(lazyDate),
        },
    ];

    // 對應時間資料
    const lazyDateMapping = ((lazyDate) => lazyDate.map((date) => moment(date).format('YYYY/MM/DD (dd)')).join('、'));

    return (

        <Tables
            className="member-list"
            columns={columns}
            data={membersData}
            rowKey="userId"
            pageSize={50}
            showTitle={true}
        />

    );

};

List.defaultProps = {
    membersData: [],
};

List.propTypes = {
    membersData: PropTypes.array.isRequired,
};

export default List;
