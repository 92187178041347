import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';
import './Collapses.scss';

const Collapses = (props) => {

    const {
        withTopSpace,
        className,
        defaultKey,
        iconPosition,
    } = props;

    return (

        <div className={`collapseWrap ${className && className} ${withTopSpace ? 'withTopSpace' : ''}`}>

            <Collapse
                defaultActiveKey={defaultKey}
                expandIconPosition={iconPosition && iconPosition}
                expandIcon={({ isActive }) => <CaretUpOutlined className="toggle-arrow" rotate={isActive ? 180 : 0} />}
            >
                {props.children}
            </Collapse>

        </div>

    );

};

Collapses.defaultProps = {
    defaultKey: 1,
    iconPosition: '',
    className: '',
    withTopSpace: false,
};

Collapses.propTypes = {
    className: PropTypes.string,
    withTopSpace: PropTypes.bool,
    defaultKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    iconPosition: PropTypes.string,
    children: PropTypes.any.isRequired,
};


export default Collapses;
