import React, { useContext, Fragment } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import Tags from '../../components/Tags';
import Loading from '../../components/Loading';
import CalendarTool from '../../components/CalendarTool';
import Tables from '../../components/Tables';

import { GlobalContext } from '../../context/global.state';
import { HomeStore } from './Home';
import moon from '../../lib/moon';
import PMB from '../../lib/moon.pmb';

const { renderWithoutValue } = moon;
const { mappingText } = PMB;

// 表格欄位
const columnData = ({ workLogTypes, dayOffTypes, btnUpdate, btnDelete }) => ([
    {
        title: '種類',
        dataIndex: 'code',
        align: 'center',
        render: (code, { colorCode, colorText, isLeave }) => (

            <Tags
                color={colorCode}
                textColor={colorText || '#000'}
                text={(isLeave ? mappingText(dayOffTypes, code)?.name : mappingText(workLogTypes, code)?.name) || '閒置'}
            />

        ),
    },
    {
        title: '時數 (分鐘)',
        dataIndex: 'minutes',
        align: 'center',
    },
    {
        title: '專案名稱',
        dataIndex: 'projectName',
        className: 'col-projectName',
        render: (projectName, { projectId }) => (

            <Link
                to={`/projects/${projectId}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {projectName || '--'}
            </Link>
        ),
    },
    {
        title: '指派項目',
        dataIndex: 'taskName',
        render: (taskName) => renderWithoutValue(taskName),
    },
    {
        title: '建立者',
        dataIndex: 'creator',
        className: 'col-creator',
        render: (creator, { creatorId }) => (

            <Link
                to={`/myProject/${creatorId}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {creator || '--'}
            </Link>

        ),
    },
    {
        title: '操作',
        dataIndex: '',
        width: 140,
        render: (data) => (

            data.isEditable &&
                <Fragment>
                    <span
                        className="btn-update"
                        onClick={() => btnUpdate(data)}
                    >
                        <EditOutlined /> 編輯
                    </span>
                    <span
                        className="btn-delete"
                        onClick={() => btnDelete(data.id)}
                    >
                        <DeleteOutlined /> 刪除
                    </span>
                </Fragment>

        ),
    },
]);

const MyWorkLog = ({
    selectedDate,
    showDetailTip,
    handleSelectedDate,
    handleClickDate,
    handleCancelDetailTip,
    handleChangeMonth,
    btnUpdate,
    btnDelete,
}) => {

    // Context
    const { workLogTypes, dayOffTypes } = useContext(GlobalContext);
    const {
        showLoading,
        subItems,
        workLogHistoryList,
    } = useContext(HomeStore);

    return (

        <div className="my-work-logs">
            <CalendarTool
                showDetailTip={showDetailTip}
                selectedDate={selectedDate}
                subItemType="simple"
                subItems={subItems}
                handleSelected={handleSelectedDate}
                handleClickDate={handleClickDate}
                handleCancelDetailTip={handleCancelDetailTip}
                panelChangeCallback={handleChangeMonth}
            >
                {
                    showLoading.myWorkLog ? <Loading /> : (

                        <Tables
                            className="myWorkLogLists"
                            columns={columnData({ workLogTypes, dayOffTypes, btnUpdate, btnDelete, selectedDate})}
                            data={workLogHistoryList}
                            rowKey="id"
                            showTitle={true}
                            showPage={false}
                        />

                    )
                }
            </CalendarTool>
        </div>

    );

};

export default MyWorkLog;
