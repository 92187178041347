import React, { useState, useContext } from 'react';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';

import Tags from '../../components/Tags';
import AdvancedSearch from '../../components/AdvancedSearch';
import SearchItems from '../../components/SearchItems';

import { SkillContext } from '../../context/skill/skill.state';
import PMB from '../../lib/moon.pmb';

const { handleAreaData } = PMB;

// 過濾已選取 checkboxes
const filterSelectedCheckboxes = (selected) => (selected && Object.keys(selected).filter((key) => selected[key])
                                                            .flatMap((obj) => +obj)) || [];

// 整理資料
const arrangeCheckboxesData = ({ data, checked = false }) => data.reduce((obj, param) => {

    obj[param.id] = checked;
    return obj;

}, {});

const Search = () => {

    // Context
    const {
        areas,
        skills,
        features,
        tools,
        memberListSearch,
        skillDispatch,
    } = useContext(SkillContext);

    // State
    const [keyword, setKeyword] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [isCheckedAll, setIsCheckedAll] = useState({
        area: false,
        skill: false,
        feature: false,
        tool: false,
    });

    // React Hook Form
    const {
        handleSubmit,
        register,
        reset,
    } = useForm();

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setKeyword('');
        resetSelected();
        skillDispatch({ type: 'skill_member_list_search', payload: { isSearch: false } });

    };

    // 還原 selected checkboxes
    const resetSelected = () => {

        let obj = { ...isCheckedAll };

        // 清除所有已被勾選的 checkboxes
        for (const i in obj) {

            obj[i] = false;

        }

        setSelectedCheckboxes({});

        // 還原全選狀態
        setIsCheckedAll({ ...obj });

    };

    // 搜尋: checkbox 全選
    const handleCheckedAllToggle = (type) => {

        const config = {
            area: areas,
            skill: skills,
            feature: features,
            tool: tools,
        };

        // 全選狀態
        setIsCheckedAll({
            ...isCheckedAll,
            [type]: !isCheckedAll[type],
        });

        // 已選取
        setSelectedCheckboxes({
            ...selectedCheckboxes,
            [type]: arrangeCheckboxesData({ type, data: config[type], checked: !isCheckedAll[type] }),
        });

    };

    // 搜尋: checkbox 事件
    const handleCheckboxChange = (type, { target }) => {

        const { name, checked } = target;

        setSelectedCheckboxes({
            ...selectedCheckboxes,
            [type]: {
                ...selectedCheckboxes[type],
                [name]: checked,
            },
        });

    };

    // 搜尋: 送資料
    const handleSearchReqData = (reqData) => {

        reqData = {
            ...reqData,
            areas: filterSelectedCheckboxes(selectedCheckboxes['area']),
            skills: filterSelectedCheckboxes(selectedCheckboxes['skill']),
            features: filterSelectedCheckboxes(selectedCheckboxes['feature']),
            tools: filterSelectedCheckboxes(selectedCheckboxes['tool']),
        };

        memberListSearch(reqData);

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    return (

        <AdvancedSearch
            name="query"
            keyword={keyword}
            searchQueryPlaceholder="中文姓名"
            formCheck={{
                register,
                handleClear: handleSearchClear,
                handleSubmit: handleSubmit(handleSearchReqData),
            }}
        >
            {/* 領域 */}
            <SearchItems
                className="checkboxLists"
                title="領域"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('area')}
            >
                {
                    areas.map(({ id, colorCode, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['area'] && selectedCheckboxes['area'][id]) ||
                                (isCheckedAll['area'] && !selectedCheckboxes['area'][id]) ? selectedCheckboxes['area'][id] : isCheckedAll['area']
                            }
                            onChange={(e) => handleCheckboxChange('area', e)}
                        >
                            <Tags
                                color={colorCode}
                                textColor="#000"
                                text={name}
                            />
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 技能 */}
            <SearchItems
                className="checkboxLists"
                title="技能"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('skill')}
            >
                {
                    skills.map(({ areaId, id, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['skill'] && selectedCheckboxes['skill'][id]) ||
                                (isCheckedAll['skill'] && !selectedCheckboxes['skill'][id]) ? selectedCheckboxes['skill'][id] : isCheckedAll['skill']
                            }
                            onChange={(e) => handleCheckboxChange('skill', e)}
                        >
                            <span
                                className="circle"
                                style={{ backgroundColor: handleAreaData(areas)[areaId]?.color }}
                            ></span>
                            {name}
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 特質 */}
            <SearchItems
                className="checkboxLists"
                title="特質"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('feature')}
            >
                {
                    features.map(({ areaId, id, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['feature'] && selectedCheckboxes['feature'][id]) ||
                                (isCheckedAll['feature'] && !selectedCheckboxes['feature'][id]) ? selectedCheckboxes['feature'][id] : isCheckedAll['feature']
                            }
                            onChange={(e) => handleCheckboxChange('feature', e)}
                        >
                            {name}
                        </Checkbox>
                    ))
                }
            </SearchItems>

            {/* 擅長工具 */}
            <SearchItems
                className="checkboxLists"
                title="擅長工具"
                showToggleAll={true}
                handleToggleAll={() => handleCheckedAllToggle('tool')}
            >
                {
                    tools.map(({ areaId, id, name }) => (
                        <Checkbox
                            key={id}
                            name={id}
                            className="checkbox-item"
                            checked={
                                (selectedCheckboxes['tool'] && selectedCheckboxes['tool'][id]) ||
                                (isCheckedAll['tool'] && !selectedCheckboxes['tool'][id]) ? selectedCheckboxes['tool'][id] : isCheckedAll['tool']
                            }
                            onChange={(e) => handleCheckboxChange('tool', e)}
                        >
                            {name}
                        </Checkbox>
                    ))
                }
            </SearchItems>
        </AdvancedSearch>

    );

};

export default Search;
