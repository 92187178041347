import React, {
    Fragment,
    createContext,
    useContext,
    useReducer,
} from 'react';

import Prompt from '../../components/Prompt';
import { GlobalContext } from '../global.state';
import { skillReducer } from './skill.reducer';

import Service from '../../lib/moon.service';

// Skill
const skillInitialState = {
    areas: [],
    skills: [],
    features: [],
    tools: [],
    skillAreaList: [],
    skillMemberList: [],
    personalSkill: {},
    searchResult: {
        isSearch: false,
    },
};

// Create Context
const SkillContext = createContext(null);

// 建立 Lightbox title
const renderLightboxTitle = (id) => (

    <Fragment>
        刪除
        <span className="small-text">(ID: {id})</span>
    </Fragment>

);

// Provider
const SkillProvider = ({ children }) => {

    // Context
    const { formStorageDispatch, lightboxDispatch } = useContext(GlobalContext);
    const [skillState, skillDispatch] = useReducer(skillReducer, skillInitialState);
    const {
        areas,
        skills,
        features,
        tools,
        skillAreaList,
        skillMemberList,
        personalSkill,
        searchResult,
    } = skillState;

    const { Provider } = SkillContext;

    // 技能領域列表
    const getSkillList = (reqData) => {

        Service.skillAreaList(reqData)
            .then(({ areas, skills, features, tools, lists }) => skillDispatch({
                type: 'skill_area_list',
                payload: {
                    areas,
                    skills,
                    features: features,
                    tools: tools,
                    skillAreaList: lists,
                },
            }));

    };

    // 新增領域
    const createArea = (reqData) => {

        Service.createArea(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        skillDispatch({ type: 'create_area', payload: resData });

                    },
                });

            });

    };

    // 編輯領域
    const updateArea = (reqData) => {

        Service.updateArea(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        skillDispatch({ type: 'update_area', payload: resData })

                    },
                });

            });

    };

    // 刪除領域
    const removeArea = ({ id, name }) => {

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.removeArea({ id })
                    .then(() => skillDispatch({ type: 'remove_area', payload: id }));

            },
        });

    };

    // 取得個人技能表
    const getPersonalSkillScoreList = (reqData) => {

        return Service.personalSkillScoreList(reqData)
            .then((resData) => {

                skillDispatch({ type: 'personal_skill_score', payload: resData });
                return resData;

            });

    };

    // 更新技能評分
    const scoreSkill = ({ userId, skills }) => {

        // 整理成後端要的格式
        const scoreList = Object.keys(skills).reduce((arr, key) => {

            arr.push({
                id: +key,
                score: skills[key],
            });

            return arr;

        }, []);

        Service.updatePersonalSkillScore({ userId, skills: scoreList })
            .then(({ lists }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        skillDispatch({ type: 'score_skill', payload: { lists, skills } });

                    },
                });

            });

    };

    // 管理技能與領域
    const manageAreaSkill = (reqData) => {

        Service.manageAreaSkill(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        skillDispatch({ type: 'manage_area_skill', payload: resData });

                    },
                });

            });

    };

    // 新增技能
    const createSkill = (reqData) => {

        Service.createSkill(reqData)
            .then((resData) => skillDispatch({ type: 'create_skill', payload: resData }));

    };

    // 編輯技能
    const updateSkill = (reqData) => {

        Service.updateSkill(reqData)
            .then((resData) => {

                skillDispatch({ type: 'update_skill', payload: resData });

            });

    };

    // 刪除技能
    const removeSkill = ({ id, name }) => {

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除專案 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.removeSkill({ id })
                    .then(() => {

                        skillDispatch({ type: 'remove_skill', payload: id });

                    });

            },
        });

    };

    // 所有員工技能評分表
    const getMemberList = (reqData) => {

        Service.skillMemberList(reqData)
            .then(({ members }) => {

                skillDispatch({ type: 'skill_member_list', payload: members });

            });

    };

    // 所有員工技能評分表搜尋
    const memberListSearch = (reqData) => {

        Service.skillMemberSearch(reqData)
            .then(({ members }) => {

                skillDispatch({ type: 'skill_member_list_search', payload: { members, isSearch: true } });

            });

    };

    // 新增特質
    const createFeature = (reqData) => {

        Service.createFeature(reqData)
            .then(({ lists }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => skillDispatch({ type: 'create_feature', payload: lists }),
                });

            });

    };

    // 編輯特質
    const updateFeature = (reqData) => {

        return Service.updateFeature(reqData)
            .then(({ lists }) => skillDispatch({ type: 'update_feature', payload: lists }));

    };

    // 刪除特質
    const removeFeature = (reqData) => {

        return Service.removeFeature(reqData)
            .then(({ lists }) => skillDispatch({ type: 'remove_feature', payload: lists }));

    };

    // 新增工具
    const createTool = (reqData) => {

        Service.createTool(reqData)
            .then(({ lists }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        skillDispatch({ type: 'create_tool', payload: lists });

                    },
                });

            });

    };

    // 編輯工具
    const updateTool = (reqData) => {

        return Service.updateTool(reqData)
            .then(({ lists }) => skillDispatch({ type: 'update_tool', payload: lists }));

    };

    // 刪除工具
    const removeTool = (reqData) => {

        return Service.removeTool(reqData)
            .then(({ lists }) => skillDispatch({ type: 'remove_tool', payload: lists }));

    };

    // 管理員工標籤 (特質、擅長工具...)
    const manageMemberLabel = (reqData) => {

        Service.manageMemberLabel(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        skillDispatch({ type: 'manage_member_label', payload: resData });

                    },
                });

            });

    };

    return (

        <Provider value={{
            //
            areas,
            skills,
            features,
            tools,

            // 技能領域
            skillAreaList,
            getSkillList,
            createArea,
            updateArea,
            removeArea,

            // 技能
            manageAreaSkill,
            createSkill,
            updateSkill,
            removeSkill,

            // 設定 > 特質
            createFeature,
            updateFeature,
            removeFeature,

            // 設定 > 擅長工具
            createTool,
            updateTool,
            removeTool,

            // 個人
            personalSkill,
            getPersonalSkillScoreList,
            scoreSkill,
            manageMemberLabel,

            // 所有人評分
            skillMemberList,
            searchResult,
            getMemberList,
            memberListSearch,

            // Dispatch
            skillDispatch,
        }}>
            {children}
        </Provider>

    );

};

export {
    SkillContext,
    SkillProvider,
};
