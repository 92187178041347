import React from 'react';
import PropTypes from 'prop-types';
import { RedoOutlined } from '@ant-design/icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import './AdvancedSearch.scss';
import Buttons from '../components/Buttons';
import FontIcon from '../components/FontIcon';

// 關鍵字
const SearchQuery = ({
    placeholder,
    register,
    keyword,
    name,
}) => (

    <label className="search-item">
        <div className="title">關鍵字</div>
        <div className="input">
            <input
                type="text"
                name={name}
                defaultValue={keyword}
                placeholder={`請輸入${placeholder || '關鍵字'}`}
                ref={register}
            />
        </div>
    </label>

);

const AdvancedSearch = (props) => {

    const {
        showQuery,
        searchQueryPlaceholder,
        children,
        keyword,
        name,
        formCheck: {
            register,
            handleClear,
            handleSubmit,
        }
    } = props;

    return (

        <div className="advanced_search_wrap">
            <h2 className="advanced-search-title">
                <FontIcon icon={faFilter} />進階搜尋
                <span
                    className="clear"
                    onClick={handleClear}
                >
                    <RedoOutlined />還原
                </span>
            </h2>

            <form
                className="advanced-search-form"
                onSubmit={handleSubmit}
            >
                {
                    showQuery &&
                        <SearchQuery
                            name={name}
                            placeholder={searchQueryPlaceholder}
                            register={register}
                            keyword={keyword}
                        />
                }

                {children}

                <Buttons
                    text="送出"
                    htmlType="submit"
                    className="btn-submit"
                />
            </form>
        </div>

    );

};

AdvancedSearch.defaultProps = {
    name: 'keyword',
    showQuery: true,
};

AdvancedSearch.propTypes = {
    name: PropTypes.string,
    showQuery: PropTypes.bool,
    searchQueryPlaceholder: PropTypes.string,
    children: PropTypes.any,
    keyword: PropTypes.string,
    formCheck: PropTypes.object,
};

export {
    AdvancedSearch as default,
};
