import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tree, Alert } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './Organize.scss';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Tab from '../../../components/Tab';
import Buttons from '../../../components/Buttons';
import TreeAction from './TreeAction';
import Service from '../../../lib/moon.service';

// 切割 id
const converToID = (str) => str.split(/-/g)[1];

// 整理 tab panel 格式
const arrangeTabPanel = ({
    menu,
    data,
    expandedKeys,
    btnToggleExpandAll,
    handleExpandTarget,
    btnCreateDepartment,
    btnUpdateDepartment,
    btnDeleteDepartment,
    btnCreateGroup,
    btnUpdateGroup,
    btnDeleteGroup,
}) => menu.reduce((acc, { id, name }) => {

    acc[id] = {
        name,
        component: (

            <Fragment>
                <Buttons
                    text="新增部門"
                    className="btn-createDepartment"
                    onClick={btnCreateDepartment}
                />

                <Buttons
                    type="default"
                    text={`全部${expandedKeys?.length ? '收起' : '展開'}`}
                    className="btn-toggle"
                    onClick={btnToggleExpandAll}
                />

                {
                    data &&
                        <Tree
                            treeData={arrangeToAntdTree(arrangeTree(data))}
                            titleRender={(data) => (
                                <TreeAction
                                    data={data}
                                    btnUpdateDepartment={btnUpdateDepartment}
                                    btnDeleteDepartment={btnDeleteDepartment}
                                    btnCreateGroup={btnCreateGroup}
                                    btnUpdateGroup={btnUpdateGroup}
                                    btnDeleteGroup={btnDeleteGroup}
                                />
                            )}
                            switcherIcon={<DownOutlined />}
                            selectable={false}
                            className="treeWrap"
                            showLine
                            expandedKeys={expandedKeys}
                            onExpand={handleExpandTarget}
                        />
                }
            </Fragment>

        ),
    };

    return acc;

}, {});

// 整理成 tree 格式
const arrangeTree = (data) => data.reduce((acc, {
    id,
    name,
    departmentId,
    departmentName,
    isDeleteDepartment,
    isDeleteGroup,
}) => {

    acc[departmentId] = acc[departmentId] || {};
    acc[departmentId].key = departmentId;
    acc[departmentId].title = departmentName;
    acc[departmentId].isDelete = isDeleteDepartment;
    acc[departmentId].children = acc[departmentId].children || [];

    // 未有子層則不印出
    if (id !== null) {

        acc[departmentId].children.push({
            key: `level2-${id}`,
            title: name,
            isDelete: isDeleteGroup,
        });

    }

    return acc;

}, {});

// 整理成 antd tree 結構
const arrangeToAntdTree = (data) => Object.keys(data).reduce((acc, key) => {

    acc.push({
        key: `level1-${key}`,
        title: data[key].title,
        isDelete: data[key].isDelete,
        children: data[key].children,
    });

    return acc;

}, []);

//
const Organize = () => {

    let history = useHistory();

    // State
    const [currActive, setCurrActive] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [tempExpandedKeys, setTempExpandedKeys] = useState({});

    // Data
    const [company, setCompany] = useState([]);
    const [tree, setTree] = useState({});

    useEffect(() => {

        document.title = '管理組織架構';

        Service.organize()
            .then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = ({ company, lists }) => {

        setCurrActive(company[0].id);
        setCompany(company);
        setTree({
            ...tree,
            [company[0].id]: lists,
        });

        setExpandedKeys({
            [company[0].id]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
        });

        setTempExpandedKeys({
            [company[0].id]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
        });

    };

    // Tab 點擊事件
    const handleClickTabMenu = (e) => {

        setCurrActive(e);

        // 若有 tree 就不打 ajax
        if (Object.keys(tree).includes(e)) return;

        Service.treeList({ id: e })
            .then(({ lists }) => {

                setTree({ ...tree, [e]: lists });
                setExpandedKeys({
                    ...expandedKeys,
                    [e]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
                });

                setTempExpandedKeys({
                    ...tempExpandedKeys,
                    [e]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
                });

            });

    };

    // 新增公司
    const btnCreateCompany = () => {

        const value = prompt('請輸入公司名稱');

        if (!value) return;

        Service.companyCreate({ name: value })
            .then(({ id, name, lists }) => {

                setCurrActive(id);
                setCompany((prev) => [...prev, { id, name }]);
                setTree({ ...tree, [id]: lists });
                setExpandedKeys({
                    ...expandedKeys,
                    [id]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
                });

                setTempExpandedKeys({
                    ...tempExpandedKeys,
                    [id]: arrangeToAntdTree(arrangeTree(lists)).flatMap(({ key }) => key),
                });

            });

    };

    // 編輯公司
    const btnUpdateCompany = (target) => {

        const filter = company.filter(({ id }) => id === +target)[0];
        const value = prompt(`將 "${filter.name}" 更名為:`);

        if (!value) return;

        Service.companyUpdate({
            id: +target,
            name: value,
        })
        .then((resData) => {

            setCompany((prev) => prev.map((obj) => (obj.id === resData.id) ? obj = resData : obj));

        });

    };

    // Tab menu 行為 (新增+編輯)
    const handleEditTabMenu = (targetKey, action) => {

        if (action === 'add') btnCreateCompany();
        else btnUpdateCompany(targetKey);

    };

    // 全部收合 tree
    const btnToggleExpandAll = () => {

        setExpandedKeys({
            ...expandedKeys,
            [currActive]: expandedKeys[currActive].length ? [] : tempExpandedKeys[currActive],
        });

    };

    // 當前收合
    const handleExpandTarget = (keys) => {

        setExpandedKeys({
            ...expandedKeys,
            [currActive]: keys,
        });

    };

    // 新增部門
    const btnCreateDepartment = () => {

        const value = prompt('請輸入部門名稱');

        if (!value) return;

        Service.departmentCreate({
            companyId: currActive,
            name: value,
        }).then(({ lists }) => {

            setTree({ ...tree, [currActive]: lists });

        });

    };

    // 編輯部門
    const btnUpdateDepartment = ({ key, title }) => {

        const value = prompt(`將 "${title}" 更名為: ${converToID(key)}`);

        if (!value) return;

        Service.departmentUpdate({
            id: +converToID(key),
            name: value,
        }).then((resData) => {

            setTree({
                ...tree,
                [currActive]: tree[currActive].map((obj) => (obj.departmentId === resData.id) ? obj = { ...obj, departmentName: resData.name } : obj),
            });

        });

    };

    // 刪除部門
    const btnDeleteDepartment = ({ key, title }) => {

        const yes = window.confirm(`確定要刪除 "${title}" ?`);

        if (!yes) return;

        Service.departmentDelete({ id: +converToID(key) })
            .then(() => {

                setTree({
                    ...tree,
                    [currActive]: tree[currActive].filter((obj) => obj.departmentId !== +converToID(key)),
                });

            });

    };

    // 新增組別
    const btnCreateGroup = ({ key }) => {

        const value = prompt('請輸入組別名稱');

        if (!value) return;

        Service.groupCreate({
            departmentId: +converToID(key),
            name: value,
        }).then((resData) => {

            setTree({
                ...tree,
                [currActive]: [...tree[currActive], { ...resData }],
            });

        });

    };

    // 編輯組別
    const btnUpdateGroup = ({ key, title }) => {

        const value = prompt(`將 "${title}" 更名為:`);

        if (!value) return;

        Service.groupUpdate({
            id: +converToID(key),
            name: value,
        }).then((resData) => {

            setTree({
                ...tree,
                [currActive]: tree[currActive].map((obj) => {

                    if (obj.id === resData.id) obj = resData;
                    return obj;

                }),
            });

        });

    };

    // 刪除組別
    const btnDeleteGroup = ({ key, title }) => {

        const yes = window.confirm(`確定要刪除 "${title}" ?`);

        if (!yes) return;

        Service.groupDelete({ id: +converToID(key) })
            .then(() => {

                setTree({
                    ...tree,
                    [currActive]: tree[currActive].filter((obj) => obj.id !== +converToID(key)),
                });

            });

    };

    return (

        <Fragment>
            <Breadcrumbs />

            <div className="content-header">
                <h1 className="title">管理組織架構</h1>
                <Buttons
                    text="組織架構大表"
                    type="default"
                    onClick={() => history.push(`/setting/organizeList`)}
                />
            </div>

            <Alert
                className="notice"
                type="warning"
                message={
                    <ul>
                        <li>每間公司會有預設無部門項目，此部門不允許編輯與刪除</li>
                        <li>無部門下的預設組別不允許刪除</li>
                        <li>組別若未能歸類部門，請掛在 "無部門" 底下</li>
                        <li>若部門底下有掛組別則無法刪除</li>
                    </ul>
                }
            />

            <Tab
                type="editable-card"
                tabData={arrangeTabPanel({
                    menu: company,
                    data: tree[currActive],
                    expandedKeys: expandedKeys[currActive],
                    btnToggleExpandAll,
                    handleExpandTarget,
                    btnCreateDepartment,
                    btnUpdateDepartment,
                    btnDeleteDepartment,
                    btnCreateGroup,
                    btnUpdateGroup,
                    btnDeleteGroup,
                })}
                onChange={handleClickTabMenu}
                onEdit={handleEditTabMenu}
                activeKey={String(currActive)}
            />
        </Fragment>

    );

};

export default Organize;
