import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import './TimelineData.scss';
import Loading from '../../components/Loading';
import { EmptyData } from '../../containers/PageBroken';
import GanttTimeline from '../../components/GanttTimeline';
import TimelineDataSearch from './TimelineDataSearch';
import TimelineDataGroupDetail from './TimelineDataGroupDetail';
import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { priceWithCommas, transferHourFormat } = moon;

// 處理日期格式
const dateFormat = (date) => (date === null) ? '' : moment(date).format('YYYY-MM-DD');

// Service: 小組預算與成本
async function fetchDataDepartment (id) {

    return await Service.projectGroupBudgetCost({ id });

};

// Service: 小組成本詳細
async function fetchDataTimeline (id) {

    return await Service.projectTimelineDataList({ id });

};

// 提示
const tooltip = ({
    codeName,
    projectName,
    taskName,
    assignee,
    hours,
    cost,
    startDate,
    endDate,
    colorCode,
}) => (`

    <div class="timeline_tooltip_wrap">
        <h3 class="title">
            <span class="name">${codeName || projectName}</span>
            ${
                !projectName ? `<span class="square" style="background-color: ${colorCode};"></span>` : ''
            }
            <span class="small-text">(共 ${transferHourFormat(hours)}h)</span>
            <span class="assignee">${assignee}</span>
        </h3>
        <div class="info">
            <div>
                <div class="label">期間</div> ${moment(startDate).format('YYYY/MM/DD (ddd)')} ~ ${moment(endDate).format('YYYY/MM/DD (ddd)')}
            </div>
            <div>
                <div class="label">項目名稱</div> ${taskName}
            </div>
            ${
                cost ? `
                    <div>
                        <div class="label">成本</div> ${priceWithCommas(cost)}
                    </div>
                ` : ''
            }
        </div>
    </div>

`);

// 整理資料結構
const handleDataStructure = (data) => data.map((obj) => {

    const {
        taskName,
        projectName,
        assignee,
        colorCode,
        startDate,
        endDate,
    } = obj;

    let arr = [
        `${taskName} / ${assignee}`,
        '',
        projectName ? '#bdf1ae' : colorCode,
        tooltip(obj),
        new Date(startDate),
        new Date(endDate),
    ];

    return Object.values(arr);

});

const TimelineData = (props) => {

    const { match } = props;
    const { params } = match;
    const pid = +params.id;

    // State
    const [selected, setSelected] = useState({});
    const [showLoading, setShowLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // Data
    const [info, setInfo] = useState({});
    const [groupBudgetLists, setGroupBudgetLists] = useState([]);
    const [groupCostLists, setGroupCostLists] = useState([]);
    const [timelineLists, setTimelineLists] = useState([]);
    const [timelineListStore, setTimelineListStore] = useState([]);

    // React Hook Form
    const { handleSubmit, reset } = useForm();

    useEffect(() => {

        document.title = '專案時間軸';

        fetchDataDepartment(pid)
            .then(handleFetchDataDepartment);

        fetchDataTimeline(pid)
            .then(handleFetchDataTimeline);

    }, [pid]);

    // 處理小組預算與成本 ajax 回傳
    const handleFetchDataDepartment = ({
        id,
        name,
        budget,
        groupBudgetLists,
        groupCostLists,
    }) => {

        setInfo({ id, name, budget });
        setGroupBudgetLists(groupBudgetLists);
        setGroupCostLists(groupCostLists);

    };

    // 處理小組預算與成本 ajax 回傳
    const handleFetchDataTimeline = ({ lists }) => {

        setTimelineListStore(lists);
        setTimelineLists(lists);
        setShowLoading(false);

    };

    // 搜尋: Rangepicker
    const handleRangepicker = (dates, dateString) => {

        if (!dates) {

            resetRangePickerValue();
            return;

        }

        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

    };

    // Checkbox 選取
    const handleCheckboxChange = ({ target }) => {

        setSelected({
            ...selected,
            [target.name]: target.checked,
        });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            id: pid,
            groups: Object.keys(selected).filter((id) => selected[id]).map((id) => parseInt(id, 10)),
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        };

        // 起始結束日未填就不送
        if (!reqData.startDate || !reqData.endDate) return;

        Service.projectTimelineDataSearch(reqData)
            .then(({ lists }) => setTimelineLists(lists));

    };

    // 搜尋: 還原所有欄位
    const handleReset = () => {

        setTimelineLists(timelineListStore);
        setSelected({});
        resetRangePickerValue();

    };

    // 還原 RangePicker
    const resetRangePickerValue = () => {

        setStartDate(null);
        setEndDate(null);

    };

    // 清除搜尋欄位
    const handleSearchClear = () => reset(handleReset());

    const { id, name, budget } = info;

    return (

        <Fragment>
            {
                id &&
                    <Link
                        to={`/projects/${id}`}
                        rel="noopener noreferrer"
                        className="timelineDataTitle"
                    >
                        {`P${id} - ${name}`}
                    </Link>
            }

            <Row gutter={20}>
                <Col flex="0 0 400px">
                    <TimelineDataGroupDetail
                        projectBudget={budget}
                        budgetDetail={groupBudgetLists}
                        costDetail={groupCostLists}
                    />
                </Col>

                <Col
                    flex="1"
                    className="timeline"
                >
                    <div className="timelineDataSearch">
                        <form
                            className="searchForm"
                            onSubmit={handleSubmit(handleReqData)}
                        >
                            <TimelineDataSearch
                                selected={selected}
                                groups={groupBudgetLists}
                                startDate={startDate}
                                endDate={endDate}
                                handleRangepicker={handleRangepicker}
                                handleCheckboxChange={handleCheckboxChange}
                                handleSearchClear={handleSearchClear}
                            />
                        </form>

                        <span className="searchResult">
                            共 {timelineLists.length} 筆資料
                        </span>
                    </div>

                    <div className="timelineDataWrap">
                        {
                            showLoading ? <Loading /> : (

                                timelineLists.length ? (

                                    <GanttTimeline
                                        width={1600}
                                        height={'80vh'}
                                        rows={handleDataStructure(timelineLists)}
                                    />

                                ) : <EmptyData />

                            )
                        }
                    </div>
                </Col>
            </Row>
        </Fragment>

    );

};

export default TimelineData;
