import React, { Fragment, useState, useContext } from 'react';
import { Radio } from 'antd';
import { CheckSquareOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Tags from '../../components/Tags';
import Buttons from '../../components/Buttons';
import { Rangepickers } from '../../components/Datepickers';
import { FormRow, OtherErrorMesg } from '../../components/LightboxForm';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import PMB from '../../lib/moon.pmb';

const RadioGroup = Radio.Group;
const { transferHourToTime, transferTimeToHour } = PMB;

// 過濾假別時數
const filterWorkLogHours = (workLogTypes, currWorkLogType = 'estimate') => workLogTypes.filter(({ code }) => code === currWorkLogType)[0] || '';

const WorkLogForm = () => {

    // Context
    const {
        workLogTypes,
        currEvent,
        workLogRemind: { workLogMinutesToday },
        formStorageData,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        createWorkLog,
        updateWorkLog,
    } = useContext(ProjectContext);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // State
    const [storage, setStorage] = useState({ ...formStorageData });
    const [showError, setShowError] = useState(false);

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // Rangepicker: 填工時
    const handleRangepicker = (dates) => {

        const [startDate, endDate] = dates;

        setStorage({
            ...formStorageData,
            ...storage,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        });

    };

    // 工時種類
    const handleWorkLogTypeChange = ({ target }) => {

        setStorage({
            ...formStorageData,
            ...storage,
            type: target.value,
        });

    };

    // 送資料
    const handleReqData = (reqData) => {

        reqData = {
            ...storage,
            ...reqData,
            minutes: transferHourToTime(+reqData.hour, +reqData.minute),
        };

        // 砍掉暫存欄位
        delete reqData.name;
        delete reqData.selectedDate;
        delete reqData.hour;
        delete reqData.minute;

        // 檢查所有欄位是否為空 vs 工時最小單位為 30 分鐘
        if (!Object.keys(reqData).every((key) => reqData[key] !== '') ||
            reqData.minutes < 30) {

            setShowError(true);
            return;

        }
        else setShowError(false);

        if (currEvent === 'updateWorkLog') {

            delete reqData.taskId;
            updateWorkLog(reqData);

        }
        else createWorkLog(reqData);

    };

    const {
        name,
        type,
        minutes,
        startDate,
    } = formStorageData;

    return (

        <form onSubmit={handleSubmit(handleReqData)}>
            <FormRow
                labelTitle="項目名稱"
                className="readOnly"
            >
                <input
                    type="text"
                    defaultValue={name}
                    readOnly
                />
            </FormRow>

            <div className="row">
                {
                    currEvent === 'createWorkLog' ? (

                        <Fragment>
                            <div className="title isRequired">登錄日期 (YYYY/MM/DD)</div>
                            <Rangepickers
                                handleChange={handleRangepicker}
                            />
                        </Fragment>

                    ) : (

                        <FormRow
                            labelTitle="登錄日期 (YYYY/MM/DD)"
                            className="readOnly"
                            notes="無法更改日期，若要更新日期請先刪除後再重新建立"
                        >
                            <input
                                name="startDate"
                                type="text"
                                defaultValue={moment(startDate).format('YYYY/MM/DD')}
                                readOnly={true}
                            />
                        </FormRow>

                    )
                }
            </div>

            <div className="items workLogWrap">
                <div className="row workLogType">
                    <div className="title isRequired">種類</div>
                    <div className="field noBorder">
                        <RadioGroup
                            name="type"
                            className="form-radios"
                            defaultValue={type}
                            onChange={handleWorkLogTypeChange}
                        >
                            {
                                workLogTypes.map(({
                                    id,
                                    code,
                                    name,
                                    colorCode,
                                    colorText,
                                }) => (

                                    <Radio
                                        key={id}
                                        value={code}
                                        className={code === 'estimate' ? 'estimate' : ''}
                                    >
                                        <span>{name}</span>
                                        <Tags
                                            color={colorCode}
                                            textColor={colorText}
                                            text={name}
                                        />
                                        {
                                            (code === 'overtime' || code === 'overtime_honor') &&
                                                <span className="small-text">(限假日)</span>
                                        }
                                        {
                                            (code === 'extra') &&
                                                <span className="small-text">(限平日超過8H)</span>
                                        }
                                    </Radio>

                                ))
                            }
                        </RadioGroup>
                    </div>
                </div>

                <div className="row workLogHours">
                    <div className="title isRequired">工時 <span className="warning-text">(最小單位為 30 分鐘)</span></div>
                    <div className="field noBorder">
                        <span className="field label-hour">
                            <input
                                type="number"
                                name="hour"
                                defaultValue={minutes && transferTimeToHour(minutes).hour}
                                placeholder="小時"
                                ref={register({ required: true, min: 0 })}
                            />
                        </span>
                        <select
                            className="label-minute"
                            name="minute"
                            defaultValue={(minutes && transferTimeToHour(minutes).minute) || '00'}
                            ref={register}
                        >
                            <option value="00">00</option>
                            <option value="30">30</option>
                        </select>
                    </div>

                    {
                        errors.hour && <div className="required">必填欄位</div>
                    }

                    <ul className="workLogReminder">
                        {
                            (currEvent === 'updateWorkLog') &&
                                <li className="reminder-item">
                                    <CheckSquareOutlined />
                                    原時數為 {transferTimeToHour(minutes).hour}h {transferTimeToHour(minutes).minute}m
                                </li>
                        }
                        <li className="reminder-item">
                            <CheckSquareOutlined />
                            今日已填 {transferTimeToHour(workLogMinutesToday).hour}h {transferTimeToHour(workLogMinutesToday).minute}m
                        </li>
                    </ul>
                </div>
            </div>

            {showError && <OtherErrorMesg />}

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default WorkLogForm;
export { filterWorkLogHours };
