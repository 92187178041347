import React, {
    Fragment,
    useEffect,
    createContext,
    useContext,
    useReducer,
} from 'react';

import Prompt from '../../components/Prompt';
import { GlobalContext } from '../global.state';
import { projectReducer } from './project.reducer';

import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { checkObjLength, deferToStuff } = moon;

// Project
const projectInitialState = {
    projectList: {},
    formOpts: {},
    detail: {},
    fileList: [],
    groupBudget: {},
    taskList: [],
    outSourceOpts: [],
    workLog: {},
    myToDo: {},
    otherFee: [],
    searchResult: {
        isSearch: false,
    },
    filterAssigneeOpts: {
        skills: [],
        features: [],
        tools: [],
        result: [],
        isResponse: false,
    },
};

// Create Context
const ProjectContext = createContext(null);

// 建立 Lightbox title
const renderLightboxTitle = (id) => (

    <Fragment>
        刪除
        <span className="small-text">(ID: {id})</span>
    </Fragment>

);

// Provider
const ProjectProvider = ({ children }) => {

    // Context
    const {
        page,
        loadingDispatch,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    //
    const [projectState, projectDispatch] = useReducer(projectReducer, projectInitialState);
    const {
        projectList,
        formOpts,
        detail,
        fileList,
        groupBudget,
        taskList,
        outSourceOpts,
        workLog,
        myToDo,
        otherFee,
        searchResult,
        filterAssigneeOpts,
    } = projectState;

    const { Provider } = ProjectContext;

    useEffect(() => {

        if (!(page === 'myProject' || page === 'projects') && checkObjLength(projectInitialState.formOpts)) return;

        // 表單 opts
        projectFormOpts();

    }, []);

    // 專案列表
    const getProjectList = (reqData) => {

        Service.projectList(reqData)
            .then((resData) => {

                projectDispatch({ type: 'project_list', payload: resData });

            });

    };

    // 取得專案所有選項
    const projectFormOpts = () => {

        Service.getProjectFormOpts()
            .then((resData) => projectDispatch({ type: 'project_form_opts', payload: resData }));

    };

    // 新增專案
    const createProject = (reqData) => {

        Service.createProject(reqData)
            .then((resData) => {

                const obj = (page === 'projects') ? {
                    callback: () => projectDispatch({ type: 'create_project', payload: resData }),
                } : {};

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', obj);

            });

    };

    // 編輯專案 (列表)
    const updateProjectList = (reqData) => {

        Service.updateProjectList(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        projectDispatch({ type: 'update_project_list', payload: resData });

                    },
                });

            });

    };

    // 編輯專案 (詳細頁)
    const updateProject = (reqData) => {

        Service.updateProject(reqData)
            .then(({ detail }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        projectDispatch({ type: 'update_project', payload: detail });

                    },
                });

            });

    };

    // 刪除專案
    const removeProject = ({ id, name }, e) => {

        e.stopPropagation();
        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除專案 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.removeProject({ id })
                    .then(() => projectDispatch({ type: 'remove_project', payload: id }));

            },
        });

    };

    // 取得專案詳細 (詳細頁)
    const projectDetail = (pid) => {

        loadingDispatch({ type: 'loading', payload: true });
        Service.projectDetail({ id: pid })
            .then((resData) => {

                const { tasks, ...noDelayData } = resData;

                projectDispatch({ type: 'project_detail', payload: noDelayData });

                // 刻意延遲
                deferToStuff().then(() => {

                    projectDispatch({ type: 'task_list', payload: tasks });
                    loadingDispatch({ type: 'success' });

                });

            });

    };

    // 取得當前專案所有項目 (我的專案)
    const myToDoList = (userId, id) => {

        Service.toDoList({ userId, id })
            .then(({ lists }) => projectDispatch({ type: 'todo_list', payload: lists, id }));

    };

    // 新增任務 (群組)
    const createTask = (reqData) => {

        Service.createTask(reqData)
            .then((data) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type: 'create_task', payload: data }),
                });

            });

    };

    // 編輯任務 (群組)
    const updateTask = (reqData) => {

        Service.updateTask(reqData)
            .then((data) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type: 'update_task', payload: data }),
                });

            });

    };

    // 刪除任務 (群組)
    const removeTask = ({ id, name }) => {

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.removeTask({ id })
                    .then(() => projectDispatch({ type: 'remove_task', payload: id }));

            },
        });

    };

    // 新增項目
    const createToDoList = (reqData) => {

        Service.createToDoList(reqData)
            .then(({ tasks }) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type: 'create_todo', payload: tasks }),
                });

            });

    };

    // 編輯項目
    const updateToDoList = (reqData) => {

        let obj = {};

        Service.updateToDoList(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        if (page === 'myProject') {

                            obj = {
                                type: 'update_my_todo',
                                payload: { name: reqData.name, pid: resData.pid },
                                id: reqData.id,
                            };

                        }
                        else {

                            obj = {
                                type: 'update_todo',
                                payload: resData.tasks,
                            };

                        }

                        projectDispatch(obj);

                    },
                });

            });

    };

    // 刪除項目
    const removeToDoList = ({ id, name }) => {

        let obj = {};

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service.removeToDoList({ id })
                    .then((resData) => {

                        if (page === 'myProject') {

                            obj = {
                                type: 'remove_my_todo',
                                payload: resData.pid,
                                id,
                            };

                        }
                        else {

                            obj = {
                                type: 'remove_todo',
                                payload: resData.tasks,
                            };

                        }

                        projectDispatch(obj);

                    });

            },
        });

    };

    // 搜尋項目
    const searchToDoList = (reqData) => {

        Service.searchToDoList(reqData)
            .then((resData) => projectDispatch({ type: 'task_search', payload: resData }));

    };

    // 工時列表
    const workLogList = (reqData) => {

        Service.workLogHistoryList(reqData)
            .then((resData) => projectDispatch({ type: 'worklog_list', payload: resData }));

    };

    // 取得當日工時列表
    const aDayWorkLogList = ({ date, taskId }) => {

        Service.aDayWorkLogHistoryList({ date, taskId })
            .then(({ lists }) => projectDispatch({ type: 'worklog_list_a_day', payload: lists }));

    };

    // 新增工時
    const createWorkLog = (reqData) => {

        Service.createWorkLog(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        const type = (page === 'myProject') ? 'create_my_worklog' : 'create_worklog';

                        formStorageDispatch({ type: 'CLEAR' });
                        projectDispatch({
                            type,
                            payload: resData,
                        });

                    },
                });

            });

    };

    // 編輯工時
    const updateWorkLog = (reqData) => {

        Service.updateWorkLog(reqData)
            .then((resData) => {

                const type = (page === 'myProject') ? 'update_my_worklog' : 'update_worklog';

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type, payload: resData }),
                });

            });

    };

    // 刪除工時
    const removeWorkLog = (reqData) => {

        const type = (page === 'myProject') ? 'remove_my_worklog' : 'remove_worklog';

        Prompt('confirm', {
            title: renderLightboxTitle(reqData.id),
            mesg: <span>你確定要刪除嗎？</span>,
            callback: () => {

                Service.removeWorkLog(reqData)
                    .then((resData) => {

                        projectDispatch({
                            type,
                            payload: resData,
                            id: reqData.id,
                        });

                    });

            },
        });

    };

    // 另計支出列表
    const otherFeeList = (pid) => {

        Service.otherFeeList({ pid })
            .then((resData) => {

                projectDispatch({ type: 'other_fee_list', payload: resData });
                loadingDispatch({ type: 'success' });

            });

    };

    // 新增另計支出
    const createOtherFee = (reqData) => {

        Service.createOtherFee(reqData)
            .then((resData) => {

                Prompt('success', {
                    callback: () => {

                        projectDispatch({ type: 'create_other_fee', payload: resData });

                    },
                });

            });

    };

    // 刪除另計支出
    const removeOtherFee = ({ id }) => {

        Service.removeOtherFee({ id: +id })
            .then(({ detail }) => {

                Prompt('success', {
                    callback: () => {

                        projectDispatch({ type: 'remove_other_fee', payload: detail, id });

                    },
                });

            });

    };

    // 上傳檔案
    const uploadFile = (reqData) => {

        Service.uploadFile(reqData)
            .then((resData) => projectDispatch({ type: 'update_file', payload: resData }));

    };

    // 刪除檔案
    const removeFile = ({ pid, id }) => {

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除這個檔案嗎？</span>,
            callback: () => {

                Service.removeFile({ pid, id })
                    .then(() => projectDispatch({ type: 'remove_file', id }));

            },
        });

    };

    // 新增小組預算
    const createGroupBudget = (reqData) => {

        Service.createGroupBudget(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type: 'create_department_budget', payload: resData }),
                });

            });

    };

    // 編輯小組預算
    const updateGroupBudget = (reqData) => {

        Service.updateGroupBudget(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => projectDispatch({ type: 'update_department_budget', payload: resData }),
                });

            });

    };

    // 刪除小組預算
    const removeGroupBudget = ({ pid, id }) => {

        Prompt('confirm', {
            title: renderLightboxTitle(id),
            mesg: <span>你確定要刪除這筆資料嗎？</span>,
            callback: () => {

                Service.removeGroupBudget({ pid, id })
                    .then((resData) => projectDispatch({ type: 'remove_department_budget', payload: resData }));

            },
        });

    };

    // 取得過濾指派人篩選項目
    const getFilterAssigneeOpts = () => {

        Service.getFilterAssigneeOpts()
            .then((resData) => projectDispatch({ type: 'get_filter_assignee_opts', payload: resData }));

    };

    // 過濾指派人
    const filterAssignee = (reqData) => Service.filterAssignee(reqData);

    return (

        <Provider value={{
            // 專案
            projectList,
            formOpts,
            searchResult,
            getProjectList,
            createProject,
            updateProjectList,
            updateProject,
            removeProject,

            // 詳細
            detail,
            projectDetail,

            // 任務(群組)與項目
            taskList,
            outSourceOpts,
            createTask,
            updateTask,
            removeTask,
            createToDoList,
            updateToDoList,
            removeToDoList,
            searchToDoList,

            // 工時
            workLog,
            workLogList,
            aDayWorkLogList,
            createWorkLog,
            updateWorkLog,
            removeWorkLog,

            // 上傳檔案
            fileList,
            uploadFile,
            removeFile,

            // 小組預算
            groupBudget,
            createGroupBudget,
            updateGroupBudget,
            removeGroupBudget,

            // 指派項目 (我的專案)
            myToDo,
            filterAssigneeOpts,
            myToDoList,
            getFilterAssigneeOpts,
            filterAssignee,

            // 另計支出
            otherFee,
            otherFeeList,
            createOtherFee,
            removeOtherFee,

            // Dispatch
            projectDispatch,
        }}>
            {children}
        </Provider>

    );

};

export {
    ProjectContext,
    ProjectProvider,
};
