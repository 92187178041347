import React, {
    Fragment,
    useState,
    useContext,
} from 'react';

import { Checkbox, message } from 'antd';

import Tags from '../../components/Tags';
import Buttons from '../../components/Buttons';

import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import PMB from '../../lib/moon.pmb';

const { collectCheckboxWithNumber } = PMB;

const Settings = () => {

    // Context
    const {
        currEvent,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const {
        areas,
        skills,
        features,
        tools,
        updateFeature,
        removeFeature,
        updateTool,
        removeTool,
    } = useContext(SkillContext);

    // Tab
    const tabItems = [
        // {
        //     key: 'area',
        //     title: '領域',
        //     data: areas,
        // },
        // {
        //     key: 'skill',
        //     title: '技能',
        //     data: skills,
        // },
        {
            key: 'feature',
            title: '特質',
            data: features,
        },
        {
            key: 'tool',
            title: '軟體與工具',
            data: tools,
        },
    ];

    // State
    const [currMenu, setCurrMenu] = useState('feature');
    const [isEdit, setIsEdit] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [collectCheckboxes, setCollectCheckboxes] = useState({});
    const [storage, setStorage] = useState({});

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 點擊當前選單
    const handleClickMenu = (key) => setCurrMenu(key);

    // 新增
    const btnCreate = () => {

        setIsEdit(false);
        setIsRemove(false);
        lightboxDispatch({ type: 'SHOW', currEvent: `create${currMenu[0].toUpperCase() + currMenu.slice(1)}` });

    };

    // 編輯
    const btnUpdate = () => {

        setIsEdit(true);
        setIsRemove(false);
        setCollectCheckboxes({});
        lightboxDispatch({ currEvent: `update${currMenu[0].toUpperCase() + currMenu.slice(1)}` })

    };

    // 刪除
    const btnRemove = () => {

        setIsRemove(true);
        setIsEdit(false);
        lightboxDispatch({ currEvent: `remove${currMenu[0].toUpperCase() + currMenu.slice(1)}` })

    };

    // 刪除 checkbox
    const handleChangeCheckboxes = (id, { target }) => {

        setCollectCheckboxes((prev) => ({
            ...prev,
            [id]: target.checked,
        }));

    };

    // 輸入框
    const handleInputChange = ({ target: { name, value } }) => {

        setStorage({
            ...storage,
            [name]: value,
        });

    };

    // 送資料
    const handleSubmit = (reqData) => {

        const lists = reqData.map((obj) => {

            obj.name = storage[obj.id] || obj.name;
            return obj;

        });

        switch (currEvent) {
            case 'updateFeature':
                return updateFeature({ lists }).then(() => {

                    setIsEdit(false);
                    message.success('編輯成功', 3);

                });

            case 'updateTool':
                return updateTool({ lists }).then(() => {

                    setIsEdit(false);
                    message.success('編輯成功', 3);

                });

            case 'removeFeature':
                return removeFeature({ lists: collectCheckboxWithNumber(collectCheckboxes) }).then(() => {

                    setIsRemove(false);
                    setCollectCheckboxes({}); // 還全 checkbox
                    message.success('刪除成功', 3);

                });

            case 'removeTool':
                return removeTool({ lists: collectCheckboxWithNumber(collectCheckboxes) }).then(() => {

                    setIsRemove(false);
                    setCollectCheckboxes({}); // 還全 checkbox
                    message.success('刪除成功', 3);

                });

            default:
                break;

        }

    };

    // 取消按鈕
    const handleCancel = () => {

        hideModal();
        setIsEdit(false);
        setIsRemove(false);
        setCollectCheckboxes({});

    };

    // Tmpl
    const renderItem = (data) => data.map(({ id, name, colorCode }) => {

        return (

            <li
                key={id}
                className="settings-item-wrap"
            >
                {
                    isRemove ? (

                        <Checkbox
                            name={id}
                            className="checkbox-item"
                            onChange={(e) => handleChangeCheckboxes(id, e)}
                        >
                            {
                                colorCode ? (

                                    <Tags
                                        color={colorCode}
                                        textColor="#000"
                                        text={name}
                                    />

                                ) : <span>{name}</span>
                            }
                        </Checkbox>

                    ) : (

                        <Fragment>
                            <span className="dot"></span>
                            <div className={`item ${isEdit ? 'isEdit' : ''}`}>
                                {
                                    isEdit ? (

                                        <Fragment>
                                            <div className="input-outer">
                                                <input
                                                    type="text"
                                                    name={id}
                                                    defaultValue={name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Fragment>

                                    ) : name
                                }
                            </div>
                        </Fragment>

                    )
                }
            </li>

        );

    });

    return (

        <Fragment>
            <ul className="settingMenu">
                {
                    tabItems.map(({ key, title }) => (

                        <li
                            key={key}
                            className={`menu-item ${(key === currMenu) ? 'on' : ''}`}
                            onClick={() => handleClickMenu(key)}
                        >
                            {title}
                        </li>

                    ))
                }
            </ul>

            <div className="settingContent">
                {
                    tabItems.map(({ key, title, data }) => (

                        <div
                            key={key}
                            className={`settingsContentWrap ${(key === currMenu) ? 'on' : ''}`}
                        >
                            <p className="top">
                                <span className="title">{title}</span>
                                <span onClick={btnCreate}>新增</span>
                                <span onClick={btnUpdate}>編輯</span>
                                <span onClick={btnRemove}>刪除</span>
                            </p>

                            <form
                                className="settings-form-wrap"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <ul className={`settings--${key}`}>
                                    {renderItem(data) || null}
                                </ul>

                                {
                                    (isEdit || isRemove) &&
                                        <div className="row row-btns">
                                            <Buttons
                                                text="取消"
                                                type="default"
                                                handleClick={handleCancel}
                                            />
                                            <Buttons
                                                text="送出"
                                                htmlType="submit"
                                                handleClick={() => handleSubmit(data)}
                                            />
                                        </div>
                                }
                            </form>
                        </div>

                    ))
                }
            </div>
        </Fragment>

    );

};

export default Settings;

/**
 * 多筆
 * https://stackoverflow.com/questions/54676966/push-method-in-react-hooks-usestate
 */
