import React, {
    createContext,
    useReducer,
    useContext,
} from 'react';
import { message } from 'antd';
import Prompt from '../../components/Prompt';
import { GlobalContext } from '../global.state';
import { memberReducer, formStorageReducer } from './member.reducer';
import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { deferToStuff } = moon;

// Member
const memberInitialState = {
    memberList: [],
    searchResult: {
        isSearch: false,
        list: [],
    },
    profile: {},
    salary: [],
    groupHistory: [],
};

// Form values
const formStorageInitialState = {
    formStorageData: {},
};

// Create Context
const MemberContext = createContext(null);

// Provider
const MemberProvider = ({ children }) => {

    // Context
    const {
        page,
        loadingDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const [memberState, memberDispatch] = useReducer(memberReducer, memberInitialState);
    const [formStorageState, formStorageDispatch] = useReducer(formStorageReducer, formStorageInitialState);
    const {
        memberList,
        searchResult,
        profile,
        salary,
        groupHistory,
    } = memberState;

    const { formStorageData } = formStorageState;
    const { Provider } = MemberContext;

    // 員工列表
    const getMemberList = (reqData) => {

        Service.memberList(reqData)
            .then(({ lists }) => memberDispatch({ type: 'MEMBER_LIST', payload: lists }));

    };

    // 新增員工
    const createMember = (reqData) => {

        Service.memberCreate(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        memberDispatch({ type: 'CREATE_MEMBER', payload: resData });

                    },
                });
            });

    };

    // 編輯員工
    const updateMember = (reqData) => {

        Service.updateProfile(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        memberDispatch({
                            type: (page === 'profile') ? 'UPDATE_PROFILE' : 'UPDATE_MEMBER',
                            payload: { resData, userId: reqData.userId },
                        });

                    },
                });
            });

    };

    // 個人資料
    const getProfile = (reqData) => {

        Service.profile(reqData)
            .then((resData) => {

                // 刻意延遲
                deferToStuff().then(() => {

                    loadingDispatch({ type: 'HIDE' });
                    memberDispatch({ type: 'PROFILE', payload: resData });

                });

            });

    };

    // 個人薪資
    const getSalary = (reqData) => {

        Service.salary(reqData)
            .then(({ lists }) => memberDispatch({ type: 'SALARY_LIST', payload: lists }));

    };

    // 新增薪資
    const createSalary = (reqData) => {

        Service.createSalary(reqData)
            .then((resData) => {

                lightboxDispatch({ type: 'HIDE' });
                Prompt('success', {
                    callback: () => {

                        formStorageDispatch({ type: 'CLEAR' });
                        memberDispatch({ type: 'CREATE_SALARY', payload: resData });

                    },
                });

            });

    };

    // 刪除薪資
    const removeSalary = (reqData) => {

        Prompt('confirm', {
            title: '刪除',
            mesg: <span>你確定要刪除這筆資料嗎？</span>,
            callback: () => {

                Service.removeSalary(reqData)
                    .then(() => memberDispatch({ type: 'REMOVE_SALARY', payload: reqData.salaryId }));

            },
        });

    };

    // 組別異動記錄列表
    const getGroupHistory = (reqData) => {

        Service.groupHistory(reqData)
            .then(({ lists }) => memberDispatch({ type: 'GROUP_HISTORY_LIST', payload: lists }));

    };

    // 新增組別異動記錄
    const createGroupHistory = async(reqData) => {

        Service.createGroupHistory(reqData)
            .then((resData) => {

                memberDispatch({ type: 'CREATE_GROUP_HISTORY', payload: resData });

            });

    };

    // 刪除組別異動記錄
    const removeGroupHistory = (reqData) => {

        Prompt('confirm', {
            title: '刪除',
            mesg: <span>你確定要刪除這筆資料嗎？</span>,
            callback: () => {

                Service.removeGroupHistory(reqData)
                    .then(() => {

                        memberDispatch({ type: 'REMOVE_GROUP_HISTORY', payload: reqData.id });
                        message.success('更新成功!');

                    });

            },
        });

    };

    return (

        <Provider value={{
            // 所有員工
            memberList,
            searchResult,
            getMemberList,
            createMember,
            updateMember,

            // 個人資料
            profile,
            getProfile,

            // 薪資
            salary,
            getSalary,
            createSalary,
            removeSalary,

            // 組別異動記錄
            groupHistory,
            getGroupHistory,
            createGroupHistory,
            removeGroupHistory,

            // Form
            formStorageData,

            // Dispatch
            memberDispatch,
            formStorageDispatch,
        }}>
            {children}
        </Provider>

    );

};

export {
    MemberContext,
    MemberProvider,
};
