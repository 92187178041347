import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './Organize.scss';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Buttons from '../../../components/Buttons';
import Tables from '../../../components/Tables';
import moon from '../../../lib/moon';
import Service from '../../../lib/moon.service';

const { renderWithoutValue } = moon;

// 整理結構
const arrangeData = (data) => data.reduce((acc, {
    id,
    name,
    departmentId,
    departmentName,
    companyId,
    companyName,
}) => {

    if (departmentId === null) departmentId = 'none';
    acc[departmentId] = acc[departmentId] || {};
    acc[departmentId].department = departmentName || '';
    acc[departmentId].companyId = companyId;
    acc[departmentId].company = companyName || '';
    acc[departmentId].groups = acc[departmentId].groups || [];
    acc[departmentId].groups.push({ id, name });

    return acc;

}, {});

// 整理成 Ant Design 要的格式
const arrangeToAntdTableFilter = (data) => data.reduce((arr, { id, name }) => {

    const obj = {
        text: name,
        value: id,
    };

    arr.push(obj);
    return arr;

}, []);

//
const OrganizeList = () => {

    let history = useHistory();

    // Data
    const [company, setCompany] = useState([]);
    const [department, setDepartment] = useState([]);
    const [lists, setLists] = useState({});

    useEffect(() => {

        document.title = '組織架構表';

        Service.organizeList()
            .then(handleFetchData);

    }, []);

    // 表格欄位
    const columns = [
        {
            title: '公司',
            render: (key) => renderWithoutValue(lists[key].company),
            filters: arrangeToAntdTableFilter(company),
            onFilter: (value, data) => (lists[data].companyId === value),
        },
        {
            title: '部門',
            render: (key) => renderWithoutValue(lists[key].department),
            filters: arrangeToAntdTableFilter(department),
            onFilter: (value, data) => (+data === value),
        },
        {
            title: '組別',
            render: (key) => lists[key].groups.map(({ id, name }) => (

                <div key={id}>{name}</div>

            )),
        },
    ];

    // 處理 ajax 回傳
    const handleFetchData = ({ company, department, lists }) => {

        setCompany(company);
        setDepartment(department);
        setLists(arrangeData(lists));

    };

    return (

        <Fragment>
            <Breadcrumbs />

            <div className="content-header">
                <h1 className="title">組織架構大表</h1>
                <Buttons
                    text="管理組織架構"
                    type="default"
                    onClick={() => history.push(`/setting/organize`)}
                />
            </div>

            <Tables
                columns={columns}
                data={Object.keys(lists)}
                rowKey={(record) => record}
            />
        </Fragment>

    );

};

export default OrganizeList;
