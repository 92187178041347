import React, {
    Fragment,
    useEffect,
    useContext,
} from 'react';

import PropTypes from 'prop-types';

import './MyProject.scss';
import Tab from '../../components/Tab';
import Tags from '../../components/Tags';
import Buttons from '../../components/Buttons';
import LightboxForm from '../../components/LightboxForm';
import ProjectForm from '../../components/project/ProjectForm';
import ToDoListForm from '../../components/project/ToDoListForm';
import WorkLogForm from '../../components/project/WorkLogForm';
import List from './List';
import TimelineDataOwn from './TimelineDataOwn';

import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import moon from '../../lib/moon';
import moonConst from '../../lib/moon.const';

const { checkObjLength } = moon;
const { memberWorkStatus, lightboxTitle } = moonConst;

// 在職狀態
const WorkStatus = ({ workStatus }) => (

    <Tags
        color={`#${memberWorkStatus[workStatus].color}`}
        text={memberWorkStatus[workStatus].text}
    />

);

const MyProject = (props) => {

    const { match: { params} } = props;
    const userId = +params.userId;

    // Context
    const {
        user,
        hasAuth: { edit_all_projects, view_personal_cost },
        visible,
        currEvent,
        formStorageDispatch,
        lightboxDispatch,
        popConfirmDispatch,
    } = useContext(GlobalContext);

    const {
        projectList,
        getProjectList,
    } = useContext(ProjectContext);

    useEffect(() => {

        // 列表
        getProjectList({ userId });

    }, [userId]);

    // Title
    const renderUserName = (name) => `${user.userId === +userId ? '我' : name}的專案`;

    //
    const renderUserInfo = ({ nameChinese, workStatus }) => (

        <Fragment>
            {renderUserName(nameChinese)}
            <WorkStatus workStatus={workStatus} />
        </Fragment>

    );

    // tabData
    const isCanViewTimeLine = userId === user.userId || view_personal_cost;
    const tabData = {
        project: {
            name: '所有專案',
            component: <List userId={userId} />,
        },
    }
    if (isCanViewTimeLine) {
        tabData.timeline = {
            name: '工時圖表',
            component: <TimelineDataOwn userId={userId} />,
        }
    }

    // 為了接收 Store 的值才寫在這裡
    document.title = checkObjLength(projectList) && renderUserName(projectList.nameChinese);

    // Tab 點擊事件
    const handleClickTab = () => popConfirmDispatch({ type: 'HIDE' });

    // 隱藏 Modal
    const hideModal = () => {

        formStorageDispatch({ type: 'CLEAR' });
        lightboxDispatch({ type: 'HIDE' });

    };

    // 新增專案
    const btnCreateProject = () => lightboxDispatch({ type: 'SHOW', currEvent: 'createProject' });

    // Lightbox 身體
    const renderLightboxContent = () => {

        switch (currEvent) {
            case 'createToDoList':
            case 'updateToDoList':
                return <ToDoListForm />;

            case 'createWorkLog':
            case 'updateWorkLog':
                return <WorkLogForm />;

            default:
                return <ProjectForm />;
        }

    };

    return (

        <Fragment>
            <div className="myProject">
                <div className="myProjectHeader">
                    <h1 className="title">{projectList?.workStatus && renderUserInfo(projectList)}</h1>

                    {
                        edit_all_projects &&
                            <Buttons
                                text="新增專案"
                                handleClick={btnCreateProject}
                            />
                    }
                </div>

                <Tab
                    className="myProjectTab"
                    onChange={handleClickTab}
                    tabData={tabData}
                />
            </div>

            {
                // Lightbox (新增、編輯 => 專案/任務/項目)
                visible &&
                    <LightboxForm
                        title={lightboxTitle[currEvent]}
                        visible={visible}
                        handleCancel={hideModal}
                        {
                            ...(currEvent === 'createProject' || currEvent === 'updateProject') ? { width: 680 } : null
                        }
                    >
                        {renderLightboxContent()}
                    </LightboxForm>
            }
        </Fragment>

    );

};

MyProject.propTypes = {
    match: PropTypes.object,
};

export default MyProject;
