import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import Tags from '../../components/Tags';

import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';
import moonConst from '../../lib/moon.const';
import PMB from '../../lib/moon.pmb';

const { scoreConfig } = moonConst;
const { handleAreaData } = PMB;

// 個人技能項目
const Item = ({
    data: { areaId, id, name, score },
    listData,
}) => (

    <div className="personal-skill-row">
        <Tags
            color={listData?.color}
            textColor="#000"
            text={listData?.name}
        />

        <span>{name}</span>

        <Progress
            className={`score ${scoreConfig[score]?.name}-place`}
            size="small"
            strokeColor={score > 7 ? scoreConfig[score]?.color : null}
            percent={score * 10}
            format={(score) => `${score / 10}分`}
        />
    </div>

);

// 標籤
const Label = ({ data: { id, name } }) => <span className="label">{name}</span>;

const PersonalSkillList = ({
    btnUpdatePersonalSkill,
    btnScoreSkill,
    btnManageLabel,
}) => {

    // Context
    const {
        hasAuth: { edit_skill_tree },
    } = useContext(GlobalContext);

    const {
        areas,
        personalSkill: {
            userId,
            nameChinese,
            skills,
            features,
            tools,
        },
    } = useContext(SkillContext);

    return (

        <Fragment>
            <h2>
                <span className="name">{nameChinese}的技能表</span>

                <Link
                    to={`/myProject/${userId}`}
                    className="username"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    (查看個人專案)
                </Link>
            </h2>

            <div className="link-actions">
                {
                    edit_skill_tree &&
                        <Fragment>
                            <span
                                className="btn-action"
                                onClick={() => btnUpdatePersonalSkill('edit')}
                            >
                                管理員工技能
                            </span>

                            <span
                                className="btn-action"
                                onClick={() => btnScoreSkill({ userId, nameChinese })}
                            >
                                技能評分
                            </span>

                            <span
                                className="btn-action"
                                onClick={() => btnManageLabel({ userId, nameChinese })}
                            >
                                特質與工具
                            </span>
                        </Fragment>
                }
            </div>

            {
                (features && features.length > 0) &&
                    <div className="personalLabelFeature">
                        <p>特質</p>
                        <div className="labelWrap">
                            {
                                features && features.map((obj, idx) => (

                                    <Label
                                        key={idx}
                                        data={obj}
                                    />

                                ))
                            }
                        </div>
                    </div>
            }

            {
                (tools && tools.length > 0) &&
                    <div className="personalLabelTool">
                        <p>擅長工具</p>
                        <div className="toolWrap">
                            {
                                tools.map((obj, idx) => (

                                    <Label
                                        key={idx}
                                        data={obj}
                                    />

                                ))
                            }
                        </div>
                    </div>
            }

            <div className="personalSkillList">
                <p>評分</p>
                <div className="skillScoreWrap">
                    {
                        skills && skills.map((obj, idx) => (

                            <Item
                                key={idx}
                                data={obj}
                                listData={handleAreaData(areas)[obj.areaId]}
                            />

                        ))
                    }
                </div>
            </div>
        </Fragment>

    );

};

export default PersonalSkillList;
