import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

const Drawers = (props) => {

    const {
        width,
        className,
        title,
        handleClose,
        children,
    } = props;

    return (

        <Drawer
            width={width}
            className={`drawerWrap ${className ? className : ''}`}
            placement="right"
            title={
                <h2 className="title">{title}</h2>
            }
            visible={true}
            closable={false}
            onClose={handleClose}
        >
            {children}
        </Drawer>

    );

};

Drawers.defaultProps = {
    width: 360,
    className: '',
    title: 'title',
};

Drawers.propTypes = {
    width: PropTypes.number,
    className: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export {
    Drawers as default,
}
