import React, { useState, useContext } from 'react';
import { Select } from 'antd';
import { useForm } from 'react-hook-form';
import Buttons from '../Buttons';
import Tags from '../Tags';
import { GlobalContext } from '../../context/global.state';
import { ProjectContext } from '../../context/project/project.state';
import { Fragment } from 'react';

const { Option } = Select;

// 項目外層
const Item = (props) => (

    <div className="item-wrap">
        <Select
            mode="multiple"
            className="multiple-select"
            placeholder={props.placeholder ?? '請輸入關鍵字'}
            onChange={props.handleChangeOpts}
            filterOption={props.handleFilterOption}
            allowClear
        >
            {props.children}
        </Select>
    </div>

);

//
const renderOpts = (opts, withTag = false) => opts && opts.map((data, idx) => (

    <Option
        key={idx}
        value={withTag ? data.skillId : data.id}
        data-value={withTag ? `${data.areaName}${data.skillName}` : data.name}
    >
        {
            withTag ? (

                <Fragment>
                    <Tags
                        color={data.areaColor}
                        textColor="#000"
                        text={data.areaName}
                    />
                    {data.skillName}
                </Fragment>

            ) : data.name
        }
    </Option>

));

//
const FilterAssigneeForm = () => {

    // Context
    const {
        lightboxDispatch,
        formStorageData: { prevStep },
    } = useContext(GlobalContext);

    const {
        filterAssigneeOpts: { skills, features, tools },
        filterAssignee,
        projectDispatch,
    } = useContext(ProjectContext);

    // React Hook Form
    const { handleSubmit } = useForm();

    // State
    const [storage, setStorage] = useState({});

    // 返回前一個 lightbox
    const handleReturnToPrevious = () => lightboxDispatch({ type: 'SHOW', currEvent: prevStep });

    // 下拉選單 filter
    const handleFilterOption = (value, option) => {

        const regex = new RegExp(value);
        return regex.test(option['data-value']);

    };

    // 下拉選單 change
    const handleChangeOpts = (value, option, curr) => setStorage({ ...storage, [curr]: value });

    // 送資料
    const handleReqData = (reqData) => {

        reqData = { ...storage };

        filterAssignee(reqData)
            .then(({ lists }) => {

                // 無結果僅停留在當前畫面
                if (!lists.length) {

                    alert('沒有結果');
                    return;

                }

                lightboxDispatch({ type: 'SHOW', currEvent: prevStep });
                projectDispatch({ type: 'filter_assignee', payload: lists });

            });

    };

    return (

        <form
            className="form-filter-assignee"
            onSubmit={handleSubmit(handleReqData)}
        >
            <div className="row-filter-assignee-item">
                <p>技能</p>
                <Item
                    placeholder="請輸入領域或技能關鍵字"
                    handleFilterOption={handleFilterOption}
                    handleChangeOpts={(value, option) => handleChangeOpts(value, option, 'skills')}
                >
                    {renderOpts(skills, true)}
                </Item>
            </div>

            <div className="row-filter-assignee-item">
                <p>特質</p>
                <Item
                    placeholder="請輸入特質關鍵字"
                    handleFilterOption={handleFilterOption}
                    handleChangeOpts={(value, option) => handleChangeOpts(value, option, 'features')}
                >
                    {renderOpts(features)}
                </Item>
            </div>

            <div className="row-filter-assignee-item">
                <p>擅長工具</p>
                <Item
                    placeholder="請輸入工具關鍵字"
                    handleFilterOption={handleFilterOption}
                    handleChangeOpts={(value, option) => handleChangeOpts(value, option, 'tools')}
                >
                    {renderOpts(tools)}
                </Item>
            </div>

            <div className="row row-btns">
                <Buttons
                    text="返回"
                    type="secondary"
                    handleClick={handleReturnToPrevious}
                />
                <Buttons
                    text="確定"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default FilterAssigneeForm;
