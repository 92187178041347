import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import './CalendarTool.scss';
import { Store } from '../Wrap';
import moonConst from '../lib/moon.const';

const { RangePicker } = DatePicker;
const { startDateText, endDateText } = moonConst;

const renderDateOfPicker = ({ holidays, currentDate }) => {

    const targetHoliday = holidays.filter((obj) => obj.date === moment(currentDate).format('YYYY-MM-DD'))[0] || '';
    const { isHoliday, name } = targetHoliday;

    return (

        // 判斷補班
        <span
            className={`date-picker-cell ${isHoliday ? 'isHoliday' : ''}`}
            {...!!(!isHoliday && name) && { 'data-work-day': '' }} // 特殊補班日
        >
            {currentDate.date()}
        </span>

    );

};

const Datepickers = ({ name, defaultDate, handleChange, ...rest }) => {

    const { calendarList } = useContext(Store);

    return (

        <DatePicker
            name={name}
            placeholder="請選擇日期"
            format="YYYY/MM/DD"
            className="date-picker-input"
            dropdownClassName="date-picker-box"
            defaultValue={defaultDate}
            defaultPickerValue={defaultDate}
            onChange={handleChange}
            dateRender={(currentDate) => renderDateOfPicker({ holidays: calendarList, currentDate })}
            showToday={true}
            allowClear={false}
            locale={{
                lang: {
                    locale: 'zh-tw',
                    today: '今天',
                    yearFormat: 'YYYY 年',
                    monthFormat: 'MM 月',
                }
            }}
            {...rest}
        />

    );
};

const Rangepickers = ({
    defaultDate,
    handleChange,
    placeholder,
    allowClear,
    allowEmpty,
    value,
}) => {

    const { calendarList } = useContext(Store);

    return (

        <RangePicker
            placeholder={placeholder}
            ranges={{
                '今天': [moment(), moment()],
                '這個月': [moment().startOf('month'), moment().endOf('month')],
            }}
            format="YYYY/MM/DD"
            defaultValue={defaultDate}
            value={value}
            className="range-picker-input"
            dropdownClassName="range-picker-box"
            onChange={handleChange}
            dateRender={(currentDate) => renderDateOfPicker({ holidays: calendarList, currentDate })}
            allowClear={allowClear}
            allowEmpty={allowEmpty}
            locale={{
                lang: {
                    locale: 'zh-tw',
                    yearFormat: 'YYYY 年',
                    monthFormat: 'MM 月',
                }
            }}
        />

    );

};

const Timepickers = ({
    name,
    dateTime,
    disabledMinutes,
    handleChange,
}) => {

    return (

        <TimePicker
            name={name}
            placeholder="請選擇時間"
            format="HH:mm"
            className="time-picker-input"
            defaultValue={dateTime}
            disabledMinutes={disabledMinutes}
            showNow={false}
            onChange={handleChange}
            locale={{
                lang: {
                    locale: 'zh-tw',
                    now: '現在',
                    ok: '確定'
                }
            }}
        />

    );

};

Datepickers.defaultProps = {
    defaultDate: null,
    defaultPickerValue: null,
};

Datepickers.propTypes = {
    name: PropTypes.string.isRequired,
    defaultDate: PropTypes.any,
    defaultPickerValue: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
};

Rangepickers.defaultProps = {
    defaultDate: [],
    placeholder: [startDateText, endDateText],
    allowClear: false,
    allowEmpty: [false, false],
};

Rangepickers.propTypes = {
    defaultDate: PropTypes.array,
    placeholder: PropTypes.array,
    allowClear: PropTypes.bool,
    allowEmpty: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
};

Timepickers.propTypes = {
    dateTime: PropTypes.string,
};

export {
    Datepickers as default,
    Rangepickers,
    Timepickers,
};
