import React, { Fragment, useState, useEffect } from 'react';
import { Collapse } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

import Breadcrumbs from '../../components/Breadcrumbs';
import Buttons from '../../components/Buttons';
import Prompt from '../../components/Prompt';
import LightboxForm, { FormRow } from '../../components/LightboxForm';
import Collapses from '../../components/Collapses';
import Tables from '../../components/Tables';
import moon from '../../lib/moon';
import Service from '../../lib/moon.service';

const { Panel } = Collapse;
const { renderWithoutValue } = moon;

// 此頁清單 type config
const listTypeMapping = {
    roles: {
        text: '角色',
        delete: 'roleDelete',
        update: 'roleUpdate',
        create: 'roleCreate',
    },
};

// Service
async function fetchData () {

    return await Service.groupsRolesSkillsList();

}

// 表格欄位: 組別、角色、技能
const columnData = ({ btnUpdate, handleDelete, currType }) => ([
    {
        title: 'ID',
        dataIndex: 'id',
        width: 120,
        align: 'center',
    },
    {
        title: '名稱',
        dataIndex: 'name',
    },
    {
        title: '代碼',
        dataIndex: 'code',
        render: (code) => renderWithoutValue(code),
    },
    {
        title: '操作',
        dataIndex: '',
        width: 200,
        render: (data) => (

            <Fragment>
                <Buttons
                    type="secondary"
                    text="編輯"
                    handleClick={(e) => btnUpdate(data,  currType, e)}
                />
                <Buttons
                    type="danger"
                    text="刪除"
                    handleClick={(e) => handleDelete(data,  currType, e)}
                />
            </Fragment>
        ),
    },
]);

const Groups = () => {

    // State
    const [visible, setVisible] = useState(false);
    const [currBtnType, setCurrBtnType] = useState('');
    const [currListsType, setCurrListsType] = useState('');

    // Data
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [skills, setSkills] = useState([]);
    const [storage, setStorage] = useState({});

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    useEffect(() => {

        document.title = '角色';

        fetchData()
            .then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = ({ departments, roles, skills }) => {

        setDepartments(departments);
        setRoles(roles);
        setSkills(skills);

    };

    // 顯示 Modal
    const showModal = () => setVisible(true);

    // 隱藏 Modal
    const hideModal = () => {

        setVisible(false);
        setStorage({});

    };

    // 建立新增按鈕
    const renderCreateBtn = (type) => (

        <div
            className="btn-create"
            onClick={(e) => btnCreate(e, type)}
        >
            <PlusOutlined />
        </div>

    );

    // 新增按鈕
    const btnCreate = (e, type) => {

        e.stopPropagation();
        setCurrListsType(type);
        setCurrBtnType('create');
        showModal();

    };

    // 編輯按鈕
    const btnUpdate = (obj, type) => {

        setCurrListsType(type);
        setCurrBtnType('update');
        setStorage(obj);
        showModal();

    };

    // 刪除
    const handleDelete = ({ id, name }, type) => {

        // 更新 state type
        setCurrListsType(type);

        Prompt('confirm', {
            title: (
                <Fragment>
                    刪除{listTypeMapping[type].text}
                    <span className="small-text">(ID: {id})</span>
                </Fragment>
            ),
            mesg: <span>你確定要刪除 <span className="data">{name}</span> 嗎？</span>,
            callback: () => {

                Service[listTypeMapping[type].delete]({ id })
                    .then(() => {

                        Prompt('success', {
                            callback: () => {

                                switch (type) {
                                    case 'departments':
                                        setDepartments(departments.filter((obj) => obj.id !== id));
                                        break;

                                    case 'roles':
                                        setRoles(roles.filter((obj) => obj.id !== id));
                                        break;

                                    default:
                                        setSkills(skills.filter((obj) => obj.id !== id));
                                        break;
                                }

                            },
                        });

                    });

            },
        });

    };

    // 送資料 (新增、編輯)
    const handleReqData = (reqData) => {

        reqData = {
            ...storage,
            ...reqData,
        };

        Service[listTypeMapping[currListsType][currBtnType]](reqData)
            .then((data) => {

                hideModal();

                Prompt('success', {
                    callback: () => {

                        // 編輯
                        if (currBtnType === 'update') {

                            // 組別
                            if (currListsType === 'departments') {

                                setDepartments(departments.map((obj) => {

                                    return (obj.id === id) ? obj = { ...reqData } : obj;

                                }));

                            }

                            // 角色
                            if (currListsType === 'roles') {

                                setRoles(roles.map((obj) => {

                                    return (obj.id === id) ? obj = { ...reqData } : obj;

                                }));

                            }

                            // 技能
                            if (currListsType === 'skills') {

                                setSkills(skills.map((obj) => {

                                    return (obj.id === id) ? obj = { ...reqData } : obj;

                                }));

                            }

                        }

                        // 新增
                        if (currBtnType === 'create') {

                            // 組別
                            if (currListsType === 'departments') {

                                setDepartments([ ...departments, { ...data } ]);

                            }

                            // 角色
                            if (currListsType === 'roles') {

                                setRoles([ ...roles, { ...data } ]);

                            }

                            // 技能
                            if (currListsType === 'skills') {

                                setSkills([ ...skills, { ...data } ]);

                            }

                        }

                    },
                });

            });

    };

    const { id, name, code } = storage;

    return (

        <Fragment>

            <Breadcrumbs />

            <Collapses
                defaultKey={['departments', 'roles', 'skills']}
                withTopSpace={true}
            >

                {/* 組別 */}
                {/* <Panel
                    key="departments"
                    className="panelWrap"
                    header="組別"
                    extra={renderCreateBtn('departments')}
                >
                    <Tables
                        columns={columnData({ btnUpdate, handleDelete, currType: 'departments' })}
                        data={departments}
                        rowKey="id"
                        showTitle={true}
                        showPage={false}
                    />
                </Panel> */}

                {/* 角色 */}
                <Panel
                    key="roles"
                    className="panelWrap"
                    header="角色"
                    extra={renderCreateBtn('roles')}
                >
                    <Tables
                        columns={columnData({ btnUpdate, handleDelete, currType: 'roles' })}
                        data={roles}
                        rowKey="id"
                        showTitle={true}
                        showPage={false}
                    />
                </Panel>

            </Collapses>

            {
                // Lightbox (新增、編輯)
                visible &&
                    <LightboxForm
                        title={
                            currBtnType === 'update' ? (

                               <Fragment>
                                   編輯{listTypeMapping[currListsType].text}
                                   <span className="small-text">(ID: {id})</span>
                               </Fragment>

                            ) : `新增${listTypeMapping[currListsType].text}`
                        }
                        visible={visible}
                        handleCancel={hideModal}
                    >
                        <form onSubmit={handleSubmit(handleReqData)}>
                            <FormRow
                                labelTitle="名稱"
                                required={true}
                                error={errors.name && true}
                            >
                                <input
                                    type="text"
                                    name="name"
                                    defaultValue={name}
                                    ref={register({ required: true })}
                                />
                            </FormRow>

                            <FormRow
                                labelTitle="代碼"
                                required={true}
                                error={errors.code && true}
                                notes="若為組合字請用駝峰式命名，Ex. needToConfirm"
                                {...(currBtnType === 'update') && { className: 'disabled' }}
                            >
                                <input
                                    type="text"
                                    name="code"
                                    defaultValue={code}
                                    ref={register({ required: true })}
                                    {...(currBtnType === 'update') && { disabled: true }}
                                />
                            </FormRow>

                            <div className="row row-btns">
                                <Buttons
                                    text="取消"
                                    type="secondary"
                                    handleClick={hideModal}
                                />
                                <Buttons
                                    text="送出"
                                    htmlType="submit"
                                />
                            </div>
                        </form>
                    </LightboxForm>
            }

        </Fragment>

    );

};

export {
    Groups as default,
};
