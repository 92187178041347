import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
    createContext,
} from 'react';

import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import {
    faCalendarDays,
    faClipboardList,
    faCalendarTimes,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './Home.scss';
import Buttons from '../../components/Buttons';
import FontIcon from '../../components/FontIcon';
import Prompt from '../../components/Prompt';
import Cards from '../../components/Cards';
import Loading from '../../components/Loading';
import LightboxForm from '../../components/LightboxForm';
import { EmptyData } from '../../containers/PageBroken';

import MyWorkLog from './MyWorkLog';
import MyTasks from './MyTasks';
import WorkLogForm from './WorkLogForm';
import TodayDayoff from './TodayDayoff';
import LeaveTypeForm from './LeaveTypeForm';

import { GlobalContext } from '../../context/global.state';
import moonConst from '../../lib/moon.const';
import Service from '../../lib/moon.service';
import PMB from '../../lib/moon.pmb';

const {
    bulletinPath,
    today,
    breadcrumb,
} = moonConst;

const { transferHourToTime } = PMB;

// Lightbox 種類設定
const CONFIG = {
    leave: '編輯假',
    workLog: '編輯工時',
    fill: '填工時',
};

// Context
const HomeStore = createContext({});

// 整理資料成月曆結構
const transferDataToCalendar = (lists) => lists.reduce((obj, { date, items }) => {

    obj[date] = items;
    return obj;

}, {});

// Service
async function fetchData () {

    return await Service.dashboard({ limit: 7 });

}

//
const Home = () => {

    // Context
    const { user, getWorkLogRemind } = useContext(GlobalContext);

    // State
    const [visible, setVisible] = useState(false);
    const [showError, setShowError] = useState(false);
    const [currWorkLogType, setCurrWorkLogType] = useState('estimate');
    const [currEvent, setCurrEvent] = useState('');
    const [showLoading, setShowLoading] = useState({
        bulletin: true,
        lazyRate: true,
        myTasks: true,
        dayOffs: true,
        myWorkLog: true,
        demoList: true,
    });

    const [selectedDate, setSelectedDate] = useState('');
    const [showDetailTip, setShowDetailTip] = useState(false);
    const [currBtnType, setCurrBtnType] = useState('');

    // Data
    const [bulletinList, setBulletinList] = useState([]);
    const [lazyRate, setLazyRate] = useState(0);
    const [myTasks, setMyTasks] = useState([]);
    const [dayOffs, setDayOffs] = useState([]);
    const [demoList, setDemoList] = useState([]);
    const [storage, setStorage] = useState({});
    const [currTask, setCurrTask] = useState({});
    const [currWorkLog, setCurrWorkLog] = useState({});
    const [subItems, setSubItems] = useState({});
    const [workLogHistoryList, setWorkLogHistoryList] = useState([]);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    useEffect(() => {

        document.title = breadcrumb.dashboard;

        fetchData()
            .then(handleFetchData);

    }, []);

    // 處理 ajax 回傳
    const handleFetchData = (data) => {

        const {
            bulletins,
            lazyRate,
            myTasks,
            dayOffs,
            myWorkLogs,
            demos,
        } = data;

        setBulletinList(bulletins);
        setLazyRate(lazyRate);
        setMyTasks(myTasks);
        setDayOffs(dayOffs);
        setDemoList(demos);

        // 每日子項目
        setSubItems(transferDataToCalendar(myWorkLogs));

        // 陣列無長度變回預設值
        setShowLoading({
            bulletin: false,
            lazyRate: false,
            myTasks: false,
            dayOffs: false,
            myWorkLog: false,
            demoList: false,
        });

    };

    // 顯示 Modal
    const showModal = () => setVisible(true);

    // 隱藏 Modal
    const hideModal = () => {

        setStorage({});
        setCurrBtnType('');
        setShowError(false);
        setVisible(false);

    };

    // Rangepicker
    const handleRangepicker = (dates) => {

        const [startDate, endDate] = dates;

        setStorage({
            ...storage,
            workLogStartDate: moment(startDate).format('YYYY-MM-DD'),
            workLogEndDate: moment(endDate).format('YYYY-MM-DD'),
        });

    };

    // 工時種類
    const handleWorkLogTypeChange = ({ target }) => {

        setStorage({
            ...storage,
            workLogType: target.value,
        });

        // 為了過濾時數而暫存的 state
        setCurrWorkLogType(target.value);

    };

    // 工時時數
    const handleWorkLogHoursChange = ({ target }) => {

        setStorage({
            ...storage,
            workLogHours: target.value,
        });

    };

    // 填工時按鈕
    const btnCreateWorkLog = ({ taskId, name }, e) => {

        e.stopPropagation();
        e.preventDefault();
        setCurrBtnType('fill');
        setCurrEvent('updateWorkLog');

        getWorkLogRemind({ userId: user.userId });

        setStorage({
            taskId, // 項目 ID
            workLogStartDate: today,
            workLogEndDate: '',
        });

        setCurrTask({ taskId, name });
        showModal();

    };

    // 行事曆選取日期 (後執行)
    const handleSelectedDate = (date) => setSelectedDate(moment(date).format('YYYY-MM-DD'));

    // 行事曆點擊事件 (先執行)
    const handleClickDate = ({ currentTarget }) => {

        const { dataset } = currentTarget;

        resetHistoryList();

        // 若找不到子項目就不做任何事
        if (!subItems[dataset.date]?.length) return;

        setShowDetailTip(true);

        Service.aDayWorkLogList({ date: dataset.date })
            .then(({ lists }) => setWorkLogHistoryList(lists));

    };

    // 切換月份
    const handleChangeMonth = (param) => {

        handleCancelDetailTip();

        Service.aMonthWorkLogList({ date: param })
            .then(({ lists }) => setSubItems(transferDataToCalendar(lists)));

    };

    // 關閉 Detail tip
    const handleCancelDetailTip = () => setShowDetailTip(false);

    // 還原行事曆 popconfirm 資料
    const resetHistoryList = () => {

        setWorkLogHistoryList([]);
        setShowDetailTip(false);

    };

    // 編輯工時、假
    const btnUpdate = (data) => {

        const {
            id,
            code,
            minutes,
        } = data;

        getWorkLogRemind({ userId: user.userId });
        setCurrWorkLogType(code);
        setCurrBtnType(data.isLeave ? 'leave' : 'workLog');
        setCurrEvent('updateWorkLog');
        setCurrTask({ name: data.taskName });

        // 假與工時欄位名稱不同，暫且都先用 workLogType / workLogHours，之後有時間再調整 storage 欄位
        setStorage({
            id,
            workLogType: code,
            workLogHours: minutes,
        });
        showModal();

    };

    // 刪除工時、假
    const btnDelete = (id) => {

        Prompt('confirm', {
            title: <Fragment>刪除 <span className="small-text">(ID: {id})</span></Fragment>,
            mesg: '你確定要刪除嗎？',
            callback: () => {

                Service.aDayDeleteWorkLog({ id: +id })
                    .then((data) => {

                        // 當資料刪除且秀出成功後按下確定才讓畫面有刪除效果
                        Prompt('success', {
                            callback: () => {

                                setWorkLogHistoryList(workLogHistoryList.filter((obj) => obj.id !== id));
                                setSubItems(transferDataToCalendar(data.aMonth));

                            },
                        });

                    });

            },
        });

    };

    // ajax: 填工時
    const createWorkLog = (reqData) => Service.fillWorkLog(reqData)
        .then(({ lists }) => {

            hideModal();

            Prompt('success', {
                callback: () => {

                    const {
                        taskId,
                        workLogStartDate,
                        workLogEndDate,
                        workLogType,
                        workLogHours,
                    } = reqData;

                    // 登錄完所顯示的提示訊息
                    setCurrWorkLog({
                        ...currWorkLog,
                        [taskId]: {
                            workLogStartDate,
                            workLogEndDate,
                            workLogType,
                            workLogHours,
                        },
                    });

                    setSubItems(transferDataToCalendar(lists));

                },
            });

        });

    // ajax: 編輯工時與假別
    const updateWorkLogOrDayoff = (reqData) => Service.aDayUpdateWorkLog(reqData)
        .then(({ aMonth, aDay }) => {

            // 當資料刪除且秀出成功後按下確定才讓畫面有刪除效果
            Prompt('success', {
                callback: () => {

                    hideModal();

                    setWorkLogHistoryList(aDay);
                    setSubItems(transferDataToCalendar(aMonth));

                },
            });

        });

    // 送資料
    const handleReqData = (reqData) => {

        const { workLogType } = storage;

        if (currBtnType === 'fill') {

            reqData = {
                ...reqData,
                ...storage,
                workLogType: workLogType ? workLogType : '',
                workLogHours: transferHourToTime(reqData.hour, reqData.minute),
            };

        }
        else {

            reqData = {
                ...reqData,
                id: storage.id,
                type: workLogType ? workLogType : '',
                minutes: transferHourToTime(reqData.hour, reqData.minute),
            };

        }

        // 檢查所有欄位是否為空 vs 工時最小單位為 30 分鐘
        if (reqData.minutes < 30 || reqData.workLogHours < 30) {

            setShowError(true);
            return;

        }
        else setShowError(false);

        delete reqData.hour;
        delete reqData.minute;

        // 檢查是否為空
        const checking = Object.keys(reqData).some((obj) => reqData[obj] === '');

        setShowError(checking);

        // 阻擋欄位為空情境
        if (checking) return;

        if (currBtnType === 'fill') createWorkLog(reqData);
        else updateWorkLogOrDayoff(reqData);

    };

    // Grid Width
    const rightList = 6;
    const { Provider } = HomeStore;

    return (

        <Provider value={{
            showLoading,
            bulletinList,
            lazyRate,
            myTasks,
            dayOffs,
            subItems,
            workLogHistoryList,
            demoList,
        }}>

            <div className="home-page">
                <Row
                    gutter={20}
                    className="item"
                >
                    <Col span={24 - rightList}>
                        <Cards
                            className="cardItem cardItem-myWorkLog"
                            linkTo={bulletinPath}
                            title={
                                <Fragment>
                                    <FontIcon icon={faCalendarDays} />工時紀錄
                                </Fragment>
                            }
                            children={
                                showLoading.myWorkLog ? <Loading /> : (

                                    <MyWorkLog
                                        selectedDate={selectedDate}
                                        showDetailTip={showDetailTip}
                                        handleSelectedDate={handleSelectedDate}
                                        handleClickDate={handleClickDate}
                                        handleCancelDetailTip={handleCancelDetailTip}
                                        handleChangeMonth={handleChangeMonth}
                                        btnUpdate={btnUpdate}
                                        btnDelete={btnDelete}
                                    />

                                )
                            }
                        />
                    </Col>

                    <Col span={rightList}>
                        {
                            !!myTasks.length &&
                                <Cards
                                    className="cardItem cardItem-myTask"
                                    linkTo={`/myProject/${user.userId}?status=execute`}
                                    blank={false}
                                    title={
                                        <Fragment>
                                            <FontIcon icon={faClipboardList} />執行中的項目
                                        </Fragment>
                                    }
                                    children={
                                        showLoading.myTasks ? <Loading /> : (

                                            <MyTasks
                                                currWorkLog={currWorkLog}
                                                handleWorkLog={btnCreateWorkLog}
                                            />

                                        )
                                    }
                                />
                        }

                        <Cards
                            className={`cardItem cardItem-dayoff ${!!myTasks.length ? '' : 'only-one'}`}
                            linkTo={`/dayoff`}
                            title={
                                <Fragment>
                                    <FontIcon icon={faCalendarTimes} />今日休假人員
                                    <span className="small-text">(共{dayOffs.length}人)</span>
                                </Fragment>
                            }
                            children={
                                dayOffs.length ? <TodayDayoff /> : (showLoading.dayOffs ? <Loading /> : <EmptyData description="今日無休假人員" />)
                            }
                        />
                    </Col>
                </Row>
            </div>

            {
                visible &&
                    <LightboxForm
                        width={520}
                        title={
                            <Fragment>
                                {CONFIG[currBtnType]}
                                {currBtnType !== 'fill' && <span className="small-text">{`(ID: ${storage.id})`}</span>}
                            </Fragment>
                        }
                        visible={visible}
                        handleCancel={hideModal}
                    >
                        <form onSubmit={handleSubmit(handleReqData)}>

                            {
                                currBtnType === 'leave' ? (

                                    <LeaveTypeForm
                                        storage={storage}
                                        selectedDate={selectedDate}
                                        currWorkLogType={currWorkLogType}
                                        handleWorkLogTypeChange={handleWorkLogTypeChange}
                                        handleWorkLogHoursChange={handleWorkLogHoursChange}
                                    />

                                ) : (

                                    <WorkLogForm
                                        storage={storage}
                                        selectedDate={selectedDate}
                                        currTask={currTask}
                                        showError={showError}
                                        currBtnType={currBtnType}
                                        currWorkLogType={currWorkLogType}
                                        handleRangepicker={handleRangepicker}
                                        handleWorkLogTypeChange={handleWorkLogTypeChange}
                                        handleWorkLogHoursChange={handleWorkLogHoursChange}
                                        register={register}
                                        errors={errors}
                                        currEvent={currEvent}
                                    />

                                )
                            }

                            <div className="row row-btns">
                                <Buttons
                                    text="取消"
                                    type="secondary"
                                    handleClick={hideModal}
                                />
                                <Buttons
                                    text="送出"
                                    htmlType="submit"
                                />
                            </div>
                        </form>
                    </LightboxForm>
            }

        </Provider>

    );

};

export {
    Home as default,
    HomeStore,
};
