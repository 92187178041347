import React, { useState, useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Buttons from '../../components/Buttons';
import { FormRow } from '../../components/LightboxForm';
import { GlobalContext } from '../../context/global.state';
import { SkillContext } from '../../context/skill/skill.state';

let count = 1;

const SettingsForm = () => {

    // Context
    const {
        currEvent,
        formStorageDispatch,
        lightboxDispatch,
    } = useContext(GlobalContext);

    const { createFeature, createTool } = useContext(SkillContext);

    // State
    const [cloneTmpl, setCloneTmpl] = useState(['name0']);

    // React Hook Form
    const {
        handleSubmit,
        register,
        errors,
    } = useForm();

    // 隱藏 Modal
    const hideModal = () => {

        lightboxDispatch({ type: 'HIDE' });
        formStorageDispatch({ type: 'CLEAR' });

    };

    // 複製模板
    const handleClone = () => {

        setCloneTmpl([
            ...cloneTmpl,
            `name${count++}`,
        ]);

    };

    // 送資料
    // Betty: 之後多其他項目有可能再調整
    const handleReqData = (reqData) => {

        const lists = Object.values(reqData);

        if (currEvent === 'createFeature') createFeature({ lists });
        else createTool({ lists });

    };

    return (

        <form
            className="form-feature"
            onSubmit={handleSubmit(handleReqData)}
        >
            <p className="btn-clone">
                <span onClick={handleClone}>
                    <PlusOutlined />新增欄位
                </span>
            </p>

            <div className="itemsWrap">
                {
                    cloneTmpl.map((key) => (

                        <FormRow
                            key={key}
                            labelTitle="名稱"
                            required={true}
                            error={errors[key] && true}
                        >
                            <input
                                type="text"
                                name={key}
                                ref={register({ required: true })}
                            />
                        </FormRow>

                    ))
                }
            </div>

            <div className="row row-btns">
                <Buttons
                    text="取消"
                    type="secondary"
                    handleClick={hideModal}
                />
                <Buttons
                    text="送出"
                    htmlType="submit"
                />
            </div>
        </form>

    );

};

export default SettingsForm;

/**
 * Reference: https://codesandbox.io/s/react-hook-form-usefieldarray-ssugn?file=/src/index.js
 */
