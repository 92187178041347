// Member
const memberReducer = (state, { type, payload }) => {

    switch (type) {
        case 'MEMBER_LIST':
            return {
                ...state,
                memberList: payload,
            };

        case 'CREATE_MEMBER':
            return {
                ...state,
                memberList: [{ ...payload }, ...state.memberList],
            };

        // 所有員工 > 編輯
        case 'UPDATE_MEMBER':
            return {
                ...state,
                memberList: state.memberList.map((obj) => {

                    if (obj.userId === payload.userId) obj = payload.resData;
                    return obj;

                }),
            };

        // 個人資料 > 編輯
        case 'UPDATE_PROFILE':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...payload.resData,
                },
            };

        case 'MEMBER_LIST_SEARCH':
            return {
                ...state,
                searchResult: { ...payload },
            };

        case 'PROFILE':
            return {
                ...state,
                profile: payload,
            };

        case 'SALARY_LIST':
            return {
                ...state,
                salary: payload,
            };

        case 'CREATE_SALARY':
            return {
                ...state,
                salary: [{ ...payload }, ...state.salary],
            };

        case 'REMOVE_SALARY':
            return {
                ...state,
                salary: state.salary.filter((obj) => obj.salaryId !== payload),
            };

        case 'GROUP_HISTORY_LIST':
            return {
                ...state,
                groupHistory: payload,
            };

        case 'CREATE_GROUP_HISTORY':
            return {
                ...state,
                groupHistory: [{ ...payload }, ...state.groupHistory],
            };

        case 'REMOVE_GROUP_HISTORY':
            return {
                ...state,
                groupHistory: state.groupHistory.filter((obj) => obj.id !== payload),
            };

        default:
            return state;
    }

};

// Form Fields
const formStorageReducer = (state, { type, payload }) => {

    switch (type) {
        case 'COLLECT':
            return {
                formStorageData: payload,
            };

        case 'CLEAR':
            return {
                formStorageData: {},
            };

        default:
            return state;
    }

};

export {
    memberReducer,
    formStorageReducer,
};
