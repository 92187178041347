import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress } from 'antd';
import { faUserTie, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faBlackTie } from '@fortawesome/free-brands-svg-icons';

import Tables from '../../components/Tables';
import Tags from '../../components/Tags';
import FontIcon from '../../components/FontIcon';
import Search from './Search';

import { SkillContext } from '../../context/skill/skill.state';
import moonConst from '../../lib/moon.const';

const { scoreConfig } = moonConst;

// 標籤
const Label = ({ data: { id, name } }) => <span className="label">{name}</span>;

const MemberSkill = () => {

    // Context
    const {
        skillMemberList,
        searchResult,
    } = useContext(SkillContext);

    // 表格欄位
    const columns = [
         {
            title: '編號',
            dataIndex: 'userId',
            className: 'col-id',
            align: 'center',
            render: (userId) => (

                <Link
                    to={`/myProject/${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    {userId}
                </Link>

            ),
        },
        {
            title: '姓名 (英文)',
            dataIndex: 'nameChinese',
            className: 'col-name',
            render: (nameChinese, { userId, nameEnglish, isTechLeader, isCGLead }) => (

                <span className="name">
                    {
                        isTechLeader &&
                            <span className="member-status leader">
                                <FontIcon icon={faBlackTie} />
                            </span>
                    }
                    {
                        isCGLead &&
                            <span className="member-status manager">
                                <FontIcon icon={faUserTie} />
                            </span>
                    }
                    <Link
                        to={`/myProject/${userId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={(isTechLeader || isCGLead) ? '' : 'no-status'}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {nameChinese}
                    </Link>
                    {nameEnglish && <span className="small-text">({nameEnglish})</span>}
                </span>

            ),
        },
        {
            title: '特質',
            dataIndex: 'features',
            className: 'col-feature',
            render: (features) => features.map((obj) => (

                <Label
                    key={obj.id}
                    data={obj}
                />

            )),
        },
        {
            title: '擅長工具',
            dataIndex: 'tools',
            className: 'col-tool',
            render: (tools) => tools.map((obj) => (

                <Label
                    key={obj.id}
                    data={obj}
                />

            )),
        },
        {
            title: '技能分數',
            dataIndex: 'skills',
            render: (skills) => skills.map(({ id, areaColor, areaName, skillName, score }) => (

                <div
                    key={id}
                    className="personal-skill-row"
                >
                    <Tags
                        color={areaColor}
                        textColor="#000"
                        text={areaName}
                    />

                    <span>{skillName}</span>

                    <Progress
                        className={`score ${scoreConfig[score]?.name}-place`}
                        size="small"
                        strokeColor={score > 7 ? scoreConfig[score]?.color : null}
                        percent={score * 10}
                        format={(score) => `${score / 10}分`}
                    />

                    {
                        // 顯示金銀銅
                        (score > 7) &&
                            <span className={`crown ${scoreConfig[score]?.name}-place`}>
                                <FontIcon icon={faCrown} />
                            </span>
                    }
                </div>

            )),
        },
    ];

    return (

        <Row gutter={30}>
            <Col
                flex="0 0 300px"
                className="column column-left"
            >
                <Search />
            </Col>

            <Col flex="1">
                <Tables
                    className="member-skill-list"
                    columns={columns}
                    data={searchResult.isSearch ? searchResult.members : skillMemberList}
                    rowKey="userId"
                    showTitle={true}
                />
            </Col>
        </Row>

    );

};

export default MemberSkill;
