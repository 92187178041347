import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const Buttons = ({
    type,
    text,
    className,
    disabled,
    icon,
    handleClick,
    ...restProps
}) => (

    <Button
        className={`btn-${type} ${className && className}`}
        type={type}
        onClick={handleClick}
        {...(disabled && { disabled })}
        {...restProps}
    >
        {icon && icon}
        <span>{text}</span>
    </Button>

);

Buttons.defaultProps = {
    type: 'primary',
    text: 'Button',
    className: '',
    disabled: false,
};

Buttons.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    handleClick: PropTypes.func,
};

export default Buttons;
